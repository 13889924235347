import { Form, MarkerStyle, Record } from '3map-models';
import {
  GeoJSONSourceSpecification,
  Map,
  SymbolLayerSpecification,
} from 'mapbox-gl';
import { getIconId } from '../marker.service';

export function createRecordLayerId(datasetId: string): string {
  return `${datasetId}_records_layer`;
}

export function createRecordLayer(
  form: Form,
  records: Record[],
): { layer: SymbolLayerSpecification; source: GeoJSONSourceSpecification } {
  const source: GeoJSONSourceSpecification = {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: records.map((record) => {
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [record.longitude, record.latitude],
          },
          properties: {
            icon: getIconId(record, form),
            recordId: record.recordId,
            featureId: record.featureId,
          },
        };
      }),
    },
  };

  const layer: SymbolLayerSpecification = {
    id: createRecordLayerId(form.id),
    type: 'symbol',
    source: createRecordLayerId(form.id),
    layout: {
      'icon-allow-overlap': true,
      'icon-image': '{icon}',
      'icon-size': 0.15,
      'icon-rotate': {
        type: 'identity',
        property: 'rotation',
      },
    },
  };

  return { layer, source };
}

export function getMarkerStyleDict(form: Form): {
  [specificId: string]: MarkerStyle;
} {
  const markerStyleDict: { [specificId: string]: MarkerStyle } = {};
  for (const specific of form.specificList)
    markerStyleDict[specific.id] = specific.markerStyle;
  return markerStyleDict;
}

export function removeLayerAndSource(map: Map, layerId: string): void {
  if (map.getLayer(layerId)) {
    map.removeLayer(layerId);
    map.removeSource(layerId);
  }
}

export function getSelectedRecordLayerId(): {
  recordLayerId: string;
  circleLayerId: string;
} {
  const recordLayerId = 'recordLayerId';
  const circleLayerId = 'circleLayerId';
  return { recordLayerId, circleLayerId };
}
