import { Component } from '@angular/core';
import { ConfirmDialogService } from './confirm-dialog.service';
import { Observable } from 'rxjs';
import { ConfirmDialogData } from './confirm-dialog.data';

@Component({
  selector: 'app-confirm-dialog',
  template: `
    <div
      class="confirm-modal-close"
      (click)="backdropClick()"
      *ngIf="data$ | async as data"
    >
      <div class="modal-close-content">
        <div class="confirm-title">{{ data.title }}</div>
        <div class="body">{{ data.content }}</div>
        <div class="actions">
          <div class="btn-cancel" (click)="onCancel()">
            {{ data.cancelText }}
          </div>
          <div class="btn-ok" (click)="onConfirm()">{{ data.confirmText }}</div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .confirm-modal-close {
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 5;
      }

      .modal-close-content {
        background-color: #ffffff;
        border-radius: 5px;
        padding: 0 10px;
        width: 320px;
      }

      .confirm-title {
        font-weight: bold;
        margin: 10px 0;
      }

      .actions {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid var(--border);
        margin-top: 20px;
        height: 45px;
        padding: 5px 0;
        cursor: pointer;
      }

      .actions > div {
        flex: 1;
        font-weight: bold;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.8em;
      }

      .btn-ok {
        color: var(--primary-color);
        border-left: 1px solid var(--border);
      }
    `,
  ],
  standalone: false,
})
export class ConfirmDialogComponent {
  data$: Observable<ConfirmDialogData | null>;

  constructor(private confirmDialogService: ConfirmDialogService) {
    this.data$ = this.confirmDialogService.getDialogData();
  }

  /**
   * Called on `confirm` button, calls onConfirm() on service
   */
  onConfirm(): void {
    this.confirmDialogService.confirm();
  }

  /**
   * Called on `cancel` button, calls onCancel() on service
   */
  onCancel(): void {
    this.confirmDialogService.cancel();
  }

  /**
   * Any click outside the current dialog windows will close the
   * dialog (same behaviour of `cancel` action)
   */
  backdropClick(): void {
    this.onCancel();
  }
}
