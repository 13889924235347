import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ProjectUser } from '3map-models';
import { faPen } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-user-list-item',
  template: `
    <div class="user-list-item show-on-hover-toggle" *ngIf="user">
      <div class="left">
        <ui-role-badge [user]="user"></ui-role-badge>
      </div>

      <div class="center">
        <div class="username">{{ user.username }}</div>

        <div class="form-list">
          <div class="form-item" *ngFor="let formId of user.formList">
            {{ formId | formIdToName }}
          </div>
          <div class="no-forms" *ngIf="user.formList.length === 0">
            ( none )
          </div>
        </div>
      </div>

      <div class="right show-on-hover">
        <div class="actions">
          <core-double-confirm-inline
            (confirm)="removeUser.emit(user)"
          ></core-double-confirm-inline>
          <fa-icon
            class="edit"
            [icon]="iconEdit"
            (click)="editUser.emit(user)"
          />
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .user-list-item {
        display: flex;
        align-items: center;
        border: 1px solid var(--border);
        border-radius: var(--radius-1);
        padding: 0 var(--spacing-2);
        margin: var(--spacing-3) 0;
      }

      .user-list-item:hover {
        background-color: var(--hover);
      }

      .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 var(--spacing-2);
      }

      .center {
        flex: 1 1 auto;
      }

      .right {
        width: 100px;
        margin-right: var(--spacing-2);
      }

      .actions {
        cursor: pointer;
        display: flex !important;
        justify-content: right;
      }

      .right fa-icon {
        margin-left: var(--spacing-3);
      }

      .username {
        margin-top: var(--spacing-2);
        margin-left: var(--spacing-2);
        font-weight: var(--font-weight-3);
        user-select: text;
      }

      .form-list {
        display: flex;
        flex-wrap: wrap;
      }

      .form-item {
        border: 1px solid #dbdbdb;
        padding: var(--spacing-1) var(--spacing-2);
        margin: var(--spacing-2);
        border-radius: var(--radius-1);
        font-size: var(--font-size-1);
      }

      .no-forms {
        font-size: var(--font-size-1);
        color: var(--grey);
        margin: var(--spacing-2);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class UserListItemComponent {
  @Input() user?: ProjectUser;
  @Output() editUser = new EventEmitter<ProjectUser>();
  @Output() removeUser = new EventEmitter<ProjectUser>();
  iconEdit = faPen;
}
