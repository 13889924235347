import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Form, Record } from '3map-models';
import { MarkerService } from '../../../marker.service';

@Component({
  selector: 'app-record-icon',
  template: `
    <app-marker-icon
      [bitmap]="getRecordIcon()"
      [widthPx]="widthPx"
    ></app-marker-icon>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class RecordIconComponent {
  @Input() record: Record | undefined;
  @Input() form: Form | undefined;
  @Input() widthPx: number = 32;

  constructor(private markerService: MarkerService) {}

  getRecordIcon(): ImageBitmap | null {
    return this.form && this.record
      ? this.markerService.getRecordIcon(this.record, this.form)
      : null;
  }
}
