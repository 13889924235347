import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  Project,
  projectDecoder,
  Record,
  recordDecoder,
  ResRecordList,
  resRecordListDecoder,
  resUsernameListDecoder,
} from '3map-models';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { notNullOrUndefined } from '@trim-web-apps/core';
import { isArray } from 'chart.js/helpers';

export interface ApiResponse<T> {
  success: boolean;
  responsePayload: T;
}

export type AddProjectError = {
  errorText:
    | 'USER_NOT_IN_PROJECT'
    | 'RECORD_NOT_IN_FORM'
    | 'RECORD_NOT_IN_FORM_SPECIFIC'
    | 'RECORD_MISSING_REQUIRED_ANSWER'
    | 'RECORD_ANSWER_OF_WRONG_TYPE'
    | 'RECORD_ANSWER_NOT_IN_FORM_SPECIFIC';
  recordCount: number;
  specificId: string | null;
};

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private http: HttpClient,
    @Inject('API_URL') private API_URL: string,
  ) {}

  uploadProject(
    project: Project,
  ): Observable<ApiResponse<AddProjectError[] | null>> {
    return this.http.post(`${this.API_URL}/admin/addProject`, project).pipe(
      map(() => {
        return { success: true, responsePayload: null };
      }),
      catchError((error) => {
        const status = error.status;
        const errList = error.error['errList'];
        const responsePayload =
          status === 400 && isArray(errList)
            ? (errList as AddProjectError[])
            : [];
        return of({ success: false, responsePayload });
      }),
    );
  }

  deleteProject(projectName: string): Observable<boolean> {
    // return timer(3000).pipe(map(() => true));
    return this.http
      .delete(`${this.API_URL}/admin/deleteProject/${projectName}`)
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      );
  }

  uploadAdminStaticFile(
    file: Blob,
    fileName: string,
    projectName: string,
  ): Observable<boolean> {
    const url = `${this.API_URL}/static/uploadFilesAdmin/${projectName}`;
    const formData = new FormData();
    formData.append(fileName, file, fileName);

    return this.http.post(url, formData).pipe(
      map(() => true),
      catchError(() => of(false)),
    );
  }

  uploadProjectStaticFile(
    file: Blob,
    fileName: string,
  ): Observable<ApiResponse<string>> {
    // const due = Math.round(Math.random() * 10000);
    // return timer(due).pipe(
    //   map(() => {
    //     return {
    //       success: Math.round(Math.random() * 100) % 2 === 0,
    //       responsePayload: fileName,
    //     };
    //   })
    // );
    const url = `${this.API_URL}/static/uploadFilesProject`;
    const formData = new FormData();
    formData.append(fileName, file, fileName);
    return this.http.post(url, formData).pipe(
      map((response) => ({ success: true, responsePayload: fileName })),
      catchError(() => of({ success: false, responsePayload: fileName })),
    );
  }

  getMarkerBlob(imageName: string, projectName: string): Observable<Blob> {
    const url = `${this.API_URL}/static/${projectName}/admin/${imageName}`;
    return this.http.get(url, { responseType: 'blob' });
  }

  getDefaultMarkerBlob(): Observable<Blob> {
    return this.http.get('/assets/marker.png', { responseType: 'blob' });
  }

  fetchAdminStaticFile(
    filename: string,
    projectName: string,
  ): Observable<Blob> {
    const url = `${this.API_URL}/static/${projectName}/admin/${filename}`;
    return this.http.get(url, { responseType: 'blob' });
  }

  getStaticFileUrl(filename: string, projectName: string): string {
    return `${this.API_URL}/static/${projectName}/project/${filename}`;
  }

  fetchProjectStaticFile(
    filename: string,
    projectName: string,
  ): Observable<Blob> {
    const url = this.getStaticFileUrl(filename, projectName);
    return this.http.get(url, { responseType: 'blob' });
  }

  getRawProject(): Observable<any> {
    return this.http.get(this.API_URL + '/project/getProject');
  }

  getProject(): Observable<Project> {
    return this.getRawProject().pipe(
      map((response) => projectDecoder().runWithException(response)),
    );
  }

  getAdminProject(projectName: string): Observable<Project> {
    const url = `${this.API_URL}/admin/getProject/${projectName}`;
    return this.http
      .get(url)
      .pipe(map((response) => projectDecoder().runWithException(response)));
  }

  getRawRecordList(): Observable<any> {
    return this.http.get(`${this.API_URL}/project/getRecordList`);
  }

  getRecordList(project: Project): Observable<ResRecordList> {
    return this.getRawRecordList().pipe(
      map((response) =>
        resRecordListDecoder(project).runWithException(response),
      ),
    );
  }

  getRecordListOld(project: Project): Observable<Record[]> {
    return this.getRawRecordList().pipe(
      map((response) => {
        const recordList = (response as any)?.recordList || [];
        return recordList && Array.isArray(recordList)
          ? (recordList as Record[])
          : [];
      }),
      map((records) => {
        return records
          .map((record) => {
            const decoded = recordDecoder(project).run(record);
            if (decoded.ok) {
              return decoded.result;
            } else {
              console.log('Invalid record found!', record);
              return null;
            }
          })
          .filter(notNullOrUndefined);
      }),
      tap(() => console.log('RECORD LIST FETCHED')),
    );
  }

  uploadRecords(recordList: Record[]): Observable<ApiResponse<Record[]>> {
    const url = `${this.API_URL}/project/uploadRecordList`;
    const response: ApiResponse<Record[]> = {
      success: false,
      responsePayload: recordList,
    };
    if (recordList.length === 0) return of({ ...response, success: true });
    return this.http.post(url, { recordList }).pipe(
      map(() => ({ ...response, success: true })),
      catchError(() => of({ ...response, success: false })),
    );
  }

  deleteRecords(recordIdList: string[]): Observable<ApiResponse<string[]>> {
    const url = `${this.API_URL}/project/deleteRecordList`;
    const response: ApiResponse<string[]> = {
      success: false,
      responsePayload: recordIdList,
    };
    return this.http.delete(url, { body: { recordIdList } }).pipe(
      map(() => ({ ...response, success: true })),
      catchError(() => of({ ...response, success: false })),
    );
  }

  getUserList(username: string): Observable<string[]> {
    const url = `${this.API_URL}/admin/getUsernameList/${username}`;
    if (username.length === 0) return of([]);
    return this.http.get(url).pipe(
      map(
        (response) =>
          resUsernameListDecoder().runWithException(response).usernameList,
      ),
      catchError(() => of([])),
    );
  }
}
