import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from './environment';

export const StoreDevTools = [
  StoreDevtoolsModule.instrument({
    maxAge: 30, // Retains last 25 states
    logOnly: environment.production, // Restrict extension to log-only mode
    connectInZone: true,
  }),
];
