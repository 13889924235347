import { FieldType } from '3map-models';
import { Component, Input, OnInit } from '@angular/core';
import { faBars, faTrash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-field-base',
  template: `
    <div class="field-type-wrapper">
      <div class="field-type-header">
        <ng-content></ng-content>
        <div class="label">Question {{ question.type }}</div>
        <div class="fill-remaining-space"></div>
        <fa-icon class="icon-remove" [icon]="iconRemove"></fa-icon>
      </div>
      <div class="field-type-content">
        <ng-container [ngSwitch]="question.type">
          <app-field-list
            *ngSwitchCase="'LIST'"
            [question]="question"
          ></app-field-list>
          <app-field-number *ngSwitchCase="'NUMBER'"></app-field-number>
          <app-field-range *ngSwitchCase="'RANGE'"></app-field-range>
          <app-field-text *ngSwitchCase="'TEXT'"></app-field-text>
        </ng-container>
      </div>
    </div>
  `,
  styles: [
    `
      .field-type-wrapper {
        border: 1px solid #dbdbdb;
        margin: 20px 0;
        border-radius: 3px;
        background-color: #ffffff;
      }

      .field-type-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 35px;
        border-bottom: 1px solid #dbdbdb;
        background-color: #2196f3;
        color: #ffffff;
        padding: 0 10px;
      }

      .field-type-content {
        padding: 10px;
      }

      fa-icon {
        cursor: pointer;
      }

      .label {
        margin-left: 10px;
      }
    `,
  ],
  standalone: false,
})
export class FieldBaseComponent implements OnInit {
  @Input() question!: FieldType;
  iconRemove = faTrash;
  iconDrag = faBars;

  ngOnInit(): void {
    if (!this.question) {
      throw Error('Missing question (FieldType)');
    }
  }
}
