import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { SelectBaseComponent } from '../select/select-base.component';

export type DropdownItem<T> = {
  label: string;
  payload: T;
};

export type DropdownEvent<T> = {
  source: DropdownItem<T> | null;
  index: number | null;
};

@Component({
    selector: 'ui-dropdown',
    template: `
    <div #wrapper [ngStyle]="{ width: widthPx ? widthPx + 'px' : 'auto' }">
      <div
        #menuTrigger
        class="menu-trigger"
        [cdkMenuTriggerFor]="disabled ? null : menu"
        [class.opened-above]="menuOpenedDirection === 'above'"
        [class.opened-below]="menuOpenedDirection === 'below'"
        [class.disabled]="disabled"
        (cdkMenuOpened)="onCdkMenuOpened()"
        (cdkMenuClosed)="onCdkMenuClosed()"
      >
        <div class="menu-item text-ellipsis">
          {{ itemActive ? itemActive.label : noneOptionLabel || placeholder }}
        </div>
        <fa-icon [icon]="iconExpand" />
      </div>

      <ng-template #menu>
        <div
          class="menu-content menu-single"
          cdkMenu
          [class.opened-above]="menuOpenedDirection === 'above'"
          [class.opened-below]="menuOpenedDirection === 'below'"
        >
          <div class="menu-content-inner" [style.width.px]="menuWidth" #menuDiv>
            <div
              (click)="onItemClick(null, null)"
              *ngIf="noneOptionLabel"
              [class.disabled]="disabled"
              class="menu-item text-ellipsis"
            >
              {{ noneOptionLabel }}
            </div>
            <div
              *ngFor="let item of itemList; let index = index"
              (click)="onItemClick(item, index)"
              [class.selected]="item.label === itemActive?.label"
              [class.disabled]="disabled"
              class="menu-item"
              #myMenuItem
            >
              <div class="text-ellipsis">{{ item.label }}</div>
              <fa-icon
                *ngIf="item.label === itemActive?.label"
                [icon]="iconSelected"
              />
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
/**
 * See SelectBaseComponent for more details.
 */
export class DropdownComponent<T> extends SelectBaseComponent {
  @Input() itemList: DropdownItem<T>[] = [];
  @Input() itemActive?: DropdownItem<T> | null;
  @Input() noneOptionLabel?: string;
  @Input() placeholder?: string = 'Select one';
  @Output() itemSelected: EventEmitter<DropdownEvent<T>> = new EventEmitter();

  onItemClick(source: DropdownItem<T> | null, index: number | null): void {
    if (this.disabled) return;
    this.itemSelected.emit({ source, index });
    this.menuTrigger?.nativeElement.click();
  }
}
