import { Component, Input } from '@angular/core';
import {
  faCalendarDay,
  faCheck,
  faCompressAlt,
  faFont,
  faHashtag,
  faListUl,
  faQuestion,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-field-type-icon',
  template: `
    <div
      class="wrapper"
      [ngClass]="hasError ? 'error' : ''"
      [matTooltip]="tooltip"
    >
      <div [ngSwitch]="field">
        <fa-icon *ngSwitchCase="'NUMBER'" [icon]="iconNumber"></fa-icon>
        <fa-icon *ngSwitchCase="'TEXT'" [icon]="iconText"></fa-icon>
        <fa-icon *ngSwitchCase="'RANGE'" [icon]="iconRange"></fa-icon>
        <fa-icon *ngSwitchCase="'LIST'" [icon]="iconList"></fa-icon>
        <fa-icon *ngSwitchCase="'CHECK'" [icon]="iconCheck"></fa-icon>
        <fa-icon *ngSwitchCase="'INT'" [icon]="iconInt"></fa-icon>
        <fa-icon *ngSwitchCase="'DATE'" [icon]="iconDate"></fa-icon>
        <fa-icon *ngSwitchDefault [icon]="iconWtf"></fa-icon>
      </div>
    </div>
  `,
  styles: [
    `
      .wrapper {
        display: flex;
        align-items: center;
        font-size: 1em;
        padding: 0 10px;
        border-radius: 3px 0 0 5px;
        background-color: #dbdbdb;
        color: #fff;
        height: 35px;
      }

      .error {
        background-color: red;
      }
    `,
  ],
  standalone: false,
})
export class FieldTypeIconComponent {
  @Input() field:
    | 'NUMBER'
    | 'TEXT'
    | 'RANGE'
    | 'LIST'
    | 'CHECK'
    | 'INT'
    | 'DATE'
    | string
    | undefined;
  @Input() hasError: boolean | undefined;
  iconList = faListUl;
  iconNumber = faHashtag;
  iconText = faFont;
  iconRange = faCompressAlt;
  iconCheck = faCheck;
  iconInt = faHashtag;
  iconDate = faCalendarDay;
  iconWtf = faQuestion;

  get tooltip(): string {
    return this.field
      ? `Question ${this.field}`
      : `Mmmh I don't know this question type...`;
  }
}
