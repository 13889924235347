import { Component } from '@angular/core';

@Component({
  selector: 'app-auth',
  template: `
    <div class="left-panel">
      <div class="welcome">Welcome to 3Map!</div>
      <div class="earth"></div>
    </div>
    <div class="right-panel">
      <div class="router-wrapper">
        <router-outlet></router-outlet>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        background: -webkit-radial-gradient(#376888, #2d5772, #1c3545);
        background: radial-gradient(#376888, #2d5772, #1c3545);
      }

      .earth {
        width: 200px;
        height: 200px;
        background-image: url(https://raw.githubusercontent.com/cartalan/TierraGirando/master/img/mapa.png);
        background-size: cover;
        border-radius: 50%;
        margin: auto;
        box-shadow:
          -30px -20px 0 0 rgba(0, 0, 0, 0.15) inset,
          -70px -30px 0 0 rgba(0, 0, 0, 0.1) inset,
          5px 5px 0 0 rgba(255, 255, 255, 0.2) inset;
        -webkit-animation: rotationEarth 5s infinite linear;
        -moz-animation: rotationEarth 5s infinite linear;
        animation: rotationEarth 7s infinite linear;
      }

      @-webkit-keyframes rotationEarth {
        from {
          background-position: left center;
        }
        to {
          background-position: -396px center;
        }
      }

      @keyframes rotationEarth {
        from {
          background-position: left center;
        }
        to {
          background-position: -396px center;
        }
      }

      @media only screen and (max-width: 600px) {
        .left-panel {
          display: none !important;
        }

        .right-panel {
          margin: 0 10px;
        }
      }

      .left-panel {
        display: flex;
        justify-content: center;
        flex-direction: column;
        background-color: #fff;
      }

      .welcome {
        font-size: 2em;
        text-align: center;
        margin-top: 25px;
      }

      .left-panel,
      .right-panel {
        height: 550px;
        width: 400px;
      }

      .right-panel {
        border-left: 1px solid #dbdbdb;
        background-color: #fff;
        display: flex;
        flex-direction: column;
      }

      .router-wrapper {
        height: 100%;
        padding: 30px;
      }
    `,
  ],
  standalone: false,
})
export class AuthComponent {}
