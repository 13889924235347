import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Record } from '3map-models';
// @ts-ignore
import * as tz_lookup from 'tz-lookup';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { EditorMode } from '../../models/EditorMode';
import { RecordEditorMetadata } from '../../models/metadata-location.model';
import {
  dateUtcValidator,
  getTodayMidnightUTC,
  latitudeValidator,
  longitudeValidator,
} from '@trim-web-apps/core';
import moment from 'moment';

interface MetadataForm {
  latitude: FormControl<number>;
  longitude: FormControl<number>;
  altitude: FormControl<number>;
  datetimeUtc: FormControl<string>;
}

@Component({
  selector: 'app-record-editor-metadata',
  template: `
    <form [formGroup]="metadataForm" *ngIf="record">
      <div class="form-item">
        <div class="label">Latitude</div>
        <input
          type="number"
          formControlName="latitude"
          [class.disabled]="readOnlyPosition"
          [readOnly]="readOnlyPosition"
        />
        <div class="error" *ngIf="!metadataForm.controls.latitude.valid">
          Invalid latitude
        </div>
      </div>

      <div class="form-item">
        <div class="label">Longitude</div>
        <input
          type="number"
          formControlName="longitude"
          [class.disabled]="readOnlyPosition"
          [readOnly]="readOnlyPosition"
        />
        <div class="error" *ngIf="!metadataForm.controls.longitude.valid">
          Invalid longitude
        </div>
      </div>

      <div class="form-item">
        <div class="label">Altitude</div>
        <input
          type="number"
          formControlName="altitude"
          [class.disabled]="readOnlyPosition"
          [readOnly]="readOnlyPosition"
        />
        <div class="error" *ngIf="!metadataForm.controls.altitude.valid">
          Invalid altitude
        </div>
      </div>

      <div class="form-item">
        <div class="label">Datetime</div>
        <input
          type="date"
          formControlName="datetimeUtc"
          [class.disabled]="readOnlyDate"
          [readOnly]="readOnlyDate"
        />
        <div class="error" *ngIf="!metadataForm.controls.datetimeUtc.valid">
          Invalid date
        </div>
      </div>

      <div class="label">Zone: {{ record.zone }}</div>
    </form>
  `,
  styles: [
    `
      .form-item {
        margin-bottom: 10px;
      }

      .label {
        font-size: 0.9em;
      }

      input {
        width: 100%;
        margin-bottom: 5px;
        height: 42px;
      }

      .error {
        color: var(--error-color);
        font-size: var(--font-size-1);
        text-align: center;
      }

      .disabled {
        cursor: not-allowed;
        color: var(--border);
      }

      .disabled:focus {
        outline: none;
        border: 1px solid #dbdbdb;
        box-shadow: none;
      }
    `,
  ],
  standalone: false,
})
export class RecordEditorMetadataComponent implements OnInit, OnDestroy {
  @Input() record?: Record;
  @Input() isFeatureOwner?: boolean;
  @Input() editorMode?: EditorMode | null;
  @Output() metadataChange = new EventEmitter<RecordEditorMetadata>();
  metadataForm: FormGroup<MetadataForm>;
  readOnlyPosition?: boolean;
  readOnlyDate?: boolean;
  private formSub?: Subscription;

  constructor() {
    this.metadataForm = new FormGroup<MetadataForm>({
      latitude: new FormControl<number>(0, {
        nonNullable: true,
        validators: [latitudeValidator, Validators.required],
      }),
      longitude: new FormControl<number>(0, {
        nonNullable: true,
        validators: [longitudeValidator, Validators.required],
      }),
      altitude: new FormControl<number>(0, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      datetimeUtc: new FormControl<string>(
        getTodayMidnightUTC().toISOString().substring(0, 10),
        {
          nonNullable: true,
          validators: [dateUtcValidator, Validators.required],
        },
      ),
    });
  }

  ngOnInit(): void {
    if (!this.record || !this.editorMode) return;

    /**
     * Update MetadataForm controls with values from current edit Record
     */
    const { latitude, longitude, altitude } = this.record;
    const datetimeUtc = this.record.datetimeUtc.toISOString().substring(0, 10);
    this.metadataForm.patchValue({
      latitude,
      longitude,
      altitude,
      datetimeUtc,
    });

    /**
     * Position can be changed only on CREATE Record.
     * Datetime can be always changed (if owner and/or ADMIN/MOD)
     */
    if (this.editorMode !== 'CREATE') this.readOnlyPosition = true;
    if (!this.isFeatureOwner) this.readOnlyDate = true;

    /**
     * Listen on MetadataForm changes and emit new values when user update
     * any of input.
     */
    this.formSub = this.metadataForm.valueChanges
      .pipe(debounceTime(100))
      .subscribe((formValue) => {
        if (this.metadataForm.invalid) return;
        const { latitude, longitude, altitude, datetimeUtc } = formValue;
        if (
          latitude === undefined ||
          longitude === undefined ||
          altitude === undefined ||
          datetimeUtc === undefined
        )
          return;

        const time = moment.utc(new Date()).format('HH:mm:ss.SSS');
        const zone = tz_lookup(latitude, longitude);
        this.metadataChange.emit({
          latitude,
          longitude,
          altitude,
          zone,
          datetimeUtc: new Date(`${formValue.datetimeUtc}T${time}Z`),
        });
      });
  }

  ngOnDestroy(): void {
    this.formSub?.unsubscribe();
  }
}
