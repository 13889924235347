import { WeatherModelInitConfig, WeatherModelMetadata } from '../types';
import { WeatherModel } from '../types/weather.model';
import { WeatherLayer } from '../types/weather-layer.type';
import {
  RAINFALL_10DAY,
  RAINFALL_1DAY,
  RAINFALL_3DAY,
  RAINFALL_7DAY,
} from '..//weather-layers/weather.layers';
import {
  createTsListForecast,
  decodeForecastSpec,
  getBaseModelTemplate,
  getDefaultDateRange,
  removeUnwantedLayers,
} from '../utils';
import { ECMWF } from './ecmwf';

export const ECMWF_ACC: WeatherModelMetadata = {
  ...ECMWF,
  id: 'ECMWF_ACC',
  label: 'ECMWF Accumulation',
  category: 'FORECAST',
  subCategory: 'RAINFALL_ACCUMULATION',
};

export function createEcmwfAcc(
  serverSpec: unknown,
  config?: WeatherModelInitConfig,
): WeatherModel {
  const spec = decodeForecastSpec(serverSpec);
  const timestepList = createTsListForecast(spec.initimeList, 1);
  const defaultDateRange = getDefaultDateRange(timestepList, 0, 0);
  const timestepType = { type: 'UNIX', interval: 1 } as const;

  const ecmwfAccLayers: WeatherLayer[] = [
    {
      ...RAINFALL_1DAY,
      timestepType,
      timestepList,
      defaultDateRange,
      conversionFactor: 1000,
    },
    {
      ...RAINFALL_3DAY,
      timestepType,
      timestepList,
      defaultDateRange,
      conversionFactor: 1000,
    },
    {
      ...RAINFALL_7DAY,
      timestepType,
      timestepList,
      defaultDateRange,
      conversionFactor: 1000,
    },
    {
      ...RAINFALL_10DAY,
      timestepType,
      timestepList,
      defaultDateRange,
      conversionFactor: 1000,
    },
  ];

  const layers = removeUnwantedLayers(
    ecmwfAccLayers,
    config?.layerIdList ?? [],
  );

  if (layers.length === 0)
    throw new Error(`No layers available for ${spec.model} model`);

  return {
    ...getBaseModelTemplate(config),
    ...ECMWF_ACC,
    layers: layers,
    initime: layers[0].timestepList[0].initime,
    timestep: layers[0].defaultDateRange.from,
    hasChart: false,
    selectedLayerId: layers[0].id,
    dateRange: layers[0].defaultDateRange,
    interpolate: 'bicubicInterpolationAvg',
  };
}
