import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { EntityResource, ResourceService } from '@trim-web-apps/project-core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-resource-section-actions',
  template: ``,
  styles: [],
  standalone: false,
})
export class ResourceSectionActionsComponent {
  resources$: Observable<EntityResource[]>;

  constructor(
    private resourceService: ResourceService,
    private router: Router,
  ) {
    this.resources$ = this.resourceService.getEntityResourceList();
  }

  createResource(entity: EntityResource): void {
    this.resourceService.createResource(entity);
    this.router.navigate([`admin/resources/${entity.id}`]);
  }
}
