import {
  FieldType,
  Form,
  FormSpecific,
  MarkerStyle,
  Project,
  ProjectUser,
} from '3map-models';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  validatorArrayUniqueItems,
  validatorNotEmptyArray,
  validatorUniqueNameProperty,
} from '../validators/input.validators';

export function projectToFormGroup(project: Project): UntypedFormGroup {
  return new UntypedFormGroup({
    name: new UntypedFormControl(project.name, [Validators.required]),
    formList: new UntypedFormArray(
      project.formList.map((form) => formToFormGroup(form))
    ),
    userList: new UntypedFormArray(
      project.userList.map((user) => projectUserToFormGroup(user))
    ),
  });
}

export function formGroupToProject(formGroup: UntypedFormGroup): Project {
  return {
    name: formGroup.get('name')?.value,
    formList: formGroup.get('formList')?.value,
    userList: formGroup.get('userList')?.value,
    webHookList: [],
    resourceList: [],
  };
}

export function formToFormGroup(form: Form): UntypedFormGroup {
  // tslint:disable-next-line:no-any
  const validators: any[] = [
    validatorUniqueNameProperty,
    validatorNotEmptyArray,
  ];

  return new UntypedFormGroup({
    id: new UntypedFormControl(form.id, [Validators.required]),
    name: new UntypedFormControl(form.name, [
      Validators.required,
      Validators.minLength(1),
    ]),
    specificList: new UntypedFormArray(
      form.specificList.map((specific) => specificToFormGroup(specific)),
      validators
    ),
    allowImage: new UntypedFormControl(form.allowImage, [Validators.required]),
    allowAudio: new UntypedFormControl(form.allowAudio, [Validators.required]),
    allowVideo: new UntypedFormControl(form.allowVideo, [Validators.required]),
  });
}

export function formGroupToForm(formGroup: UntypedFormGroup): Form {
  // console.log((formGroup.get('specificList') as FormArray).controls);
  return {
    id: formGroup.get('id')?.value,
    name: formGroup.get('name')?.value,
    specificList: (
      formGroup.get('specificList') as UntypedFormArray
    )?.controls.map((c) => formGroupToSpecific(c as UntypedFormGroup)),
    allowImage: formGroup.get('allowImage')?.value,
    allowAudio: formGroup.get('allowAudio')?.value,
    allowVideo: formGroup.get('allowVideo')?.value,
    allowFile: formGroup.get('allowFile')?.value,
  };
}

export function specificToFormGroup(specific: FormSpecific): UntypedFormGroup {
  // tslint:disable-next-line:no-any
  const validators: any[] = [
    validatorUniqueNameProperty,
    validatorNotEmptyArray,
  ];

  return new UntypedFormGroup({
    id: new UntypedFormControl(specific.id, [Validators.required]),
    name: new UntypedFormControl(specific.name, [Validators.required]),
    questions: new UntypedFormArray(
      specific.questions.map((ft) => fieldTypeToFormGroup(ft)),
      validators
    ),
    markerStyle: markerStyleToFormGroup(specific.markerStyle),
  });
}

export function markerStyleToFormGroup(
  markerStyle: MarkerStyle
): UntypedFormGroup {
  return new UntypedFormGroup({
    image: new UntypedFormControl(markerStyle.image, [Validators.required]),
    imageNumber: new UntypedFormControl(markerStyle.imageNumber, [
      Validators.required,
    ]),
    iconFieldType: new UntypedFormControl(markerStyle.iconFieldType || null),
    iconNumberRanges: new UntypedFormArray(
      markerStyle.iconNumberRanges
        ? markerStyle.iconNumberRanges.map((n) => new UntypedFormControl(n))
        : []
    ),
  });
}

export function formGroupToMarkerStyle(
  formGroup: UntypedFormGroup
): MarkerStyle {
  let markerStyle: MarkerStyle = {
    image: formGroup.get('image')?.value,
    imageNumber: formGroup.get('imageNumber')?.value,
  };

  if (formGroup.get('iconFieldType')?.value !== null) {
    markerStyle = {
      ...markerStyle,
      iconFieldType: formGroup.get('iconFieldType')?.value,
    };
  }

  if (
    (formGroup.get('iconNumberRanges') as UntypedFormArray).controls.length > 0
  ) {
    markerStyle = {
      ...markerStyle,
      iconNumberRanges: (
        formGroup.get('iconNumberRanges') as UntypedFormArray
      ).controls.map((c) => c.value),
    };
  }

  return markerStyle;
}

export function formGroupToSpecific(formGroup: UntypedFormGroup): FormSpecific {
  const id = formGroup.get('id')?.value;
  const name = formGroup.get('name')?.value;
  const questions = (
    formGroup.get('questions') as UntypedFormArray
  ).controls.map((c) => formGroupToFieldType(c as UntypedFormGroup));
  let markerStyle = formGroupToMarkerStyle(
    formGroup.get('markerStyle') as UntypedFormGroup
  );

  if (markerStyle.iconFieldType) {
    const question = questions.find((q) => q.id === markerStyle.iconFieldType);
    if (question) {
      const options = question.options || [];
      markerStyle = {
        ...markerStyle,
        imageNumber: options.length + 1,
      };
    } else {
      markerStyle = { image: markerStyle.image, imageNumber: 1 };
    }
  }

  return { id, name, questions, markerStyle };
}

export function formArrayToFieldType(formArray: UntypedFormArray): FieldType[] {
  return formArray.controls.map((c) =>
    formGroupToFieldType(c as UntypedFormGroup)
  );
}

export function fieldTypeToFormGroup(fieldType: FieldType): UntypedFormGroup {
  // tslint:disable-next-line:no-any
  const validators: any[] =
    fieldType.type === 'LIST'
      ? [validatorArrayUniqueItems, validatorNotEmptyArray]
      : [validatorArrayUniqueItems];

  const options = fieldType.options
    ? new UntypedFormArray(
        fieldType.options.map(
          (opt) => new UntypedFormControl(opt, [Validators.required])
        ),
        validators
      )
    : new UntypedFormArray([]);

  const info = fieldType.info
    ? new UntypedFormControl(fieldType.info)
    : new UntypedFormControl('');

  return new UntypedFormGroup({
    id: new UntypedFormControl(fieldType.id, [Validators.required]),
    name: new UntypedFormControl(fieldType.name, [Validators.required]),
    type: new UntypedFormControl(fieldType.type, [Validators.required]),
    required: new UntypedFormControl(fieldType.required, [Validators.required]),
    immutable: new UntypedFormControl(fieldType.immutable, [
      Validators.required,
    ]),
    info,
    options,
  });
}

export function formGroupToFieldType(formGroup: UntypedFormGroup): FieldType {
  return {
    id: formGroup.get('id')?.value,
    name: formGroup.get('name')?.value,
    type: formGroup.get('type')?.value,
    required: formGroup.get('required')?.value,
    immutable: formGroup.get('immutable')?.value,
    options: formGroup.get('options')?.value,
    info: formGroup.get('info')?.value,
  };
}

export function projectUserToFormGroup(
  projectUser: ProjectUser
): UntypedFormGroup {
  return new UntypedFormGroup({
    username: new UntypedFormControl(projectUser.username),
    // formList: new FormArray(projectUser.formList.map(formId => new FormControl(formId))),
    formList: new UntypedFormControl(projectUser.formList),
    role: new UntypedFormControl(projectUser.role),
    preferences: new UntypedFormControl(projectUser.preferences),
  });
}

export function formGroupToProjectUser(
  formGroup: UntypedFormGroup
): ProjectUser {
  return {
    username: formGroup.get('username')?.value,
    // formList: (formGroup.get('formList') as FormArray).controls.map(c => c.value),
    formList: formGroup.get('formList')?.value,
    role: formGroup.get('role')?.value,
    preferences: formGroup.get('preferences')?.value,
  };
}
