import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { EditorTab } from '../../models/EditorTab';
import { EditorActions, EditorSelectors } from '../../+state';

@Component({
  selector: 'app-editor-tab-list',
  template: `
    <div class="tabs subtitle" *ngIf="activeTab$ | async as activeTab">
      <div
        class="tab"
        (click)="onTabClick(tab)"
        *ngFor="let tab of editorTabs$ | async"
        [class.active-tab]="
          activeTab.type === tab.type && activeTab.label === tab.label
        "
      >
        {{ tab.label }}
      </div>
    </div>
  `,
  styles: [
    `
      .tabs {
        display: flex;
        overflow-y: auto;
        width: 100%;
        margin: 10px 0;
        border-top: 1px solid var(--border);
        cursor: pointer;
      }

      .tab {
        flex: 0 0 auto;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
      }

      .active-tab {
        font-weight: 400;
        border-bottom: 1px solid var(--primary-color);
      }

      .tabs,
      .tab {
        transform: rotateX(180deg);
        -moz-transform: rotateX(180deg); /* Mozilla */
        -webkit-transform: rotateX(180deg); /* Safari and Chrome */
        -ms-transform: rotateX(180deg); /* IE 9+ */
        -o-transform: rotateX(180deg); /* Opera */
      }
    `,
  ],
  standalone: false,
})
export class EditorTabListComponent {
  activeTab$: Observable<EditorTab | null>;
  editorTabs$: Observable<EditorTab[]>;

  constructor(private store: Store) {
    this.activeTab$ = this.store.select(EditorSelectors.selectActiveTab());
    this.editorTabs$ = this.store.select(EditorSelectors.selectEditorTabs());
  }

  onTabClick(activeTab: EditorTab): void {
    this.store.dispatch(EditorActions.setActiveTab({ activeTab }));
  }
}
