import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import * as DeploySelectors from '../+state/deploy.selectors';
import * as DeployActions from '../+state/deploy.actions';
import { Store } from '@ngrx/store';
import { DeployStatus } from '../models/DeployStatus';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-deploy',
  template: `
    <ng-container *ngIf="view$ | async as view">
      <app-section-label sectionLabel="Deploy Project" />
      <div class="deploy-container">
        <ui-btn
          (btnClick)="onDeploy()"
          [disabled]="!view.deployAllowed"
          label="Deploy"
        />

        <app-deploy-stepper-item
          *ngFor="let step of view.stepperList"
          [label]="step.label"
          [deployStatus]="step.status"
        />

        <app-validation-errors />
      </div>
    </ng-container>
  `,
  styles: [
    `
      :host {
        height: 100vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
      }

      .deploy-container {
        padding: 0 30px 30px 30px;
        overflow: auto;
      }

      app-deploy-actions {
        margin-bottom: 30px;
      }
    `,
  ],
  standalone: false,
})
export class DeployComponent implements OnInit, OnDestroy {
  view$: Observable<{
    deployAllowed: boolean;
    stepperList: {
      status: DeployStatus;
      label: string;
    }[];
  }>;

  constructor(private store: Store) {
    this.view$ = this.store.select(DeploySelectors.selectDeployView()).pipe(
      map((view) => {
        return {
          deployAllowed: view.isProjectValid,
          stepperList: [
            {
              status: view.isProjectValid ? 'DEPLOYED' : 'ERROR',
              label: 'Project Valid',
            },
            {
              status: view.deployState.project,
              label: 'Project Deploy',
            },
            {
              status: view.deployState.markers,
              label: 'Markers Upload',
            },
            {
              status: view.deployState.resources,
              label: 'Resources Upload',
            },
          ],
        };
      }),
    );
  }

  ngOnInit(): void {
    this.store.dispatch(DeployActions.validateRecords());
  }

  ngOnDestroy(): void {
    this.store.dispatch(DeployActions.reset());
  }

  onDeploy(): void {
    this.store.dispatch(DeployActions.deployProject());
  }
}
