/**
 * Usage:
 * <ui-expansion-panel>
 *     <div header > This will be rendered in header selection of ng-content </div>
 *     <div content > This will be rendered in content selection of ng-content </div>
 * </ui-expansion-panel>
 */

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'ui-expansion-panel',
    template: `
    <div #panelWrapper class="panel-wrapper" [class.shadow]="shadow">
      <div
        class="header"
        [class.border]="addBorder"
        [ngStyle]="{
          'background-color': headerBgColor,
          'border-color': headerBgColor,
          color: headerTextColor,
        }"
        (click)="onClick()"
      >
        <div class="header-content">
          <ng-content select="[header]"></ng-content>
        </div>
        <div class="toggle">
          <fa-icon [icon]="iconExpand" *ngIf="collapsed"></fa-icon>
          <fa-icon [icon]="iconCollapse" *ngIf="!collapsed"></fa-icon>
        </div>
      </div>
      <div
        class="content"
        *ngIf="!collapsed"
        [style.width.px]="panelWidth"
        [class.no-padding]="noContentPadding"
        [class.border]="addBorder"
      >
        <ng-content select="[content]"></ng-content>
      </div>
    </div>
  `,
    styles: [
        `
      .panel-wrapper {
        width: 100%;
        background-color: var(--bg-color);
        overflow: hidden;
        border-radius: var(--radius-1);
      }

      .header {
        display: flex;
        align-items: center;
        cursor: pointer;
        background-color: var(--border);
        padding: var(--spacing-2) var(--spacing-3);
      }

      .header.border {
        border-width: 1px;
        border-style: solid;
      }

      .header-content {
        flex: 1 1 auto;
      }

      .content {
        padding: var(--spacing-3);
        overflow: hidden;
        border-bottom-right-radius: var(--radius-1);
        border-bottom-left-radius: var(--radius-1);
      }

      .content.border {
        border: 1px solid var(--border);
        border-top: none;
      }

      .no-padding {
        padding: 0 !important;
      }
    `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ExpansionPanelComponent implements AfterViewInit {
  @Input() collapsed?: boolean;
  @Input() shadow?: boolean;
  @Input() noContentPadding?: boolean;
  @Input() headerBgColor?: string;
  @Input() headerTextColor?: string;
  @Input() addBorder?: boolean;
  @ViewChild('panelWrapper') panelWrapper?: ElementRef;
  iconExpand = faChevronDown;
  iconCollapse = faChevronUp;
  panelWidth?: number;

  ngAfterViewInit(): void {
    this.setWidth();
  }

  onClick(): void {
    this.setWidth();
    this.collapsed = !this.collapsed;
  }

  setWidth(): void {
    // width has a strange behavior on open/close? comment this out to see the difference
    // this.panelWidth = this.panelWrapper?.nativeElement.offsetWidth;
    // console.log('this.panelWidth', this.panelWidth);
  }
}
