import { Form, FormSpecific } from '3map-models';
import { hash, removeDuplicate } from '@trim-web-apps/core';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
// import { TableMediaIconComponent } from '../table-media-icon/table-media-icon.component';
import * as moment from 'moment';
import { TableData } from '../+types/table-data.type';
import { TableMediaIconComponent } from '../table-media-icon/table-media-icon.component';

export function getColumns(
  tableData: TableData,
  iconCb: (recordId: string, mediaIndex: number) => void,
  iconFileCb: (fileName: string, projectName: string) => void,
): ColDef[] {
  const enabledSpecificIds = tableData.dataset.specificIds;
  const form = tableData.form;
  const cols = form.specificList
    .filter((specific) => enabledSpecificIds.includes(specific.id))
    .map((specific) => getSpecificColumns(form, specific, iconCb, iconFileCb))
    .reduce((acc, item) => [...acc, ...item], []);

  return removeDuplicate<ColDef>(cols, 'headerName');
}

export function getSpecificColumns(
  form: Form,
  formSpecific: FormSpecific,
  iconCb: (recordId: string, mediaIndex: number) => void,
  iconFileCb: (fileName: string, projectName: string) => void,
): ColDef[] {
  const baseColumns: ColDef[] = getBaseColumns();
  const mediaColumns: ColDef[] = getMediaCols(form, iconCb, iconFileCb);
  const dataColumns: ColDef[] = formSpecific.questions.map((fieldType) => {
    switch (fieldType.type) {
      case 'NUMBER':
        return {
          headerName: fieldType.name,
          field: hash(fieldType.name),
          filter: 'agNumberColumnFilter',
          width: 100,
          filterParams: {
            inRangeInclusive: true,
          },
        };
      case 'TEXT':
        return {
          headerName: fieldType.name,
          field: hash(fieldType.name),
          filter: 'agTextColumnFilter',
        };
      case 'RANGE':
        return {
          headerName: fieldType.name,
          field: hash(fieldType.name),
          filter: 'agNumberColumnFilter',
          width: 100,
        };
      case 'LIST':
        return {
          headerName: fieldType.name,
          field: hash(fieldType.name),
        };
      case 'CHECK':
        return {
          headerName: fieldType.name,
          field: hash(fieldType.name),
          width: 100,
        };
      case 'INT':
        return {
          headerName: fieldType.name,
          field: hash(fieldType.name),
          filter: 'agNumberColumnFilter',
          width: 100,
        };
      case 'DATE':
        return {
          headerName: fieldType.name,
          field: hash(fieldType.name),
          filter: 'agDateColumnFilter',
          width: 100,
          cellRenderer: dateCellRenderer,
          filterParams: { inRangeInclusive: true, comparator: dateComparator },
          // this is used for csv/xls export to determine the moment format
          headerClass: 'dateFieldType',
          comparator: dateComparator,
        };
    }
  });
  return [...baseColumns, ...dataColumns, ...mediaColumns];
}

export function getBaseColumns(): ColDef[] {
  return [
    {
      headerName: 'Record ID',
      field: 'recordId',
      // suppressFiltersToolPanel: true,
      filter: 'agTextColumnFilter',
      hide: true,
      width: 280,
    },
    {
      headerName: 'Feature ID',
      field: 'featureId',
      filter: 'agTextColumnFilter',
      hide: true,
      width: 280,
    },
    {
      headerName: 'Project',
      field: 'projectName',
      hide: true,
      width: 100,
    },
    {
      headerName: 'Forms Collection ID',
      field: 'formId',
      hide: true,
      width: 100,
    },
    {
      headerName: 'Form ID',
      field: 'formSpecificId',
      hide: true,
      width: 280,
    },
    {
      headerName: 'Form Name',
      field: 'formSpecificName',
      width: 150,
    },
    {
      headerName: 'Username',
      field: 'username',
      width: 150,
    },
    {
      headerName: 'Datetime UTC',
      field: 'datetimeUtc',
      filter: 'agDateColumnFilter',
      cellRenderer: datetimeCellRenderer,
      width: 150,
      hide: true,
      filterParams: { inRangeInclusive: true, comparator: dateComparator },
      comparator: dateComparator,
    },
    {
      headerName: 'Datetime Local',
      field: 'datetimeLocal',
      filter: 'agDateColumnFilter',
      cellRenderer: datetimeLocalCellRenderer,
      width: 150,
      hide: false,
      filterParams: { inRangeInclusive: true, comparator: dateComparator },
      comparator: dateComparator,
    },
    {
      headerName: 'Zone',
      field: 'zone',
      hide: true,
      width: 150,
    },
    {
      headerName: 'Latitude',
      field: 'latitude',
      hide: true,
      filter: 'agNumberColumnFilter',
      width: 100,
    },
    {
      headerName: 'Longitude',
      field: 'longitude',
      hide: true,
      filter: 'agNumberColumnFilter',
      width: 100,
    },
    {
      headerName: 'Altitude',
      field: 'altitude',
      hide: true,
      filter: 'agNumberColumnFilter',
      width: 100,
    },
  ];
}

export function getMediaCols(
  form: Form,
  iconCb: (recordId: string, mediaIndex: number) => void,
  iconFileCb: (fileName: string, projectName: string) => void,
): ColDef[] {
  if (
    !form.allowAudio &&
    !form.allowImage &&
    !form.allowVideo &&
    !form.allowFile
  )
    return [];

  return [
    {
      headerName: 'Media',
      field: '',
      hide: false,
      width: 100,
      cellRenderer: TableMediaIconComponent,
      cellRendererParams: { iconCb, iconFileCb, color: 'irishGreen' },
    },
  ];
}

export function compareIgnoreCase(a: any, b: any): number {
  if (a === null || a === undefined) return -1;
  if (b === null || b === undefined) return 1;

  if (typeof a === 'string' && typeof b === 'string')
    return a.toLowerCase().localeCompare(b.toLowerCase());

  if (a === b) return 0;

  return a < b ? -1 : 1;
}

export function datetimeCellRenderer(params: ICellRendererParams): string {
  return params.value
    ? moment(params.value).utc().format('DD MMM YYYY - HH:mm')
    : '';
}

export function datetimeLocalCellRenderer(params: ICellRendererParams): string {
  return params.value ? moment(params.value).format('DD MMM YYYY - HH:mm') : '';
}

export function dateCellRenderer(params: ICellRendererParams): string {
  return params.value
    ? moment(params.value).utc(true).format('DD MMM YYYY')
    : '';
}

export function dateComparator(
  dateFromFilter: Date,
  cellValue: moment.Moment,
): number {
  // dateFromFilter: Date object ad MIDNIGHT from ag-grid datepicker
  // cellValue: from fieldType
  const localDateString = moment(dateFromFilter).format('YYYY-MM-DD');
  const filterUtc = moment.utc(localDateString, 'YYYY-MM-DD').set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  const recordUtc = cellValue.clone().utc().set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  if (filterUtc < recordUtc) return 1;
  if (filterUtc > recordUtc) return -1;
  return 0;
}
