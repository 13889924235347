import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import {
  faFileArrowDown,
  faImage,
  faMicrophone,
  faVideo,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { Media } from '3map-models';

@Component({
    selector: 'map3-table-media-icon',
    template: `
    <div class="imageContainer">
      <fa-icon
        *ngIf="iconImage"
        [icon]="iconImage"
        (click)="openMediaGallery()"
      />
      <fa-icon
        *ngIf="iconVideo"
        [icon]="iconVideo"
        (click)="openMediaGallery()"
      />
      <fa-icon
        *ngIf="iconAudio"
        [icon]="iconAudio"
        (click)="openMediaGallery()"
      />
      <fa-icon *ngIf="iconFile" [icon]="iconFile" (click)="downloadFile()" />
    </div>
  `,
    styles: [
        `
      .imageContainer {
        text-align: center;
        font-size: 1.3em;
      }

      fa-icon {
        padding: 0 5px;
        cursor: pointer;
      }
    `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TableMediaIconComponent implements ICellRendererAngularComp {
  @Input() params!: ICellRendererParams;
  iconCb?: (recordId: string, mediaIndex: number) => void;
  iconFileCb?: (fileName: string, projectName: string) => void;

  iconVideo?: IconDefinition;
  iconAudio?: IconDefinition;
  iconImage?: IconDefinition;
  iconFile?: IconDefinition;

  private projectName?: string;
  private recordId?: string;
  private mediaList?: Media[];

  agInit(params: ICellRendererParams): void {
    this.projectName = params?.data?.projectName;
    this.mediaList = params?.data?.mediaList;
    this.recordId = params?.data?.recordId;

    this.iconCb = (params as any).iconCb;
    this.iconFileCb = (params as any).iconFileCb;

    if (!this.recordId) return;
    if (!this.mediaList || !Array.isArray(this.mediaList)) return;

    for (let i = 0; i < this.mediaList.length; i++) {
      const media = this.mediaList[i];
      if (media.type === 'AUDIO') this.iconAudio = faMicrophone;
      if (media.type === 'IMAGE') this.iconImage = faImage;
      if (media.type === 'VIDEO') this.iconVideo = faVideo;
      if (media.type === 'FILE') this.iconFile = faFileArrowDown;

      if (this.iconAudio && this.iconImage && this.iconVideo && this.iconFile)
        break;
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  openMediaGallery(): void {
    if (
      this.recordId &&
      this.mediaList &&
      this.mediaList.length > 0 &&
      this.iconCb
    )
      this.iconCb(this.recordId, 0);
  }

  downloadFile(): void {
    const media = this.mediaList?.find((media) => media.type === 'FILE');
    if (media && this.iconFileCb && this.projectName) {
      this.iconFileCb(media.fileName, this.projectName);
    }
  }
}
