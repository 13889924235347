import { Component, Inject } from '@angular/core';
import { QuestionsGroup } from '@trim-web-apps/project-core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-question-group-rename',
  template: `
    <div class="question-group-rename-wrapper">
      <div class="body">
        <p>Rename Group</p>
        <input
          type="text"
          placeholder="New Group name"
          [(ngModel)]="groupName"
        />
      </div>

      <div class="actions">
        <ui-btn (click)="dialogRef.close()" type="border" label="Back"></ui-btn>
        <div class="fill-remaining-space"></div>
        <ui-btn
          (btnClick)="onConfirm()"
          [disabled]="!isNameValid()"
          label="Confirm"
        ></ui-btn>
      </div>
    </div>
  `,
  styles: [
    `
      .question-group-rename-wrapper {
        background-color: var(--bg-color);
        border-radius: var(--radius-1);
      }

      .body,
      .actions {
        padding: 20px;
      }

      input {
        width: 100%;
      }

      .actions {
        display: flex;
        align-items: center;
        width: 350px;
      }
    `,
  ],
  standalone: false,
})
export class QuestionGroupRenameComponent {
  groupName: string;
  private groupNames: string[];

  constructor(
    public dialogRef: DialogRef<string | undefined>,
    @Inject(DIALOG_DATA)
    public data: {
      questionGroup: QuestionsGroup;
      questionGroupList: QuestionsGroup[];
    },
  ) {
    this.groupName = this.data.questionGroup.groupName;
    this.groupNames = this.data.questionGroupList.map((g) => g.groupName);
  }

  isNameValid(): boolean {
    return (
      this.groupName.length > 0 && !this.groupNames.includes(this.groupName)
    );
  }

  onConfirm(): void {
    if (!this.isNameValid()) return;
    this.dialogRef.close(this.groupName);
  }
}
