import { Action, createReducer, on } from '@ngrx/store';
import * as TableActions from './table.actions';
import * as ProjectActions from '../../project/+state/project.actions';

export const FEATURE_KEY = 'table';

export interface State {
  activeTable: string | null;
}

export const initialState: State = {
  activeTable: null,
};

const tableReducer = createReducer(
  initialState,
  on(ProjectActions.closeProject, () => ({
    ...initialState,
  })),
  on(
    TableActions.setActiveTable,
    (state, { tableId }): State => ({
      ...state,
      activeTable: tableId,
    }),
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return tableReducer(state, action);
}
