export * from './lib/types';
export * from './lib/weather-layers/weather.layers';
export * from './lib/weather-model-builder';

export { AARHUS } from './lib/weather-model-list/aarhus';
export { ALADIN } from './lib/weather-model-list/aladin';
export { AROME } from './lib/weather-model-list/arome';
export { ECMWF } from './lib/weather-model-list/ecmwf';
export { ECMWF_ACC } from './lib/weather-model-list/ecmwf_acc';
export { GFS } from './lib/weather-model-list/gfs';
export { GFS_ACC } from './lib/weather-model-list/gfs_acc';
export { GFS_HISTORY } from './lib/weather-model-list/gfs_history';
export { GPM } from './lib/weather-model-list/gpm2';
export { HARMONIE_AROME } from './lib/weather-model-list/harmonie_arome';
export { LAMMA_ARW_3 } from './lib/weather-model-list/lamma_arw_3';
export { MOLOCH } from './lib/weather-model-list/moloch';
export { NDVI } from './lib/weather-model-list/ndvi';
export { RFE } from './lib/weather-model-list/rfe';
export { SHEVENINGEN } from './lib/weather-model-list/scheveningen';
export { WRF_AARHUS_05 } from './lib/weather-model-list/wrf_aarhus_05';
export { WRF_AARHUS_25 } from './lib/weather-model-list/wrf_aarhus_25';
export { WRF_JAPAN_05 } from './lib/weather-model-list/wrf_japan_05';
export { WRF_JAPAN_25 } from './lib/weather-model-list/wrf_japan_25';
export { CHIRPS } from './lib/weather-model-list/chirps';

import { AARHUS } from './lib/weather-model-list/aarhus';
import { ALADIN } from './lib/weather-model-list/aladin';
import { AROME } from './lib/weather-model-list/arome';
import { ECMWF } from './lib/weather-model-list/ecmwf';
import { ECMWF_ACC } from './lib/weather-model-list/ecmwf_acc';
import { GFS } from './lib/weather-model-list/gfs';
import { GFS_ACC } from './lib/weather-model-list/gfs_acc';
import { GFS_HISTORY } from './lib/weather-model-list/gfs_history';
import { GPM } from './lib/weather-model-list/gpm2';
import { HARMONIE_AROME } from './lib/weather-model-list/harmonie_arome';
import { LAMMA_ARW_3 } from './lib/weather-model-list/lamma_arw_3';
import { MOLOCH } from './lib/weather-model-list/moloch';
import { NDVI } from './lib/weather-model-list/ndvi';
import { RFE } from './lib/weather-model-list/rfe';
import { SHEVENINGEN } from './lib/weather-model-list/scheveningen';
import { WRF_AARHUS_05 } from './lib/weather-model-list/wrf_aarhus_05';
import { WRF_AARHUS_25 } from './lib/weather-model-list/wrf_aarhus_25';
// import { WRF_JAPAN_05 } from './lib/weather-model-list/wrf_japan_05';
// import { WRF_JAPAN_25 } from './lib/weather-model-list/wrf_japan_25';
import { CPC } from './lib/weather-model-list/cpc';
import { AROME2 } from './lib/weather-model-list/arome2';
import { OPEN_WRF_BALEARES } from './lib/weather-model-list/open_wrf_baleares';
import { OPEN_WRF_LIGURIAN } from './lib/weather-model-list/open_wrf_ligurian';
import { CHIRPS } from './lib/weather-model-list/chirps';

export const FULL_MODEL_LIST = [
  AARHUS,
  ALADIN,
  AROME,
  AROME2,
  ECMWF,
  ECMWF_ACC,
  GFS,
  GFS_ACC,
  GFS_HISTORY,
  GPM,
  HARMONIE_AROME,
  LAMMA_ARW_3,
  MOLOCH,
  NDVI,
  RFE,
  SHEVENINGEN,
  WRF_AARHUS_05,
  WRF_AARHUS_25,
  // WRF_JAPAN_05,
  // WRF_JAPAN_25,
  CPC,
  OPEN_WRF_BALEARES,
  OPEN_WRF_LIGURIAN,
  CHIRPS,
];
