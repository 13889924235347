import { createReducer, on } from '@ngrx/store';
import { FeatureHistoryActions } from '../../feature-history/+state';
import * as MediaGalleryActions from './media-gallery.actions';
import { TableActions } from '../../table/+state';
import { ProjectActions } from '../../project/+state';

export const featureKey = 'media-gallery';

export type State = {
  recordId: string | null;
  mediaIndex: number;
};

const initial: State = {
  recordId: null,
  mediaIndex: -1,
};

export const reducer = createReducer(
  initial,
  on(
    ProjectActions.closeProject,
    MediaGalleryActions.closeGallery,
    () => initial,
  ),
  on(
    FeatureHistoryActions.setGalleryData,
    TableActions.setGalleryData,
    (state, { recordId, mediaIndex }): State => {
      return { ...state, recordId, mediaIndex };
    },
  ),
);
