import { Component, inject, signal } from '@angular/core';
import { getDefaultRawStyle } from '@trim-web-apps/project-core';
import { faImage, faVectorSquare } from '@fortawesome/free-solid-svg-icons';
import * as uuid from 'uuid';
import { Store } from '@ngrx/store';
import { Resource } from '3map-models';
import { createResource } from '../+state/resource.actions';
import { DialogRef } from '@angular/cdk/dialog';
import { isResourceFileOverLimit } from '../utils';

@Component({
  selector: 'app-resource-create]',
  template: `
    <div class="create-resource-wrapper">
      <div class="section-title">Resource Type</div>
      <div class="actions">
        <ui-btn [icon]="iconGeo" (click)="geo.click()" label="GeoJSON" />
        <ui-btn [icon]="iconImg" (click)="image.click()" label="Image" />
        <input #geo type="file" (change)="onFileSelected(geo, 'geojson')" />
        <input #image type="file" (change)="onFileSelected(image, 'image')" />
      </div>
      <div class="image-too-large" *ngIf="fileTooLarge()">
        File too large. Max size is 15MB.
      </div>
    </div>
  `,
  styles: [
    `
      .create-resource-wrapper {
        background-color: var(--bg-color);
        padding: var(--spacing-3);
        border-radius: var(--radius-1);
      }

      .section-title {
        font-size: var(--font-size-3);
        margin-bottom: var(--spacing-3);
        color: var(--primary-color);
      }

      .actions {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: var(--spacing-3);
      }

      input {
        display: none;
      }

      .image-too-large {
        color: var(--error-color);
        text-align: center;
        margin-top: var(--spacing-3);
      }
    `,
  ],
  standalone: false,
})
export class ResourceCreateComponent {
  private store = inject(Store);
  private dialogRef = inject(DialogRef);
  iconImg = faImage;
  iconGeo = faVectorSquare;
  fileTooLarge = signal<boolean>(false);

  onFileSelected(input: HTMLInputElement, type: 'geojson' | 'image'): void {
    const file = input.files?.item(0);
    if (!file) return;
    input.value = '';
    if (isResourceFileOverLimit(file)) return this.fileTooLarge.set(true);
    this.fileTooLarge.set(false);
    if (type === 'geojson') this.createGeoJsonResource(file);
    if (type === 'image') this.createImageResource(file);
    this.dialogRef.close();
  }

  private createImageResource(file: File): void {
    const { name, ext } = splitFilename(file.name);
    const resource: Resource = {
      type: 'IMAGE',
      name: name,
      filename: `${uuid.v4()}.${ext}`,
    };
    this.store.dispatch(createResource({ resource, file }));
  }

  private createGeoJsonResource(file: File): void {
    const { name } = splitFilename(file.name);
    const resource: Resource = {
      type: 'GEOJSON',
      name: name,
      filename: `${uuid.v4()}.geojson`,
      style: getDefaultRawStyle(),
    };
    this.store.dispatch(createResource({ resource, file }));
  }
}

function splitFilename(filename: string): { name: string; ext: string } {
  const name = filename.split('.')[0] || '';
  const ext = filename.split('.').pop() || '';
  return { name, ext };
}
