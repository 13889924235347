import { Component, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AdminActions, AdminSelectors } from './+state';

@Component({
  selector: 'app-admin',
  template: `
    <ng-container *ngIf="projectInit$ | async as projectInit">
      <div class="admin-container" *ngIf="projectInit === 'SUCCESS'">
        <app-sidenav />
        <div class="admin-content">
          <router-outlet />
        </div>
      </div>

      <div class="loading" *ngIf="projectInit === 'PENDING'">
        Opening Project...
      </div>
    </ng-container>

    <app-marker-canvas></app-marker-canvas>

    <app-deploy-alert
      [isModified]="(projectModified$ | async) ?? false"
      [isProjectValid]="(projectValid$ | async) ?? false"
      routerLink="deploy"
    />
  `,
  styles: [
    `
      .admin-container {
        display: grid;
        grid-template-columns: 300px 1fr;
        height: 100vh;
        overflow: hidden;
      }

      app-section-label {
        display: block;
      }

      .admin-content {
        display: block;
        min-width: 100%;
        max-width: 100%;
      }

      .loading {
        height: 100dvh;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    `,
  ],
  standalone: false,
})
export class AdminComponent implements OnDestroy {
  projectInit$: Observable<'INITIAL' | 'PENDING' | 'SUCCESS' | 'ERROR'>;
  projectModified$: Observable<boolean>;
  projectValid$: Observable<boolean>;

  constructor(private store: Store) {
    this.projectInit$ = this.store.select(AdminSelectors.selectProjectInit());
    this.projectModified$ = this.store.select(AdminSelectors.selectModified());
    this.projectValid$ = this.store.select(
      AdminSelectors.selectIsProjectValid(),
    );
    this.store.dispatch(AdminActions.initProject());
  }

  ngOnDestroy(): void {
    this.store.dispatch(AdminActions.close());
  }
}
