import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-section-label',
  template: `
    <div class="section-label-wrapper">
      <div class="label">
        <ng-container *ngIf="backRoute">
          <div class="back" routerLink="/admin/{{ backRoute }}">
            <fa-icon [icon]="iconBack"></fa-icon>
            {{ backRoute | uppercase }}
          </div>
        </ng-container>
        <div class="label">{{ sectionLabel }}</div>
      </div>

      <div class="section-actions">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styles: [
    `
      .section-label-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 25px 30px 0 30px;
      }

      .section-label-wrapper > .label {
        color: var(--primary-color);
        font-size: 1.5em;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        margin-bottom: 20px;
      }

      .back {
        font-size: 0.8em;
        margin-right: 20px;
        cursor: pointer;
        border-right: 1px solid var(--primary-color);
        padding-right: 20px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SectionLabelComponent {
  @Input() sectionLabel?: string;
  @Input() backRoute?: string;
  iconBack = faChevronLeft;
}
