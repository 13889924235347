import { Project } from '3map-models';
import { Action, createReducer, on } from '@ngrx/store';
import {
  close,
  initProject,
  initProjectError,
  initProjectSuccess,
  setModified,
} from './admin.actions';
import {
  FormActions,
  MarkerStyleActions,
  SpecificActions,
} from '../form/+state';
import { UserActions } from '../user/+state';
import { WebHookActions } from '../webhooks/+state';
import { LinkActions } from '../link/+state';
import { ResourceActions } from '../resource/+state';

export const ADMIN_FEATURE_KEY = 'admin';

export type State = {
  projectInit: 'INITIAL' | 'PENDING' | 'SUCCESS' | 'ERROR';
  projectOrigin: Project | null;
  modified: boolean;
};

export const initialState: State = {
  projectInit: 'INITIAL',
  projectOrigin: null,
  modified: false,
};

export const adminReducer = createReducer(
  initialState,
  on(close, (): State => ({ ...initialState })),
  on(initProject, (): State => ({ ...initialState, projectInit: 'PENDING' })),
  on(
    initProjectSuccess,
    (_, { project }): State => ({
      ...initialState,
      projectInit: 'SUCCESS',
      projectOrigin: project,
    }),
  ),
  on(initProjectError, (state): State => ({ ...state, projectInit: 'ERROR' })),
  on(setModified, (state, { modified }): State => ({ ...state, modified })),
  on(
    FormActions.createForm,
    FormActions.updateForm,
    FormActions.removeForm,
    SpecificActions.createSpecific,
    SpecificActions.onDuplicateSpecific,
    SpecificActions.duplicateSpecific,
    SpecificActions.removeSpecific,
    SpecificActions.setSpecificName,
    SpecificActions.upsertQuestions,
    SpecificActions.upsertQuestionGroup,
    SpecificActions.removeQuestion,
    SpecificActions.moveQuestionGroup,
    SpecificActions.addQuestionGroup,
    SpecificActions.removeQuestionGroup,
    SpecificActions.removeAllQuestionGroup,
    SpecificActions.renameQuestionGroup,
    SpecificActions.setLogicList,
    MarkerStyleActions.setMarkerStyleQuestion,
    MarkerStyleActions.setImageBase64List,
    MarkerStyleActions.setImageBase64,
    ResourceActions.createResource,
    ResourceActions.removeResource,
    ResourceActions.setResourceBbox,
    ResourceActions.updateResourceFile,
    ResourceActions.setResourceName,
    ResourceActions.setResourceStyle,
    UserActions.setUsers,
    UserActions.removeUsers,
    WebHookActions.upsertWebHook,
    WebHookActions.removeWebHook,
    LinkActions.upsertLink,
    LinkActions.removeLink,
    (state): State => ({ ...state, modified: true }),
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return adminReducer(state, action);
}
