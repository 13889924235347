import { Component, Inject } from '@angular/core';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { ModelPickerMenuData } from './weather-model-picker.component';
import { WeatherModel } from '@trim-web-apps/weather-models';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { fromEvent, take } from 'rxjs';

@Component({
    selector: 'weather-core-model-picker-mobile',
    template: `
    <weather-core-dialog-mobile title="Select Model" (closeDialog)="close()">
      <div class="content" *ngIf="data">
        @for (cat of data | keyvalue; track cat.key) {
          <div class="category">
            {{ $any(cat.key) | weatherCategory }}
          </div>
          @for (subCat of cat.value | keyvalue; track subCat.key) {
            <weather-core-model-picker-item-mobile
              [label]="$any(subCat.key) | weatherSubCategory"
              [modelList]="$any(subCat.value)"
              (modelSelect)="close($event)"
            />
          }
        }
      </div>
    </weather-core-dialog-mobile>
  `,
    styles: [
        `
      .content {
        padding: var(--spacing-2);
        display: flex;
        flex-direction: column;
        gap: var(--spacing-3);
      }

      .category {
        margin: var(--spacing-2) 0;
        color: var(--primary-color);
        font-size: var(--font-size-3);
        text-align: center;
        font-weight: var(--font-weight-3);
        margin-top: var(--spacing-3);
      }
    `,
    ],
    standalone: false
})
export class WeatherModelPickerMobileComponent {
  iconBack = faArrowLeft;

  constructor(
    private dialogRef: DialogRef<WeatherModel>,
    @Inject(DIALOG_DATA) public data?: ModelPickerMenuData,
  ) {
    history.pushState(null, '');
    fromEvent(window, 'popstate')
      .pipe(take(1))
      .subscribe(() => this.dialogRef.close());
  }

  close(model?: WeatherModel): void {
    this.dialogRef.close(model);
  }
}
