import { Component, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { fromEvent, Observable, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { DropdownEvent, DropdownItem } from '@trim-web-apps/map3-ui';
import { WeatherService } from '../../services/weather.service';
import { setUtcOffset } from '../../+state/weather.actions';

@Component({
    selector: 'weather-core-settings-mobile',
    template: `
    <weather-core-dialog-mobile title="Settings" (closeDialog)="close()">
      <div class="item">
        <div class="label">UTC offset</div>
        <div class="menu">
          <ui-dropdown
            [itemList]="utcOffsetList"
            [itemActive]="utcOffset$ | async"
            (itemSelected)="onUtcOffsetChange($event)"
          />
        </div>
      </div>

      <weather-core-settings
        *ngIf="data"
        [weatherModelId]="data"
        (closeSettings)="close()"
      />
    </weather-core-dialog-mobile>
  `,
    styles: [
        `
      .label {
        color: var(--primary-color);
        font-weight: var(--font-weight-3);
        margin-bottom: var(--spacing-2);
        font-size: var(--font-size-2);
      }
    `,
    ],
    standalone: false
})
export class SettingsMobileComponent {
  utcOffsetList: DropdownItem<string>[];
  utcOffset$: Observable<DropdownItem<string>>;

  constructor(
    private store: Store,
    private weatherService: WeatherService,
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data?: string,
  ) {
    console.log(this.data);
    history.pushState(null, '');
    fromEvent(window, 'popstate')
      .pipe(take(1))
      .subscribe(() => this.dialogRef.close());

    this.utcOffsetList = this.weatherService
      .getUtcOffsetList()
      .map((offset) => {
        return {
          label: this.utcOffsetToString(offset),
          payload: offset,
        };
      });

    this.utcOffset$ = this.weatherService.getUtcOffset().pipe(
      map((offset) => {
        return {
          label: this.utcOffsetToString(offset),
          payload: offset,
        };
      }),
    );
  }

  onUtcOffsetChange(evt: DropdownEvent<string>): void {
    const utcOffset = evt.source ? evt.source.payload : null;
    this.store.dispatch(setUtcOffset({ utcOffset }));
  }

  utcOffsetToString = (offset: string | null) =>
    offset === null ? 'Auto' : offset;

  close(): void {
    this.dialogRef.close();
  }
}
