import { Component, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { MapResource, MapResourceSelectors } from '../../+state';
import { MapResourceService } from '../../+state/map-resource.service';
import { Map } from 'mapbox-gl';
import { MapSelectors } from '../../../map/+state';

@Component({
  selector: 'app-map-resource-list[map]',
  template: ``,
  standalone: false,
})
export class MapResourceListComponent implements OnDestroy {
  @Input() map?: Map;
  private sub: Subscription | undefined;

  constructor(
    private store: Store,
    private mapResourceService: MapResourceService,
  ) {
    this.sub = combineLatest([
      this.store.select(MapResourceSelectors.selectMapResourceList()),
      this.store.select(MapSelectors.selectMapStyleId()),
    ]).subscribe(([resList]) => {
      this.onResourceListChange(resList);
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  onResourceListChange(resourceList: MapResource[]): void {
    for (const res of resourceList) {
      if (res.type === 'GEOJSON') this.addGeoJsonResource(res);
      if (res.type === 'IMAGE') this.addImageResource(res);
    }
  }

  /**
   'type' property for mapbox.AnyLayer is derived from style
   Examples:
   style: { 'circle-radius': 5 }   => 'circle'
   style: { 'line-color': 'red' }  => 'line'
   style: { 'fill-opacity': 0.5 }  => 'fill'

   Available:
   "symbol" | "circle" | "background" | "fill-extrusion" | "fill" | "heatmap" | "hillshade" | "line" | "raster" | "custom"'

   If style is empty, defaults to 'circle'
   */
  private addGeoJsonResource(resource: MapResource): void {
    const style = resource.style || {};
    const geoType = Object.keys(style)[0].split('-')[0] || 'circle';
    const sourceName = resource.filename + '__source';
    const geoJsonLayerId = resource.filename + '__json';
    const namesLayerId = resource.filename + '__names';
    const data = this.mapResourceService.getGeojson(resource);

    if (resource.status === 'DISABLED' || resource.status === 'ERROR') {
      if (this.map?.getLayer(geoJsonLayerId))
        this.map.removeLayer(geoJsonLayerId);
      if (this.map?.getLayer(namesLayerId)) this.map.removeLayer(namesLayerId);
      if (this.map?.getSource(sourceName)) this.map.removeSource(sourceName);
    }

    if (resource.status === 'ENABLED') {
      const geoJsonLayer = this.map?.getLayer(geoJsonLayerId) || null;
      const namesLayer = this.map?.getLayer(namesLayerId) || null;

      if (geoJsonLayer && namesLayer) return;
      if (!data) return;

      const firstRecordLayer = this.map
        ?.getStyle()
        ?.layers.find((l) => l.id.includes('_records_layer'));

      this.map
        ?.addSource(sourceName, {
          type: 'geojson',
          data: JSON.parse(data),
        })
        .addLayer(
          {
            id: geoJsonLayerId,
            source: sourceName,
            type: geoType as any,
            paint: style,
          },
          firstRecordLayer?.id,
        )
        .addLayer(
          {
            id: namesLayerId,
            source: sourceName,
            type: 'symbol',
            layout: {
              'text-field': '{name}',
              'text-offset': [0, -1.0],
              'text-size': 14,
            },
          },
          firstRecordLayer?.id,
        );
    }
  }

  private addImageResource(resource: MapResource): void {
    const style = resource.style;
    const opacityValue = style?.['raster-opacity'];
    const opacity = opacityValue !== undefined ? opacityValue : 1;
    const sourceName = resource.filename + '__source';
    const imageLayerId = resource.filename + '__image';
    const imageLayer = this.map?.getLayer(imageLayerId) || null;

    if (resource.status === 'DISABLED' || resource.status === 'ERROR') {
      if (this.map?.getLayer(imageLayerId)) this.map.removeLayer(imageLayerId);
      if (this.map?.getSource(sourceName)) this.map.removeSource(sourceName);
    }

    if (resource.status === 'ENABLED') {
      const base64 = this.mapResourceService.getImageBase64(resource);
      if (!base64) return;
      if (imageLayer) return;

      const firstRecordLayer = this.map
        ?.getStyle()
        ?.layers.find((l) => l.id.includes('_records_layer'));

      this.map
        ?.addSource(sourceName, {
          type: 'image',
          url: base64,
          coordinates: resource.boundingBox as any,
        })
        .addLayer(
          {
            id: imageLayerId,
            source: sourceName,
            type: 'raster',
            paint: { 'raster-opacity': opacity },
          },
          firstRecordLayer?.id,
        );
    }
  }
}
