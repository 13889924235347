import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-create-project-name',
  template: `
    <div class="create-project-wrapper">
      <div class="info">New Project name must be unique and without space</div>
      <input
        type="text"
        [formControl]="projectName"
        (ngModelChange)="onProjectNameChange($event)"
        placeholder="New Project name"
      />

      <ui-btn
        [fullWidth]="true"
        (btnClick)="onCreateProject()"
        label="Create"
      />
      <ng-template #disabledBtn>
        <!-- what?? -->
        <ui-btn label="Create" [disabled]="true" />
        <!-- <button class="btn-success btn-disabled" disabled>Create</button> -->
      </ng-template>

      <div class="error-label" *ngIf="uniqueName === false">
        Project name already exists
      </div>
    </div>
  `,
  styles: [
    `
      .create-project-wrapper {
        display: flex;
        flex-direction: column;
        padding: var(--spacing-3);
        gap: var(--spacing-3);
      }

      .error-label {
        text-align: center;
      }
    `,
  ],
  standalone: false,
})
export class CreateProjectNameComponent {
  @Input() collaborationList:
    | {
        projectName: string;
        role: 'ADMIN' | 'USER' | 'MOD';
      }[]
    | null
    | undefined;
  @Output() projectNameCreated: EventEmitter<string>;
  projectName: UntypedFormControl;
  uniqueName: boolean | undefined;

  constructor() {
    this.projectNameCreated = new EventEmitter<string>();
    this.projectName = new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(1),
    ]);
  }

  onProjectNameChange(newProjectName: string): void {
    this.uniqueName = this.isProjectNameUnique(newProjectName);
  }

  isProjectNameUnique(formValue: string): boolean {
    const projectList =
      this.collaborationList?.map((col) => col.projectName) || [];
    const projectNames = projectList.map((projectName) =>
      projectName.toLowerCase().trim(),
    );
    const name = formValue.toLowerCase().trim();
    return !projectNames.includes(name);
  }

  onCreateProject(): void {
    const projectName = (this.projectName.value as string)?.trim();
    const validName = typeof projectName === 'string' && projectName.length > 0;
    if (validName && this.isProjectNameUnique(projectName)) {
      this.projectNameCreated.emit(projectName);
    }
  }
}
