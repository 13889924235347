import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { Resource } from '3map-models';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { Dialog } from '@angular/cdk/dialog';
import { ResourceIdWarningComponent } from './resource-id-warning.component';

@Component({
  selector: 'app-resource-name',
  template: `
    <div class="label">Resource Name</div>
    <div class="row">
      <input
        #nameInput
        *ngIf="resource"
        [value]="resource.name"
        (input)="nameChange.emit(nameInput.value)"
        type="text"
      />
      <div class="show-on-hover-toggle row">
        <ui-btn
          label="Change file"
          type="border"
          (click)="fileChange.emit()"
          size="small"
          class=""
        />
        <fa-icon
          class="show-on-hover"
          [icon]="iconAlert"
          (click)="showDialog()"
        />
      </div>
    </div>
  `,
  styles: [
    `
      .label {
        font-size: 0.9em;
        margin: 20px 0 5px 0;
      }

      .row {
        display: flex;
        align-items: center;
        gap: var(--spacing-3);
      }

      input {
        width: 300px;
      }

      fa-icon {
        cursor: pointer;
        color: var(--warn-color);
      }
    `,
  ],
  standalone: false,
})
export class ResourceNameComponent {
  private dialog = inject(Dialog);
  @Input() resource?: Resource;
  @Output() nameChange = new EventEmitter<string>();
  @Output() fileChange = new EventEmitter<void>();
  iconAlert = faCircleExclamation;

  showDialog() {
    this.dialog.open(ResourceIdWarningComponent);
  }
}
