import { Component, Input, OnInit } from '@angular/core';
import { FieldText } from '3map-models';
import { UntypedFormControl, Validators } from '@angular/forms';
import { FieldTypeBaseComponent } from '../../../models/FieldTypeBaseComponent';
import { EditorError } from '../../../models/EditorError';

@Component({
  selector: 'app-field-text',
  template: `
    <app-field-name [fieldType]="fieldType" />
    <textarea
      placeholder="Enter text"
      [readOnly]="!allowEdit"
      [formControl]="formControl!"
      (focusout)="inputFocusOut()"
    ></textarea>
    <div class="error" *ngIf="hasError()">Required field</div>
  `,
  styleUrls: ['../../field-type.base.component.scss'],
  standalone: false,
})
export class FieldTextComponent
  extends FieldTypeBaseComponent
  implements OnInit
{
  @Input() fieldText: FieldText | undefined;

  ngOnInit(): void {
    if (!this.fieldType) throw Error('[ FieldText ] Missing FieldType');
    const value = this.fieldText?.text || '';
    const validators = this.fieldType.required ? [Validators.required] : [];
    this.formControl = new UntypedFormControl(value, validators);
    // this.bindFormControlEmitter();
  }

  override getField(): FieldText | null {
    if (this.formControl?.valid) {
      return this.formControl.value.length === 0
        ? null
        : {
            text: this.formControl.value.trim(),
            type: 'TEXT',
          };
    }
    throw new EditorError('Invalid FieldText');
  }
}
