import { Action, createReducer, on } from '@ngrx/store';
import * as FeatureHistoryActions from './feature-history.actions';
import * as ProjectActions from '../../project/+state/project.actions';

export const FEATURE_HISTORY_FEATURE_KEY = 'feature-history';

export interface State {
  selectedRecordId: string | null;
  activeTab: string | null;
}

export const initialState: State = {
  selectedRecordId: null,
  activeTab: null,
};

export const featureHistoryReducer = createReducer(
  initialState,
  on(
    ProjectActions.closeProject,
    FeatureHistoryActions.modalClosed,
    (): State => ({ ...initialState }),
  ),
  on(FeatureHistoryActions.init, (state, { activeTab, selectedRecordId }) => ({
    ...state,
    selectedRecordId,
    activeTab,
  })),
  on(
    FeatureHistoryActions.setSelectedRecordId,
    (state, { selectedRecordId }) => ({
      ...state,
      selectedRecordId,
    }),
  ),
  on(FeatureHistoryActions.onTabChange, (state, { activeTab }) => {
    return { ...state, activeTab };
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return featureHistoryReducer(state, action);
}
