import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  faCheck,
  faTriangleExclamation,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'ui-checkbox',
    template: `
    <div class="checkbox-wrapper" (click)="checkboxClick.emit()">
      <div class="circle" *ngIf="!loading && !error" [class.checked]="checked">
        <fa-icon *ngIf="checked" [icon]="icon"></fa-icon>
      </div>

      <div class="loading-spinner" *ngIf="loading && !error">
        <ui-spinner-circle [size]="16" [loading]="true"></ui-spinner-circle>
      </div>

      <div class="icon-error" *ngIf="error && !loading">
        <fa-icon [icon]="iconError"></fa-icon>
      </div>

      <div class="this-should-not-happen" *ngIf="loading && error"></div>

      <div class="label text-ellipsis" *ngIf="label" [class.bold]="checked">
        {{ label }}
      </div>
    </div>
  `,
    styles: [
        `
      .checkbox-wrapper {
        display: grid;
        grid-template-columns: 16px auto;
        align-items: center;
        width: fit-content;
        cursor: pointer;
        padding: var(--spacing-1) 0;
      }

      .circle {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid var(--grey);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
      }

      .loading-spinner {
        display: flex;
        width: 16px;
        height: 16px;
      }

      .checked {
        background-color: var(--primary-color);
        border: 1px solid var(--primary-color);
        color: #ffffff;
        font-size: 12px;
      }

      .label {
        padding: 0 var(--spacing-3);
        font-size: var(--font-size-2);
      }

      .bold {
        font-weight: var(--font-weight-2);
        color: #333333;
      }

      .icon-error {
        width: 16px;
        height: 16px;
        color: var(--error-color);
        font-size: 1.1em;
        display: flex;
        align-items: center;
      }
    `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CheckboxComponent {
  @Input() checked?: boolean;
  @Input() label?: string;
  @Input() loading?: boolean;
  @Input() error?: boolean;
  @Output() checkboxClick = new EventEmitter<void>();
  icon: IconDefinition = faCheck;
  iconError: IconDefinition = faTriangleExclamation;
}
