import { Injectable } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { AnyLayer, LngLat, LngLatBounds, MapMouseEvent } from 'mapbox-gl';
import { HttpClient } from '@angular/common/http';
import { Record } from '3map-models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  selectedLngLat$: Observable<{ lng: number; lat: number } | null>;
  private selectedLngLatObs: BehaviorSubject<{
    lng: number;
    lat: number;
  } | null>;
  private popup: mapboxgl.Popup | undefined;
  private _map: mapboxgl.Map | undefined;

  constructor(private http: HttpClient) {
    this.selectedLngLatObs = new BehaviorSubject<{
      lng: number;
      lat: number;
    } | null>(null);
    this.selectedLngLat$ = this.selectedLngLatObs.asObservable();
  }

  set map(map: mapboxgl.Map) {
    this._map = map;
  }

  get map(): mapboxgl.Map {
    if (!this._map) {
      throw Error('[lib-map.map-service] Map is undefined');
    }
    return this._map;
  }

  getMapBounds(): LngLatBounds {
    const bounds = this.map.getBounds();
    if (!bounds) throw Error('[lib-map.map-service] Bounds are undefined');
    return bounds;
  }

  resizeMap(): void {
    if (this._map) {
      this.map.resize();
    }
  }

  removeMap(): void {
    if (this._map) {
      this._map.remove();
    }
  }

  emitLngLat(evt: MapMouseEvent | null): void {
    this.selectedLngLatObs.next(
      evt !== null ? { lng: evt.lngLat.lng, lat: evt.lngLat.lat } : null,
    );
  }

  addPopup(lngLat: { lng: number; lat: number }, content: Node): void {
    this.removePopup();
    this.popup = new mapboxgl.Popup({
      closeOnClick: false,
      closeButton: false,
      maxWidth: '350px',
    })
      .setLngLat([lngLat.lng, lngLat.lat])
      .setDOMContent(content)
      // .setHTML(content)
      .addTo(this.map);
  }

  removePopup(): void {
    this.popup?.remove();
  }

  zoomToRecords(records: Record[]): void {
    records.length === 1
      ? this.map.setCenter([records[0].longitude, records[0].latitude])
      : this.setMapToBounds(
          records.map((rec) => new LngLat(rec.longitude, rec.latitude)),
        );
  }

  setMapToBounds(coordinates: mapboxgl.LngLat[]): void {
    if (coordinates && coordinates.length > 0) {
      // thanks mapbox
      const bounding = coordinates.reduce(
        (bounds, coord) => {
          return bounds.extend(coord);
        },
        new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]),
      );
      this.map?.fitBounds(bounding, { padding: 250 });
    }
  }

  getIconImage(markerStyleImage: string): Observable<Blob> {
    return this.http.get(
      `https://api.trimweb.it/3map-static/${markerStyleImage}`,
      { responseType: 'blob' },
    );
  }

  createCanvasLayer(id: string) {
    this.map.resize(); // TODO is this needed??
    const bounds = this.getMapBounds();
    const cnvSrc = {
      type: 'canvas',
      animate: false,
      canvas: id,
      coordinates: [
        [bounds.getSouthWest().lng, bounds.getNorthEast().lat],
        [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
        [bounds.getNorthEast().lng, bounds.getSouthWest().lat],
        [bounds.getSouthWest().lng, bounds.getSouthWest().lat],
      ],
    };

    this.map.addSource(id, cnvSrc as any); // mapboxgl.CanvasSourceRaw);
    const layer: AnyLayer = {
      id,
      source: id,
      type: 'raster',
      paint: {
        'raster-fade-duration': 0,
      },
    };

    // layer `weather_model` is created from map tiler app!
    if (this.map.getLayer('weather_model'))
      this.map.addLayer(layer, 'weather_model');
    else this.map.addLayer(layer);
  }

  removeCanvasLayer(id: string): void {
    if (this.map.getLayer(id)) this.map.removeLayer(id);
    if (this.map.getSource(id)) this.map.removeSource(id);
  }

  getPaddedBounds(): LngLatBounds {
    const bounds = this.getMapBounds();
    return new LngLatBounds(
      { lng: bounds.getWest() - 1, lat: bounds.getSouth() - 1 },
      { lng: bounds.getEast() + 1, lat: bounds.getNorth() + 1 },
    );
  }

  getBoundsAsArray(): number[] {
    const bounds = this.getMapBounds();
    return [
      Math.floor(bounds.getWest()),
      Math.floor(bounds.getSouth()),
      Math.ceil(bounds.getEast()),
      Math.ceil(bounds.getNorth()),
    ];
  }

  getPaddedBoundsAsArray(): number[] {
    const bounds = this.getPaddedBounds();
    return [
      Math.floor(bounds.getWest()),
      Math.floor(bounds.getSouth()),
      Math.ceil(bounds.getEast()),
      Math.ceil(bounds.getNorth()),
    ];
  }
}
