import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, map, Observable } from 'rxjs';
import { DropdownItem } from '@trim-web-apps/map3-ui';
import { WeatherActions, WeatherSelectors } from '../../+state';
import {
  createInitimeLabel,
  timestepToLabel,
} from '../../utils/weather.functions';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { WeatherModel } from '@trim-web-apps/weather-models';

type View = {
  pickerData: {
    itemList: DropdownItem<number>[];
    itemActive: DropdownItem<number> | null;
    activeIndex: number;
    tiffLoading: boolean;
  } | null;
  latestInit: string | null;
  model?: WeatherModel | null;
};

@Component({
    selector: 'weather-core-timestep-picker',
    template: `
    <div class="ts-picker-wrapper" *ngIf="view$ && (view$ | async) as view">
      <ng-container *ngIf="view.pickerData">
        <fa-icon
          [icon]="iconPrev"
          [class.disabled]="view.pickerData.tiffLoading"
          class="timestep-btn ts-prev"
          (click)="changeTs(-1, view)"
        />

        <ui-dropdown
          [itemList]="view.pickerData.itemList"
          [itemActive]="view.pickerData.itemActive"
          [disabled]="view.pickerData.tiffLoading"
          (itemSelected)="onItemSelected($event.source)"
        />

        <fa-icon
          [icon]="iconNext"
          [class.disabled]="view.pickerData.tiffLoading"
          class="timestep-btn ts-next"
          (click)="changeTs(1, view)"
        />
      </ng-container>

      <ng-container *ngIf="!view.pickerData && view.latestInit">
        <div class="latest-update" [class.is-mobile]="isMobile">
          <div>Last update</div>
          <div>{{ view.latestInit }} UTC</div>
        </div>
      </ng-container>
    </div>
  `,
    styles: [
        `
      .ts-picker-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
      }

      ui-dropdown {
        width: 100%;
        padding: 0 var(--spacing-2);
      }

      .timestep-btn {
        cursor: pointer;
      }

      .latest-update {
        flex: 1;
        text-align: center;
        font-size: var(--font-size-2);
      }

      .latest-update.is-mobile {
        display: flex;
        gap: var(--spacing-3);
        justify-content: center;
      }

      fa-icon.disabled {
        color: var(--grey);
        cursor: progress;
      }
    `,
    ],
    standalone: false
})
export class TimestepPickerComponent implements OnInit {
  @Input() isMobile?: boolean;
  @Input() weatherModelId?: string;
  view$?: Observable<View>;
  iconPrev = faChevronLeft;
  iconNext = faChevronRight;

  constructor(private store: Store) {}

  ngOnInit(): void {
    if (!this.weatherModelId) return;

    this.view$ = combineLatest([
      this.store.select(WeatherSelectors.selectById(this.weatherModelId)),
      this.store.select(WeatherSelectors.selectUtcOffset()),
    ]).pipe(
      map(([model, utcOffset]) => {
        if (!model) return { pickerData: null, latestInit: null, model };

        const latestInit = createInitimeLabel(model.initime);

        if (
          model.category === 'FORECAST' &&
          model.subCategory === 'RAINFALL_ACCUMULATION'
        ) {
          return {
            pickerData: null,
            latestInit,
            model,
          };
        }

        let itemList: DropdownItem<number>[] = [];
        let itemActive: DropdownItem<number> | null = null;
        let activeIndex = -1;
        const layer = model.layers.find((l) => l.id === model.selectedLayerId);
        const tsList =
          layer?.timestepList
            .find((ts) => ts.initime === model.initime)
            ?.timesteps.filter(
              (t: number) =>
                t >= model.dateRange.from && t <= model.dateRange.to,
            ) || [];

        if (!layer || !tsList) return { pickerData: null, latestInit };

        const sortedTsList =
          model.category === 'MONITORING' ? tsList.reverse() : tsList;

        for (let i = 0; i < sortedTsList.length; i++) {
          const ts = sortedTsList[i];
          const tsLabel = timestepToLabel(ts, model.initime, layer, utcOffset);
          const item = {
            // label: `${tsLabel} ${utcOffsetLabel}`,
            label: `${tsLabel}`,
            payload: ts,
          };

          itemList = [...itemList, item];

          if (item.payload === model.timestep) {
            itemActive = item;
            activeIndex = i;
          }
        }

        const { tiffLoading } = model;
        const pickerData = { itemList, itemActive, activeIndex, tiffLoading };

        return { pickerData, latestInit: 'SOME INIT', model };
      }),
    );
  }

  changeTs(direction: number, view: View): void {
    const { pickerData, model } = view;
    if (!pickerData || !model || view.pickerData?.tiffLoading) return;
    if (pickerData.activeIndex === -1) return;
    // monitoring models have the latest timestep at the beginning, the "next" timestep is actually the previous one
    const reverse = model.category === 'MONITORING' ? -1 : 1;
    const newTsIndex = pickerData.activeIndex + direction * reverse;
    if (newTsIndex >= 0 && newTsIndex < pickerData.itemList.length)
      this.setTs(pickerData.itemList[newTsIndex].payload);
  }

  setTs(timestep: number): void {
    if (this.weatherModelId)
      this.store.dispatch(
        WeatherActions.setTimestep({ modelId: this.weatherModelId, timestep }),
      );
  }

  onItemSelected(item: DropdownItem<number> | null): void {
    if (item !== null) this.setTs(item.payload);
  }
}
