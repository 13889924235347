import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SidenavActions } from '../../sidenav/+state';
import { map, of, switchMap } from 'rxjs';
import { MapResourceService } from './map-resource.service';
import { withLatestFrom } from 'rxjs/operators';
import { ProjectSelectors } from '../../project/+state';
import { MapResourceSelectors } from './index';
import { Store } from '@ngrx/store';

@Injectable()
export class MapResourceEffects {
  constructor(
    private action$: Actions,
    private mapResourceService: MapResourceService,
    private store: Store,
  ) {}

  enableResource$ = createEffect(() =>
    this.action$.pipe(
      ofType(SidenavActions.enableMapResource),
      map((action) => action.resourceId),
      withLatestFrom(
        this.store.select(ProjectSelectors.selectProject()),
        this.store.select(MapResourceSelectors.selectMapResourceDict()),
      ),
      switchMap(([resourceId, project, resourceDict]) => {
        const resource = resourceDict[resourceId];
        if (!project || !resource)
          return of(SidenavActions.enableMapResourceError({ resourceId }));
        return this.mapResourceService
          .fetchImage(resource, project.name)
          .pipe(
            map((fetchSuccess) =>
              fetchSuccess
                ? SidenavActions.enableMapResourceSuccess({ resourceId })
                : SidenavActions.enableMapResourceError({ resourceId }),
            ),
          );
      }),
    ),
  );
}
