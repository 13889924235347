import { isValidLatitude, isValidLongitude } from '@trim-web-apps/core';
import { MapboxPlugin } from './MapConfig';
import { Resource } from '3map-models';
import { Map } from 'mapbox-gl';

export function getMapPlugins(): MapboxPlugin[] {
  return [
    'SCALE',
    'SWITCHER',
    'RULER',
    'ZOOM',
    'NAVIGATION',
    'GEOLOCATE',
    'FULLSCREEN',
    'ATTRIBUTION',
  ];
}

export function isValidLngLat(lngLat: any): boolean {
  return isValidLatitude(lngLat.lat) && isValidLongitude(lngLat.lng);
}

export function isValidZoom(zoom: any): boolean {
  return !isNaN(zoom) && zoom >= 0 && zoom <= 30;
}

export function addResourceToMap(
  map: Map,
  resource: Resource,
  resourceData: string,
  beforeLayerId?: string,
): string[] | null {
  if (resource.type === 'GEOJSON')
    return addGeoJsonResourceToMap(map, resource, resourceData, beforeLayerId);
  if (resource.type === 'IMAGE')
    return addImageResourceToMap(map, resource, resourceData, beforeLayerId);
  return null;
}

/**
 'type' property for mapbox.AnyLayer is derived from style
 Examples:
 style: { 'circle-radius': 5 }   => 'circle'
 style: { 'line-color': 'red' }  => 'line'
 style: { 'fill-opacity': 0.5 }  => 'fill'

 Available:
 "symbol" | "circle" | "background" | "fill-extrusion" | "fill" | "heatmap" | "hillshade" | "line" | "raster" | "custom"'

 If style is empty, defaults to 'circle'
 */
export function addGeoJsonResourceToMap(
  map: Map,
  resource: Resource,
  resData: string,
  beforeLayerId?: string,
): string[] | null {
  const style = resource.style || {};
  const geoType = Object.keys(style)[0].split('-')[0] || 'circle';
  const layerIds = createGeoJsonLayerIds(resource);
  if (!layerIds || layerIds.length !== 2) return null;
  const [geoJsonLayerId, namesLayerId] = layerIds;
  const geoJsonLayer = map.getLayer(geoJsonLayerId) || null;
  const namesLayer = map.getLayer(namesLayerId) || null;

  if (geoJsonLayer && namesLayer) return [geoJsonLayerId, namesLayerId];
  if (!resData) return null;

  map
    .addSource(`${geoJsonLayerId}__source`, {
      type: 'geojson',
      data: JSON.parse(resData),
    })
    .addLayer(
      {
        id: geoJsonLayerId,
        source: `${geoJsonLayerId}__source`,
        type: geoType as any,
        paint: style,
      },
      beforeLayerId,
    )
    .addLayer(
      {
        id: namesLayerId,
        source: `${geoJsonLayerId}__source`,
        type: 'symbol',
        layout: {
          'text-field': '{name}',
          'text-offset': [0, -1.0],
          'text-size': 14,
        },
      },
      beforeLayerId,
    );

  return [geoJsonLayerId, namesLayerId];
}

export function addImageResourceToMap(
  map: Map,
  resource: Resource,
  base64: string,
  beforeLayerId?: string,
): string[] | null {
  if (!resource.boundingBox) throw new Error('No bounding box provided');
  const style = resource.style;
  const opacity =
    style && style.hasOwn('raster-opacity') ? style['raster-opacity'] : 1;

  const imageLayerId = resource.filename;
  const imageLayer = map.getLayer(imageLayerId);

  if (imageLayer) return [imageLayerId];

  map
    .addSource(`${resource.filename}`, {
      type: 'image',
      url: base64,
      coordinates: resource.boundingBox as any,
    })
    .addLayer(
      {
        id: resource.filename,
        source: `${resource.filename}`,
        type: 'raster',
        paint: { 'raster-opacity': opacity },
      },
      beforeLayerId,
    );
  return [imageLayerId];
}

export function removeResourceFromMap(map: Map, resource: Resource): void {
  const layerIds =
    resource.type === 'GEOJSON'
      ? createGeoJsonLayerIds(resource) || []
      : [resource.filename];
  layerIds.map((layerId) => {
    if (!map.getLayer(layerId)) return;
    map.removeLayer(layerId);
    map.removeSource(layerId);
  });
}

export function createGeoJsonLayerIds(resource: Resource): string[] | null {
  const geoJsonLayerId = resource.filename + '__json';
  const namesLayerId = resource.filename + '__names';
  if (resource.type === 'GEOJSON') return [geoJsonLayerId, namesLayerId];
  return null;
}
