import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { AuthActions } from '../auth';
import { CollaborationActions } from './index';

export interface Collaboration {
  projectName: string;
  role: 'ADMIN' | 'MOD' | 'USER';
}

export const FEATURE_KEY = 'collaboration';

export interface State {
  collaborations: Collaboration[];
  newProjectName: string;
  fetchPending: boolean;
  fetchError: boolean;
  createPending: boolean;
  createError: boolean;
}

const initialState: State = {
  collaborations: [],
  newProjectName: '',
  fetchPending: false,
  fetchError: false,
  createPending: false,
  createError: false,
};

const colReducer: ActionReducer<State> = createReducer(
  initialState,
  on(AuthActions.authLogout, (): State => initialState),
  on(
    CollaborationActions.fetchCollaborations,
    (): State => ({ ...initialState, fetchPending: true }),
  ),
  on(
    CollaborationActions.fetchCollaborationsError,
    (): State => ({ ...initialState, fetchError: true }),
  ),
  on(
    CollaborationActions.fetchCollaborationsSuccess,
    (state, { collaborations }): State => ({
      ...initialState,
      collaborations,
    }),
  ),
  on(
    CollaborationActions.createNewProject,
    (state): State => ({ ...state, createPending: true }),
  ),
  on(CollaborationActions.createNewProjectSuccess, (): State => initialState),
  on(
    CollaborationActions.createNewProjectError,
    (): State => ({ ...initialState, createError: true }),
  ),
  on(
    CollaborationActions.setProjectName,
    (state, { newProjectName }): State => ({
      ...state,
      newProjectName,
    }),
  ),
);

export function reducer(state: State | undefined, action: Action): State {
  return colReducer(state, action);
}
