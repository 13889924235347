import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import * as ResourceActions from '../+state/resource.actions';
import { Store } from '@ngrx/store';
import { Resource } from '3map-models';
import { GeoStyle, geoStyleToRaw, rawToGeoStyle } from '../utils';

type View = {
  resource: Resource;
  type: string;
  color: string;
};

function transform(resource?: Resource): View | null {
  if (!resource) return null;

  return {
    ...rawToGeoStyle(resource.style),
    resource,
  };
}

@Component({
  selector: 'app-resource-geojson',
  template: `
    <div class="resource-geojson-wrapper" *ngIf="view">
      <div class="row">
        <div class="control type">
          <div class="label">Type</div>
          <ui-select
            [items]="['fill', 'line', 'circle']"
            [itemSelected]="view.type"
            [widthPx]="150"
            (itemSelectedChange)="onTypeChange($event)"
          />
        </div>

        <div class="control color">
          <div class="label">Color</div>
          <input
            class="control-item color-picker"
            (colorPickerChange)="onColorChange($event)"
            [colorPicker]="view.color"
            [style.background]="view.color"
          />
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .resource-geojson-wrapper {
        display: flex;
        flex-direction: column;
      }

      .resource-geojson-wrapper .row {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
      }

      .resource-geojson-wrapper .row:last-child input,
      .resource-geojson-wrapper .row:last-child button {
        width: 150px;
      }

      .resource-geojson-wrapper .label {
        font-size: 0.9em;
        margin-bottom: 5px;
      }

      .resource-geojson-wrapper .control {
        margin-right: 30px;
      }
    `,
  ],
  standalone: false,
})
export class ResourceGeojsonComponent implements OnInit, OnDestroy {
  @Input({ transform }) view?: View | null;

  // old
  colorDebounce: Subject<{ geoStyle: GeoStyle; color: string }>;
  colorDebounceSub: Subscription | undefined;

  constructor(private store: Store) {
    this.colorDebounce = new Subject<{ geoStyle: GeoStyle; color: string }>();
  }

  ngOnInit(): void {
    this.colorDebounceSub = this.colorDebounce
      .pipe(debounceTime(100))
      .subscribe(({ geoStyle, color }) => {
        const style = geoStyleToRaw({ ...geoStyle, color });
        const id = this.view?.resource.filename;
        if (!id) return;
        this.store.dispatch(ResourceActions.setResourceStyle({ id, style }));
      });
  }

  ngOnDestroy(): void {
    this.colorDebounceSub?.unsubscribe();
  }

  onTypeChange(value: string | null): void {
    if (!value || !this.view) return;
    const id = this.view.resource.filename;
    const geoStyle = rawToGeoStyle(this.view.resource.style);
    const style = geoStyleToRaw({ ...geoStyle, type: value });
    this.store.dispatch(ResourceActions.setResourceStyle({ id, style }));
  }

  onColorChange(color: string): void {
    if (!this.view) return;
    const geoStyle = rawToGeoStyle(this.view.resource.style);
    this.colorDebounce.next({ geoStyle, color });
  }
}
