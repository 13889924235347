import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ProjectUser } from '3map-models';

@Component({
  selector: 'app-role-picker',
  template: `
    <div class="role-picker-wrapper" *ngIf="user">
      <div class="label">Select one role</div>
      <div class="checkbox-list">
        <ui-checkbox
          *ngFor="let role of roles"
          [label]="role"
          [checked]="user.role === role"
          (click)="roleChange.emit(role)"
        />
      </div>
    </div>
  `,
  styles: [
    `
      .label {
        margin: 10px 0;
      }

      .checkbox-list {
        display: flex;
        justify-content: center;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class RolePickerComponent {
  @Input() user?: ProjectUser;
  @Output() roleChange = new EventEmitter<'ADMIN' | 'MOD' | 'USER'>();
  roles: ('ADMIN' | 'MOD' | 'USER')[] = ['ADMIN', 'MOD', 'USER'];
}
