import { Link } from '3map-models';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { faShareFromSquare } from '@fortawesome/free-regular-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-links-nav-item',
  template: `
    <app-sidenav-item
      label="Links"
      [icon]="iconLinks"
      [sidenavOpen]="sidenavOpen"
      [expandable]="true"
    >
      <div *ngIf="links.length === 0" class="no-links">No Links found</div>
      <div
        class="link show-on-hover-toggle"
        (click)="onLinkClick(link)"
        *ngFor="let link of links"
      >
        <div class="text-ellipsis">{{ link.name }}</div>
        <fa-icon class="show-on-hover" [icon]="iconLink"></fa-icon>
      </div>
    </app-sidenav-item>
  `,
  styles: [
    `
      fa-icon {
        margin-left: 10px;
      }

      .link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LinksNavItemComponent {
  @Input() links: Link[] = [];
  @Input() sidenavOpen = false;
  iconLinks = faLink;
  iconLink = faShareFromSquare;

  onLinkClick(link: Link): void {
    window.open(link.url, '_blank');
  }
}
