import { WeatherModelInitConfig, WeatherModelMetadata } from '../types';
import { WeatherModel } from '../types/weather.model';
import { WeatherLayer } from '../types/weather-layer.type';
import {
  HIGH_CLOUD_COVER,
  LOW_CLOUD_COVER,
  MEDIUM_CLOUD_COVER,
  PRESSURE,
  RAINFALL_1HOUR,
  RAINFALL_TOTAL,
  RELATIVE_HUMIDITY,
  TEMPERATURE,
  WIND_KTS,
} from '../weather-layers/weather.layers';
import {
  createTsListForecast,
  decodeForecastSpec,
  getBaseModelTemplate,
  getDefaultDateRange,
  getTsListRainfall,
  removeUnwantedLayers,
} from '../utils';

export const HARMONIE_AROME: WeatherModelMetadata = {
  id: 'HARMONIE_AROME',
  label: 'Harmonie Arome',
  category: 'FORECAST',
  subCategory: 'LOCAL_MODELS',
  info: {
    resolution: { deg: 0.029, km: 2.5 },
    coverage: 'Netherlands',
    url: 'https://dataplatform.knmi.nl/dataset/harmonie-arome-cy43-p1-1-0',
    credits: 'KNMI - Koninklijk Nederlands Meteorologisch Instituut',
    schedule: [{ init: '00', time: '05:30' }],
  },
  bbox: [
    [0.0, 56.002],
    [11.281, 56.002],
    [11.281, 49],
    [0.0, 49],
    [0.0, 56.002],
  ],
};

export function createHarmonieArome(
  serverSpec: unknown,
  config?: WeatherModelInitConfig,
): WeatherModel {
  const spec = decodeForecastSpec(serverSpec);
  const timestepList = createTsListForecast(spec.initimeList, 48);
  const tsRainfall = getTsListRainfall(timestepList);
  const defaultDateRange = getDefaultDateRange(timestepList, 0, 47);
  const defaultDateRngRainfall = getDefaultDateRange(tsRainfall, 1, 47);
  const timestepType = { type: 'UNIX', interval: 3600 } as const;

  const harmonieLayers: WeatherLayer[] = [
    {
      ...WIND_KTS,
      timestepType,
      timestepList,
      defaultDateRange,
    },
    {
      ...TEMPERATURE,
      timestepType,
      timestepList,
      defaultDateRange,
    },
    {
      ...RAINFALL_TOTAL,
      timestepType,
      timestepList: tsRainfall,
      defaultDateRange: defaultDateRngRainfall,
    },
    {
      ...RAINFALL_1HOUR,
      timestepType,
      timestepList: tsRainfall,
      defaultDateRange: defaultDateRngRainfall,
    },
    {
      ...RELATIVE_HUMIDITY,
      timestepType,
      timestepList,
      defaultDateRange,
      conversionFactor: 100,
    },
    {
      ...PRESSURE,
      timestepType,
      timestepList,
      defaultDateRange,
    },
    {
      ...LOW_CLOUD_COVER,
      timestepType,
      timestepList: tsRainfall,
      defaultDateRange: defaultDateRngRainfall,
      conversionFactor: 100,
    },
    {
      ...MEDIUM_CLOUD_COVER,
      timestepType,
      timestepList: tsRainfall,
      defaultDateRange: defaultDateRngRainfall,
      conversionFactor: 100,
    },
    {
      ...HIGH_CLOUD_COVER,
      timestepType,
      timestepList: tsRainfall,
      defaultDateRange: defaultDateRngRainfall,
      conversionFactor: 100,
    },
  ];

  const layers = removeUnwantedLayers(
    harmonieLayers,
    config?.layerIdList ?? [],
  );

  if (layers.length === 0)
    throw new Error(`No layers available for ${spec.model} model`);

  return {
    ...getBaseModelTemplate(config),
    ...HARMONIE_AROME,
    layers,
    initime: layers[0].timestepList[0].initime,
    timestep: layers[0].defaultDateRange.from,
    selectedLayerId: layers[0].id,
    dateRange: layers[0].defaultDateRange,
  };
}
