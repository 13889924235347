import { Injectable, isDevMode } from '@angular/core';
import { ApiService } from '@trim-web-apps/api3map';
import { lastValueFrom, Observable, of } from 'rxjs';
import { blobToString, notNullOrUndefined } from '@trim-web-apps/core';
import { Resource } from '3map-models';

@Injectable({ providedIn: 'root' })
export class ResourceService {
  private fileToUpload: { [filename: string]: Blob | null } = {};

  constructor(private api: ApiService) {}

  getResource(filename: string, projectName: string): Observable<Blob | null> {
    const localFile = this.fileToUpload[filename];
    if (localFile) return of(localFile); // file already exists (user provided)
    if (localFile === null) return of(null); // file from user was not valid (e.g. too large or invalid type)
    return this.api.fetchAdminStaticFile(filename, projectName); // fetch from server
  }

  getLocalResource(filename: string): Blob | null | undefined {
    return this.fileToUpload[filename];
  }

  removeResource(filename: string): void {
    this.fileToUpload[filename] = null;
    delete this.fileToUpload[filename];
    this.debug();
  }

  getFileToUpload(): { filename: string; blob: Blob }[] {
    return Object.keys(this.fileToUpload)
      .map((filename) => {
        const blob = this.fileToUpload[filename];
        return blob ? { filename, blob } : null;
      })
      .filter(notNullOrUndefined);
  }

  reset(): void {
    this.fileToUpload = {};
  }

  async setResourceFile(resource: Resource, file: File | null): Promise<void> {
    if (resource.type === 'GEOJSON' && file) {
      // todo: refactor to avoid back and forth conversion between blob and string?
      try {
        const data = await lastValueFrom(blobToString(file));
        const parsed = JSON.parse(data);
        const features = parsed.features ?? [];
        const fixedNameFeatures = features.map((f: any) => {
          const properties = f.properties;
          const name = properties?.Name;
          if (name !== undefined)
            return { ...f, properties: { ...f.properties, name } };
          return f;
        });
        this.fileToUpload[resource.filename] = new Blob([
          JSON.stringify({ ...parsed, features: fixedNameFeatures }),
        ]);
      } catch (e) {
        this.fileToUpload[resource.filename] = file;
      }
    } else {
      this.fileToUpload[resource.filename] = file;
    }
    this.debug();
  }

  private debug(): void {
    if (isDevMode()) console.log('ResourceService', this.fileToUpload);
  }
}
