import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Form, FormSpecific, Media, Record } from '3map-models';

@Component({
  selector: 'app-record-card[record][form][specific]',
  template: `
    <div class="record-card-wrapper" *ngIf="record && form && specific">
      <div class="record-card-header">
        <app-record-card-header
          [record]="record"
          [specific]="specific"
          [form]="form"
        />

        <app-record-card-tabs
          [specific]="specific"
          [activeTab]="activeTab"
          (tabClicked)="tabClicked.emit($event)"
        />
      </div>

      <div class="record-card-body">
        <app-record-card-questions
          [record]="record"
          [specific]="specific"
          [activeTab]="activeTab"
        />
      </div>
      <div class="media-list" *ngIf="record.mediaList.length">
        <div class="label">Media</div>
        <div class="media-list-inner">
          <app-media-card
            *ngFor="let media of record.mediaList"
            [media]="media"
            [projectName]="record.projectName"
            [showControls]="false"
            (click)="mediaClick.emit(media)"
          />
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .record-card-wrapper {
        background-color: #ffffff;
        height: 100%;
        border-radius: 10px;
        box-shadow:
          0 3px 6px rgba(0, 0, 0, 0.16),
          0 3px 6px rgba(0, 0, 0, 0.23);
        display: flex;
        flex-direction: column;
      }

      .record-card-header {
        height: 120px;
        padding: var(--spacing-2);
      }

      .record-card-body {
        flex: 1;
        overflow: auto;
        padding: var(--spacing-2);
      }

      .media-list {
        overflow: auto;
      }

      .media-list .label {
        font-weight: var(--font-weight-3);
        padding: var(--spacing-2);
        padding-bottom: 0;
      }

      .media-list-inner {
        display: flex;
        width: fit-content;
        gap: var(--spacing-2);
        padding: var(--spacing-2);
      }

      app-media-card {
        width: 100px;
        height: 100px;
        border: 1px solid var(--border);
        border-radius: 10px;
        overflow: hidden;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class RecordCardComponent {
  @Input() record: Record | undefined;
  @Input() form: Form | undefined;
  @Input() specific: FormSpecific | undefined;
  @Input() activeTab: string | null = null;
  @Output() tabClicked = new EventEmitter<string | null>();
  @Output() mediaClick = new EventEmitter<Media>();
}
