import { createAction, props } from '@ngrx/store';
import { ResRecordList } from '3map-models';

export const fetchRecords = createAction('[Record] Fetch Records');

export const fetchRecordsSuccess = createAction(
  '[Record / API] Fetch Records Success',
  props<{ resRecordList: ResRecordList }>(),
);
export const fetchRecordsError = createAction(
  '[Record / API] Fetch Records Error',
);

export const clearRecords = createAction('[Record] Clear Records');
