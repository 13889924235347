import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createResource, removeResource } from './resource.actions';
import { ResourceService } from './resource.service';
import { from, map } from 'rxjs';
import { Router } from '@angular/router';
import { AdminActions } from '../../+state';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class ResourceEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private resourceService: ResourceService,
    private router: Router,
  ) {}

  createResource$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createResource),
        switchMap(({ resource, file }) =>
          from(this.resourceService.setResourceFile(resource, file)).pipe(
            map(() => {
              this.router.navigate([`/admin/resources/${resource.filename}`]);
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  removeResource$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(removeResource),
        map(({ id }) => {
          this.resourceService.removeResource(id);
        }),
      ),
    { dispatch: false },
  );

  adminClose$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AdminActions.close),
        map(() => {
          this.resourceService.reset();
        }),
      ),
    { dispatch: false },
  );
}
