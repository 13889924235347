import { Pipe, PipeTransform } from '@angular/core';
import { ModelInfo } from '@trim-web-apps/weather-models';

@Pipe({
    name: 'resolutionLabel',
    standalone: false
})
export class ResolutionLabelPipe implements PipeTransform {
  transform(res: ModelInfo, type: 'deg' | 'km' = 'km'): string {
    if (!res || !res.resolution) return '';
    const value = res.resolution[type];
    return type === 'deg' ? `${value}°` : `${value} km`;
  }
}
