import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { ApiService } from '@trim-web-apps/api3map';
import { ProjectUser } from '3map-models';

@Component({
  selector: 'app-user-search',
  template: `
    <div class="title" (click)="userControl.reset()">Add Users</div>
    <div class="search">
      <input
        type="text"
        [formControl]="userControl"
        placeholder="Search username"
      />
      <ng-container *ngIf="searchResult$ | async as searchResult">
        <div
          *ngIf="searchResult.length > 0"
          class="search-result-wrapper shadow"
        >
          <div
            class="result-item"
            *ngFor="let search of searchResult"
            (click)="onUsernameSelected(search.username)"
          >
            <div class="label">{{ search.username }}</div>
            <ui-checkbox *ngIf="search.added" [checked]="true" />
          </div>
          <div class="search-result-confirm" *ngIf="searchResult.length > 0">
            <!--          <ui-btn size="small" type="flat" label="All"  />-->
            <!--          <ui-btn size="small" type="flat" label="None" />-->
            <ui-btn size="small" label="Close" (click)="userControl.reset()" />
          </div>
        </div>
      </ng-container>
    </div>
    <div class="added-username-list" (click)="userControl.reset()">
      <div class="username" *ngFor="let user of addedUserList$ | async">
        <div class="label">{{ user.username }}</div>
        <core-double-confirm-inline
          (confirm)="removeUsername(user.username)"
        ></core-double-confirm-inline>
      </div>
    </div>
    <ui-btn
      label="Next"
      (click)="userControl.reset()"
      [disabled]="(addedUserList$ | async)?.length === 0"
      (btnClick)="onNext()"
    />
  `,
  styles: [
    `
      :host input {
        width: 350px;
      }

      .search {
        margin: 20px 0;
      }

      .search-result-wrapper {
        border: 1px solid var(--border);
        position: absolute;
        background-color: #ffffff;
        z-index: 3;
        width: 350px;
        max-height: 300px;
        overflow: auto;
      }

      .search-result-confirm {
        position: sticky;
        bottom: 0;
        background-color: var(--bg-color);
        display: flex;
        justify-content: center;
        padding: var(--spacing-2) var(--spacing-3);
        border-top: 1px solid var(--border);
      }

      .result-item {
        padding: var(--spacing-2) var(--spacing-3);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
      }

      .result-item:hover {
        background-color: var(--hover);
      }

      .added-username-list {
        height: 350px;
        overflow: auto;
        margin: 20px 0;
      }

      .username {
        display: flex;
        justify-content: space-between;
        padding: var(--spacing-2);
        &:hover {
          background-color: var(--hover);
        }
      }
    `,
  ],
  standalone: false,
})
export class UserSearchComponent {
  @Output() userList = new EventEmitter<ProjectUser[]>();
  userControl: FormControl<string | null>;
  searchResult$: Observable<{ username: string; added: boolean }[]>;
  addedUserList$ = new BehaviorSubject<ProjectUser[]>([]);

  constructor(private api: ApiService) {
    this.userControl = new FormControl<string | null>('');
    this.searchResult$ = combineLatest([
      this.search(),
      this.addedUserList$.asObservable(),
    ]).pipe(
      map(([usernameList, addedUserList]) => {
        return usernameList.map((username) => {
          const added = addedUserList.map((user) => user.username);
          return {
            username,
            added: added.includes(username),
          };
        });
      }),
    );
  }

  onUsernameSelected(username: string): void {
    const users = this.addedUserList$.getValue();
    const userAdded = users.findIndex((u) => u.username === username) > -1;
    if (!userAdded) {
      this.addedUserList$.next([
        ...users,
        {
          username,
          role: 'USER',
          formList: [],
          preferences: {},
        },
      ]);
    }

    if (userAdded) this.removeUsername(username);
  }

  removeUsername(username: string): void {
    const users = this.addedUserList$.getValue();
    this.addedUserList$.next(users.filter((u) => u.username !== username));
  }

  onNext(): void {
    const userList = this.addedUserList$.getValue();
    if (userList.length > 0) this.userList.emit(userList);
  }

  private search(): Observable<string[]> {
    return this.userControl.valueChanges.pipe(
      debounceTime(150),
      switchMap((inputValue) => {
        return inputValue !== null && inputValue.length > 0
          ? this.api.getUserList(inputValue)
          : of([]);
      }),
    );
  }
}
