import { Component } from '@angular/core';

@Component({
  selector: 'app-resource-id-warning',
  template: `
    <div class="wrapper">
      <div class="title">Potential Cache Issue Ahead</div>

      <p>
        Every Resource file has a unique identifier (UUID) given by the system
        upon creation.
      </p>

      <p class="alert">
        This identifier <b>will not</b> change when you upload a new file.
        <br />
        This could lead to caching issues on your devices.
      </p>

      <p>
        To avoid cache issues, it is recommended to create a new Resource
        instead.
      </p>
    </div>
  `,
  styles: [
    `
      .wrapper {
        background-color: var(--bg-color);
        padding: var(--spacing-3);
        max-width: 550px;
        border-radius: var(--radius-1);
      }

      .title {
        text-align: center;
        font-weight: var(--font-weight-3);
      }

      .alert {
        border: 1px solid var(--warn-color);
        padding: var(--spacing-2);
        background-color: rgba(255, 193, 7, 0.3);
        border-radius: var(--radius-1);
        text-align: center;
      }
    `,
  ],
  standalone: false,
})
export class ResourceIdWarningComponent {}
