import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  faArrowRight,
  faChevronDown,
  faChevronUp,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FormSpecificLogic, QuestionLogic } from '@trim-web-apps/project-core';
import {
  insertElementArrayAtIndex,
  removeElementFromArrayByIndex,
} from '@trim-web-apps/core';

@Component({
  selector: 'app-logic-item[logic]',
  template: `
    <div class="header show-on-hover-toggle" (click)="show = !show">
      <div class="questions-labels">
        <div class="q1 row text-ellipsis">{{ logic.q1.name }}</div>
        <div class="row q2 text-ellipsis">
          <fa-icon [icon]="iconArrow"></fa-icon>
          <div class="q2">{{ logic.q2.name }}</div>
        </div>
      </div>

      <div class="actions show-on-hover">
        <fa-icon [icon]="iconRemove" (click)="remove.emit(logic)"></fa-icon>
        <fa-icon [icon]="show ? iconCollapse : iconExpand"></fa-icon>
      </div>
    </div>

    <ng-container *ngIf="show">
      <div class="logic" *ngFor="let q1 of logic.q1.options">
        <div class="label text-ellipsis">{{ q1 }}</div>
        <div class="label">
          <app-logic-r2-selector
            [r1]="q1"
            [logic]="logic"
            (r2ListChange)="onR2ListChange(q1, $event)"
          >
          </app-logic-r2-selector>
        </div>
      </div>
    </ng-container>
  `,
  styles: [
    `
      .header {
        display: flex;
        justify-content: space-between;
      }

      .questions-labels {
        width: 80%;
        color: var(--primary-color);
        cursor: pointer;
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;
      }

      .actions {
        display: flex;
        align-items: center;
      }

      .q1 {
        margin: 10px 0 5px 0;
      }

      .q2 {
        display: flex;
      }

      .q2 fa-icon {
        margin: 0 10px;
      }

      .logic {
        display: flex;
        margin-bottom: 5px;
        align-items: center;
        width: 100%;
      }

      .actions fa-icon {
        margin: 0 5px;
        padding: 0 5px;
        color: #333;
      }

      .logic .label:first-child {
        padding-right: var(--spacing-2);
      }

      .label {
        width: 50%;
      }
    `,
  ],
  standalone: false,
})
export class LogicItemComponent implements OnInit {
  @Input() logic!: FormSpecificLogic;
  @Output() remove: EventEmitter<FormSpecificLogic>;
  @Output() logicChange: EventEmitter<FormSpecificLogic>;
  isListOrCheck: boolean | undefined;
  show: boolean;
  iconRemove = faTrash;
  iconExpand = faChevronDown;
  iconCollapse = faChevronUp;
  iconArrow = faArrowRight;

  constructor() {
    this.remove = new EventEmitter<FormSpecificLogic>();
    this.logicChange = new EventEmitter<FormSpecificLogic>();
    this.show = false;
  }

  ngOnInit(): void {
    const type = this.logic.q2.type;
    this.isListOrCheck = type === 'LIST' || type == 'CHECK';
  }

  onR2ListChange(r1: string, r2List: string[]): void {
    const qLogic = { r1, r2: r2List };
    const qLogicIndex = this.logic.logic.findIndex((l) => l.r1 === r1);
    const type = this.logic.q2.type;
    const isListOrCheck = type === 'LIST' || type == 'CHECK';

    if (isListOrCheck) {
      const newQuestionLogic =
        qLogicIndex > -1
          ? insertElementArrayAtIndex<QuestionLogic>(
              this.logic.logic,
              qLogic,
              qLogicIndex,
            )
          : [...this.logic.logic, qLogic];
      this.logicChange.emit({ ...this.logic, logic: newQuestionLogic });
    } else {
      const qLogicExists = qLogicIndex !== -1;
      const newQuestionLogic = qLogicExists
        ? removeElementFromArrayByIndex<QuestionLogic>(
            this.logic.logic,
            qLogicIndex,
          )
        : [...this.logic.logic, qLogic];
      this.logicChange.emit({ ...this.logic, logic: newQuestionLogic });
    }
  }
}
