import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ProjectActions from './project.actions';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ApiService } from 'libs/api3map/src/lib/api.service';
import { MarkerService } from '../../marker.service';
import { AuthActions } from '../../../auth/state/auth';
import { WeatherService } from '@trim-web-apps/weather-core';
import { NotificationService } from '@trim-web-apps/core';

@Injectable({ providedIn: 'root' })
export class ProjectEffects {
  constructor(
    private action$: Actions,
    private api: ApiService,
    private markerService: MarkerService,
    private weatherService: WeatherService,
    private notify: NotificationService,
  ) {}

  fetchProject$ = createEffect(() =>
    this.action$.pipe(
      ofType(ProjectActions.fetchProject),
      tap(() => this.markerService.reset()),
      switchMap(() =>
        this.api.getProject().pipe(
          // delay(5000),
          switchMap((project) => {
            return this.markerService
              .getProjectIconsBase64(project)
              .pipe(
                map((iconsFetched) =>
                  iconsFetched
                    ? ProjectActions.fetchProjectSuccess({ project })
                    : ProjectActions.fetchProjectError(),
                ),
              );
          }),
          catchError(() => of(ProjectActions.fetchProjectError())),
        ),
      ),
    ),
  );

  fetchProjectError$ = createEffect(() =>
    this.action$.pipe(
      ofType(ProjectActions.fetchProjectError),
      map(() => {
        this.notify.error(
          'Not authorized or session expired - please log in again',
          10000,
        );
        return AuthActions.authLogout();
      }),
    ),
  );

  closeProject$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(ProjectActions.closeProject),
        tap(() => this.resetServices()),
      ),
    { dispatch: false },
  );

  private resetServices(): void {
    this.markerService.reset();
    this.weatherService.reset();
  }
}
