import { createAction, props } from '@ngrx/store';
import { Resource } from '3map-models';

export const createResource = createAction(
  '[ ResourceList ] Create Resource',
  props<{ resource: Resource; file: File }>(),
);

export const removeResource = createAction(
  '[ ResourceList ] Remove Resource',
  props<{ id: string }>(),
);

export const setResourceName = createAction(
  '[ Resource ] Set Resource Name',
  props<{ id: string; name: string }>(),
);

export const updateResourceFile = createAction(
  '[ Resource ] Update Resource Data',
);

export const setResourceStyle = createAction(
  '[ Resource ] Set Resource Style',
  props<{ id: string; style: any }>(),
);

export const setResourceBbox = createAction(
  '[ Resource ] Set Resource BBOX',
  props<{ id: string; boundingBox: number[][] }>(),
);
