import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { AuthActions, AuthSelectors } from '../state/auth';

@Injectable()
export class AuthAdminGuard {
  constructor(
    private router: Router,
    private store: Store,
  ) {}

  public canActivateOld(): Observable<boolean> {
    return this.store.pipe(
      select(AuthSelectors.selectIsAdminLogged()),
      tap((isLogged) => {
        if (!isLogged) this.router.navigate(['auth']);
      }),
    );
  }

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    console.log('admin guard auth');
    const redirectPath = route.data['redirectPath'];

    return this.store.pipe(
      select(AuthSelectors.selectIsAdminLogged()),
      tap((isLogged) => {
        if (!isLogged && redirectPath !== undefined) {
          this.store.dispatch(AuthActions.authLogout());
          this.router.navigate([redirectPath]);
        } else if (!isLogged && redirectPath === undefined) {
          console.warn(
            `[ Auth Guard ] route with path: '${route.routeConfig?.path}' has no redirectPath`,
          );
          this.router.navigate(['auth']);
        }
      }),
    );
  }

  private getFallbackRoute(): Observable<string | undefined> {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => {
        const root = this.router.routerState.snapshot.root;
        return this.lastChild(root).data['fallbackRoute'];
      }),
    );
  }

  private lastChild(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    return route.firstChild ? this.lastChild(route.firstChild) : route;
  }
}
