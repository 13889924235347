import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { addDuplicatedForm, onDuplicateForm } from './form.actions';
import { map, switchMap, take } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromMarkerStyle from './marker-style.selectors';
import * as fromForm from './form.selectors';
import { Form, FormSpecific } from '3map-models';
import { cloneSpecific } from './form-specific.functions';
import * as uuid from 'uuid';
import { createUniqueName } from '@trim-web-apps/core';

@Injectable({ providedIn: 'root' })
export class FormEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
  ) {}

  onDuplicate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(onDuplicateForm),
      switchMap(({ originForm }) =>
        combineLatest([
          this.store.pipe(select(fromMarkerStyle.selectEntities())),
          this.store.pipe(select(fromForm.selectFormList())),
          of(originForm),
        ]).pipe(take(1)),
      ),
      map(([markerStyleDict, existingForms, originForm]) => {
        const existingFormNames = existingForms.map((f) => f.name);
        const newSpecificList: FormSpecific[] = [];
        const icons: { specificId: string; imgBase64List: string[] }[] = [];

        for (const specific of originForm.specificList) {
          const imgBase64List =
            markerStyleDict[specific.id]?.imgBase64List || [];
          const newSpecific = cloneSpecific(specific);
          newSpecificList.push({ ...newSpecific, name: specific.name });
          icons.push({ specificId: newSpecific.id, imgBase64List });
        }

        const newForm: Form = {
          ...originForm,
          id: uuid.v4(),
          name: createUniqueName(existingFormNames, originForm.name),
          specificList: newSpecificList,
        };
        return addDuplicatedForm({ form: newForm, icons });
      }),
    ),
  );
}
