import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WeatherModelMetadata } from '@trim-web-apps/weather-models';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { ModelPickerMenuData } from './weather-model-picker.component';

@Component({
    selector: 'weather-core-model-picker-desktop',
    template: `
    <div class="menu-bar" *ngIf="modelPickerMenuData">
      @for (cat of modelPickerMenuData | keyvalue; track cat.key) {
        <div class="btn-list">
          <ui-btn
            cdkMenuItem
            [cdkMenuTriggerFor]="subCat"
            [fullWidth]="true"
            [label]="$any(cat.key) | weatherCategory"
          />
        </div>

        <ng-template #subCat>
          <div class="menu shadow" cdkMenu>
            @for (subCat of cat.value | keyvalue; track subCat.key) {
              <div
                class="trigger sub-cat-label"
                cdkMenuItem
                [cdkMenuTriggerFor]="model"
              >
                {{ $any(subCat.key) | weatherSubCategory }}
                <fa-icon [icon]="iconCaret" />
              </div>
              <ng-template #model>
                <div class="menu shadow" cdkMenu>
                  @for (model of subCat.value; track model) {
                    <div
                      class="trigger"
                      cdkMenuItem
                      (click)="modelSelect.emit(model)"
                    >
                      <div class="label">{{ model.label }}</div>
                      <div class="res">{{ model.info | resolutionLabel }}</div>
                    </div>
                  }
                </div>
              </ng-template>
            }
          </div>
        </ng-template>
      }
    </div>
  `,
    styles: [
        `
      .menu-bar {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .menu {
        border-radius: 10px;
        overflow: clip;
        margin: 0 var(--spacing-1) var(--spacing-1) var(--spacing-1);
      }

      .trigger {
        padding: var(--spacing-2) var(--spacing-2);
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: var(--spacing-4);
        background-color: #fff;
        min-width: 150px;
        &:hover {
          background-color: var(--hover);
          cursor: pointer;
        }
      }

      ui-btn {
        margin-right: var(--spacing-1);
      }

      .btn-list {
        display: flex;
        justify-content: center;
      }

      .res {
        color: var(--grey);
        font-size: var(--font-size-2);
      }
    `,
    ],
    standalone: false
})
export class WeatherModelPickerDesktopComponent {
  @Input() modelPickerMenuData?: ModelPickerMenuData;
  @Output() modelSelect = new EventEmitter<WeatherModelMetadata>();
  iconCaret = faCaretRight;
}
