import { WeatherModelInitConfig, WeatherModelMetadata } from '../types';
import { WeatherModel } from '../types/weather.model';
import { WeatherLayer } from '../types/weather-layer.type';
import { CURRENT } from '../weather-layers/weather.layers';
import {
  createTsListForecast,
  decodeForecastSpec,
  getBaseModelTemplate,
  getDefaultDateRange,
  removeUnwantedLayers,
} from '../utils';

export const SHEVENINGEN: WeatherModelMetadata = {
  id: 'SHEVENINGEN',
  label: 'Sheveningen',
  category: 'FORECAST',
  subCategory: 'LOCAL_MODELS',
  info: {
    resolution: { deg: 0.007, km: 0.8 },
    url: 'https://data.fcoo.dk/webmap/v2/data/',
    credits: 'FCOO',
  },
  bbox: [
    [3.49973, 51.64803],
    [5.50229, 51.64803],
    [5.50229, 52.46897],
    [3.49973, 52.46897],
    [3.49973, 51.64803],
  ],
};

export function createScheveningen(
  serverSpec: unknown,
  config?: WeatherModelInitConfig,
): WeatherModel {
  const spec = decodeForecastSpec(serverSpec);
  const timestepList = createTsListForecast(spec.initimeList, 109);
  const defaultDateRange = getDefaultDateRange(timestepList, 0, 108);
  const timestepType = { type: 'UNIX', interval: 1800 } as const;

  const schevLayers: WeatherLayer[] = [
    {
      ...CURRENT,
      timestepType,
      timestepList,
      defaultDateRange,
    },
  ];

  const layers = removeUnwantedLayers(schevLayers, config?.layerIdList ?? []);

  if (layers.length === 0)
    throw new Error(`No layers available for ${spec.model} model`);

  return {
    ...getBaseModelTemplate(config),
    ...SHEVENINGEN,
    layers: layers,
    initime: layers[0].timestepList[0].initime,
    timestep: layers[0].defaultDateRange.from,
    selectedLayerId: layers[0].id,
    dateRange: layers[0].defaultDateRange,
  };
}
