import {
  createTsListForecast,
  decodeForecastSpec,
  getBaseModelTemplate,
  getDefaultDateRange,
  removeUnwantedLayers,
} from '../utils';
import { WeatherModelInitConfig, WeatherModelMetadata } from '../types';
import { WeatherModel } from '../types/weather.model';
import { WeatherLayer } from '../types/weather-layer.type';
import { CURRENT } from '../weather-layers/weather.layers';

export const AARHUS: WeatherModelMetadata = {
  id: 'AARHUS',
  label: 'AARHUS',
  category: 'FORECAST',
  subCategory: 'LOCAL_MODELS',
  info: {
    resolution: { deg: 0.009, km: 1 },
    url: 'https://data.fcoo.dk/webmap/v2/data/',
    credits: 'FCOO',
  },
  bbox: [
    [9.3981474, 56.3388888],
    [15.0740736, 56.3388888],
    [15.0740736, 53.5055547],
    [9.3981474, 53.5055547],
    [9.3981474, 56.3388888],
  ],
};

export function createAarhus(
  serverSpec: unknown,
  config?: WeatherModelInitConfig,
): WeatherModel {
  const spec = decodeForecastSpec(serverSpec);
  const ts = createTsListForecast(spec.initimeList, 55);
  const defaultDateRng = getDefaultDateRange(ts, 0, 54);
  const timestepType = { type: 'UNIX', interval: 3600 } as const;

  const aarhusLayers: WeatherLayer[] = [
    {
      ...CURRENT,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
  ];

  const layers = removeUnwantedLayers(aarhusLayers, config?.layerIdList ?? []);

  if (layers.length === 0)
    throw new Error(`No layers available for ${spec.model} model`);

  return {
    ...getBaseModelTemplate(config),
    ...AARHUS,
    layers,
    initime: layers[0].timestepList[0].initime,
    timestep: layers[0].defaultDateRange.from,
    selectedLayerId: layers[0].id,
    dateRange: layers[0].defaultDateRange,
  };
}
