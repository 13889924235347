import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-sidenav-item',
  template: `
    <div class="item" [class.active]="active">
      <fa-icon *ngIf="icon" [icon]="icon" />
      <div class="label">{{ label }}</div>
    </div>
  `,
  styles: [
    `
      .item {
        padding: var(--spacing-2) var(--spacing-1);
        display: flex;
        border-radius: var(--radius-2);
        align-items: center;
        cursor: pointer;
      }

      .label {
        font-weight: var(--font-weight-3);
        flex: 1 1 auto;
      }

      fa-icon,
      .label {
        color: var(--grey);
        padding: 0 var(--spacing-2);
      }

      .item.active fa-icon,
      .item.active .label {
        color: var(--bg-color) !important;
      }

      .item:hover {
        background-color: var(--hover);
      }

      .active {
        background-color: var(--primary-color) !important;
        color: var(--bg-color) !important;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SidenavItemComponent {
  @Input() label?: string;
  @Input() icon?: IconDefinition;
  @Input() active?: boolean;
}
