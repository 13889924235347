import { Action, createReducer, on } from '@ngrx/store';
import {
  deployMarkers,
  deployMarkersError,
  deployMarkersSuccess,
  deployProject,
  deployProjectError,
  deployProjectSuccess,
  deployResources,
  deployResourcesError,
  deployResourcesSuccess,
  reset,
} from './deploy.actions';
import { DeployStatus } from '../models/DeployStatus';
// import { AdminActions } from '../../+state';
import * as AdminActions from '../../+state/admin.actions';
import { AddProjectError } from '@trim-web-apps/api3map';

export const ADMIN_DEPLOY_FEATURE_KEY = 'admin-deploy';

export interface State {
  project: DeployStatus;
  markers: DeployStatus;
  resources: DeployStatus;
  validationErrorList: AddProjectError[] | null;
}

export const initialState: State = {
  project: 'STAGED',
  markers: 'STAGED',
  resources: 'STAGED',
  validationErrorList: null,
};

// TODO handle errors (keep records list)
const deployReducer = createReducer(
  initialState,
  on(
    reset,
    AdminActions.close,
    AdminActions.initProjectSuccess,
    AdminActions.initProjectError,
    (): State => ({ ...initialState }),
  ),
  on(
    deployProject,
    (state): State => ({
      ...state,
      project: 'PENDING',
      validationErrorList: null,
    }),
  ),
  on(
    deployProjectSuccess,
    (state): State => ({ ...state, project: 'DEPLOYED' }),
  ),
  on(
    deployProjectError,
    (state, { errorList }): State => ({
      ...state,
      project: 'ERROR',
      validationErrorList: errorList,
    }),
  ),
  on(deployMarkers, (state): State => ({ ...state, markers: 'PENDING' })),
  on(
    deployMarkersSuccess,
    (state): State => ({ ...state, markers: 'DEPLOYED' }),
  ),
  on(deployMarkersError, (state): State => ({ ...state, markers: 'ERROR' })),
  on(deployResources, (state): State => ({ ...state, resources: 'PENDING' })),
  on(
    deployResourcesSuccess,
    (state): State => ({ ...state, resources: 'DEPLOYED' }),
  ),
  on(
    deployResourcesError,
    (state): State => ({ ...state, resources: 'PENDING' }),
  ),
);

export function reducer(state: State | undefined, action: Action): State {
  return deployReducer(state, action);
}
