import { WeatherTimestep } from './types/weather.timestep';
import { WeatherLayer } from './types/weather-layer.type';
import { WeatherModelInitConfig } from './types';

export function getBaseModelTemplate(config?: WeatherModelInitConfig) {
  return {
    visible: config?.visible ?? true,
    interpolate: config?.interpolate ?? 'bicubicInterpolation',
    chartEnabled: config?.chartEnabled ?? false,
    tiffLoading: false,
    chartData: [],
    chartDataLoading: false,
    chartMode: 'compact',
    windStyle: 'Arrow',
    hasChart: true,
    tiffFetchError: false,
  };
}

export function decodeForecastSpec(spec: any): {
  model: string;
  initimeList: number[];
} {
  return {
    model: parseString(spec['model']),
    initimeList: parseNumberArray(spec['initimeList']),
  };
}

export function decodeGfsHistorySpec(spec: any): {
  model: string;
  timestepList: number[];
  timestepMonthList: number[];
} {
  return {
    model: parseString(spec['model']),
    timestepList: parseNumberArray(spec['timestepList']),
    timestepMonthList: parseNumberArray(spec['timestepMonthList']),
  };
}

export function decodeGpmLikeSpec(spec: any): {
  model: string;
  timestepList: number[];
  timestepMonthList: number[];
  timestepQuarterList: number[];
  timestepSemesterList: number[];
  timestepYearList: number[];
  latestUpdate: number;
} {
  return {
    model: parseString(spec['model']),
    timestepList: parseNumberArray(spec['timestepList']),
    timestepMonthList: parseNumberArray(spec['timestepMonthList']),
    timestepQuarterList: parseNumberArray(spec['timestepQuarterList']),
    timestepSemesterList: parseNumberArray(spec['timestepSemesterList']),
    timestepYearList: parseNumberArray(spec['timestepYearList']),
    latestUpdate: parseNumber(spec['latestUpdate']),
  };
}

export function decodeNdviSpec(spec: any): {
  model: string;
  timestepDekadalList: number[];
  latestUpdate: number;
} {
  return {
    model: parseString(spec['model']),
    timestepDekadalList: parseNumberArray(spec['timestepDekadalList']),
    latestUpdate: parseNumber(spec['latestUpdate']),
  };
}

export function decodeCpcSpec(spec: any): {
  model: string;
  timestepList: number[];
} {
  return {
    model: parseString(spec['model']),
    timestepList: parseNumberArray(spec['timestepList']),
  };
}

export function createTsListForecast(
  initimeList: number[],
  tsCount: number,
): WeatherTimestep[] {
  return initimeList
    .sort((a, b) => b - a)
    .map((ini) => ({
      initime: ini,
      timesteps: new Array(tsCount).fill(null).map((_, i) => i),
    }));
}

export function createTsListGpm(
  timesteps: number[],
  isMonth: boolean,
): WeatherTimestep[] {
  timesteps.sort();
  return [{ initime: isMonth ? -2 : -1, timesteps }];
}

export function getDefaultDateRange(
  tsList: WeatherTimestep[],
  from: number,
  to?: number,
): {
  from: number;
  to: number;
} {
  if (to === undefined) to = tsList[0].timesteps.length - 1;
  return {
    from: tsList[0].timesteps[from],
    to: tsList[0].timesteps[to],
  };
}

export function getTsListRainfall(
  tsList: WeatherTimestep[],
): WeatherTimestep[] {
  return tsList.map((t) => ({ ...t, timesteps: t.timesteps.slice(1) }));
}

export function removeUnwantedLayers(
  layerList: WeatherLayer[],
  allowedModelIdList: string[],
): WeatherLayer[] {
  return allowedModelIdList.length > 0
    ? layerList.filter((l) => allowedModelIdList.includes(l.id))
    : layerList;
}

function parseString(input: unknown): string {
  if (typeof input !== 'string')
    throw new Error(`Expected string, got "${input}"`);
  return input;
}

function parseNumber(input: unknown): number {
  const num = parseInt(`${input}`, 10);
  if (isNaN(num)) throw new Error(`Expected number, got "${input}"`);
  return num;
}

function parseNumberArray(input: unknown): number[] {
  if (!Array.isArray(input))
    throw new Error(`Expected array of numbers, got "${input}"`);
  const list = input.map((t) => parseInt(t, 10));
  if (list.some((t) => isNaN(t)))
    throw new Error(`Expected array of numbers, got "${input}"`);
  return list;
}
