import { ChangeDetectionStrategy, Component } from '@angular/core';
import * as TableSelectors from '../../+state/table.selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Form } from '3map-models';
import * as TableActions from '../../+state/table.actions';
import * as SidenavSelectors from '../../../sidenav/+state/sidenav.selectors';

@Component({
  selector: 'app-table-list',
  template: `
    <div
      class="table-list-wrapper"
      *ngIf="datasetList$ | async as dsList"
      [class.sidebar-open]="sidebarOpen$ | async"
    >
      <ng-container *ngIf="dsList.length > 0">
        <app-table-tabs
          [datasetList]="dsList"
          (datasetSelected)="onDatasetSelected($event)"
        />
        <app-table
          *ngFor="let ds of dsList; trackBy: trackBy"
          [ngStyle]="{ display: ds.active ? 'block' : 'none' }"
          [datasetId]="ds.form.id"
        />
      </ng-container>

      <div *ngIf="dsList.length === 0" class="no-ds-list-alert primary-text">
        <div>Nothing to show</div>
        <div>Please enable a Form Collection</div>
      </div>
    </div>
  `,
  styles: [
    `
      .table-list-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      app-table {
        flex: 1;
        padding: var(--spacing-2);
      }

      .sidebar-open {
        margin-left: 240px; /* 300px sidenav width - 70px sidebar (closed)  */
      }

      .no-ds-list-alert {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: var(--grey);
      }

      .no-ds-list-alert div {
        margin: 10px 0;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TableListComponent {
  datasetList$: Observable<{ form: Form; active: boolean }[]>;
  sidebarOpen$: Observable<boolean>;

  constructor(private store: Store) {
    this.datasetList$ = this.store.select(TableSelectors.selectTableList());
    this.sidebarOpen$ = this.store.select(SidenavSelectors.selectSidenavOpen());
  }

  onDatasetSelected(tableId: string) {
    this.store.dispatch(TableActions.setActiveTable({ tableId }));
  }

  trackBy = (index: number, item: { form: Form; active: boolean }) =>
    item.form.id;
}
