import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { SidenavData } from '../../+types/sidenav-data.type';
import { faAnglesLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Dialog } from '@angular/cdk/dialog';
import { SettingsComponent } from '../settings/settings.component';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons/faTriangleExclamation';

@Component({
  selector: 'app-sidenav-header',
  template: `
    <div class="logo-nav-item-wrapper" *ngIf="data">
      <div class="row-logo">
        <div class="logo">
          <img
            class="logo-full"
            [src]="data.opened ? logoWhite : logoWhiteSm"
            (click)="logoClick()"
            alt="logo"
          />
        </div>

        <div class="sidebar-toggle" *ngIf="data.opened">
          <fa-icon [icon]="toggleIcon" (click)="collapseSidenav()"></fa-icon>
        </div>
      </div>

      <div class="row-project" [class.collapsed]="!data.opened">
        <div class="project-name" *ngIf="data.opened">
          {{ data.project.name }}
        </div>
        <fa-icon [icon]="iconInfo" (click)="clickInfo()" />
        <fa-icon
          class="alert-icon"
          *ngIf="data.totalRemoteCount > data.recordList.length && data.opened"
          [icon]="iconAlert"
          (click)="clickInfo()"
        />
      </div>
    </div>
  `,
  styles: [
    `
      .logo-nav-item-wrapper {
        padding: var(--spacing-3);
        background-color: var(--primary-color);
        color: var(--bg-color);
      }

      .row-logo {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .row-project.collapsed {
        display: flex;
        justify-content: center;
      }

      .logo img {
        height: 30px;
        cursor: pointer;
      }

      fa-icon {
        cursor: pointer;
      }

      .row-project {
        display: flex;
        padding-top: var(--spacing-3);
        justify-content: right;
      }

      .project-name {
        font-size: var(--font-size-2);
        color: var(--bg-color);
        font-weight: var(--font-weight-3);
        padding: 0 var(--spacing-3);
      }

      .alert-icon {
        color: var(--warn-color);
        margin-left: var(--spacing-2);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SidenavHeaderComponent {
  @Input() data?: SidenavData;
  @Output() toggleSidenavOpened = new EventEmitter<boolean>();
  toggleIcon = faAnglesLeft;
  iconInfo = faInfoCircle;
  iconAlert = faTriangleExclamation;
  logoWhite = 'assets/3map-project/trim_white_simple.png';
  logoWhiteSm = 'assets/3map-project/trim_white_simple_sm.png';

  constructor(private dialog: Dialog) {}

  logoClick(): void {
    if (!this.data?.opened) this.toggleSidenavOpened.emit(true);
  }

  clickInfo(): void {
    if (this.data) this.dialog.open(SettingsComponent, { data: this.data });
  }

  collapseSidenav(): void {
    this.toggleSidenavOpened.emit(false);
  }
}
