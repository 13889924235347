import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectSelectors } from '../../project/+state';
import * as fromSidenav from './sidenav.reducer';
import { AuthSelectors } from '../../../auth/state/auth';
import { RecordSelectors } from '../../record/+state';
import { SidenavData } from '../+types/sidenav-data.type';
import { MapResourceSelectors } from '../../map-resource/+state';

const selectSidenavState = () =>
  createFeatureSelector<fromSidenav.State>(fromSidenav.FEATURE_KEY);

export const selectSidenavOpen = () =>
  createSelector(selectSidenavState(), (state) => state.opened);

export const selectSection = () =>
  createSelector(selectSidenavState(), (state) => state.section);

export const selectSidenavDatasetList = () =>
  createSelector(
    ProjectSelectors.selectUserForms(),
    ProjectSelectors.selectDatasetIds(),
    (formList, datasetIds) => {
      return formList.map((form) => {
        return { form, enabled: (datasetIds as string[]).includes(form.id) };
      });
    },
  );

const selectFromAuth = () =>
  createSelector(
    AuthSelectors.selectAuthUsername(),
    AuthSelectors.selectIsAdmin(),
    AuthSelectors.selectProjectToken(),
    (username, isAdmin, projectToken) => {
      return username && projectToken
        ? { username, isAdmin, projectToken }
        : null;
    },
  );

export const selectSidenavData = () =>
  createSelector(
    selectSidenavOpen(),
    ProjectSelectors.selectProject(),
    selectSection(),
    selectSidenavDatasetList(),
    MapResourceSelectors.selectMapResourceList(),
    RecordSelectors.selectRecordFetchLoading(),
    selectFromAuth(),
    RecordSelectors.selectRecords(),
    RecordSelectors.selectRecordsRemoteCount(),
    (
      opened,
      project,
      section,
      formDatasetList,
      mapResourceList,
      recordLoading,
      authData,
      recordList,
      totalRemoteCount,
    ): SidenavData | null => {
      if (!project || !authData) return null;
      return {
        opened,
        project,
        section,
        formDatasetList,
        mapResourceList,
        recordLoading,
        ...authData,
        recordList,
        totalRemoteCount,
      };
    },
  );
