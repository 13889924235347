import { Component, Inject } from '@angular/core';
import { WeatherModel } from '@trim-web-apps/weather-models';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'weather-core-model-info',
    template: `
    <div class="model-info-wrapper shadow">
      <div class="model-info" *ngIf="model">
        <div class="info-row">
          <div class="label">Name</div>
          <div class="value">{{ model.label }}</div>
        </div>

        <ng-container *ngIf="model.info">
          <div class="info-row" *ngIf="model.info.latestUpdate">
            <div class="label">Latest update</div>
            <div class="value">
              {{
                model.info.latestUpdate * 1000
                  | date: 'dd MMM YYYY - hh:mm' : '+00:00'
              }}
              UTC
            </div>
          </div>

          <div class="info-row" *ngIf="model.info.resolution">
            <div class="label">Resolution</div>
            <div class="value">
              {{ model.info | resolutionLabel: 'deg' }}
              (~{{ model.info | resolutionLabel }})
            </div>
          </div>

          <div class="info-row" *ngIf="model.info.coverage">
            <div class="label">Coverage</div>
            <div class="value">{{ model.info.coverage }}</div>
          </div>

          <div class="info-row" *ngIf="model.info.credits && model.info.url">
            <div class="label">Source</div>
            <div class="value url" (click)="openUrl(model.info.url)">
              {{ model.info.credits }}
              <fa-icon [icon]="iconUrl"></fa-icon>
            </div>
          </div>

          <div class="info-row scheduled">
            <div class="label">Scheduled download</div>
            <ng-container *ngIf="model.info.schedule; else noSchedule">
              <div class="value" *ngFor="let s of model.info.schedule">
                <div class="schedule-left">
                  {{ model.category === 'FORECAST' ? 'init' : '' }}
                  {{ s.init }}
                </div>
                ->
                <div class="schedule-right">{{ s.time }} UTC</div>
              </div>
            </ng-container>
            <ng-template #noSchedule>
              <div class="value">Currently not scheduled</div>
            </ng-template>
          </div>
        </ng-container>

        <div class="info-row">
          <div class="label">Layers list</div>
          <div class="value value-list">
            <div class="item" *ngFor="let layer of model.layers">
              <div class="layer-name text-ellipsis">{{ layer.label }}</div>
              <div class="layer-unit">{{ layer.unit }}</div>
            </div>
          </div>
        </div>

        <ng-container *ngIf="!model.info">
          <div class="model-info-nope" *ngIf="!model">
            Model details not available
          </div>
        </ng-container>
      </div>
    </div>
  `,
    styles: [
        `
      .model-info-wrapper {
        background-color: #ffffff;
        padding: 10px;
        overflow: auto;
        max-height: 80vh;
        user-select: text;
        border-radius: 7px;
        cursor: text;
      }

      .info-row {
        margin-bottom: 20px;
      }

      .label {
        margin-bottom: 5px;
        color: var(--primary-color);
      }

      .value {
        margin: 0 10px;
      }

      .item {
        display: flex;
        justify-content: space-between;
      }

      .layer-name {
        margin-bottom: 5px;
        padding-right: 10px;
      }

      .layer-unit {
        white-space: nowrap;
        margin-bottom: 5px;
        color: var(--grey);
      }

      .model-info-nope {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .url {
        cursor: pointer;
        display: flex;
        align-items: center;
      }

      .url fa-icon {
        margin-left: 5px;
      }

      .scheduled .value {
        display: flex;
        align-items: center;
      }

      .schedule-left {
        width: 60px;
      }

      .schedule-right {
        margin-left: 10px;
      }
    `,
    ],
    standalone: false
})
export class ModelInfoComponent {
  iconUrl = faExternalLinkAlt;
  constructor(
    public dialogRef: DialogRef<string>,
    @Inject(DIALOG_DATA) public model: WeatherModel,
  ) {}

  openUrl(url?: string): void {
    if (url) window.open(url, '_blank');
  }
}
