import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProjectUser } from '3map-models';

@Component({
    selector: 'ui-role-badge',
    template: `
    <div
      class="role-badge-wrapper"
      *ngIf="user"
      [class.user]="user.role === 'USER'"
      [class.admin]="user.role === 'ADMIN'"
      [class.mod]="user.role === 'MOD'"
    >
      {{ user.role }}
    </div>
  `,
    styles: [
        `
      .role-badge-wrapper {
        color: var(--bg-color);
        padding: var(--spacing-1) var(--spacing-2);
        font-weight: var(--font-weight-3);
        font-size: var(--font-size-1);
        text-align: center;
        width: 60px;
        border-radius: var(--radius-1);
      }

      .user {
        background-color: var(--success-color);
      }

      .mod {
        background-color: var(--warn-color);
      }

      .admin {
        background-color: var(--error-color);
      }
    `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class RoleBadgeComponent {
  @Input() user?: ProjectUser;
}
