import { Component, Input, OnInit } from '@angular/core';
import { isNumber } from '@trim-web-apps/core';
import * as mapboxgl from 'mapbox-gl';
import { Store } from '@ngrx/store';
import * as ResourceActions from '../+state/resource.actions';
import { Resource } from '3map-models';

@Component({
  selector: 'app-resource-image[resource]',
  template: `
    <div class="resource-image-wrapper">
      <div class="row">
        <div class="control opacity">
          <div class="label">Opacity (0 to 1)</div>
          <input
            #opacityInput
            type="number"
            [value]="transparency"
            (input)="onOpacityChange(opacityInput.value)"
            placeholder="Transparency"
          />
        </div>

        <div class="sw control">
          <div class="label">Bottom right point (South West)</div>
          <core-lng-lat-input
            [lat]="sw?.lat"
            [lng]="sw?.lng"
            (lngLatChange)="onSwChange($event)"
          />
        </div>

        <div class="ne control">
          <div class="label">Top left point (North East)</div>
          <core-lng-lat-input
            [lat]="ne?.lat"
            [lng]="ne?.lng"
            (lngLatChange)="onNeChange($event)"
          />
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .resource-image-wrapper {
        display: flex;
        flex-direction: column;
      }

      .resource-image-wrapper .row {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
      }

      .resource-image-wrapper .row:last-child input {
        width: 150px;
      }

      .resource-image-wrapper .label {
        font-size: 0.9em;
        margin-bottom: 5px;
      }

      .resource-image-wrapper .control {
        margin-right: 30px;
      }
    `,
  ],
  standalone: false,
})
export class ResourceImageComponent implements OnInit {
  @Input() resource!: Resource;
  sw?: { lat: number; lng: number };
  ne?: { lat: number; lng: number };
  transparency?: number;

  constructor(private store: Store) {}

  ngOnInit(): void {
    const style = this.resource.style || {};
    const bbox = this.resource.boundingBox;
    this.transparency = style['raster-opacity'] ? style['raster-opacity'] : 1;
    if (bbox) {
      this.sw = { lng: bbox[0][0], lat: bbox[3][1] };
      this.ne = { lng: bbox[1][0], lat: bbox[0][1] };
    }
  }

  onSwChange(lngLat: { lat: number; lng: number }): void {
    this.sw = lngLat;
    this.setBbox();
  }

  onNeChange(lngLat: { lat: number; lng: number }): void {
    this.ne = lngLat;
    this.setBbox();
  }

  onOpacityChange(valueStr: string): void {
    const value = parseFloat(valueStr);
    if (isNumber(value))
      this.store.dispatch(
        ResourceActions.setResourceStyle({
          id: this.resource.filename,
          style: { 'raster-opacity': value },
        }),
      );
  }

  private setBbox(): void {
    if (!this.sw || !this.ne) return;

    const left = this.sw.lng < this.ne.lng ? this.sw.lng : this.ne.lng;
    const right = this.sw.lng > this.ne.lng ? this.sw.lng : this.ne.lng;
    const bottom = this.sw.lat < this.ne.lat ? this.sw.lat : this.ne.lat;
    const top = this.sw.lat > this.ne.lat ? this.sw.lat : this.ne.lat;

    const topLeft = new mapboxgl.LngLat(left, top);
    const topRight = new mapboxgl.LngLat(right, top);
    const bottomRight = new mapboxgl.LngLat(right, bottom);
    const bottomLeft = new mapboxgl.LngLat(left, bottom);

    const boundingBox = [
      topLeft.toArray(),
      topRight.toArray(),
      bottomRight.toArray(),
      bottomLeft.toArray(),
    ];

    this.store.dispatch(
      ResourceActions.setResourceBbox({
        id: this.resource.filename,
        boundingBox,
      }),
    );
  }
}
