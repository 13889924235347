import { WeatherModelInitConfig } from './types';
import { WeatherModel } from './types/weather.model';
import { createGfs } from './weather-model-list/gfs';
import { createGpm2 } from './weather-model-list/gpm2';
import { createAarhus } from './weather-model-list/aarhus';
import { createAladin } from './weather-model-list/aladin';
import { createArome } from './weather-model-list/arome';
import { createGfsAcc } from './weather-model-list/gfs_acc';
import { createGfsHistory } from './weather-model-list/gfs_history';
import { createHarmonieArome } from './weather-model-list/harmonie_arome';
import { createLammaArw3 } from './weather-model-list/lamma_arw_3';
import { createMoloch } from './weather-model-list/moloch';
import { createNdvi } from './weather-model-list/ndvi';
import { createRfe } from './weather-model-list/rfe';
import { createScheveningen } from './weather-model-list/scheveningen';
import { createWrfAarhus05 } from './weather-model-list/wrf_aarhus_05';
import { createWrfAarhus25 } from './weather-model-list/wrf_aarhus_25';
import { createWrfJapan05 } from './weather-model-list/wrf_japan_05';
import { createWrfJapan25 } from './weather-model-list/wrf_japan_25';
import { createEcmwfAcc } from './weather-model-list/ecmwf_acc';
import { createEcmwf } from './weather-model-list/ecmwf';
import { createCPC } from './weather-model-list/cpc';
import { createArome2 } from './weather-model-list/arome2';
import { createOpenWrfBal } from './weather-model-list/open_wrf_baleares';
import { createOpenWrfLig } from './weather-model-list/open_wrf_ligurian';
import { createChirps } from './weather-model-list/chirps';

export function createWeatherModel(
  id: string,
  serverSpec: unknown,
  props?: WeatherModelInitConfig,
): WeatherModel {
  if (id === 'AARHUS') return createAarhus(serverSpec, props);
  if (id === 'ALADIN') return createAladin(serverSpec, props);
  if (id === 'AROME') return createArome(serverSpec, props);
  if (id === 'AROME2') return createArome2(serverSpec, props);
  if (id === 'ECMWF') return createEcmwf(serverSpec, props);
  if (id === 'ECMWF_ACC') return createEcmwfAcc(serverSpec, props);
  if (id === 'GFS') return createGfs(serverSpec, props);
  if (id === 'GFS_ACC') return createGfsAcc(serverSpec, props);
  if (id === 'GFS_HISTORY') return createGfsHistory(serverSpec, props);
  if (id === 'GPM2') return createGpm2(serverSpec, props);
  if (id === 'HARMONIE_AROME') return createHarmonieArome(serverSpec, props);
  if (id === 'LAMMA_ARW_3') return createLammaArw3(serverSpec, props);
  if (id === 'MOLOCH') return createMoloch(serverSpec, props);
  if (id === 'NDVI') return createNdvi(serverSpec, props);
  if (id === 'RFE') return createRfe(serverSpec, props);
  if (id === 'SHEVENINGEN') return createScheveningen(serverSpec, props);
  if (id === 'WRF_AARHUS_05') return createWrfAarhus05(serverSpec, props);
  if (id === 'WRF_AARHUS_25') return createWrfAarhus25(serverSpec, props);
  if (id === 'WRF_JAPAN_05') return createWrfJapan05(serverSpec, props);
  if (id === 'WRF_JAPAN_25') return createWrfJapan25(serverSpec, props);
  if (id === 'CPC') return createCPC(serverSpec, props);
  if (id === 'ECMWF') return createEcmwf(serverSpec, props);
  if (id === 'OPEN_WRF_BALEARES') return createOpenWrfBal(serverSpec, props);
  if (id === 'OPEN_WRF_LIGURIAN') return createOpenWrfLig(serverSpec, props);
  if (id === 'CHIRPS') return createChirps(serverSpec, props);

  throw new Error(`Unknown modelId: ${id}`);
}
