import { Component, EventEmitter, Input, Output } from '@angular/core';
import { isValidLatitude, isValidLongitude } from '../../utils/core.utils';

@Component({
    selector: 'core-lng-lat-input',
    template: `
    <div class="lng-lat-wrapper">
      <input
        #lngInput
        [(ngModel)]="lng"
        (input)="onInputChange()"
        placeholder="Longitude"
        type="number"
      />
      <input
        #latInput
        [(ngModel)]="lat"
        (input)="onInputChange()"
        placeholder="Latitude"
        type="number"
      />
    </div>
  `,
    styles: [
        `
      .lng-lat-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
      }

      input {
        max-width: 150px;
      }
    `,
    ],
    standalone: false
})
export class LngLatInputComponent {
  @Input() lng?: number;
  @Input() lat?: number;
  @Output() lngLatChange = new EventEmitter<{ lng: number; lat: number }>();

  onInputChange(): void {
    if (this.lng === undefined || this.lat === undefined) return;
    if (!isValidLongitude(this.lng) || !isValidLatitude(this.lat)) return;
    this.lngLatChange.emit({ lng: this.lng, lat: this.lat });
  }
}
