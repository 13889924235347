import { Pipe, PipeTransform } from '@angular/core';
import { utcOffsetFormatter } from '../weather.functions';

@Pipe({
    name: 'offsetLabel',
    standalone: false
})
export class OffsetLabelPipe implements PipeTransform {
  transform(utcOffset: string, ...args: unknown[]): string {
    return utcOffsetFormatter(utcOffset);
  }
}
