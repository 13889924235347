import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, of } from 'rxjs';
import { NominatimResponse } from './nominatim-response.type';

@Injectable({ providedIn: 'root' })
export class NominatimService {
  constructor(private http: HttpClient) {}

  /**
   * Perform an HTTP GET request to `/search` endpoint, which will return a list
   * of matching results (empty list if none is found).
   * Return the FIRST result of the list or NULL if list is empty.
   */
  search(search: string): Observable<NominatimResponse | null> {
    const url = `https://nominatim.openstreetmap.org/search?q=${search}&format=json`;
    return this.http.get(url).pipe(
      map((apiResponse: any) => {
        if (!Array.isArray(apiResponse) || apiResponse.length === 0)
          return null;
        return {
          lng: parseFloat(apiResponse[0]['lon']),
          lat: parseFloat(apiResponse[0]['lat']),
          location: search,
          apiResponse,
        };
      }),
      catchError(() => of(null)),
    );
  }

  reverse(lngLat: {
    lng: number;
    lat: number;
  }): Observable<NominatimResponse | null> {
    const { lng, lat } = lngLat;
    const url = `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`;
    return this.http.get(url).pipe(
      map((apiResponse: any) => {
        const location =
          apiResponse['address']['town'] ||
          apiResponse['address']['county'] ||
          apiResponse['address']['state'] ||
          apiResponse['address']['country'];
        return location ? { lng, lat, apiResponse, location } : null;
      }),
      catchError(() => of(null)),
    );
  }
}
