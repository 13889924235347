import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { isValidOffset } from '../utils/core.utils';

@Pipe({
    name: 'formatUnix',
    standalone: false
})
export class FormatUnixPipe implements PipeTransform {
  transform(unix: number, momentFormat?: string, offset?: string): string {
    momentFormat = momentFormat || 'YYYY-MM-DD HH:mm:ss';
    offset = offset && isValidOffset(offset) ? offset : '+00:00';
    return moment.unix(unix).utcOffset(offset).format(momentFormat);
  }
}
