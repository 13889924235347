import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MapActions } from '../../map/+state';
import { ProjectSelectors } from '../../project/+state';
import { combineLatest, map, of, switchMap } from 'rxjs';
import { RecordSelectors } from '../../record/+state';
import { take } from 'rxjs/operators';
import { FeatureHistoryActions } from './index';

@Injectable()
export class FeatureHistoryEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
  ) {}

  /**
   * Open the FeatureHistory modal when a Record is selected.
   * First, from the given recordId get corresponding Record and its FormSpecific.
   * If they do not exist, do not open the modal.
   *
   * init() action requires an `activeTab` property, its value will be:
   * - null, if FormSpecific has no groups
   * - first `groupName`, if FormSpecific do have groups
   */
  singleRecordSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapActions.selectSingleRecord),
      map((action) => action.selectedRecordId),
      switchMap((selectedRecordId) =>
        // get user's Forms and Record dictionary
        combineLatest([
          this.store.select(ProjectSelectors.selectUserForms()),
          this.store.select(RecordSelectors.selectRecordsDict()),
          of(selectedRecordId),
        ]).pipe(take(1)),
      ),
      map(([userForms, recordDict, selectedRecordId]) => {
        const record = selectedRecordId ? recordDict[selectedRecordId] : null;
        if (!record) return FeatureHistoryActions.modalClosed();

        const specific = userForms
          .map((form) => form.specificList)
          .reduce((acc, item) => [...acc, ...item], [])
          .find((s) => s.id === record.formSpecificId);

        if (!specific) return FeatureHistoryActions.modalClosed();

        if (specific.questionGroups && specific.questionGroups.length > 0) {
          const firstGroup = specific.questionGroups[0];
          return FeatureHistoryActions.init({
            selectedRecordId,
            activeTab: firstGroup.name,
          });
        }

        return FeatureHistoryActions.init({
          selectedRecordId,
          activeTab: null,
        });
      }),
    ),
  );
}
