import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { arrows } from '../../geotiffDrawer/arrows';
import { GeotiffDrawer } from '../../geotiffDrawer/geotiffDrawer';
import * as mapboxgl from 'mapbox-gl';
import { WeatherApiService } from '../../services/weather-api.service';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MapService } from '@trim-web-apps/map';
import {
  InterpolateType,
  WeatherLayer,
  WeatherPopupData,
} from '@trim-web-apps/weather-models';
import {
  bboxForApiRequest,
  bboxToBounds,
  isBoundsContained,
} from '../../utils/weather.functions';
import { roundToDecimal } from '@trim-web-apps/core';

@Component({
  selector: 'weather-core-canvas',
  template: ` <canvas style="display:none;" #canvas></canvas> `,
  styles: [],
  standalone: false,
})
export class WeatherCanvasComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  @Input() weatherModelId!: string;
  @Input() weatherLayer!: WeatherLayer;
  @Input() initime!: number;
  @Input() timestep!: number;
  @Input() weatherModelVisible!: boolean;
  @Input() interpolate!: InterpolateType;
  @Input() windStyle!: string;
  @Input() weatherModelBbox!: number[][];
  // @Input() tiffUrl: string // TODO fetch tiff using url in state
  @Output() tiffLoading: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() tiffFetchError: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() tiffParsed: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('canvas', { static: false }) canvasRef!: ElementRef;

  private geotiffDrawer?: GeotiffDrawer;
  private currentTiffBounds!: mapboxgl.LngLatBounds;
  private moveEndSubs!: Subscription;

  constructor(
    private weatherApi: WeatherApiService,
    private mapService: MapService,
  ) {}

  ngOnInit() {
    // this.mapService.map.on('moveend', this.draw)
    this.moveEndSubs = fromEvent(this.mapService.map, 'moveend')
      .pipe(debounceTime(100))
      .subscribe(() => this.draw());
  }

  ngAfterViewInit(): void {
    console.log(`[${this.weatherModelId}] after view init`);
    this.setupDrawer();
  }

  ngOnChanges() {
    this.currentTiffBounds = null as any;
    this.draw();
  }

  ngOnDestroy(): void {
    this.moveEndSubs?.unsubscribe();
    if (
      this.geotiffDrawer &&
      this.mapService.map.getLayer(this.geotiffDrawer.canvas.id)
    ) {
      this.mapService.removeCanvasLayer(this.geotiffDrawer.canvas.id);
    }
    this.mapService.map.off('moveend', this.draw);
  }

  rebuild(): void {
    this.setupDrawer();
    this.currentTiffBounds = null as any;
    this.draw();
  }

  draw = async (): Promise<void> => {
    if (!this.weatherModelVisible) {
      this.geotiffDrawer?.clearCanvas();
      return;
    }

    try {
      this.tiffLoading.emit(true);
      await this.fetchTiff();
      this.geotiffDrawer?.setInterpolate(this.interpolate);
      const drawStart = Date.now(); // ms
      this.geotiffDrawer?.draw(this.weatherLayer, arrows.get(this.windStyle)); // arrows.get('Arrow'))
      console.log(
        `[${this.weatherModelId}] tiff draw: ${Date.now() - drawStart} ms`,
      );
      this.tiffParsed.emit();
      this.tiffLoading.emit(false);
    } catch (e) {
      console.log(e);
      this.tiffFetchError.emit(true);
      this.geotiffDrawer?.reset();
    }
  };

  private setupDrawer(): void {
    this.geotiffDrawer = new GeotiffDrawer(
      this.canvasRef.nativeElement,
      this.mapService.map,
    );
    this.mapService.createCanvasLayer(this.geotiffDrawer.canvas.id);
  }

  private async fetchTiff(): Promise<void> {
    if (this.tiffFetchRequired()) {
      // const tiff = await this.weatherApi.fetchGeotiff(this.weatherModel, this.mapService.getPaddedBoundsAsArray()).toPromise()
      const tiff = await this.weatherApi
        .fetchGeotiffByUrl(this.createTiffUrl())
        .toPromise();
      await this.geotiffDrawer?.setTiffImage(tiff);
      this.currentTiffBounds = this.mapService.getPaddedBounds();
    }
  }

  /** @deprecated url is created in selector `selectTiffUrl` */
  private createTiffUrl(): string {
    const url =
      this.weatherModelId +
      '/geotiff?' +
      'layer=' +
      this.weatherLayer.id +
      '&timestep=' +
      this.timestep +
      bboxForApiRequest(this.mapService.getPaddedBoundsAsArray());

    return this.initime >= 0 ? `${url}&initime=${this.initime}` : url;
  }

  private tiffFetchRequired(): boolean {
    /**
     * 1) The model is not contained into the current tiff (the tiff is not complete)
     * 2) The current screen is not contained into the current tiff (the tiff does not cover the entire screen)
     */
    return (
      !isBoundsContained(
        bboxToBounds(this.weatherModelBbox),
        this.currentTiffBounds,
      ) &&
      !isBoundsContained(this.mapService.getMapBounds(), this.currentTiffBounds)
    );
  }

  getPixelValue(lngLat: { lng: number; lat: number }): WeatherPopupData {
    if (
      this.weatherModelVisible &&
      lngLat &&
      this.geotiffDrawer &&
      this.geotiffDrawer.tiffImageExists() &&
      this.geotiffDrawer.isLngLatInsideImage(lngLat.lng, lngLat.lat) &&
      this.weatherLayer
    ) {
      const conversionFactor = this.weatherLayer.conversionFactor;
      const value = this.geotiffDrawer.getPixelValue(
        lngLat.lng,
        lngLat.lat,
        conversionFactor,
      );

      console.log(value);

      if (value.length === 1) {
        // not a wind-like layer
        return {
          modelId: this.weatherModelId,
          data: [
            {
              field: this.weatherLayer.label,
              // value: Math.round(value[0] * 10) / 10,
              value: roundToDecimal(
                value[0],
                this.weatherLayer.popupDecimalDigits,
              ),
              unit: this.weatherLayer.unit,
              tiffValue: value[0],
            },
          ],
        };
      }

      // wind-like layer
      return {
        modelId: this.weatherModelId,
        data: [
          {
            field: 'Direction',
            value: Math.round(value[0] + 180) % 360,
            unit: 'deg',
            tiffValue: value[0],
          },
          {
            field: 'Speed',
            value: Math.round(value[1] * 10) / 10,
            unit: this.weatherLayer.unit,
            tiffValue: value[1],
          },
        ],
      };

      // return value.length === 1
      //   ? {
      //       modelId: this.weatherModelId,
      //       data: {
      //         fields: [this.weatherLayer.label],
      //         values: [Math.round(value[0] * 10) / 10],
      //         units: [this.weatherLayer.unit],
      //       },
      //     }
      //   : {
      //       modelId: this.weatherModelId,
      //       data: {
      //         fields: ['Direction', 'Speed'],
      //         values: [
      //           Math.round(value[0] + 180) % 360,
      //           Math.round(value[1] * 10) / 10,
      //         ],
      //         units: ['deg', this.weatherLayer.unit],
      //       },
      //     };
    }

    return {
      modelId: this.weatherModelId,
      data: null,
    };
  }
}
