import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FieldType, Form, FormSpecific } from '3map-models';
import { ValidatorFn, Validators } from '@angular/forms';
import { FormSpecificQuestionsComponent } from '../../components/form-specific-questions/form-specific-questions.component';
import { MarkerListComponent } from '../../components/marker-list/marker-list.component';
import { Observable } from 'rxjs';
import { MarkerItem, QuestionsGroup } from '@trim-web-apps/project-core';
import { tap } from 'rxjs/operators';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import * as SpecificSelectors from '../../+state/form-specific.selectors';
import * as SpecificActions from '../../+state/form-specific.actions';
import * as MarkerStyleActions from '../../+state/marker-style.actions';

@Component({
  selector: 'app-form-specific',
  template: `
    <div class="header">
      <app-section-label sectionLabel="Edit Form" backRoute="forms">
        <app-input-text
          *ngIf="specific$ | async as specific"
          label="Form name"
          (textChange)="onSpecificNameChanged($event, specific)"
          [text]="specific.name"
          [validators]="validators"
        >
        </app-input-text>
      </app-section-label>
    </div>

    <div class="form-specific-container" *ngIf="specific$ | async as specific">
      <div class="left">
        <ng-container *ngIf="questionsGroups$ | async as questionsGroups">
          <app-specific-questions-no-group
            *ngIf="questionsGroups.length === 0"
            [specificId]="specific.id"
          >
          </app-specific-questions-no-group>

          <app-form-specific-questions-groups
            *ngIf="questionsGroups.length > 0"
            [specificId]="specific.id"
          >
          </app-form-specific-questions-groups>
        </ng-container>
      </div>

      <div class="right">
        <div
          class="toggle toggle-markers"
          [ngClass]="showMarkerPanel ? 'toggle-active' : ''"
          (click)="showMarkerPanel = !showMarkerPanel"
        >
          <div class="label">Markers</div>
          <fa-icon
            [icon]="showMarkerPanel ? iconCollapse : iconExpand"
          ></fa-icon>
        </div>
        <div
          class="panel panel-markers"
          [ngClass]="showMarkerPanel ? 'panel-active' : ''"
          *ngIf="showMarkerPanel"
        >
          <app-marker-style-selector
            [specific]="specific"
            [markerItems]="(markerItems$ | async) ?? []"
            [markerStyleQuestion]="markerStyleQuestion$ | async"
            (questionSelected)="onMarkerStyleQuestionChange($event, specific)"
            (markerItemChange)="onMarkerItemChange($event, specific.id)"
          ></app-marker-style-selector>
        </div>

        <div
          class="toggle toggle-logic"
          [ngClass]="showLogicPanel ? 'toggle-active' : ''"
          (click)="showLogicPanel = !showLogicPanel"
        >
          <div class="label">Logic</div>
          <fa-icon
            [icon]="showLogicPanel ? iconCollapse : iconExpand"
          ></fa-icon>
        </div>
        <div
          class="panel panel-logic"
          [ngClass]="showLogicPanel ? 'panel-active' : ''"
          *ngIf="showLogicPanel"
        >
          <app-form-specific-logic
            [specific]="specific"
          ></app-form-specific-logic>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        height: 100vh;
      }

      .form-specific-container {
        display: flex;
        height: 100%;
        flex-wrap: wrap;
      }

      .form-specific-container > .left {
        flex: 2;
        min-width: 400px;
        margin: 10px;
        position: relative;
        border: 1px solid #bdbdbd;
        border-radius: 3px;
      }

      .form-specific-container > .right {
        flex: 1;
        min-width: 400px;
        margin: 10px;
        overflow: auto;
        display: flex;
        flex-direction: column;
      }

      .panel {
        position: relative;
        height: 100%;
      }

      .toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        border: 1px solid #bdbdbd;
        border-radius: 3px;
        min-height: 40px;
        max-height: 40px;
        padding: 0 10px;
      }

      .toggle-logic {
        margin-top: 10px;
      }

      .panel {
        border: 1px solid #bdbdbd;
        border-radius: 3px;
      }

      .toggle-active {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      .panel-active {
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    `,
  ],
  standalone: false,
})
export class FormSpecificViewComponent implements OnInit {
  @ViewChildren(FormSpecificQuestionsComponent) questionsComponentList:
    | QueryList<FormSpecificQuestionsComponent>
    | undefined;
  @ViewChild(MarkerListComponent) markerComp: MarkerListComponent | undefined;

  form: Form | undefined;
  validators: ValidatorFn[];
  markerItems: MarkerItem[];
  specific$: Observable<FormSpecific | null> | undefined;
  markerStyleQuestion$: Observable<FieldType | null> | undefined;
  markerItems$: Observable<MarkerItem[]> | undefined;
  questionsGroups$: Observable<QuestionsGroup[]> | undefined;

  showMarkerPanel: boolean;
  showLogicPanel: boolean;
  iconExpand = faChevronDown;
  iconCollapse = faChevronUp;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
  ) {
    this.validators = [Validators.required];
    this.markerItems = [];
    this.showMarkerPanel = true;
    this.showLogicPanel = true;
  }

  async ngOnInit(): Promise<void> {
    const { specificId } = this.route.snapshot.params;

    this.specific$ = this.store
      .select(SpecificSelectors.selectSpecificById(specificId))
      .pipe(
        tap((specific) => {
          if (specific === null) this.router.navigate(['/admin/forms']);
        }),
      );

    this.questionsGroups$ = this.store.select(
      SpecificSelectors.selectQuestionsGroupList(specificId),
    );

    this.markerItems$ = this.store.select(
      SpecificSelectors.selectMarkerItems(specificId),
    );
    this.markerStyleQuestion$ = this.store.select(
      SpecificSelectors.selectMarkerStyleQuestion(specificId),
    );
  }

  onMarkerStyleQuestionChange(
    question: FieldType | null,
    specific: FormSpecific,
  ): void {
    this.store.dispatch(
      MarkerStyleActions.setMarkerStyleQuestion({
        specificId: specific.id,
        question,
      }),
    );
  }

  onSpecificNameChanged(newSpecificName: string, specific: FormSpecific): void {
    this.store.dispatch(
      SpecificActions.setSpecificName({
        specificId: specific.id,
        name: newSpecificName,
      }),
    );
  }

  onMarkerItemChange(
    evt: { base64: string; index: number },
    specificId: string,
  ): void {
    this.store.dispatch(
      MarkerStyleActions.setImageBase64({
        specificId,
        base64: evt.base64,
        index: evt.index,
      }),
    );
  }
}
