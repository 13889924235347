import { Component, Input, OnInit } from '@angular/core';
import { FieldType } from '3map-models';
import { map, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as SpecificSelectors from '../../+state/form-specific.selectors';
import * as SpecificActions from '../../+state/form-specific.actions';

@Component({
  selector: 'app-specific-questions-no-group[specificId]',
  template: `
    <app-form-specific-questions
      *ngIf="questions$ | async as questions"
      [questions]="questions"
      (switchToGroups)="switchToGroups(questions)"
      (questionsChange)="onQuestionsChange($event)"
    >
    </app-form-specific-questions>
  `,
  standalone: false,
})
export class FormSpecificQuestionsNoGroupComponent implements OnInit {
  @Input() specificId!: string;
  questions$: Observable<FieldType[]> | undefined;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.questions$ = this.store
      .select(SpecificSelectors.selectSpecificById(this.specificId))
      .pipe(map((specific) => specific?.questions || []));
  }

  switchToGroups(questionList: FieldType[]): void {
    this.store.dispatch(
      SpecificActions.addQuestionGroup({
        specificId: this.specificId,
        groupName: 'New Group',
        questionList,
      }),
    );
  }

  onQuestionsChange(questions: FieldType[]): void {
    this.store.dispatch(
      SpecificActions.upsertQuestions({
        specificId: this.specificId,
        questions,
      }),
    );
  }
}
