import { Component } from '@angular/core';
import { FieldType, Form, FormSpecific, Project, Record } from '3map-models';
import { Observable, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { EditorActions, EditorSelectors } from '../../+state';
import { FieldTypeComponentInput } from '../../models/FieldTypeComponentInput';
import {
  FieldCheck,
  FieldDate,
  FieldInt,
  FieldList,
  FieldNumber,
  FieldRange,
  FieldText,
  Media,
} from '3map-models/dist/lib/model';
import { EditorTab } from '../../models/EditorTab';
import { EditorMode } from '../../models/EditorMode';
import { RecordEditorMetadata } from '../../models/metadata-location.model';
import { ProjectSelectors } from '../../../project/+state';
import { UploadMediaService } from '../../upload-media.service';

@Component({
  selector: 'app-record-editor',
  template: `
    <div class="record-editor-wrapper" *ngIf="targetRecord$ | async as record">
      <ng-container *ngIf="form$ | async as form">
        <ng-container *ngIf="specific$ | async as specific">
          <ng-container *ngIf="activeTab$ | async as activeTab">
            <app-record-card-header
              [specific]="specific"
              [record]="record"
              [form]="form"
            />

            <app-editor-tab-list />

            <div class="content">
              <div class="metadata" *ngIf="activeTab.type === 'METADATA'">
                <app-record-editor-metadata
                  [record]="record"
                  [isFeatureOwner]="(isFeatureOwner$ | async) ?? false"
                  [editorMode]="editorMode$ | async"
                  (metadataChange)="onLocationChange($event)"
                />
              </div>
              <div class="questions" *ngIf="activeTab.type === 'QUESTIONS'">
                <app-field-types-list
                  *ngIf="questions$ | async as questions"
                  [questions]="questions"
                  (fieldChange)="onFieldChange($event, specific)"
                />
              </div>
              <div class="media" *ngIf="activeTab.type === 'MEDIA'">
                <app-record-editor-media
                  *ngIf="project$ | async as project"
                  [project]="project"
                  [record]="record"
                  [uploadMediaListBase64]="
                    (uploadMediaListBase64$ | async) ?? []
                  "
                  (fileListSelected)="onFileListSelected($event)"
                  (removeMedia)="onRemoveMedia($event)"
                />
              </div>
            </div>

            <div class="footer">
              <app-record-editor-bottom-actions
                [activeTab]="activeTab"
                [request]="request$ | async"
                [recordValid]="(recordValid$ | async) ?? false"
                (next)="onNext()"
                (confirm)="onConfirm()"
              />
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  `,
  styles: [
    `
      .record-editor-wrapper {
        background-color: #ffffff;
        padding: 10px;
        border-radius: 10px;
      }

      .content {
        height: 50vh;
        overflow: auto;
      }

      .footer {
        display: flex;
        justify-content: center;
      }
    `,
  ],
  standalone: false,
})
export class RecordEditorComponent {
  questions$: Observable<FieldTypeComponentInput[]>;
  specific$: Observable<FormSpecific | null>;
  form$: Observable<Form | null>;
  activeTab$: Observable<EditorTab | null>;
  targetRecord$: Observable<Record | null>;
  isFeatureOwner$: Observable<boolean>;
  editorMode$: Observable<EditorMode | null>;
  project$: Observable<Project | null>;
  uploadMediaListBase64$: Observable<{ media: Media; base64: string }[]>;
  request$: Observable<{ pending: boolean; error: string | null }>;
  recordValid$: Observable<boolean>;

  constructor(
    private store: Store,
    private uploadMediaService: UploadMediaService,
  ) {
    this.activeTab$ = this.store.select(EditorSelectors.selectActiveTab());
    this.isFeatureOwner$ = this.store.select(
      EditorSelectors.selectIsRecordOwner(),
    );
    this.targetRecord$ = this.store.select(
      EditorSelectors.selectTargetRecord(),
    );
    this.specific$ = this.store.select(
      EditorSelectors.selectSpecificOfTargetRecord(),
    );
    this.form$ = this.store.select(EditorSelectors.selectFormOfTargetRecord());
    this.questions$ = this.store.select(
      EditorSelectors.selectFieldTypeComponentInputs(),
    );
    this.editorMode$ = this.store.select(EditorSelectors.selectMode());
    this.project$ = this.store.select(ProjectSelectors.selectProject());
    this.uploadMediaListBase64$ = this.store
      .select(EditorSelectors.selectUploadMedia())
      .pipe(
        switchMap((mediaList) =>
          this.uploadMediaService.getMediaListAsBase64(mediaList),
        ),
      );
    this.request$ = this.store.select(EditorSelectors.selectRequest());
    this.recordValid$ = this.store.select(
      EditorSelectors.selectIsValidRecord(),
    );
  }

  onFieldChange(
    evt: {
      fieldType: FieldType;
      field:
        | FieldNumber
        | FieldText
        | FieldList
        | FieldRange
        | FieldCheck
        | FieldInt
        | FieldDate
        | null;
    },
    specific: FormSpecific,
  ): void {
    this.store.dispatch(
      EditorActions.editQuestion({
        field: evt.field,
        fieldType: evt.fieldType,
        specific: specific,
      }),
    );
  }

  onNext(): void {
    this.store.dispatch(EditorActions.nextTab());
  }

  onConfirm(): void {
    this.store.dispatch(EditorActions.uploadRecord());
  }

  onLocationChange(metaLocation: RecordEditorMetadata): void {
    this.store.dispatch(EditorActions.editRecordMetadata({ metaLocation }));
  }

  onFileListSelected(uploadMediaList: { media: Media; blob: Blob }[]): void {
    const mediaList = uploadMediaList.map((upload) => {
      this.uploadMediaService.setMediaForUpload(upload);
      return upload.media;
    });
    if (mediaList.length === 0) return;
    this.store.dispatch(EditorActions.addUploadMediaList({ mediaList }));
  }

  onRemoveMedia(media: Media): void {
    this.store.dispatch(EditorActions.removeMedia({ media }));
  }
}
