import { WeatherModelInitConfig, WeatherModelMetadata } from '../types';
import { WeatherModel } from '../types/weather.model';
import { createWrfAarhus05 } from './wrf_aarhus_05';

export const WRF_AARHUS_25: WeatherModelMetadata = {
  id: 'WRF_AARHUS_25',
  label: 'WRF Aarhus',
  category: 'FORECAST',
  subCategory: 'LOCAL_MODELS',
  info: { resolution: { deg: 0.025, km: 2.5 } },
  bbox: [
    [4.4875, 59.4125],
    [16.0125, 59.4125],
    [16.0125, 52.6875],
    [4.4875, 52.6875],
    [4.4875, 59.4125],
  ],
};

export function createWrfAarhus25(
  serverSpec: unknown,
  config?: WeatherModelInitConfig,
): WeatherModel {
  return {
    ...createWrfAarhus05(serverSpec, config),
    ...WRF_AARHUS_25,
  };
}
