export type WeatherTimestep = {
  initime: number;
  timesteps: number[];
};

export type TimestepType = {
  type:
    | 'UNIX'
    | 'DAYS'
    | 'DEKADAL'
    | '1MONTH'
    | '1MONTH_AVG'
    | '3MONTH'
    | 'QUARTER'
    | 'QUARTER_AVG'
    | '6MONTH'
    | 'SEMESTER'
    | 'SEMESTER_AVG'
    | '1YEAR'
    | '1YEAR_AVG';
  interval: number;
};
