import { Component, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { Form, FormSpecific, Media, Record } from '3map-models';
import {
  BehaviorSubject,
  lastValueFrom,
  Observable,
  Subscription,
  throttleTime,
} from 'rxjs';
import { FeatureHistoryActions, FeatureHistorySelectors } from '../../+state';
import { MediaGallerySelectors } from '../../../media-gallery/+state';
import { take } from 'rxjs/operators';
import { DownloadService } from '@trim-web-apps/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-feature-history-modal',
  template: `
    <div
      class="record-modal-wrapper"
      (click)="closeModal()"
      *ngIf="modalData$ | async as modalData"
      appKeyboardListener
      (keyPress)="onKeyPressed($event, modalData)"
    >
      <div class="icon-wrapper">
        <div
          class="icon"
          *ngIf="modalData.hasRecordOnLeft"
          (click)="onNextRecordClick($event, modalData.records[0])"
        >
          <fa-icon [icon]="iconPrev" />
        </div>
      </div>

      <ng-container *ngFor="let record of modalData.records; let i = index">
        <div class="record-card" [class.center-record]="i === 1">
          <app-record-card
            *ngIf="record"
            [record]="record"
            [form]="modalData.form"
            [specific]="modalData.specific"
            [activeTab]="modalData.activeTab"
            (tabClicked)="onTabChange($event)"
            (click)="$event.stopPropagation()"
            (mediaClick)="onMediaClick($event, record)"
          />
        </div>
      </ng-container>

      <div class="icon-wrapper">
        <div
          class="icon"
          *ngIf="modalData.hasRecordOnRight"
          (click)="onNextRecordClick($event, modalData.records[2])"
        >
          <fa-icon [icon]="iconNext" />
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .record-modal-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 5;
        display: flex;
        align-items: center;
        overflow: hidden;
        justify-content: center;
      }

      .icon-wrapper {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .icon {
        background-color: #ffffff;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        font-size: var(--font-size-3);
      }

      .record-card {
        margin: 10px;
        height: 60%;
        width: 450px;
        min-width: 350px;
      }

      .record-card.center-record {
        height: 70%;
      }
    `,
  ],
  standalone: false,
})
export class FeatureHistoryModalComponent implements OnDestroy {
  iconPrev = faChevronLeft;
  iconNext = faChevronRight;
  modalData$: Observable<{
    form: Form;
    specific: FormSpecific;
    records: (Record | null)[];
    activeTab: string | null;
    hasRecordOnLeft: boolean;
    hasRecordOnRight: boolean;
  } | null>;
  private navigateRecordThrottle: BehaviorSubject<Record | null>;
  private navigateRecordThrottleSub: Subscription | undefined;

  constructor(
    private store: Store,
    private download: DownloadService,
  ) {
    this.modalData$ = this.store.pipe(
      select(FeatureHistorySelectors.selectModalData()),
    );
    this.navigateRecordThrottle = new BehaviorSubject<Record | null>(null);
    this.navigateRecordThrottleSub = this.navigateRecordThrottle
      .pipe(throttleTime(100))
      .subscribe((record) => this.navigateRecord(record));
  }

  ngOnDestroy(): void {
    this.navigateRecordThrottleSub?.unsubscribe();
  }

  async onKeyPressed(
    key: 'Escape' | 'ArrowLeft' | 'ArrowRight',
    modalData: {
      form: Form;
      specific: FormSpecific;
      records: (Record | null)[];
      activeTab: string | null;
    },
  ): Promise<void> {
    const isGalleryOpen = await lastValueFrom(
      this.store
        .select(MediaGallerySelectors.selectIsGalleryOpen())
        .pipe(take(1)),
    );
    if (isGalleryOpen) return;
    if (key === 'Escape') this.closeModal();
    if (key === 'ArrowLeft' || key === 'ArrowRight') {
      const nextRecord =
        key === 'ArrowLeft' ? modalData.records[0] : modalData.records[2];
      // this.navigateRecord(nextRecord);
      this.navigateRecordThrottle.next(nextRecord);
    }
  }

  closeModal() {
    this.store.dispatch(FeatureHistoryActions.modalClosed());
  }

  onNextRecordClick(evt: Event, record: Record | null): void {
    evt.stopImmediatePropagation();
    this.navigateRecordThrottle.next(record);
  }

  onTabChange(activeTab: string | null): void {
    this.store.dispatch(FeatureHistoryActions.onTabChange({ activeTab }));
  }

  onMediaClick(media: Media, record: Record): void {
    if (media.type === 'FILE') {
      const projectName = record.projectName;
      const url = `${environment.API_URL}/static/${projectName}/project/${media.fileName}`;
      this.download.downloadText(url, media.fileName);
    } else {
      const { recordId } = record;
      const mediaIndex = record.mediaList
        .filter((m) => m.type !== 'FILE')
        .indexOf(media);
      this.store.dispatch(
        FeatureHistoryActions.setGalleryData({ recordId, mediaIndex }),
      );
    }
  }

  private navigateRecord(record: Record | null): void {
    const selectedRecordId = record?.recordId || null;
    if (selectedRecordId)
      this.store.dispatch(
        FeatureHistoryActions.setSelectedRecordId({ selectedRecordId }),
      );
  }
}
