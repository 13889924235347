import { WeatherModelInitConfig, WeatherModelMetadata } from '../types';
import { WeatherModel } from '../types/weather.model';
import { WeatherLayer } from '../types/weather-layer.type';
import {
  RAINFALL_3HOUR,
  TEMPERATURE,
  TOTAL_PRECIPITATION,
  WIND_KTS,
} from '../weather-layers/weather.layers';
import {
  createTsListForecast,
  decodeForecastSpec,
  getBaseModelTemplate,
  getDefaultDateRange,
  getTsListRainfall,
  removeUnwantedLayers,
} from '../utils';

export const ECMWF: WeatherModelMetadata = {
  id: 'ECMWF',
  label: 'ECMWF',
  category: 'FORECAST',
  subCategory: 'GLOBAL_MODELS',
  bbox: [
    [-180, 90],
    [180, 90],
    [180, -90],
    [-180, -90],
    [-180, 90],
  ],
  info: {
    resolution: { deg: 0.25, km: 25 },
    coverage: 'Global',
    url: 'https://www.ecmwf.int/en/forecasts/datasets/open-data',
    credits: 'ECMWF',
    schedule: [
      { init: '00', time: '08:00' },
      { init: '12', time: '20:00' },
    ],
  },
};

export function createEcmwf(
  serverSpec: unknown,
  config?: WeatherModelInitConfig,
): WeatherModel {
  const spec = decodeForecastSpec(serverSpec);
  const timestepList = createTsListForecast(spec.initimeList, 48);
  const tsRainfall = getTsListRainfall(timestepList);
  const defaultDateRange = getDefaultDateRange(timestepList, 0, 47);
  const defaultDateRngRainfall = getDefaultDateRange(tsRainfall, 1, 47);
  const timestepType = { type: 'UNIX', interval: 3600 * 3 } as const;

  const ecmwfLayers: WeatherLayer[] = [
    {
      ...WIND_KTS,
      timestepType,
      timestepList,
      defaultDateRange,
    },
    {
      ...WIND_KTS,
      id: 'wind_925',
      label: 'Wind (925 hPa)',
      timestepType,
      timestepList,
      defaultDateRange,
    },
    {
      ...TEMPERATURE,
      timestepType,
      timestepList,
      defaultDateRange,
    },
    {
      ...TOTAL_PRECIPITATION,
      timestepType,
      timestepList: tsRainfall,
      defaultDateRange: defaultDateRngRainfall,
      conversionFactor: 1000, // ECMWF precipitation is in m
    },
    {
      ...RAINFALL_3HOUR,
      timestepType,
      timestepList: tsRainfall,
      defaultDateRange: defaultDateRngRainfall,
      conversionFactor: 1000, // ECMWF precipitation is in m
    },
  ];

  const layers = removeUnwantedLayers(ecmwfLayers, config?.layerIdList ?? []);

  if (layers.length === 0)
    throw new Error(`No layers available for ${spec.model} model`);

  return {
    ...getBaseModelTemplate(config),
    ...ECMWF,
    layers,
    initime: layers[0].timestepList[0].initime,
    timestep: layers[0].defaultDateRange.from,
    selectedLayerId: layers[0].id,
    dateRange: layers[0].defaultDateRange,
  };
}
