import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  /**
   * @deprecated
   */
  showDownloadDialog(file: Blob, filename: string): void {
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = URL.createObjectURL(file);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  downloadBlob(file: Blob, filename: string): void {
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = URL.createObjectURL(file);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  /** @deprecated Use {@link downloadBlob} instead.
   *
   * This might remove \n characters from input string. \n
   * This seems to happen with csv files. Use downloadBlob instead, e.g.:
   *
   * const blob = new Blob([dataStr], { type: 'text/csv;charset=utf-8' });
   * this.download.downloadBlob(blob, filename);
   */
  downloadText(dataStr: string, filename: string): void {
    const anchor = document.createElement('a');
    anchor.setAttribute('href', dataStr);
    anchor.setAttribute('target', '_blank');
    anchor.setAttribute('download', filename);
    document.body.appendChild(anchor); // required for firefox
    anchor.click();
    anchor.remove();
  }
}
