import {
  AttributionControl,
  FullscreenControl,
  GeolocateControl,
  IControl,
  Map,
  NavigationControl,
  ScaleControl,
} from 'mapbox-gl';
import { MapConfig } from './MapConfig';
import { zoomControl } from '../set-zoom-plugin/set-zoom-plugin';
import { getStyleUri, MAP_STYLE_LIST } from '../map-utils/map.styles';
import RulerControl from '@mapbox-controls/ruler';
import { MapboxStyleSwitcherControl } from 'mapbox-gl-style-switcher';

export function createMap(
  mapContainer: string | HTMLElement,
  config: MapConfig,
): Map {
  const map = new Map({
    container: mapContainer,
    style: getStyleUri(config.style),
    zoom: config.zoom,
    minZoom: 2.5,
    center: config.center,
    touchZoomRotate: true,
    attributionControl: false,
    touchPitch: false,
    dragRotate: false,
    logoPosition: 'top-right',
    accessToken:
      'pk.eyJ1Ijoic2YzMSIsImEiOiJjam40bTEzODIwNXhkM3BtaXVhNnpzY3JrIn0.2KWgl9Ssf9RKhqhLA_dWNw',
  });

  if (config.maxBounds) {
    map.setMaxBounds(config.maxBounds);
  }

  map.touchZoomRotate.disableRotation();
  // map.scrollZoom.setWheelZoomRate(0.5);

  config.plugins.forEach((plugin) => {
    switch (plugin) {
      case 'SCALE':
        return map.addControl(new ScaleControl(), config.controlPosition);
      case 'RULER':
        return map.addControl(new RulerControl(), config.controlPosition);
      case 'ZOOM':
        return map.addControl(zoomControl, config.controlPosition);
      case 'NAVIGATION':
        return map.addControl(
          new NavigationControl({
            showCompass: false,
            showZoom: true,
          }),
          config.controlPosition,
        );
      case 'ATTRIBUTION':
        return map.addControl(
          new AttributionControl({ compact: true }),
          config.controlPosition,
        );
      case 'GEOLOCATE':
        return map.addControl(
          new GeolocateControl({
            positionOptions: { enableHighAccuracy: true },
            trackUserLocation: true,
          }),
          config.controlPosition,
        );
      case 'SWITCHER':
        return map.addControl(
          new MapboxStyleSwitcherControl(MAP_STYLE_LIST) as IControl,
          config.controlPosition,
        );
      case 'FULLSCREEN':
        return map.addControl(new FullscreenControl(), config.controlPosition);
      default:
        console.warn(`[ MapBuilder ] Unsupported plugin: ${plugin}`);
        return map;
    }
  });
  return map;
}
