import { Component, Input } from '@angular/core';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-deploy-alert',
  template: `
    <div class="top-banner valid" *ngIf="isModified && isProjectValid">
      Project updated, changes need to be deployed
    </div>
    <div class="top-banner invalid" *ngIf="isModified && !isProjectValid">
      Invalid Project (see more)
    </div>
  `,
  styles: [
    `
      .top-banner {
        position: absolute;
        top: 0;
        left: 300px;
        right: 0;
        cursor: pointer;
        color: #ffffff;
        text-align: center;
        font-size: 0.9em;
      }

      .valid {
        background-color: var(--success-color);
      }

      .invalid {
        background-color: var(--warn-color);
      }
    `,
  ],
  standalone: false,
})
export class DeployAlertComponent {
  @Input() isModified: boolean | undefined;
  @Input() isProjectValid: boolean | undefined;
  icon = faArrowRight;
}
