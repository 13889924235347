import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { faVectorSquare } from '@fortawesome/free-solid-svg-icons';
import { MapResource } from '../../../map-resource/+state';

@Component({
  selector: 'app-resources-nav-item',
  template: `
    <app-sidenav-item
      label="Resources"
      [icon]="iconResources"
      [sidenavOpen]="sidenavOpen"
      [expandable]="true"
    >
      <div *ngIf="mapResourceList.length === 0">No Resources found</div>

      <ng-container *ngFor="let res of mapResourceList">
        <div class="map-resource" *ngIf="res.status !== 'ERROR'">
          <ui-checkbox
            [checked]="res.status === 'ENABLED'"
            [loading]="res.status === 'LOADING'"
            [error]="$any(res.status) === 'ERROR'"
            [label]="res.name"
            (checkboxClick)="onClick(res)"
          ></ui-checkbox>
        </div>
      </ng-container>
    </app-sidenav-item>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ResourcesNavItemComponent {
  @Input() sidenavOpen = false;
  @Input() mapResourceList: MapResource[] = [];
  @Output() toggleResource: EventEmitter<MapResource> =
    new EventEmitter<MapResource>();
  iconResources = faVectorSquare;

  onClick(res: MapResource): void {
    if (res.status !== 'LOADING') this.toggleResource.emit(res);
  }
}
