import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRecord from './record.reducer';

const { selectAll, selectEntities } = fromRecord.adapter.getSelectors();
const selectRecordState = createFeatureSelector<fromRecord.State>(
  fromRecord.recordsFeatureKey,
);

export const selectRecordFetchState = () =>
  createSelector(selectRecordState, (state) => state.recordsFetchState);

export const selectRecords = () =>
  createSelector(selectRecordState, (state) => selectAll(state));

export const selectRecordsRemoteCount = () =>
  createSelector(selectRecordState, (state) => state.totalRemoteCount);

export const selectRecordsDict = () =>
  createSelector(selectRecordState, (state) => selectEntities(state));

export const selectRecordFetchLoading = () =>
  createSelector(selectRecordFetchState(), (status) => status === 'PENDING');

export const selectRecordSameFeature = (featureId: string) =>
  createSelector(selectRecords(), (records) =>
    records.filter((r) => r.featureId === featureId),
  );

export const selectRecordById = (recordId: string) =>
  createSelector(selectRecordsDict(), (records) => records[recordId] || null);
