export async function splitMarkersImage(
  blob: Blob,
  iconCount: number,
): Promise<ImageBitmap[]> {
  const ctx = document.createElement('canvas').getContext('2d');
  if (!ctx) throw new Error('Could not get canvas context');
  const src = URL.createObjectURL(blob);
  const image: HTMLImageElement = await new Promise((resolve) => {
    const htmlImage = new Image();
    htmlImage.onload = () => resolve(htmlImage);
    htmlImage.src = src;
  });
  const iconWidth = image.naturalWidth / iconCount;
  ctx.canvas.width = iconWidth;
  ctx.canvas.height = image.naturalHeight;
  const bitmapList: ImageBitmap[] = [];
  for (let i = 0; i < iconCount; i++) {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.drawImage(
      image,
      i * iconWidth, // top left corner
      0, // top left corner
      iconWidth, // offset (to bottom) from top left corner of source image
      image.naturalHeight, // offset (to right) from top left corner of source image
      0, // top left corner
      0, // top left corner
      iconWidth, // offset (to bottom) from top left corner of destination image
      image.naturalHeight, // offset (to right) from top left corner of destination image
    );
    bitmapList.push(await createImageBitmap(ctx.canvas));
  }
  return bitmapList;
}
