export type TableCreateRecordEvent = {
  type: 'CREATE_RECORD';
  formId: string;
  specificId: string;
  latitude: number;
  longitude: number;
  altitude: number;
};

export type TableEditRecordEvent = {
  type: 'EDIT_RECORD';
  recordId: string;
};

export type TableUpdateRecordEvent = {
  type: 'UPDATE_RECORD';
  recordId: string;
};

export type TableDeleteRecordsEvent = {
  type: 'DELETE_RECORD_LIST';
  recordIdsList: string[];
};

export type TableZoomToRecordEvent = {
  type: 'ZOOM_TO_RECORD';
  recordId: string;
  formId: string;
  featureId: string;
};

export type TableZoomToRecordListEvent = {
  type: 'ZOOM_TO_RECORD_LIST';
  recordIdsList: string[];
};

export type TableProcessedRecordsEvent = {
  type: 'PROCESSED_RECORDS';
  processedRecordsIds: string[];
  formId: string;
};

export type TableToggleHistoryEvent = {
  type: 'TOGGLE_HISTORY';
  formId: string;
};

export type TableSpecificEnabledListEvent = {
  type: 'ENABLED_SPECIFIC_ID_LIST';
  formId: string;
  specificIdsList: string[];
};

export type TableMediaEvent = {
  type: 'TABLE_MEDIA';
  recordId: string;
  mediaIndex: number;
};

export type TableMigrateFeatures = {
  type: 'TABLE_MIGRATE_FEATURES';
  recordIds: string[];
};

export type TableEvent =
  | TableCreateRecordEvent
  | TableEditRecordEvent
  | TableUpdateRecordEvent
  | TableDeleteRecordsEvent
  | TableZoomToRecordEvent
  | TableZoomToRecordListEvent
  | TableToggleHistoryEvent
  | TableProcessedRecordsEvent
  | TableSpecificEnabledListEvent
  | TableMediaEvent
  | TableMigrateFeatures;
