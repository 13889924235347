import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WeatherModel } from '@trim-web-apps/weather-models';

@Component({
    selector: 'weather-core-model-status',
    template: `
    <div class="model-status-wrapper text-ellipsis" *ngIf="weatherModel">
      <ui-checkbox
        [checked]="weatherModel.visible"
        [loading]="weatherModel.tiffLoading"
        [error]="weatherModel.tiffFetchError"
      />
      <div class="label">{{ weatherModel.label }}</div>
      <div class="res">{{ weatherModel.info | resolutionLabel }}</div>
    </div>
  `,
    styles: [
        `
      .model-status-wrapper {
        display: flex;
        align-items: center;
        gap: var(--spacing-2);
        /*width: 200px;*/
      }

      .res {
        color: var(--grey);
        font-size: var(--font-size-2);
      }
    `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ModelStatusComponent {
  @Input() weatherModel?: WeatherModel;
}
