import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { ProjectUser } from '3map-models';
import { Action, createReducer, on } from '@ngrx/store';
import { removeUsers, setUsers } from './user.actions';
// import { AdminActions } from '../../+state';
import * as AdminActions from '../../+state/admin.actions';
// import { FormActions } from '../../form/+state';
import * as FormActions from '../../form/+state/form.actions';

export const ADMIN_USER_FEATURE_KEY = 'admin-user';
export type State = EntityState<ProjectUser>;

export const adapter = createEntityAdapter<ProjectUser>({
  selectId: (user) => user.username,
});

const initialState = adapter.getInitialState();

const userReducer = createReducer(
  initialState,
  on(
    AdminActions.initProjectError,
    AdminActions.close,
    (): State => ({ ...initialState }),
  ),
  on(AdminActions.initProjectSuccess, (state, { project }): State => {
    return adapter.setAll(project.userList, state);
  }),
  // on(formActions.CREATE_FORM, (state, { form }) =>
  //   adapter.map((pUser) => {
  //     return { ...pUser, formList: [...pUser.formList, form.id] };
  //   }, state)
  // ),
  on(FormActions.removeForm, (state, { form }) =>
    adapter.map((pUser) => {
      return {
        ...pUser,
        formList: pUser.formList.filter((id) => id !== form.id),
      };
    }, state),
  ),
  on(setUsers, (state, { users }) => {
    return adapter.upsertMany(users, state);
  }),
  on(removeUsers, (state, { users }) => {
    return adapter.removeMany(
      users.map((u) => u.username),
      state,
    );
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return userReducer(state, action);
}
