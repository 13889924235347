import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'core-std-checkbox',
    template: `
    <div class="container">
      <span
        *ngIf="lblPosition === 'left'"
        class=" text-ellipsis checkbox-label lbl-left"
      >
        {{ label }}
      </span>
      <div
        class="checkbox"
        [ngStyle]="getStyle()"
        [ngClass]="checked ? 'selected' : 'not-selected'"
        (click)="emit()"
      ></div>
      <span
        *ngIf="lblPosition === 'right'"
        class="text-ellipsis checkbox-label lbl-right"
      >
        {{ label }}
      </span>
    </div>
  `,
    styles: [
        `
      .container {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
      }

      .checkbox {
        border: 1px solid #1e4294;
        width: 15px;
        height: 15px;
        border-radius: 1px;
        cursor: pointer;
      }

      .selected {
        background-color: #1e4294;
      }

      .checkbox-label {
        margin: 0 5px;
      }
    `,
    ],
    standalone: false
})
/** @deprecated use ui-checkbox */
export class StdCheckboxComponent {
  @Input() checked = false;
  @Input() dimension = 15;
  @Input() label: string = '';
  @Input() lblPosition: 'left' | 'right' = 'right';

  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  clickedWithValue: EventEmitter<boolean> = new EventEmitter<boolean>();

  getStyle(): { [k: string]: string } {
    return {
      width: `${this.dimension}px`,
      height: `${this.dimension}px`,
    };
  }

  emit(): void {
    this.clicked.emit();
    this.clickedWithValue.emit(!this.checked);
  }
}
