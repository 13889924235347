import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WeatherItemData } from './weather-item-data';
import {
  faChartBar,
  faCog,
  faSlidersH,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { WeatherModel } from '@trim-web-apps/weather-models';
import { WeatherItemConfig } from '../../weather.types';

@Component({
    selector: 'weather-core-item-mobile',
    template: `
    <div class="mobile-item-wrapper" *ngIf="weatherItemData">
      <ng-container *ngIf="!weatherItemData.isTiffOutOfMap">
        <div class="row">
          <weather-core-layer-picker
            [weatherModelId]="weatherItemData.model.id"
          />
        </div>

        <ng-container *ngIf="!weatherItemData.model.chartEnabled">
          <div class="row">
            <weather-core-timestep-picker
              [weatherModelId]="weatherItemData.model.id"
              [isMobile]="true"
            />
          </div>

          <div class="row">
            <weather-core-legend
              [layer]="weatherItemData.selectedLayer"
              [isMobile]="true"
            />
          </div>
        </ng-container>

        <div
          class="chart-wrapper row"
          *ngIf="weatherItemData.model.chartEnabled"
        >
          <weather-core-chart-mobile
            [weatherModelId]="weatherItemData.model.id"
          />
        </div>
      </ng-container>

      <div
        class="tiff-out-of-map row"
        *ngIf="weatherItemData.isTiffOutOfMap"
        (click)="centerMap.emit(weatherItemData.model)"
      >
        {{ weatherItemData.model.label }} not available in this area.
        <div
          (click)="centerMap.emit(weatherItemData.model)"
          *ngIf="weatherItemData.showCenterMap"
        >
          Tap here to center the map
        </div>
      </div>

      @if (config?.statusSection) {
        <div class="bottom row">
          <div class="action-btn status">
            <weather-core-model-status
              [weatherModel]="weatherItemData.model"
              (click)="toggleVisibility.emit(weatherItemData.model.id)"
            />
          </div>

          <div class="action-btn">
            <fa-icon
              [icon]="iconRemove"
              (click)="removeModel.emit(weatherItemData.model.id)"
            />
          </div>

          <ng-container *ngIf="!weatherItemData.isTiffOutOfMap">
            <div class="action-btn" (click)="showSettings.emit()">
              <fa-icon [icon]="iconSettings" />
            </div>
            <div class="action-btn" (click)="toggleChartVisibility.emit()">
              <ng-container *ngIf="weatherItemData.model.chartEnabled">
                <fa-icon [icon]="iconControls" />
              </ng-container>

              <ng-container *ngIf="!weatherItemData.model.chartEnabled">
                <fa-icon [icon]="iconChart" />
              </ng-container>
            </div>
          </ng-container>
        </div>
      }
    </div>
  `,
    styles: [
        `
      .mobile-item-wrapper {
        background-color: white;
        width: 100%;
        padding: 0 10px;
      }

      .row {
        padding: 10px 0;
      }

      .bottom {
        display: flex;
        border-top: 1px solid #eee;
        gap: var(--spacing-3);
      }

      .status {
        flex: 1;
      }

      .chart-wrapper {
        padding: 10px;
        overflow: scroll;
      }

      .action-btn {
        display: flex;
        padding: 0 var(--spacing-2);
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .action-btn.status {
        flex: 1 1 auto;
        align-items: start;
      }

      .tiff-out-of-map {
        flex: 1;
        text-align: center;
        color: var(--error-color);
      }
    `,
    ],
    standalone: false
})
export class WeatherItemMobileComponent {
  @Input() weatherItemData?: WeatherItemData;
  @Input() config?: WeatherItemConfig;
  @Output() toggleVisibility = new EventEmitter<string>();
  @Output() removeModel = new EventEmitter<string>();
  @Output() toggleChartVisibility = new EventEmitter<void>();
  @Output() centerMap = new EventEmitter<WeatherModel>();
  @Output() showSettings = new EventEmitter<void>();
  iconRemove = faTrash;
  iconChart = faChartBar;
  iconControls = faSlidersH;
  iconSettings = faCog;
}
