import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ProjectActions } from './project/+state';
import { SidenavSelectors } from './sidenav/+state';

@Component({
  selector: 'app-project',
  template: `
    <app-sidenav></app-sidenav>
    <div class="section-wrapper" *ngIf="section$ | async as section">
      <app-map
        class="section"
        [ngStyle]="{ display: section === 'MAP' ? 'block' : 'none' }"
      ></app-map>
      <app-table-list
        class="section"
        [ngStyle]="{ display: section === 'TABLE' ? 'block' : 'none' }"
      ></app-table-list>
    </div>
    <!--    <app-state-debug></app-state-debug>-->
    <app-feature-history-modal></app-feature-history-modal>
    <app-record-editor-modal></app-record-editor-modal>
    <app-confirm-dialog></app-confirm-dialog>
    <app-media-gallery></app-media-gallery>
  `,
  styles: [
    `
      .section-wrapper {
        margin-left: 60px; /* sidenav width when closed*/
      }

      .section-wrapper,
      .section {
        height: 100%;
      }
    `,
  ],
  standalone: false,
})
export class ProjectComponent implements OnDestroy {
  section$: Observable<'MAP' | 'TABLE'>;

  constructor(private store: Store) {
    this.store.dispatch(ProjectActions.fetchProject());
    this.section$ = this.store.select(SidenavSelectors.selectSection());
  }

  ngOnDestroy(): void {
    this.store.dispatch(ProjectActions.closeProject());
  }
}
