import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Media } from '3map-models';
import { environment } from '../../../../../environments/environment';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-media-card',
  template: `
    <div class="media-card-wrapper" *ngIf="media">
      <div class="media">
        <img *ngIf="media.type === 'IMAGE'" [src]="mediaUrl" alt="" />
        <video
          *ngIf="media.type === 'VIDEO'"
          [src]="mediaUrl"
          [controls]="showControls"
        ></video>
        <audio
          *ngIf="media.type === 'AUDIO'"
          [src]="mediaUrl"
          [controls]="showControls"
        ></audio>
        <div class="file" *ngIf="media.type === 'FILE'">
          <fa-icon [icon]="iconFile" />
          <div class="label">File {{ fileExtension }}</div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .media-card-wrapper {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .media img,
      .media video {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      .file {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      fa-icon {
        font-size: var(--font-size-5);
        cursor: pointer;
        color: var(--primary-color);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MediaCardComponent {
  @Input() projectName?: string;
  @Input() media?: Media;
  @Input() base64Img?: string;
  @Input() showControls?: boolean = true;
  iconFile = faFileArrowDown;

  get mediaUrl(): string | null {
    if (this.base64Img) return this.base64Img;
    if (!this.media || !this.projectName) return null;
    const apiUrl = environment.API_URL;
    return `${apiUrl}/static/${this.projectName}/project/${this.media.fileName}`;
  }

  get fileExtension(): string | null {
    if (!this.media) return null;
    const ext = this.media.fileName.split('.').pop();
    return ext ? ext.toUpperCase() : null;
  }
}
