import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faImage, faVectorSquare } from '@fortawesome/free-solid-svg-icons';
import { Resource } from '3map-models';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons/faTriangleExclamation';

@Component({
  selector: 'app-resource-thumbnail[resource]',
  template: `
    <ng-container *ngIf="resource">
      <div class="resource-thumbnail" (click)="onResourceSelected($event)">
        <div class="header">
          <div class="icon">
            <fa-icon
              [icon]="iconImg"
              *ngIf="resource.type === 'IMAGE'"
              matTooltip="Image Resource"
            />
            <fa-icon
              [icon]="iconGeo"
              *ngIf="resource.type === 'GEOJSON'"
              matTooltip="GeoJSON Resource"
            />
          </div>
          <div class="actions" (click)="$event.stopImmediatePropagation()">
            <core-double-confirm-inline
              (confirm)="removeResource.emit(resource)"
            />
          </div>
        </div>

        <div class="name">
          <fa-icon [icon]="iconInvalid" *ngIf="!resource.valid" />
          {{ resource.name }}
        </div>
      </div>
    </ng-container>
  `,
  styles: [
    `
      .resource-thumbnail {
        box-shadow:
          0 1px 3px rgba(0, 0, 0, 0.12),
          0 1px 2px rgba(0, 0, 0, 0.24);
        border-radius: 3px;
        height: 150px;
        background-color: var(--primary-color);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
      }

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 35px;
        padding-left: 10px;
        color: var(--bg-color);
        background-color: rgba(255, 255, 255, 0.15);
      }

      .icon {
        font-size: 1.2em;
      }

      .name {
        background-color: var(--bg-color);
        height: 35px;
        display: flex;
        align-items: center;
        gap: var(--spacing-2);
        padding-left: 10px;
      }

      .name fa-icon {
        color: red;
      }
    `,
  ],
  standalone: false,
})
export class ResourceThumbnailComponent {
  @Input() resource?: Resource & { valid: boolean };
  @Output() removeResource = new EventEmitter<Resource>();
  @Output() resourceSelected = new EventEmitter<Resource>();
  iconImg = faImage;
  iconGeo = faVectorSquare;
  iconInvalid = faTriangleExclamation;

  onResourceSelected(evt: Event): void {
    evt.stopImmediatePropagation();
    this.resourceSelected.emit(this.resource);
  }
}
