import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { faCloudSun } from '@fortawesome/free-solid-svg-icons';
import { WeatherService } from '@trim-web-apps/weather-core';
import { map, Observable } from 'rxjs';
import {
  FULL_MODEL_LIST,
  WeatherModelMetadata,
  WeatherModelSpecRequest,
} from '@trim-web-apps/weather-models';

type WeatherModelView = {
  modelId: string;
  modelLabel: string;
  specRequest: WeatherModelSpecRequest | null;
};

@Component({
  selector: 'app-weather-nav-item',
  template: `
    <app-sidenav-item
      label="Weather"
      [icon]="iconWeather"
      [sidenavOpen]="sidenavOpen"
      [expandable]="true"
    >
      <ng-container *ngIf="view$ | async as view">
        <div class="model-category-label primary-text-bold">Forecast</div>
        <div class="model-by-category" *ngFor="let meta of view.forecast">
          <div class="nav-item-checkbox">
            <ui-checkbox
              [checked]="meta.specRequest?.status === 'success'"
              [error]="meta.specRequest?.status === 'error'"
              [loading]="meta.specRequest?.status === 'pending'"
              [label]="meta.modelLabel"
              (checkboxClick)="toggleModel(meta)"
            />
          </div>
        </div>

        <div class="model-category-label primary-text-bold">Observations</div>
        <div class="model-by-category" *ngFor="let meta of view.monit">
          <div class="nav-item-checkbox">
            <ui-checkbox
              [checked]="meta.specRequest?.status === 'success'"
              [error]="meta.specRequest?.status === 'error'"
              [loading]="meta.specRequest?.status === 'pending'"
              [label]="meta.modelLabel"
              (checkboxClick)="toggleModel(meta)"
            />
          </div>
        </div>
      </ng-container>
    </app-sidenav-item>
  `,
  styles: [
    `
      .model-category-label {
        padding: var(--spacing-2) 0;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class WeatherNavItemComponent {
  @Input() sidenavOpen?: boolean;
  iconWeather = faCloudSun;
  view$: Observable<{
    monit: WeatherModelView[];
    forecast: WeatherModelView[];
  }>;

  constructor(private weather: WeatherService) {
    const sortedModelList = [...FULL_MODEL_LIST].sort((modelA, modelB) => {
      const modelNameA = modelA.label.toLowerCase();
      const modelNameB = modelB.label.toLowerCase();
      if (modelNameA < modelNameB) return -1;
      if (modelNameA > modelNameB) return 1;
      return 0;
    });

    const modelListByCategory = {
      forecast: sortedModelList.filter((m) => m.category === 'FORECAST'),
      monit: sortedModelList.filter((m) => m.category === 'MONITORING'),
    };

    this.view$ = this.weather.getSpecRequestList().pipe(
      map((specReqList) => {
        const forecast = createModelView(
          modelListByCategory.forecast,
          specReqList,
        );
        const monit = createModelView(modelListByCategory.monit, specReqList);
        return { forecast, monit };
      }),
    );
  }

  toggleModel(view: WeatherModelView): void {
    const status = view.specRequest?.status;
    if (status === 'pending') return;
    status === 'success'
      ? this.weather.removeWeatherModel(view.modelId)
      : this.weather.initModel(view.modelId);
  }
}

function createModelView(
  metadataList: WeatherModelMetadata[],
  specReqList: WeatherModelSpecRequest[],
): WeatherModelView[] {
  return metadataList.map((model): WeatherModelView => {
    return {
      modelId: model.id,
      modelLabel: model.label,
      specRequest:
        specReqList.find((specReq) => specReq.modelId === model.id) || null,
    };
  });
}
