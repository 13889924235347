import { createAction, props } from '@ngrx/store';
import { ProjectUser } from '3map-models';

export const setUsers = createAction(
  '[ Project ] Set Users',
  props<{ users: ProjectUser[] }>(),
);

export const removeUsers = createAction(
  '[ Project ] Remove Users',
  props<{ users: ProjectUser[] }>(),
);
