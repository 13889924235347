import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { FieldType } from '3map-models/dist/lib/model';
import { FormSpecific } from '3map-models';
import {
  addQuestionGroup,
  createSpecific,
  duplicateSpecific,
  moveQuestionGroup,
  removeAllQuestionGroup,
  removeQuestionGroup,
  removeSpecific,
  renameQuestionGroup,
  setLogicList,
  setSpecificName,
  upsertQuestionGroup,
  upsertQuestions,
} from './form-specific.actions';
import * as formActions from './form.actions';
import { notNullOrUndefined } from '@trim-web-apps/core';
// import { AdminActions } from '../../+state';
import * as AdminActions from '../../+state/admin.actions';

export const ADMIN_SPECIFIC_FEATURE_KEY = 'admin-form-specific';

type Logic = {
  q1: string;
  q2: string;
  logic: InnerLogic[];
};

type InnerLogic = {
  r1: string;
  r2: string[];
};

export interface EntitySpecific {
  id: string;
  formId: string;
  name: string;
  questions: FieldType[];
  questionGroups?: {
    name: string;
    questions: string[];
  }[];
  listLogic?: Logic[];
}

export type State = EntityState<EntitySpecific>;

export const adapter: EntityAdapter<EntitySpecific> =
  createEntityAdapter<EntitySpecific>();
const initialState: State = adapter.getInitialState();

const formSpecificReducer = createReducer(
  initialState,
  on(
    AdminActions.close,
    AdminActions.initProjectError,
    (): State => initialState,
  ),
  on(AdminActions.initProjectSuccess, (state, { project }): State => {
    const entities = project.formList
      .map((f) => f.specificList.map((s) => createEntitySpecific(f.id, s)))
      .reduce((acc, item) => [...acc, ...item], []);
    return adapter.setAll(entities, state);
  }),
  on(formActions.addDuplicatedForm, (state, { form }) => {
    const entities = form.specificList.map((specific) =>
      createEntitySpecific(form.id, specific),
    );
    return adapter.addMany(entities, state);
  }),
  on(formActions.removeForm, (state, { form }) => {
    const entities = Object.keys(state.entities)
      .map((key) => state.entities[key])
      .filter(notNullOrUndefined)
      .filter((entity) => form.id === entity.formId);
    return adapter.removeMany(
      entities.map((e) => e.id),
      state,
    );
  }),
  on(createSpecific, duplicateSpecific, (state, { formId, specific }) => {
    return adapter.addOne(createEntitySpecific(formId, specific), state);
  }),
  on(removeSpecific, (state, { specificId }) => {
    return adapter.removeOne(specificId, state);
  }),
  on(setSpecificName, (state, { specificId, name }) => {
    return adapter.updateOne({ id: specificId, changes: { name } }, state);
  }),
  on(upsertQuestions, (state, { specificId, questions }) => {
    const listLogic = getUpdatedListLogic(
      state.entities[specificId],
      questions,
    );
    return adapter.updateOne(
      {
        id: specificId,
        changes: { questions, listLogic },
      },
      state,
    );
  }),
  on(
    upsertQuestionGroup,
    (state, { specificId, questionsGroupList }): State => {
      const questions = questionsGroupList
        .map((group) => group.questions)
        .reduce((acc, item) => [...acc, ...item]);
      const questionGroups = questionsGroupList.map((group) => ({
        name: group.groupName,
        questions: group.questions.map((q) => q.id),
      }));
      const listLogic = getUpdatedListLogic(
        state.entities[specificId],
        questions,
      );
      return adapter.updateOne(
        {
          id: specificId,
          changes: {
            questions,
            questionGroups,
            listLogic,
          },
        },
        state,
      );
    },
  ),
  on(
    addQuestionGroup,
    (state, { specificId, groupName, questionList }): State => {
      const entity = state.entities[specificId];
      if (!entity) return state;
      const questionGroups = entity.questionGroups || [];
      const questions = questionList?.map((ft) => ft.id) || [];
      return adapter.updateOne(
        {
          id: specificId,
          changes: {
            questionGroups: [...questionGroups, { name: groupName, questions }],
          },
        },
        state,
      );
    },
  ),
  on(removeQuestionGroup, (state, { specificId, groupName }): State => {
    const entity = state.entities[specificId];
    const questions = entity?.questions || [];
    const questionGroups = entity?.questionGroups;
    const listLogic = entity?.listLogic;

    if (!entity || !questionGroups) return state;

    const groupToRemove = questionGroups?.find((g) => g.name === groupName);
    const questionsToRemove = groupToRemove?.questions || [];

    if (!groupToRemove) return state;

    const newQuestionGroups = questionGroups.filter(
      (g) => g.name !== groupName,
    );
    const newQuestions = questions.filter(
      (q) => !questionsToRemove.includes(q.id),
    );
    const newQuestionsIds = newQuestions.map((q) => q.id);
    const newListLogic = listLogic?.filter((l) => {
      return newQuestionsIds.includes(l.q1) && newQuestionsIds.includes(l.q2);
    });

    return adapter.updateOne(
      {
        id: specificId,
        changes: {
          questions: newQuestions,
          listLogic: newListLogic,
          questionGroups: newQuestionGroups,
        },
      },
      state,
    );
  }),
  on(removeAllQuestionGroup, (state, { specificId }): State => {
    return adapter.updateOne(
      {
        id: specificId,
        changes: { questionGroups: undefined },
      },
      state,
    );
  }),
  on(renameQuestionGroup, (state, { specificId, oldName, newName }): State => {
    const questionGroups = state.entities[specificId]?.questionGroups;
    if (!questionGroups) return state;
    const groupIndex = questionGroups.findIndex((g) => g.name === oldName);
    if (groupIndex === -1) return state;
    const newQuestionGroups = [...questionGroups];
    newQuestionGroups[groupIndex] = {
      name: newName,
      questions: questionGroups[groupIndex].questions,
    };
    return adapter.updateOne(
      {
        id: specificId,
        changes: {
          questionGroups: newQuestionGroups,
        },
      },
      state,
    );
  }),
  on(
    moveQuestionGroup,
    (state, { specificId, fromGroup, toGroupName }): State => {
      const entity = state.entities[specificId];
      const questionGroups = entity?.questionGroups;
      const questionIdsList = fromGroup.questions.map((ft) => ft.id);
      if (!entity || !questionGroups) return state;

      const newQuestionGroups = questionGroups.map((group) => {
        if (group.name === fromGroup.groupName) {
          return {
            name: group.name,
            questions: group.questions.filter(
              (id) => !questionIdsList.includes(id),
            ),
          };
        }

        if (group.name === toGroupName) {
          return {
            name: group.name,
            questions: [...group.questions, ...questionIdsList],
          };
        }

        return group;
      });

      // restore questions order based on new group order
      const questions = newQuestionGroups
        .map((g) => g.questions)
        .reduce((acc, item) => [...acc, ...item])
        .map((id) => entity.questions.find((ft) => ft.id === id))
        .filter(notNullOrUndefined);

      return adapter.updateOne(
        {
          id: specificId,
          changes: { questionGroups: newQuestionGroups, questions },
        },
        state,
      );
    },
  ),
  on(setLogicList, (state, { specificId, logicList }): State => {
    const listLogic = logicList.map((logic) => ({
      ...logic,
      q1: logic.q1.id,
      q2: logic.q2.id,
    }));
    return adapter.updateOne(
      {
        id: specificId,
        changes: { listLogic },
      },
      state,
    );
  }),
);

export function reducer(state: State | undefined, action: Action): State {
  return formSpecificReducer(state, action);
}

export function createEntitySpecific(
  formId: string,
  specific: FormSpecific,
): EntitySpecific {
  const entity: EntitySpecific = {
    id: specific.id,
    formId: formId,
    name: specific.name,
    questions: specific.questions,
  };

  if (specific.questionGroups) entity.questionGroups = specific.questionGroups;
  if (specific.listLogic) entity.listLogic = specific.listLogic;

  return entity;
}

function getUpdatedListLogic(
  entity: EntitySpecific | undefined,
  questions: FieldType[],
): Logic[] | undefined {
  const listLogic = entity?.listLogic;
  let newListLogic: Logic[] = [];
  if (!listLogic) return undefined;

  for (const logicItem of listLogic) {
    const controllerQuestion = questions.find((q) => q.id === logicItem.q1);
    const controlledQuestion = questions.find((q) => q.id === logicItem.q2);
    let newLogic: InnerLogic[] = [];

    for (const l of logicItem.logic) {
      const r1Exists = controllerQuestion?.options?.includes(l.r1);
      const r2List = l.r2.filter((r2) =>
        controlledQuestion?.options?.includes(r2),
      );

      if (r1Exists) {
        newLogic = [...newLogic, { ...l, r2: r2List }];
      }
    }

    if (newLogic.length > 0) {
      newListLogic = [...newListLogic, { ...logicItem, logic: newLogic }];
    }
  }

  return newListLogic;
}
