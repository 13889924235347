import { Component, Input, OnInit } from '@angular/core';
import { FieldTypeBaseComponent } from '../../../models/FieldTypeBaseComponent';
import { FieldRange } from '3map-models';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { extractTheFuckingNumber } from '../field-number/field-number.component';
import { EditorError } from '../../../models/EditorError';
import { isNumber } from '@trim-web-apps/core';

@Component({
  selector: 'app-field-range',
  template: `
    <app-field-name [fieldType]="fieldType" />
    <form class="field-range-form " [formGroup]="rangeFormGroup!">
      <input
        [readOnly]="!allowEdit"
        type="number"
        placeholder="Min"
        formControlName="min"
        (focusout)="inputFocusOut()"
      />
      <input
        [readOnly]="!allowEdit"
        type="number"
        placeholder="Max"
        formControlName="max"
        (focusout)="inputFocusOut()"
      />
    </form>
    <div class="error" *ngIf="hasError()">Required field</div>
  `,
  styleUrls: ['../../field-type.base.component.scss'],
  standalone: false,
})
export class FieldRangeComponent
  extends FieldTypeBaseComponent
  implements OnInit
{
  @Input() fieldRange: FieldRange | undefined;
  rangeFormGroup: UntypedFormGroup | undefined;

  ngOnInit(): void {
    if (!this.fieldType) throw Error('[ FieldRange ] Missing FieldType');
    const min = isNumber(this.fieldRange?.min) ? this.fieldRange?.min : '';
    const max = isNumber(this.fieldRange?.max) ? this.fieldRange?.max : '';
    const validators = this.fieldType.required
      ? [Validators.pattern('^[0-9]*$'), Validators.required]
      : [Validators.pattern('^[0-9]*$')];
    this.rangeFormGroup = new UntypedFormGroup({
      min: new UntypedFormControl(min, validators),
      max: new UntypedFormControl(max, validators),
    });
  }

  override inputFocusOut() {
    try {
      const field = this.getField();
      if (field) this.fieldChange.emit({ fieldType: this.fieldType, field });
    } catch (e) {}
  }

  override getField(): FieldRange | null {
    if (this.rangeFormGroup?.valid) {
      const min = this.getValue('min');
      const max = this.getValue('max');
      return min && max ? { min, max, type: 'RANGE' } : null;
    }
    throw new EditorError('Invalid FieldRange');
  }

  private getValue(minMax: 'min' | 'max'): number | null {
    if (!this.rangeFormGroup || this.rangeFormGroup.invalid) return null;
    return extractTheFuckingNumber(this.rangeFormGroup.get(minMax)?.value);
  }
}
