import { Component, Input, OnInit } from '@angular/core';
import { map, Observable } from 'rxjs';
import { WeatherModel } from '@trim-web-apps/weather-models';
import { Store } from '@ngrx/store';
import { WeatherSelectors } from '../../+state';
import { Dialog } from '@angular/cdk/dialog';
import { ModelInfoComponent } from '../../components/model-info/model-info.component';
import { Map } from 'mapbox-gl';
import { WeatherItemConfig } from '../../weather.types';
import { DEFAULT_WEATHER_ITEM_CONFIG } from '../../weather.const';

@Component({
    selector: 'weather-core-weathers-list',
    template: `
    <ng-container *ngIf="weatherModelIdList$ | async as modelIdList">
      <div class="weather-list-wrapper">
        <weather-core-item
          class="weather-item"
          *ngFor="let model of modelIdList"
          [weatherModelId]="model"
          [showRemoveIcon]="showRemoveIcon ?? true"
          [isMobile]="isMobile ?? false"
          [map]="map"
          [config]="config"
          (showInfo)="onShowInfo($event)"
        />
      </div>
    </ng-container>
  `,
    styles: [
        `
      .weather-list-wrapper {
        width: 100%;
      }
    `,
    ],
    standalone: false
})
export class WeathersListComponent implements OnInit {
  @Input() showRemoveIcon?: boolean;
  @Input() isMobile?: boolean;
  @Input() map?: Map;
  @Input() config?: WeatherItemConfig;
  weatherModelIdList$: Observable<string[]>;
  utcOffset$: Observable<string>;

  constructor(
    private store: Store,
    private dialog: Dialog,
  ) {
    this.utcOffset$ = this.store.select(WeatherSelectors.selectUtcOffset());
    this.weatherModelIdList$ = this.store
      .select(WeatherSelectors.selectSpecEntities())
      .pipe(
        map((dict) => Object.keys(dict)),
        map((idList) =>
          this.isMobile && idList.length > 0 ? [idList[0]] : idList,
        ),
      );
  }

  ngOnInit(): void {
    if (!this.map) console.error('Map is required');
    this.config = { ...DEFAULT_WEATHER_ITEM_CONFIG, ...this.config };
  }

  onShowInfo(model: WeatherModel): void {
    this.dialog.open(ModelInfoComponent, {
      width: '400px',
      data: model,
    });
  }
}
