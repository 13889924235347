import { WeatherModelInitConfig, WeatherModelMetadata } from '../types';
import { WeatherModel } from '../types/weather.model';
import { WeatherLayer } from '../types/weather-layer.type';
import {
  createTsListForecast,
  decodeForecastSpec,
  getBaseModelTemplate,
  getDefaultDateRange,
  getTsListRainfall,
  removeUnwantedLayers,
} from '../utils';
import {
  PRESSURE,
  RAINFALL_1HOUR,
  SNOWFALL_1HOUR,
  TEMPERATURE,
  TOTAL_CLOUD_COVER,
  WIND_KTS,
} from '../weather-layers/weather.layers';
import { DateTime } from 'luxon';

function getTsCount(init: number): number {
  const initHour = DateTime.fromSeconds(init, { zone: 'utc' }).hour;
  if (initHour === 0) return 73;
  if (initHour === 6) return 43;
  if (initHour === 12) return 85;
  if (initHour === 18) return 55;
  throw new Error(`Unexpected init: ${initHour}`);
}

export const MOLOCH: WeatherModelMetadata = {
  id: 'MOLOCH',
  label: 'MOLOCH',
  category: 'FORECAST',
  subCategory: 'LOCAL_MODELS',
  info: {
    // resolutionDeg: '0.034° x 0.024° (~2.5 km)',
    resolution: { deg: 0.035, km: 2.5 },
    // coverage: 'Lat: 34.2; 49.6 -- Lon: 2.4; 19.6',
    coverage: '???',
    url: 'http://www.lamma.rete.toscana.it/modelli/atmo/bolam-e-moloch-info-sui-modelli',
    credits: 'Consorzio LaMMA',
    schedule: [
      { init: '00', time: '07:30' },
      { init: '06', time: '13:00' },
      { init: '12', time: '20:00' },
      { init: '18', time: '03:00 (next day)' },
    ],
  },
  bbox: [
    [2.3827, 34.199999],
    [2.3827, 49.464598],
    [19.890301, 49.464598],
    [19.890301, 34.199999],
    [2.3827, 34.199999],
  ],
};

export function createMoloch(
  serverSpec: unknown,
  config?: WeatherModelInitConfig,
): WeatherModel {
  const spec = decodeForecastSpec(serverSpec);
  const timestepList = spec.initimeList
    .map((init) => createTsListForecast([init], getTsCount(init)))
    .reduce((acc, item) => [...acc, ...item], [])
    .reverse();
  const tsRainfall = getTsListRainfall(timestepList);
  const defaultDateRange = getDefaultDateRange(timestepList, 0);
  const defaultDateRngRainfall = getDefaultDateRange(tsRainfall, 1, 120);
  const timestepType = { type: 'UNIX', interval: 3600 } as const;

  const molochLayers: WeatherLayer[] = [
    {
      ...WIND_KTS,
      timestepType,
      timestepList,
      defaultDateRange,
    },
    {
      ...WIND_KTS,
      id: 'wind_950',
      label: 'Wind (950 hPa)',
      timestepType,
      timestepList,
      defaultDateRange,
    },
    {
      ...TEMPERATURE,
      timestepType,
      timestepList,
      defaultDateRange,
    },
    {
      ...PRESSURE,
      timestepType,
      timestepList,
      defaultDateRange,
    },
    {
      ...TOTAL_CLOUD_COVER,
      timestepType,
      timestepList,
      defaultDateRange,
    },
    {
      ...RAINFALL_1HOUR,
      timestepType,
      timestepList: tsRainfall,
      defaultDateRange: defaultDateRngRainfall,
    },
    {
      ...SNOWFALL_1HOUR,
      timestepType,
      timestepList,
      defaultDateRange,
      conversionFactor: 1000, // snowfall is in m
    },
  ];

  const layers = removeUnwantedLayers(molochLayers, config?.layerIdList ?? []);

  if (layers.length === 0)
    throw new Error(`No layers available for ${spec.model} model`);

  return {
    ...getBaseModelTemplate(config),
    ...MOLOCH,
    layers: layers,
    initime: layers[0].timestepList[0].initime,
    timestep: layers[0].defaultDateRange.from,
    selectedLayerId: layers[0].id,
    dateRange: layers[0].defaultDateRange,
  };
}
