import { FieldType } from '3map-models/dist/lib/model';

export type FormSpecificLogic = {
  q1: FieldType;
  q2: FieldType;
  logic: QuestionLogic[];
};

export type QuestionLogic = {
  r1: string;
  r2: string[];
};
