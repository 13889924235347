import { Component, Input, OnInit } from '@angular/core';
import { FieldTypeBaseComponent } from '../../../models/FieldTypeBaseComponent';
import { FieldDate } from '3map-models';
import { UntypedFormControl, Validators } from '@angular/forms';
import { EditorError } from '../../../models/EditorError';

@Component({
  selector: 'app-field-date',
  template: `
    <app-field-name [fieldType]="fieldType"></app-field-name>
    <input
      type="date"
      (focusout)="inputFocusOut()"
      [readOnly]="!allowEdit"
      [formControl]="formControl!"
    />
    <div class="error" *ngIf="hasError()">Date is required</div>
  `,
  styleUrls: ['../../field-type.base.component.scss'],
  standalone: false,
})
export class FieldDateComponent
  extends FieldTypeBaseComponent
  implements OnInit
{
  @Input() fieldDate: FieldDate | undefined;

  ngOnInit(): void {
    if (!this.fieldType) throw Error('[ FieldDate ] Missing FieldType');
    const value = this.fieldDate?.date.toISOString().substr(0, 10) || null;
    const validators = this.fieldType.required ? [Validators.required] : [];
    this.formControl = new UntypedFormControl(value, validators);
    // this.bindFormControlEmitter();
  }

  override getField(): FieldDate | null {
    if (this.formControl?.valid) {
      const dateValue = this.formControl.value;
      const datetime = new Date(`${dateValue}T00:00:00.000Z`);
      return datetime !== null && !isNaN(datetime.getTime())
        ? {
            date: datetime,
            type: 'DATE',
          }
        : null;
    }
    throw new EditorError('Invalid FieldDate');
  }
}
