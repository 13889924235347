import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResourceListComponent } from './containers/resource-list.component';
import { ResourceComponent } from './containers/resource.component';
import { CoreModule } from '@trim-web-apps/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResourceThumbnailComponent } from './components/resource-thumbnail.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MapModule } from '@trim-web-apps/map';
import { ResourceImageComponent } from './containers/resource-image.component';
import { ResourceGeojsonComponent } from './containers/resource-geojson.component';
import { ResourceMapComponent } from './components/resource-map.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ResourceNameComponent } from './components/resource-name.component';
import { ResourceCreateComponent } from './containers/resource-create.component';
import { ResourceSectionActionsComponent } from './components/resource-section-actions.component';
import { SharedModule } from '../shared/shared.module';
import { Map3UiModule } from '@trim-web-apps/map3-ui';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EffectsModule } from '@ngrx/effects';
import { ResourceEffects } from './+state/resource.effects';
import { ResourceIdWarningComponent } from './components/resource-id-warning.component';

@NgModule({
  declarations: [
    ResourceListComponent,
    ResourceComponent,
    ResourceThumbnailComponent,
    ResourceImageComponent,
    ResourceGeojsonComponent,
    ResourceMapComponent,
    ResourceNameComponent,
    ResourceCreateComponent,
    ResourceSectionActionsComponent,
    ResourceIdWarningComponent,
  ],
  imports: [
    CommonModule,
    // StoreModule.forFeature(ADMIN_RESOURCE_FEATURE_KEY, reducer),
    EffectsModule.forFeature([ResourceEffects]),
    CoreModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MatTooltipModule,
    MapModule,
    ColorPickerModule,
    FormsModule,
    SharedModule,
    Map3UiModule,
  ],
  exports: [ResourceSectionActionsComponent],
})
export class ResourceModule {}
