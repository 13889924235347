import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'ui-dropdown-icon',
    template: `
    <div class="dropdown-icon-wrapper" *ngIf="icon">
      <fa-icon [cdkMenuTriggerFor]="dropdown" [icon]="icon"></fa-icon>
      <ng-template #dropdown>
        <div class="menu-content" cdkMenu>
          <div
            *ngFor="let item of itemList"
            (click)="itemSelected.emit(item)"
            class="menu-item text-ellipsis"
            cdkMenuItem
          >
            {{ item }}
          </div>
        </div>
      </ng-template>
    </div>
  `,
    styles: [
        `
      .dropdown-icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        cursor: pointer;
      }

      fa-icon {
        padding: var(--spacing-1) var(--spacing-2);
      }

      .menu-content {
        border-radius: var(--radius-1);
      }
    `,
    ],
    // see style in +styles/menu.style.scss
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DropdownIconComponent {
  @Input() icon?: IconDefinition;
  @Input() itemList: string[] = [];
  @Output() itemSelected = new EventEmitter<string>();
}
