import { createAction, props } from '@ngrx/store';
import { Project } from '3map-models';

export const fetchProject = createAction('[Project2] Fetch Project');

export const fetchProjectSuccess = createAction(
  '[Project2/API] Fetch Project Success',
  props<{ project: Project }>(),
);

export const fetchProjectError = createAction(
  '[Project2/API] Fetch Project Error',
);

export const closeProject = createAction('[Project] Close Project');
