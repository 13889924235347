import { createSelector } from '@ngrx/store';
import { notNullOrUndefined } from '@trim-web-apps/core';
import { selectAppAdminState } from '../../admin.reducer.map';

// export const selectState = createFeatureSelector<State>(ADMIN_USER_FEATURE_KEY);
export const selectState = () =>
  createSelector(selectAppAdminState(), (state) => state.users);

export const selectUsers = () => {
  return createSelector(selectState(), (users) =>
    Object.keys(users.entities)
      .map((k) => users.entities[k])
      .filter(notNullOrUndefined),
  );
};
