import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaGalleryComponent } from './media-gallery/media-gallery.component';
import { StoreModule } from '@ngrx/store';
import * as fromMediaGallery from './+state/media-gallery.reducer';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [MediaGalleryComponent],
  exports: [MediaGalleryComponent],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromMediaGallery.featureKey,
      fromMediaGallery.reducer,
    ),
    FontAwesomeModule,
    SharedModule,
  ],
})
export class MediaGalleryModule {}
