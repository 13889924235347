import {
  formatDateDay,
  formatDateMonth,
  monitoringDayIndexToDate,
  monitoringMonthIndexToDate,
} from '3map-models';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TimestepType } from '@trim-web-apps/weather-models';
import { DatePickerInlineData } from '../date-range-picker-inline/date-range-picker-inline.component';
import {
  faChevronDown,
  faChevronUp,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'weather-core-date-index-test',
    template: `
    <div class="dev-tool">
      <div class="section" (click)="showTestTool = !showTestTool">
        <div>Index testing</div>
        <fa-icon [icon]="iconCollapse" *ngIf="showTestTool"></fa-icon>
        <fa-icon [icon]="iconShow" *ngIf="!showTestTool"></fa-icon>
      </div>
      <div class="dev-tool-content" *ngIf="showTestTool">
        <div class="date-picker-data-wrapper" *ngIf="data">
          <div class="from">
            <div class="row">From</div>
            <div class="row">
              <div class="label">LABEL</div>
              <div class="em">{{ data.from.selected.label }}</div>
            </div>
            <div class="row">
              <div class="api">API</div>
              <div class="em">{{ data.from.selected.apiValue }}</div>
            </div>
          </div>

          <div class="to">
            <div class="row">To</div>
            <div class="row">
              <div class="label">LABEL</div>
              <div class="em">{{ data.to.selected.label }}</div>
            </div>
            <div class="row">
              <div class="api">API</div>
              <div class="em">{{ data.to.selected.apiValue }}</div>
            </div>
          </div>
        </div>

        <div class="index-to-date-string">
          <div class="row"></div>
          <div class="row">
            <input
              placeholder="Convert index"
              #indexInput
              (keyup)="onInputChange(indexInput.value)"
            />
            <div class="index-result">{{ dateString }}</div>
          </div>
        </div>
      </div>
    </div>
  `,
    styles: [
        `
      .date-picker-data-wrapper {
        display: flex;
        justify-content: space-between;
      }

      .section {
        color: var(--border);
        margin: 10px 0;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
      }

      .row {
        display: flex;
        align-items: center;
      }

      .label,
      .api {
        font-size: 0.7em;
        width: 40px;
        user-select: all !important;
      }

      .index-to-date-string {
        margin-top: 20px;
      }

      .index-to-date-string input {
        width: 120px;
      }

      .index-result {
        margin-left: 10px;
        user-select: all !important;
      }
    `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DateIndexTestComponent {
  @Input() tsType?: TimestepType;
  @Input() data?: DatePickerInlineData;
  dateString?: string;
  showTestTool?: boolean;
  iconCollapse: IconDefinition = faChevronUp;
  iconShow: IconDefinition = faChevronDown;

  onInputChange(input: string): void {
    const index = parseInt(input);
    this.dateString = this.getDateString(index);
  }

  private getDateString(index: number): string {
    if (!this.tsType) return 'tsType not provided';
    // if (isNaN(index)) return 'Not a number';
    if (isNaN(index)) return 'tsType not provided';
    switch (this.tsType.type) {
      case 'DAYS':
        return formatDateDay(monitoringDayIndexToDate(index));
      case '1MONTH':
      case '3MONTH':
      case 'QUARTER':
      case '6MONTH':
      case 'SEMESTER':
      case '1YEAR':
        return formatDateMonth(monitoringMonthIndexToDate(index));
      default:
        return 'TS type not supported';
    }
  }

  // daily(): void {
  //   const dt = monitoringDayIndexToDate(index);
  //   const format = formatDateDay(dt);
  // }

  // monthly(): void {
  //   const dt = monitoringMonthIndexToDate(index);
  //   const format = formatDateMonth(dt);
  // }
}
