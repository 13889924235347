import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Media, Record } from '3map-models';
import { environment } from '../../../../../environments/environment';

type MediaView = {
  mediaUrl: string;
  media: Media;
};

function toMediaView(record: Record): MediaView[] {
  const { projectName, mediaList } = record;
  return mediaList.map((media) => {
    const mediaUrl = `${environment.API_URL}/static/${projectName}/project/${media.fileName}`;
    return { mediaUrl, media };
  });
}

@Component({
  selector: 'app-media-thumbnail-preview',
  template: `
    <div class="media-preview-list">
      <div class="media-preview-list-inner" *ngIf="mediaViewList">
        <div class="media-preview" *ngFor="let view of mediaViewList">
          <img
            *ngIf="view.media.type === 'IMAGE'"
            [src]="view.mediaUrl"
            alt=""
          />
          <video
            *ngIf="view.media.type === 'VIDEO'"
            [src]="view.mediaUrl"
            [controls]="false"
            [autoplay]="false"
          ></video>
          <audio
            *ngIf="view.media.type === 'AUDIO'"
            [src]="view.mediaUrl"
            [controls]="false"
            [autoplay]="false"
          ></audio>
          <div class="file" *ngIf="view.media.type === 'FILE'">
            <img src="assets/3map-project/icon-file-resource.png" />
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .media-preview-list {
        overflow: auto;
      }

      .media-preview-list-inner {
        display: flex;
        gap: 10px;
        width: fit-content;
        margin-top: 5px;
      }

      .media-preview {
        height: 30px;
        width: 30px;
        overflow: hidden;
        border-radius: var(--radius-1);
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      .file img {
        object-fit: fill;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MediaThumbnailPreviewComponent {
  @Input({ transform: toMediaView }) mediaViewList?: MediaView[];
}
