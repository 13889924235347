import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SidenavActions } from '../../sidenav/+state';
import { TableActions, TableSelectors } from './index';
import { map } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ProjectSelectors } from '../../project/+state';

@Injectable()
export class TableEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
  ) {}

  sidenavRemoveDataset = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SidenavActions.removeDataset),
        map((action) => action.id),
        withLatestFrom(
          this.store.select(ProjectSelectors.selectAllDataset()),
          this.store.select(TableSelectors.selectActiveTableId()),
        ),
        map(([removeDatasetId, datasetList, activeTableId]) => {
          if (datasetList.length === 0)
            this.store.dispatch(TableActions.setActiveTable({ tableId: null }));
          else {
            const datasetIdList = datasetList.map((ds) => ds.formId);

            // activeTableId is still valid if it is in the datasetIdList
            const isActiveTableIdValid =
              datasetIdList.includes(removeDatasetId);

            // if activeTableId is not valid, set the last datasetId in the list as the activeTableId
            if (activeTableId && !isActiveTableIdValid) {
              const lastDatasetId = datasetIdList[datasetIdList.length - 1];
              this.store.dispatch(
                TableActions.setActiveTable({ tableId: lastDatasetId }),
              );
            }
          }
        }),
      ),
    { dispatch: false },
  );
}
