import { createAction, props } from '@ngrx/store';
import { WebHook } from '3map-models';

export const upsertWebHook = createAction(
  '[ WebHookList ] Upsert WebHook ',
  props<{ webHook: WebHook }>(),
);

export const removeWebHook = createAction(
  '[ WebHookList ] Remove WebHook',
  props<{ webHook: WebHook }>(),
);
