import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

@Component({
  selector: 'app-input-username',
  template: `
    <div class="input-field" [ngClass]="{ 'input-error': error$ | async }">
      <fa-icon class="input-icon" [icon]="icon"></fa-icon>
      <input
        type="text"
        [formControl]="usernameControl"
        [autocomplete]="false"
        [placeholder]="label"
      />
    </div>
    <div class="input-error-msg" *ngIf="validation">{{ error$ | async }}</div>
  `,
  styleUrls: ['../input.styles.scss'],
  standalone: false,
})
export class InputUsernameComponent implements OnInit {
  @Input() label = 'Username';
  @Input() validation = true;
  @Output() formControl: EventEmitter<UntypedFormControl> =
    new EventEmitter<UntypedFormControl>();

  usernameControl: UntypedFormControl;
  error$: Observable<string>;
  icon = faUser;

  constructor() {
    this.usernameControl = new UntypedFormControl('');
    this.error$ = this.usernameControl.valueChanges.pipe(
      debounceTime(1000),
      map(() => {
        return this.usernameControl.invalid
          ? `Username must be at least 4`
          : '';
      }),
    );
  }

  ngOnInit(): void {
    this.usernameControl.setValidators(
      this.validation ? [Validators.required, Validators.minLength(4)] : [],
    );
    this.formControl.emit(this.usernameControl);
  }
}
