import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { WeatherItemData } from './weather-item-data';
import { WeatherModel } from '@trim-web-apps/weather-models';
import {
  faCircleInfo,
  faCog,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { TemplatePortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { WeatherItemConfig } from '../../weather.types';

@Component({
    selector: 'weather-core-item-desktop',
    template: `
    <div class="weather-item-wrapper" *ngIf="weatherItemData">
      <div class="row">
        <div class="controls">
          @if (config?.statusSection) {
            <weather-core-model-status
              [weatherModel]="weatherItemData.model"
              (click)="toggleVisibility.emit(weatherItemData.model.id)"
            />

            <div class="remove-model" *ngIf="weatherItemData.showRemoveIcon">
              <fa-icon
                [icon]="iconRemove"
                (click)="removeModel.emit(weatherItemData.model.id)"
              />
            </div>

            <fa-icon [icon]="iconSettings" (click)="openSettings()" />

            <ng-template #settings>
              <div
                class="settings shadow"
                cdkDrag
                cdkDragRootElement=".cdk-overlay-pane"
              >
                <div class="settings-title">
                  {{ weatherItemData.model.label }} Settings
                </div>
                <weather-core-settings
                  [weatherModelId]="weatherItemData.model.id"
                  (closeSettings)="closeSettings()"
                />
              </div>
            </ng-template>

            <div class="separator"></div>
          }

          <ui-checkbox
            *ngIf="
              weatherItemData.model.hasChart && !weatherItemData.isTiffOutOfMap
            "
            [label]="'Chart'"
            [checked]="weatherItemData.model.chartEnabled"
            (click)="toggleChartVisibility.emit()"
          />

          <weather-core-layer-picker
            *ngIf="!weatherItemData.isTiffOutOfMap"
            [weatherModelId]="weatherItemData.model.id"
          />

          <weather-core-timestep-picker
            *ngIf="!weatherItemData.isTiffOutOfMap"
            [weatherModelId]="weatherItemData.model.id"
          />
        </div>

        <div class="legend" *ngIf="!weatherItemData.isTiffOutOfMap">
          <div class="legend-inner">
            <weather-core-legend
              [layer]="weatherItemData.selectedLayer"
              [isMobile]="true"
            />
          </div>
        </div>

        <div class="tiff-out-of-map" *ngIf="weatherItemData.isTiffOutOfMap">
          {{ weatherItemData.model.label }} not available in this area.
          <span
            (click)="centerMap.emit(weatherItemData.model)"
            *ngIf="weatherItemData.showCenterMap"
          >
            Click here to center the map
          </span>
        </div>

        <div class="info">
          <fa-icon
            [icon]="iconInfo"
            (click)="showInfo.emit(weatherItemData.model)"
          />
        </div>
      </div>

      <div class="row">
        <weather-core-chart [weatherModelId]="weatherItemData.model.id" />
      </div>
    </div>
  `,
    styles: [
        `
      .weather-item-wrapper {
        background-color: #ffffff;
        margin: var(--spacing-2);
        padding: 0 var(--spacing-2);
        box-shadow:
          0 3px 6px rgba(0, 0, 0, 0.16),
          0 3px 6px rgba(0, 0, 0, 0.23);
        border-radius: 7px;
        min-height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      fa-icon {
        cursor: pointer;
      }

      .settings {
        background-color: #ffffff;
        width: 400px;
        overflow: auto;
        padding: var(--spacing-3);
      }

      .settings-title {
        font-size: var(--font-size-3);
        /*font-weight: var(--font-weight-3);*/
        color: var(--primary-color);
        text-align: right;
      }

      .row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }

      .controls {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }

      weather-core-model-status {
        width: 200px;
      }

      weather-core-chart {
        width: 100%;
      }

      .remove-model,
      .separator,
      .legend,
      weather-core-layer-picker,
      weather-core-timestep-picker,
      ui-checkbox {
        margin: 0 var(--spacing-2);
      }

      weather-core-layer-picker {
        width: 250px;
      }

      weather-core-timestep-picker {
        width: 300px;
      }

      .controls,
      .legend {
        padding: var(--spacing-1) 0;
      }

      .legend {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 300px;
        flex: 1;
      }

      .legend-inner {
        min-width: 300px;
      }

      weather-core-legend {
        width: 100%;
      }

      .weather-item-wrapper .separator {
        height: 25px;
        border-left: 1px solid #dbdbdb;
      }

      .tiff-out-of-map {
        flex: 1;
        text-align: center;
        color: var(--error-color);
      }

      .tiff-out-of-map span {
        cursor: pointer;
      }
    `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class WeatherItemDesktopComponent implements AfterViewInit, OnDestroy {
  @ViewChild('settings') _dialogTemplate?: TemplateRef<any>;
  @Input() weatherItemData?: WeatherItemData;
  @Input() config?: WeatherItemConfig;
  @Output() toggleVisibility = new EventEmitter<string>();
  @Output() removeModel = new EventEmitter<string>();
  @Output() toggleChartVisibility = new EventEmitter<void>();
  @Output() showInfo = new EventEmitter<WeatherModel>();
  @Output() centerMap = new EventEmitter<WeatherModel>();

  iconRemove = faTrash;
  iconInfo = faCircleInfo;
  iconSettings = faCog;

  private _overlayRef!: OverlayRef;
  private _portal?: TemplatePortal;

  constructor(
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
  ) {}

  ngAfterViewInit() {
    if (!this._dialogTemplate) return;
    this._portal = new TemplatePortal(
      this._dialogTemplate,
      this._viewContainerRef,
    );
    this._overlayRef = this._overlay.create({
      positionStrategy: this._overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),
      hasBackdrop: false,
    });
    this._overlayRef.backdropClick().subscribe(() => this._overlayRef.detach());
  }

  ngOnDestroy() {
    this._overlayRef?.dispose();
  }

  openSettings() {
    this.closeSettings();
    this._overlayRef?.attach(this._portal);
  }

  closeSettings(): void {
    if (this._overlayRef?.hasAttached()) this._overlayRef.detach();
  }
}
