import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { routes } from './app.routing';
import { BrowserModule } from '@angular/platform-browser';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { EffectsModule } from '@ngrx/effects';
import { environment } from '../environments/environment';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { metaReducers } from './app.meta-reducer';
import { AppProjectModule } from './project/app-project.module';
import { AdminModule } from './admin/admin.module';
import { StoreDevTools } from '../environments/store-dev-tools';
import { CoreModule } from '@trim-web-apps/core';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    StoreModule.forRoot({}, { metaReducers }),
    EffectsModule.forRoot(),
    RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' }),
    StoreDevTools,
    BrowserModule,
    NoopAnimationsModule,
    AuthModule,
    AdminModule,
    AppProjectModule,
    CoreModule,
  ],
  providers: [
    { provide: 'API_URL', useValue: environment.API_URL },
    { provide: 'LSK_MAP', useValue: environment.LSK_MAP },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
