import { Component, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { SidenavData } from '../../+types/sidenav-data.type';
import packageJson from '../../../../../../../../package.json';
import { environment } from '../../../../../environments/environment';
import { DownloadService } from '@trim-web-apps/core';
import { ApiService } from '@trim-web-apps/api3map';
import { take } from 'rxjs/operators';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons/faTriangleExclamation';

@Component({
  selector: 'app-settings',
  template: `
    <div class="settings-wrapper shadow" *ngIf="data">
      <div class="row">
        <div class="label">App info</div>
        <div class="value selectable-text">v.{{ version }}-{{ build }}</div>
      </div>

      <div class="row">
        <div class="label">User</div>
        <div class="value selectable-text">{{ data.username }}</div>
      </div>

      <div class="row">
        <div class="label">Project</div>
        <div class="value selectable-text">{{ data.project.name }}</div>
        <div class="download" (click)="downloadProject()">
          {{ projectDownloading ? '(downloading...)' : '(download)' }}
        </div>
      </div>

      <div class="row">
        <div class="label">Records</div>
        <div class="value selectable-text">{{ data.recordList.length }}</div>
        <div class="download" (click)="downloadRecordList()">
          {{ recordDownloading ? '(downloading...)' : '(download)' }}
        </div>
      </div>

      <div class="row">
        <div class="label">Token</div>
        <div class="value selectable-text">{{ data.projectToken }}</div>
      </div>

      <div
        class="row alert"
        *ngIf="data.totalRemoteCount > data.recordList.length"
      >
        <fa-icon [icon]="iconAlert" />
        <div>
          <div class="bold">Records list exceeds the limit</div>
          <div>
            This Project has {{ data.totalRemoteCount }} records. Only the first
            25000 are displayed.
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .settings-wrapper {
        background-color: var(--bg-color);
        width: 500px;
        padding: var(--spacing-3);
        border-radius: var(--radius-1);
      }

      .row {
        display: flex;
        padding: var(--spacing-1) 0;
      }

      .label {
        flex: 0 0 80px;
        font-weight: var(--font-weight-3);
      }

      fa-icon {
        padding: 0 var(--spacing-2);
      }

      .download {
        color: var(--grey);
        margin-left: var(--spacing-2);
        font-size: var(--font-size-2);
        cursor: pointer;
      }

      .row.alert {
        width: 100%;
        margin-top: var(--spacing-4);
        padding: var(--spacing-2);
        color: var(--warn-color);
        justify-content: center;
        align-items: center;
        border: 1px solid var(--warn-color);
        border-radius: var(--radius-1);
      }

      .row.alert .bold {
        font-weight: var(--font-weight-3);
        font-size: var(--font-size-2);
      }

      .row.alert fa-icon {
        font-size: var(--font-size-4);
        margin-right: var(--spacing-2);
      }
    `,
  ],
  standalone: false,
})
export class SettingsComponent {
  version = packageJson.version;
  build = environment.BRANCH;
  recordDownloading = false;
  projectDownloading = false;
  iconAlert = faTriangleExclamation;

  constructor(
    private download: DownloadService,
    private api: ApiService,
    public dialogRef: DialogRef<string>,
    @Inject(DIALOG_DATA) public data?: SidenavData,
  ) {}

  /** For some reason we get the project from the API instead of the store... */
  downloadProject(): void {
    const project = this.data?.project;
    if (!project || this.projectDownloading) return;
    this.projectDownloading = true;
    this.api
      .getRawProject()
      .pipe(take(1))
      .subscribe((fetchedProject) => {
        const data = JSON.stringify(fetchedProject);
        const blob = new Blob([data], { type: 'text/json;charset=utf-8' });
        const filename = `${project.name}.json`;
        this.download.downloadBlob(blob, filename);
        this.projectDownloading = false;
      });
  }

  /** ...also for the Record list... */
  downloadRecordList(): void {
    const project = this.data?.project;
    if (!project || this.projectDownloading) return;
    this.recordDownloading = true;
    this.api
      .getRawRecordList()
      .pipe(take(1))
      .subscribe((fetchedRecords) => {
        const data = JSON.stringify(fetchedRecords);
        const blob = new Blob([data], { type: 'text/json;charset=utf-8' });
        const filename = `${project.name}_records.json`;
        this.download.downloadBlob(blob, filename);
        this.projectDownloading = false;
      });
  }
}
