import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { Column } from 'ag-grid-community';
import { notNullOrUndefined } from '@trim-web-apps/core';
import { TableData } from '../+types/table-data.type';
import { DropdownEvent, DropdownItem } from '@trim-web-apps/map3-ui';

@Component({
    selector: 'map3-table-actions',
    template: `
    <div class="table-actions-wrapper" *ngIf="tableData">
      <div [cdkMenuTriggerFor]="specificMenu" class="action-group">Forms</div>

      <ng-template #specificMenu>
        <div class="specific-list-wrapper" cdkMenu>
          <div
            class="specific"
            *ngFor="let specific of tableData.form.specificList"
            (click)="toggleSpecific(specific.id)"
          >
            <div class="checkbox">
              <ui-checkbox [checked]="isEnabled(specific.id)"></ui-checkbox>
            </div>

            <div class="form-name text-ellipsis">
              {{ specific.name }}
            </div>
          </div>
        </div>
      </ng-template>

      <div class="action-group">
        <ui-checkbox
          [checked]="tableData.dataset.showHistory"
          (click)="toggleHistory.emit()"
          label="Show History"
        ></ui-checkbox>
      </div>

      <div class="group-by action-group">
        <div class="label">Group by</div>
        <ui-dropdown
          [itemList]="columnsList"
          [itemActive]="selectedColumn"
          [widthPx]="250"
          noneOptionLabel="None"
          (itemSelected)="onColumnNameSelected($event)"
        />
      </div>

      <div class="fill-remaining-space"></div>

      <div class="action-group filter-cols">
        <ui-btn
          label="Reset filters"
          type="border"
          (btnClick)="resetFilters.emit()"
        ></ui-btn>
      </div>
    </div>
  `,
    styles: [
        `
      .table-actions-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: var(--spacing-2);
        padding-bottom: var(--spacing-2);
      }

      .action-group {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: var(--spacing-2);
      }

      .specific-list-wrapper {
        background-color: #ffffff;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        border-radius: 10px;
        max-width: 300px;
      }

      .group-by .label {
        margin-right: 10px;
      }

      .filter-cols {
        display: flex;
        align-items: center;
      }

      .specific-list-wrapper {
        padding: 10px 0;
        max-height: 420px;
        width: 350px;
        overflow: auto;
      }

      .specific {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.9em;
        margin: 5px 18px;
        cursor: pointer;
      }

      .checkbox {
        width: 16px;
      }

      .form-name {
        padding: 0 13px;
        flex: 1;
        text-align: left;
      }
    `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TableActionsComponent implements OnChanges {
  @Input() tableData: TableData | null = null;
  @Input() columns: Column[] = [];
  @Input() groupByColumn: Column | null | undefined;
  @Output() columnSelected: EventEmitter<Column | null> = new EventEmitter();
  @Output() toggleHistory: EventEmitter<void> = new EventEmitter();
  @Output() resetFilters: EventEmitter<void> = new EventEmitter();
  @Output() enabledSpecificList: EventEmitter<string[]> = new EventEmitter();

  columnsList: DropdownItem<string>[] = [];
  selectedColumn: DropdownItem<string> | null = null;

  ngOnChanges(): void {
    this.columnsList = this.columns
      .map((col) => col.getColDef().headerName)
      .filter(notNullOrUndefined)
      .map((colName) => ({ label: colName, payload: colName }));

    const groupByColName = this.groupByColumn?.getColDef().headerName;
    this.selectedColumn = groupByColName
      ? { label: groupByColName, payload: groupByColName }
      : null;
  }

  onColumnNameSelected(evt: DropdownEvent<string>): void {
    const colName = evt.source?.payload;
    const col = this.columns.find(
      (col) => col.getColDef().headerName === colName
    );
    this.columnSelected.emit(col || null);
  }

  isEnabled(specificId: string): boolean {
    if (!this.tableData) return false;
    return this.tableData.dataset.specificIds.includes(specificId);
  }

  toggleSpecific(specificId: string): void {
    if (!this.tableData) return;
    const enabledSpecificIds = this.tableData.dataset.specificIds;
    const specificIds = this.isEnabled(specificId)
      ? enabledSpecificIds.filter((i) => i !== specificId)
      : [...enabledSpecificIds, specificId];

    this.enabledSpecificList.emit(specificIds);
  }
}
