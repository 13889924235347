import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Form } from '3map-models';
import { Action, createReducer, on } from '@ngrx/store';
import {
  addDuplicatedForm,
  createForm,
  removeForm,
  updateForm,
} from './form.actions';
import * as specificActions from './form-specific.actions';
import { removeElementFromArray } from '@trim-web-apps/core';
// import { AdminActions } from '../../+state';
import * as AdminActions from '../../+state/admin.actions';

export const ADMIN_FORM_FEATURE_KEY = 'admin-form';

export interface EntityForm {
  id: string;
  name: string;
  specificListIds: string[];
  allowImage: boolean;
  allowAudio: boolean;
  allowVideo: boolean;
  allowFile: boolean;
  preventDownloadInProjectData: boolean;
}

export type State = EntityState<EntityForm>;

export const adapter: EntityAdapter<EntityForm> =
  createEntityAdapter<EntityForm>();
export const initialState: State = adapter.getInitialState();

const formReducer = createReducer(
  initialState,
  on(
    AdminActions.close,
    AdminActions.initProjectError,
    (): State => ({ ...initialState }),
  ),
  on(AdminActions.initProjectSuccess, (state, { project }): State => {
    const entities = project.formList.map((f) => createEntityForm(f));
    return adapter.setAll(entities, state);
  }),
  on(createForm, addDuplicatedForm, (state, { form }) => {
    const entityForm = createEntityForm(form);
    return adapter.addOne(entityForm, state);
  }),
  on(updateForm, (state, { form }) => {
    const entityForm = state.entities[form.id];
    return entityForm
      ? adapter.updateOne({ id: form.id, changes: form }, state)
      : state;
  }),
  on(removeForm, (state, { form }) => {
    return adapter.removeOne(form.id, state);
  }),
  on(
    specificActions.createSpecific,
    specificActions.duplicateSpecific,
    (state, { formId, specific }) => {
      const entity = state.entities[formId];
      if (!entity) return state;
      const specificListIds = [...entity.specificListIds, specific.id];
      const update = { id: formId, changes: { specificListIds } };
      return adapter.updateOne(update, state);
    },
  ),
  on(specificActions.removeSpecific, (state, { formId, specificId }) => {
    const entity = state.entities[formId];
    if (!entity) return state;
    const ids = entity.specificListIds;
    const specificListIds = removeElementFromArray(ids, specificId);
    const update = { id: formId, changes: { specificListIds } };
    return adapter.updateOne(update, state);
  }),
);

export function reducer(state: State | undefined, action: Action): State {
  return formReducer(state, action);
}

export function createEntityForm(form: Form): EntityForm {
  const { specificList, ...formLike } = form;
  return {
    ...formLike,
    specificListIds: form.specificList.map((s) => s.id),
    preventDownloadInProjectData: form.preventDownloadInProjectData ?? false,
  };
}
