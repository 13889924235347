import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WeatherModelSpecRequest } from '@trim-web-apps/weather-models';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'weather-core-item-spec-request',
    template: `
    <ng-container *ngIf="specRequest?.status !== 'success'">
      <div class="wrapper" [class.is-mobile]="isMobile">
        <div class="loading" *ngIf="specRequest?.status === 'pending'">
          <ui-checkbox *ngIf="!isMobile" [loading]="true" />
          Initializing...
        </div>
        <div class="error" *ngIf="specRequest?.status === 'error'">
          <ui-checkbox [error]="true" />
          Currently unavailable.
          <div class="actions">
            <ui-btn
              size="small"
              type="border"
              label="Try again"
              (click)="reInitModel.emit()"
            />
            <ui-btn
              size="small"
              type="border"
              label="Close"
              (click)="removeModel.emit()"
            />
          </div>
        </div>
      </div>
    </ng-container>
  `,
    styles: [
        `
      .wrapper {
        background-color: #ffffff;
        margin: var(--spacing-2);
        padding: 0 var(--spacing-2);
        box-shadow:
          0 3px 6px rgba(0, 0, 0, 0.16),
          0 3px 6px rgba(0, 0, 0, 0.23);
        border-radius: 7px;
        min-height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .loading,
      .error,
      .actions {
        display: flex;
        align-items: center;
        gap: var(--spacing-3);
      }

      .error {
        color: var(--error-color);
      }

      .wrapper.is-mobile {
        background-color: white;
        width: 100%;
        padding: 0 10px;
        margin: 0;
        border-radius: 0;
        align-items: center;
        min-height: 250px;
      }

      .wrapper.is-mobile .error {
        flex-direction: column;
        gap: var(--spacing-4);
        padding: var(--spacing-3) 0;
      }
    `,
    ],
    standalone: false
})
export class WeatherItemSpecRequestComponent {
  @Input() specRequest?: WeatherModelSpecRequest;
  @Input() isMobile?: boolean;
  @Output() removeModel = new EventEmitter<void>();
  @Output() reInitModel = new EventEmitter<void>();
  iconRemove = faTrash;
}
