import { WeatherModelInitConfig, WeatherModelMetadata } from '../types';
import { WeatherModel } from '../types/weather.model';
import { WeatherLayer } from '../types/weather-layer.type';
import {
  createTsListForecast,
  decodeForecastSpec,
  getBaseModelTemplate,
  getDefaultDateRange,
  getTsListRainfall,
  removeUnwantedLayers,
} from '../utils';
import {
  PRESSURE,
  RAINFALL_3HOUR,
  RELATIVE_HUMIDITY,
  TEMPERATURE,
  WIND_KTS,
} from '../weather-layers/weather.layers';

export const GFS: WeatherModelMetadata = {
  id: 'GFS',
  label: 'GFS',
  category: 'FORECAST',
  subCategory: 'GLOBAL_MODELS',
  info: {
    resolution: { deg: 0.25, km: 25 },
    coverage: 'Global',
    url: 'https://www.ncei.noaa.gov/products/weather-climate-models/global-forecast',
    credits: 'NOAA',
    schedule: [
      { init: '00', time: '05:00' },
      { init: '06', time: '11:00' },
      { init: '12', time: '17:00' },
      { init: '18', time: '23:00' },
    ],
  },
  bbox: [
    [-180, 90],
    [180, 90],
    [180, -90],
    [-180, -90],
    [-180, 90],
  ],
};

export function createGfs(
  serverSpec: unknown,
  config?: WeatherModelInitConfig,
): WeatherModel {
  const spec = decodeForecastSpec(serverSpec);
  const timestepList = createTsListForecast(spec.initimeList, 121);
  const tsRainfall = getTsListRainfall(timestepList);
  const defaultDateRange = getDefaultDateRange(timestepList, 0, 120);
  const defaultDateRngRainfall = getDefaultDateRange(tsRainfall, 1, 120);
  const timestepType = { type: 'UNIX', interval: 3600 * 3 } as const;

  const gfsLayers: WeatherLayer[] = [
    { ...WIND_KTS, timestepType, timestepList, defaultDateRange },
    {
      ...WIND_KTS,
      id: 'wind_925',
      label: 'Wind (925 hPa)',
      timestepType,
      timestepList,
      defaultDateRange,
    },
    { ...TEMPERATURE, timestepType, timestepList, defaultDateRange },
    {
      ...RAINFALL_3HOUR,
      timestepType,
      timestepList: tsRainfall,
      defaultDateRange: defaultDateRngRainfall,
    },
    { ...RELATIVE_HUMIDITY, timestepType, timestepList, defaultDateRange },
    { ...PRESSURE, timestepType, timestepList, defaultDateRange },
  ];

  const layers = removeUnwantedLayers(gfsLayers, config?.layerIdList ?? []);

  if (layers.length === 0)
    throw new Error(`No layers available for ${spec.model} model`);

  return {
    ...getBaseModelTemplate(config),
    ...GFS,
    layers,
    initime: layers[0].timestepList[0].initime,
    timestep: layers[0].defaultDateRange.from,
    selectedLayerId: layers[0].id,
    dateRange: layers[0].defaultDateRange,
  };
}
