import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faCheck, faTrash, faUndoAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'core-double-confirm-inline',
    template: `
    <div class="step-1" *ngIf="!showStep2">
      <fa-icon
        [icon]="icon"
        (click)="showStep2 = true"
        [ngStyle]="{ color: iconHexColor ? iconHexColor : 'unset' }"
      ></fa-icon>
    </div>
    <div class="step-2" *ngIf="showStep2" (mouseleave)="showStep2 = false">
      <fa-icon [icon]="iconUndo" (click)="showStep2 = false"></fa-icon>
      <fa-icon [icon]="iconConfirm" (click)="onConfirm()"></fa-icon>
    </div>
  `,
    styles: [
        `
      :host,
      .step-2 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
      }

      fa-icon {
        padding: 0 10px;
        cursor: pointer;
        flex: 0 0 20px;
      }
    `,
    ],
    standalone: false
})
export class DoubleConfirmInlineComponent {
  @Input() icon: IconDefinition;
  @Input() iconConfirm: IconDefinition;
  @Input() iconUndo: IconDefinition;
  @Input() iconHexColor: string | undefined;
  @Output() confirm: EventEmitter<void>;

  showStep2: boolean | undefined;

  constructor() {
    this.icon = faTrash;
    this.iconConfirm = faCheck;
    this.iconUndo = faUndoAlt;
    this.confirm = new EventEmitter<void>();
  }

  onConfirm(): void {
    this.showStep2 = false;
    this.confirm.emit();
  }
}
