import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MarkerItem } from '@trim-web-apps/project-core';

@Component({
  selector: 'app-marker-list',
  template: `
    <div *ngIf="markerItems.length === 0">Loading markers</div>
    <app-marker-item
      *ngFor="let marker of markerItems; let i = index"
      [markerItem]="marker"
      (markerItemChanged)="onMarkerItemChange($event, i)"
    >
    </app-marker-item>
  `,
  standalone: false,
})
export class MarkerListComponent {
  @Input() markerItems: MarkerItem[];
  @Output() markerItemChange: EventEmitter<{ base64: string; index: number }>;

  constructor() {
    this.markerItems = [];
    this.markerItemChange = new EventEmitter<{
      base64: string;
      index: number;
    }>();
  }

  onMarkerItemChange(item: MarkerItem, index: number): void {
    this.markerItemChange.emit({ base64: item.imageBase64, index });
  }
}
