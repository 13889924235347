import { Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { WeatherModel } from '@trim-web-apps/weather-models';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'weather-core-model-picker-item-mobile',
    template: `
    <div class="card shadow" (click)="isOpen.set(!isOpen())">
      <div class="label">
        {{ label }}
        <fa-icon [icon]="isOpen() ? iconCollapse : iconExpand" />
      </div>
      <div class="content" *ngIf="isOpen()">
        @for (model of modelList; track model.id) {
          <div class="model" (click)="modelSelect.emit(model)">
            <div>{{ model.label }}</div>
            <div class="res">{{ model.info | resolutionLabel }}</div>
          </div>
        }
      </div>
    </div>
  `,
    styles: [
        `
      .card {
        /*padding: var(--spacing-3) var(--spacing-2);*/
        border-radius: var(--radius-2);
        overflow: hidden;
        background-color: var(--primary-color);
        color: var(--bg-color);
      }

      .content {
        background-color: var(--bg-color);
        color: var(--text-color);
      }

      .label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: var(--spacing-3);
        font-weight: var(--font-weight-3);
      }

      .model {
        padding: var(--spacing-3);
        border-bottom: 1px solid var(--border);
        display: flex;
        justify-content: space-between;
      }

      .res {
        color: var(--grey);
        font-size: var(--font-size-2);
      }
    `,
    ],
    standalone: false
})
export class WeatherModelPickerItemMobileComponent {
  @Input() label?: string;
  @Input() modelList?: WeatherModel[];
  @Output() modelSelect = new EventEmitter<WeatherModel>();
  isOpen = signal<boolean>(false);
  iconCollapse = faChevronUp;
  iconExpand = faChevronDown;
}
