import { Pipe, PipeTransform } from '@angular/core';
import { weatherModelCategoryToString } from '../weather.functions';
import { WeatherModel } from '@trim-web-apps/weather-models';

@Pipe({
    name: 'weatherCategory',
    standalone: false
})
export class WeatherCategoryPipe implements PipeTransform {
  transform(category: WeatherModel['category']): string {
    return weatherModelCategoryToString(category);
  }
}
