import { createSelector } from '@ngrx/store';
import { EntityForm } from './form.reducer';
import { Form, FormSpecific } from '3map-models';
import { notNullOrUndefined } from '@trim-web-apps/core';
import * as fromRoot from '../project.selectors';
import * as fromSpecific from '../specific';

export const selectState = createSelector(
  fromRoot.selectProjectState,
  (state) => state.forms
);

export const selectFormEntityById = (props: { formId: string }) => {
  return createSelector(selectState, (state) => state.entities[props.formId]);
};

export const selectFormList = () => {
  return createSelector(
    selectState,
    fromSpecific.selectAllSpecific(),
    (state, allSpecificList) => {
      return Object.keys(state.entities)
        .map((formId) => {
          const formEntity = state.entities[formId];
          const specificList = allSpecificList.filter((s) =>
            formEntity?.specificListIds.includes(s.id)
          );
          return formEntity && specificList
            ? entityToForm(formEntity, specificList)
            : null;
        })
        .filter(notNullOrUndefined);
    }
  );
};

export const selectFormById = (props: { formId: string }) =>
  createSelector(
    selectFormEntityById(props),
    fromSpecific.selectSpecificListByFormId(props),
    (entity, specificList) => {
      return entityToForm(entity, specificList);
    }
  );

export function entityToForm(
  entity: EntityForm | undefined,
  specificList: FormSpecific[] | undefined
): Form | null {
  if (entity && specificList) {
    return {
      id: entity.id,
      name: entity.name,
      specificList: specificList,
      allowImage: entity.allowImage,
      allowAudio: entity.allowAudio,
      allowVideo: entity.allowVideo,
      allowFile: entity.allowFile,
    };
  }

  return null;
}
