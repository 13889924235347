import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromMap from '../../+state/map.reducer';
import * as MapActions from '../../+state/map.actions';
import { Map } from 'mapbox-gl';
import { MapboxService } from '../../mapbox.service';
import { BehaviorSubject, Observable, Subscription, tap } from 'rxjs';
import * as SidenavSelectors from '../../../sidenav/+state/sidenav.selectors';
import { selectMapDatasetList } from '../../+state/map.selectors';
import { Form, Record } from '3map-models';
import { MapSelectors } from '../../+state';
import { debounceTime, skip } from 'rxjs/operators';
import { FeatureViewData } from '../../components/feature-view/feature-view.component';
import {
  createRecordLayer,
  getSelectedRecordLayerId,
  removeLayerAndSource,
} from '../../mapbox.utils';
import { CtxMenuCreateData } from '../../+models/ctx-menu-create-data.model';
import { FormDataset } from '../../+models/form-dataset.model';

@Component({
  selector: 'app-map',
  template: `
    <map-core
      (mapReady)="onMapLoaded($event)"
      (mapStyleChanged)="onMapStyleChange($event)"
    />

    <ng-container *ngIf="formDatasetList$ | async as formDsList">
      <div class="left-col" [class.sidebar-open]="sidebarOpen$ | async">
        <div class="map-form-card-list" *ngIf="mapReady$ | async">
          <ng-container *ngFor="let form of formDsList; trackBy: trackByFn">
            <app-form-card
              [formDataset]="form"
              (disableForm)="onDisableForm($event)"
              (zoomToForm)="onZoomToForm($event)"
              (setEnabledSpecificIds)="onSetEnabledSpecifics($event, form.form)"
            />

            <app-dataset-layer
              [datasetId]="form.dataset.formId"
              (featureClick)="onFeatureClick($event, form.dataset.formId)"
            />
          </ng-container>

          <div class="legend">
            <app-map-form-card-legend [formDatasetList]="formDsList" />
          </div>
        </div>

        <ng-container
          *ngIf="contextMenuCreateFeatureData$ | async as ctxMenuData"
        >
          <app-map-context-menu
            *ngIf="map"
            [map]="map"
            [contextMenuData]="ctxMenuData"
            (createFeature)="onCreateFeature($event)"
          />
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="featureViewData$ | async as featureData">
      <app-feature-view
        *ngIf="featureData"
        [featureViewData]="featureData"
        (zoomToRecord)="onZoomToRecord($event)"
        (recordSelected)="onRecordSelected($event)"
        (recordDelete)="onRecordDelete($event)"
        (recordEdit)="onRecordEdit($event)"
        (recordUpdate)="onRecordUpdate($event)"
      />
    </ng-container>

    <app-map-resource-list *ngIf="map" [map]="map" />

    <div class="weather-wrapper" [class.sidebar-open]="sidebarOpen$ | async">
      <app-weather *ngIf="map" [map]="map" [mapStyleId]="mapStyleId" />
    </div>
  `,
  styles: [
    `
      .left-col {
        position: absolute;
        top: 0;
        left: 70px;
        overflow: auto;
        max-height: 100vh;
      }

      .left-col.sidebar-open {
        left: 300px;
      }

      .weather-wrapper {
        position: absolute;
        bottom: 0;
        left: 70px;
        max-height: 60vh;
        width: calc(100vw - 70px);
        overflow: auto;
      }

      .weather-wrapper.sidebar-open {
        left: 300px;
        width: calc(100vw - 300px);
      }

      app-feature-view {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: #ffffff;
        z-index: 5;
      }

      .map-form-card-list {
        padding: var(--spacing-3);
      }
    `,
  ],
  standalone: false,
})
export class MapComponent implements OnDestroy {
  map?: Map;
  mapStyleId?: string;
  sidebarOpen$: Observable<boolean>;
  formDatasetList$: Observable<FormDataset[]>;
  mapReady$: Observable<boolean>;
  featureViewData$: Observable<FeatureViewData | null>;
  contextMenuCreateFeatureData$: Observable<CtxMenuCreateData[]>;

  private mapClickObs$ = new BehaviorSubject<{
    featureId: string;
    formId: string;
  } | null>(null);
  private mapClickObsSub?: Subscription;

  constructor(
    private store: Store<fromMap.State>,
    private mapboxService: MapboxService,
  ) {
    this.sidebarOpen$ = this.store.select(SidenavSelectors.selectSidenavOpen());
    this.formDatasetList$ = this.store.select(selectMapDatasetList());
    this.mapReady$ = this.store.select(MapSelectors.selectMapReadyForRecords());
    this.contextMenuCreateFeatureData$ = this.store.select(
      MapSelectors.selectContextMenuCreateFeature(),
    );

    /**
     * Add some padding to mapbox-gl controls then a Feature is selected.
     * Padding should match the width of Feature side panel (see {@link FeatureViewComponent} ).
     */
    this.featureViewData$ = this.store
      .select(MapSelectors.selectFeatureRecords())
      .pipe(
        tap((selectedFeatureData) => {
          if (!this.mapboxService.isMapInitialized()) return;
          this.addSelectedRecordLayer(selectedFeatureData);
          const ctrl = this.mapboxService.map
            ?.getContainer()
            ?.getElementsByClassName('mapboxgl-ctrl-top-right')[0];
          const padding = selectedFeatureData ? '350px' : 'unset';
          if (ctrl) (ctrl as HTMLElement).style['paddingRight'] = padding;
        }),
      );

    /**
     * There are two different "click" Events that needs to be handled here:
     * 1. click from Map itself (any point on Map)
     * 2. click on a Feature (see {@link DatasetLayerComponent} )
     * In either case, a {@link MapActions.mapClick} should be dispatched. The payload of this Action
     * depends on which one is firing.
     *
     * Problem: when a Feature is clicked BOTH of them are fired, since a Feature click is also a Map click
     * (fired by mapbox library). If click is NOT on a Feature a single Map click will be fired.
     *
     * This workaround is based on the fire order of the above Events. In case of both of them are fired (click on
     * Feature), then Map click [1] is triggered *BEFORE* the Feature click [2].
     *
     * So, the problem now is to keep only the latest Event:
     * - on a Map click [1] Event, this is already the latest
     * - on a Feature click [2] Event, we need to skip the first one (Map click) and take the latest.
     *
     * This is done piping all click Events through a Subject (`mapClickObs$`) along with a `debounceTime` operator.
     * This will "wait" the given time and then will emit only the latest received object.
     */
    this.mapClickObsSub = this.mapClickObs$
      .pipe(skip(1), debounceTime(10))
      .subscribe((feature) =>
        this.store.dispatch(MapActions.mapClick({ feature })),
      );
  }

  ngOnDestroy(): void {
    this.store.dispatch(MapActions.mapComponentDestroy());
    this.mapboxService.map = null;
    this.mapClickObsSub?.unsubscribe();
  }

  trackByFn(
    index: number,
    item: { form: Form; dataset: { formId: string; specificIds: string[] } },
  ): string {
    return item.form.id;
  }

  onMapLoaded(map: Map): void {
    this.mapboxService.map = map;
    this.map = map;
    map.on('click', () => this.mapClickObs$.next(null));
  }

  onMapStyleChange(mapStyleId: string): void {
    this.mapStyleId = mapStyleId;
    if (this.mapboxService.isMapInitialized())
      this.store.dispatch(MapActions.MAP_STYLE_CHANGED({ mapStyleId }));
  }

  onDisableForm(id: string): void {
    this.store.dispatch(MapActions.removeDataset({ id }));
  }

  onFeatureClick(featureId: string, formId: string): void {
    this.mapClickObs$.next({ featureId, formId });
  }

  onZoomToForm(formId: string): void {
    this.store.dispatch(MapActions.zoomToForm({ formId }));
  }

  onZoomToRecord(recordId: string): void {
    this.store.dispatch(
      MapActions.zoomToRecords({ recordIdsList: [recordId] }),
    );
  }

  onRecordSelected(record: Record | null): void {
    const selectedRecordId = record ? record.recordId : null;
    this.store.dispatch(MapActions.selectSingleRecord({ selectedRecordId }));
  }

  onCreateFeature(evt: {
    formId: string;
    specificId: string;
    latitude: number;
    longitude: number;
    altitude: number;
  }): void {
    this.store.dispatch(MapActions.createRecord(evt));
  }

  onRecordEdit(record: Record): void {
    this.store.dispatch(MapActions.editRecord({ recordId: record.recordId }));
  }

  onRecordDelete(record: Record): void {
    this.store.dispatch(
      MapActions.deleteRecords({ recordIdsList: [record.recordId] }),
    );
  }

  onRecordUpdate(record: Record): void {
    this.store.dispatch(MapActions.updateRecord({ recordId: record.recordId }));
  }

  onSetEnabledSpecifics(specificIdsList: string[], form: Form): void {
    const action = MapActions.setEnabledSpecificList({
      formId: form.id,
      specificIdsList,
    });
    this.store.dispatch(action);
  }

  /**
   * Show selected Record by adding two layer:
   * 1. recordLayer: built with same function ({@link createRecordLayer }) but with increased "icon-size" property
   * 2. circleLayer: a circleLayer a bit larger than recordLayer icon
   *
   * Both layers add/remove logic is entirely handled here.
   * @param featureData
   * @private
   */
  private addSelectedRecordLayer(featureData: FeatureViewData | null): void {
    if (!this.map) return;
    // use hardcoded layerIds since the select Record can be only 1
    const { recordLayerId, circleLayerId } = getSelectedRecordLayerId();

    // remove layer on each state change
    removeLayerAndSource(this.map, recordLayerId);
    removeLayerAndSource(this.map, circleLayerId);

    // keep going only if there's some FeatureData and Record to display; return otherwise
    if (!featureData || featureData.records.length === 0) return;

    const form = featureData.form;
    const record = featureData.records[0];
    const { layer, source } = createRecordLayer(form, [record]);

    this.map.addSource(recordLayerId, source).addLayer({
      ...layer,
      id: recordLayerId,
      source: recordLayerId,
      layout: {
        ...layer.layout,
        'icon-size': 0.25,
      },
    });

    this.map
      .addSource(circleLayerId, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [record.longitude, record.latitude],
              },
              properties: {},
            },
          ],
        },
      })
      .addLayer({
        id: circleLayerId,
        type: 'circle',
        source: circleLayerId,
        paint: {
          'circle-radius': 21,
          'circle-color': 'rgba(255, 255, 255, 0)',
          'circle-stroke-color': '#1e4294',
          'circle-stroke-width': 2,
        },
        filter: ['==', '$type', 'Point'],
      });
  }
}
