import { WeatherModelInitConfig, WeatherModelMetadata } from '../types';
import { WeatherModel } from '../types/weather.model';
import { WeatherLayer } from '../types/weather-layer.type';
import { RAINFALL_1DAY } from '../weather-layers/weather.layers';
import {
  createTsListGpm,
  decodeGfsHistorySpec,
  getBaseModelTemplate,
  removeUnwantedLayers,
} from '../utils';
import { GFS } from './gfs';

export const GFS_HISTORY: WeatherModelMetadata = {
  ...GFS,
  id: 'GFS_HISTORY',
  label: 'GFS History',
  category: 'FORECAST',
  subCategory: 'HISTORY',
};

export function createGfsHistory(
  serverSpec: unknown,
  config?: WeatherModelInitConfig,
): WeatherModel {
  const spec = decodeGfsHistorySpec(serverSpec);
  const timestepList = createTsListGpm(spec.timestepList, false);
  const timestepTypeDay = { type: 'DAYS', interval: 1 } as const;
  const timestepListMonth = createTsListGpm(spec.timestepMonthList, true);
  const timestepTypeMonth = { type: '1MONTH', interval: 1 } as const;

  // TODO is gfs history working?
  const l = timestepList[0].timesteps.length;
  const defaultDateRangeDay = {
    from:
      l > 30 ? timestepList[0].timesteps[l - 30] : timestepList[0].timesteps[0],
    to: timestepList[0].timesteps[l - 1],
  };

  // why was this commented out?
  const defaultDateRangeMonth = {
    from: timestepListMonth[0].timesteps[
      timestepListMonth[0].timesteps.length - 12
    ],
    to: timestepListMonth[0].timesteps[
      timestepListMonth[0].timesteps.length - 1
    ],
  };

  const gfsHistoryLayers: WeatherLayer[] = [
    {
      ...RAINFALL_1DAY,
      timestepType: timestepTypeDay,
      timestepList,
      defaultDateRange: defaultDateRangeDay,
    },
    // {...RAINFALL_MONTHLY, timestepType: timestepTypeMonth, timestepList: timestepListMonth, defaultDateRange: defaultDateRangeMonth},
  ];

  const layers = removeUnwantedLayers(
    gfsHistoryLayers,
    config?.layerIdList ?? [],
  );

  if (layers.length === 0)
    throw new Error(`No layers available for ${spec.model} model`);

  return {
    ...getBaseModelTemplate(config),
    ...GFS_HISTORY,
    layers,
    initime: layers[0].timestepList[0].initime,
    timestep: layers[0].defaultDateRange.to,
    hasChart: true,
    selectedLayerId: layers[0].id,
    dateRange: layers[0].defaultDateRange,
  };
}
