import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Media, Project, Record } from '3map-models';
import * as uuid from 'uuid';
import { removeElementFromArray } from '@trim-web-apps/core';
import {
  faFile,
  faFileAudio,
  faFileImage,
  faFileVideo,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-record-editor-media[project][record]',
  template: `
    <div class="error-list" *ngIf="errorFileMessages.length">
      <div
        class="error-item text-ellipsis"
        *ngFor="let error of errorFileMessages"
      >
        {{ error }}
      </div>
    </div>

    <div class="media-list">
      <div class="media-wrapper" *ngFor="let media of record?.mediaList ?? []">
        <app-record-editor-media-item
          [media]="media"
          [projectName]="project?.name"
          (removeMedia)="removeMedia.emit($event)"
        />
      </div>

      <div class="media-wrapper" *ngFor="let media64 of uploadMediaListBase64">
        <app-record-editor-media-item
          [base64Img]="media64.base64"
          [media]="media64.media"
          [projectName]="project?.name"
          (removeMedia)="removeMedia.emit($event)"
        />
      </div>

      <div class="media-upload" (click)="input.click()">
        <fa-icon class="file" [icon]="iconFile"></fa-icon>
        <fa-icon class="video" [icon]="iconVideo"></fa-icon>
        <fa-icon class="image" [icon]="iconImage"></fa-icon>
        <fa-icon class="audio" [icon]="iconAudio"></fa-icon>
        <ui-btn [type]="'border'" label="Upload media"></ui-btn>
      </div>
      <input
        style="display:none;"
        [multiple]="true"
        #input
        type="file"
        (change)="onFileSelected($event)"
      />
    </div>
  `,
  styles: [
    `
      .media-list {
        display: grid;
        grid-template-columns: 220px 220px;
        grid-auto-rows: 220px;
        grid-gap: 20px;
        padding: 10px;
      }

      .error-list {
        position: absolute;
        width: 480px;
        z-index: 2;
      }

      .error-item {
        background-color: var(--error-color);
        color: #ffffff;
        padding: 5px;
        margin-bottom: 2px;
        border-radius: 3px;
        font-weight: bold;
        font-size: 0.9em;
      }

      .media-upload {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-template-areas:
          'file vid'
          'img aud'
          'btn btn';
        cursor: pointer;
      }

      .media-upload fa-icon {
        font-size: 2.5em;
        padding: 0 10px;
        color: var(--primary-color);
      }

      .file {
        grid-area: file;
        align-self: end;
        justify-self: end;
      }

      .video {
        grid-area: vid;
        align-self: end;
      }

      .image {
        grid-area: img;
        justify-self: end;
      }

      .audio {
        grid-area: aud;
      }

      .media-upload ui-btn {
        grid-area: btn;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class RecordEditorMediaComponent {
  @Input() record: Record | undefined;
  @Input() project: Project | undefined;
  @Input() uploadMediaListBase64: { media: Media; base64: string }[] = [];
  @Output() fileListSelected: EventEmitter<{ media: Media; blob: Blob }[]> =
    new EventEmitter<{ media: Media; blob: Blob }[]>();
  @Output() removeMedia: EventEmitter<Media> = new EventEmitter<Media>();
  errorFileMessages: string[] = [];
  iconFile: IconDefinition = faFile;
  iconImage: IconDefinition = faFileImage;
  iconVideo: IconDefinition = faFileVideo;
  iconAudio: IconDefinition = faFileAudio;

  onFileSelected(evt: Event): void {
    const element = evt.currentTarget as HTMLInputElement;
    const fileList: FileList | null = element.files;
    const fileToUpload: { media: Media; blob: Blob }[] = [];
    if (!fileList) return;

    /**
     * Loop over selected files
     */
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList.item(i);

      /**
       * This should not happen, anyway...
       */
      if (!file) {
        this.addFileError(`Invalid file`);
        continue;
      }

      /**
       * Create Media from the given file.
       * Skip the rest and show error if MimeType is not supported.
       */
      const media = this.createMediaFromFile(file);
      if (!media) {
        this.addFileError(`File type not supported: ${file.name}`);
        continue;
      }

      /**
       * Check if the current Form allows the Media type.
       */
      if (!this.isAllowedMimeType(media)) {
        this.addFileError(`This form does not allow ${media.type} files`);
        continue;
      }

      /**
       * Everything should be ok, add the new Media and file to the upload queue.
       */
      fileToUpload.push({ media, blob: file });
    }

    this.fileListSelected.emit(fileToUpload);
  }

  /**
   * Create a Media object from the given file.
   * Note: Media.filename will be a unique uuid with the file extension.
   * @param file
   * @private
   */
  private createMediaFromFile(file: File): Media {
    const fileId = uuid.v4();
    const extension = file.name.split('.').pop();
    const fileName = `${fileId}.${extension}`;
    switch (file.type) {
      case 'image/png':
      case 'image/jpeg':
        return { fileName, type: 'IMAGE' };
      case 'video/mp4':
        return { fileName, type: 'VIDEO' };
      case 'audio/mp3':
        return { fileName, type: 'AUDIO' };
      default:
        return { fileName, type: 'FILE' };
    }
  }

  /**
   * Return true if the current Form allows the given Media type, false otherwise.
   * @param media
   * @private
   */
  private isAllowedMimeType(media: Media): boolean {
    if (!this.project || !this.record) return false;
    const form = this.project.formList.find(
      (f) => f.id === this.record?.formId,
    );
    if (!form) return false;
    switch (media.type) {
      case 'AUDIO':
        return form.allowAudio;
      case 'VIDEO':
        return form.allowVideo;
      case 'IMAGE':
        return form.allowImage;
      case 'FILE':
        return form.allowFile;
      default:
        return false;
    }
  }

  /**
   * Add an error message to the global errors list and set a timeout to automatically remove it from the list.
   * @param error
   * @private
   */
  private addFileError(error: string): void {
    this.errorFileMessages.push(error);
    setTimeout(() => this.removeError(error), 5000);
  }

  /**
   * Helper method to just remove the given error string from the global errors list.
   * @param error
   * @private
   */
  private removeError(error: string): void {
    this.errorFileMessages = removeElementFromArray<string>(
      this.errorFileMessages,
      error,
    );
  }
}
