import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'ui-tooltip',
    template: `<div class="tooltip shadow">{{ text }}</div>`,
    styles: [
        `
      .tooltip {
        background-color: var(--bg-color);
        border-radius: var(--radius-1);
        font-size: var(--font-size-1);
        padding: var(--spacing-1) var(--spacing-2);
      }
    `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TooltipComponent {
  @Input() text?: string;
}
