import { createAction, props } from '@ngrx/store';

export const MAP_STYLE_CHANGED = createAction(
  '[Map] Map Style Changed',
  props<{ mapStyleId: string }>(),
);
export const ADD_MARKERS = createAction('[Map] Add Markers');
export const ADD_MARKERS_SUCCESS = createAction('[Map] Add Markers Success');
export const ADD_MARKERS_ERROR = createAction('[Map] Add Markers Error');

export const removeDataset = createAction(
  '[Map] Remove Dataset',
  props<{ id: string }>(),
);

export const setEnabledSpecificList = createAction(
  '[Map] Set Enabled Specifics',
  props<{ formId: string; specificIdsList: string[] }>(),
);

export const mapComponentDestroy = createAction('[Map] MapComponent Destroy');

export const mapClick = createAction(
  '[Map] Record Map Click',
  props<{ feature: { featureId: string; formId: string } | null }>(),
);

export const setMapRecords = createAction(
  '[Map] Set Map Records',
  props<{
    formId: string;
    recordIdsByFeatureId: { [featureId: string]: string[] };
  }>(),
);

export const zoomToForm = createAction(
  '[Map] Zoom To Form',
  props<{ formId: string }>(),
);

export const zoomToRecords = createAction(
  '[Map] Zoom To Records',
  props<{ recordIdsList: string[] }>(),
);

export const selectSingleRecord = createAction(
  '[Map] Select Single Record',
  props<{ selectedRecordId: string | null }>(),
);

export const createRecord = createAction(
  '[Map] Create Record',
  props<{
    formId: string;
    specificId: string;
    latitude: number;
    longitude: number;
    altitude: number;
  }>(),
);

export const editRecord = createAction(
  '[Map] Edit Record',
  props<{ recordId: string }>(),
);

export const updateRecord = createAction(
  '[Map] Update Record',
  props<{ recordId: string }>(),
);

export const deleteRecords = createAction(
  '[Map] Delete Records',
  props<{ recordIdsList: string[] }>(),
);
