import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromTable from './table.reducer';
import { RecordSelectors } from '../../record/+state';
import { ProjectSelectors } from '../../project/+state';
import { AuthSelectors } from '../../../auth/state/auth';
import { TableData } from '@trim-web-apps/map3-table';
import { splitByFeatureId } from '../../shared/project.utils';

export const selectTableState = () =>
  createFeatureSelector<fromTable.State>(fromTable.FEATURE_KEY);

export const selectActiveTableId = () =>
  createSelector(selectTableState(), (state) => state.activeTable);

export const selectTableList = () =>
  createSelector(
    ProjectSelectors.selectDatasetFormList(),
    selectActiveTableId(),
    (formDatasetList, activeTableId) => {
      if (formDatasetList.length === 0) return [];
      const activeTable = activeTableId ?? formDatasetList[0].dataset.formId;
      return formDatasetList.map((formDataset) => {
        return {
          form: formDataset.form,
          active: formDataset.dataset.formId === activeTable,
        };
      });
    },
  );

// todo merge splitByFeature fn used in Set Map Records action
export const selectTableData = (id: string) =>
  createSelector(
    RecordSelectors.selectRecords(),
    ProjectSelectors.selectDatasetForm(id),
    AuthSelectors.selectAuthUsername(),
    AuthSelectors.selectCollaboration(),
    (recordList, datasetForm, username, col): TableData | null => {
      if (!datasetForm || !username || !col) return null;
      const { form, dataset } = datasetForm;
      const role = col.role;
      const records = recordList.filter((r) =>
        dataset.specificIds.includes(r.formSpecificId),
      );
      if (!dataset.showHistory) {
        const recordByFeatureId = splitByFeatureId(records);
        const onlyLastRecords = Object.keys(recordByFeatureId).map(
          (k) => recordByFeatureId[k][0],
        );
        return { form, records: onlyLastRecords, dataset, username, role };
      }
      return { form, records, dataset, username, role };
    },
  );
