import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription, throttleTime } from 'rxjs';
import {
  faChevronLeft,
  faChevronRight,
  faXmark,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { MediaGalleryActions, MediaGallerySelectors } from '../+state';
import { GalleryData } from '../models/gallery-data.model';
import { KeyPressEvent } from '../../shared/directives/keyboardListener/keyboard-listener.directive';

@Component({
  selector: 'app-media-gallery',
  template: `
    <div
      class="media-gallery-modal"
      *ngIf="galleryData"
      (click)="closeGallery()"
      appKeyboardListener
      (keyPress)="onKeyPress($event)"
    >
      <div class="modal-header primary-text-bold">
        <div class="empty"></div>
        <div class="label-count" *ngIf="currentMediaIndex !== undefined">
          Media {{ currentMediaIndex + 1 }} / {{ galleryData.mediaList.length }}
        </div>
        <div class="actions">
          <fa-icon [icon]="iconClose"></fa-icon>
        </div>
      </div>
      <div class="modal-content">
        <div class="icon-wrapper">
          <fa-icon
            [icon]="iconPrev"
            (click)="onNavigateMedia(-1, $event)"
            *ngIf="galleryData.mediaList.length > 1 && currentMediaIndex !== 0"
          ></fa-icon>
        </div>
        <div class="media-wrapper" (click)="$event.stopImmediatePropagation()">
          <app-media-card
            class="media-full"
            [media]="galleryData.currentMedia"
            [projectName]="galleryData.projectName"
          ></app-media-card>
        </div>
        <div class="icon-wrapper">
          <fa-icon
            [icon]="iconNext"
            (click)="onNavigateMedia(1, $event)"
            *ngIf="
              galleryData.mediaList.length > 1 &&
              currentMediaIndex !== galleryData.mediaList.length - 1
            "
          ></fa-icon>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .media-gallery-modal {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        background-color: #333333;
        z-index: 5;
        display: grid;
        grid-template-rows: 70px 1fr 70px;
        grid-template-columns: 50px 1fr 50px;
        justify-items: center;
        align-items: center;
      }

      .modal-header {
        grid-row: 1 / 2;
        grid-column: 1 / 4;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        color: #ffffff;
        align-items: center;
        padding: 0 30px;
      }

      .actions {
        font-size: 2em;
        cursor: pointer;
      }

      .modal-content {
        grid-column: 1 / 4;
        grid-row: 2 / 3;
        display: flex;
        align-items: center;
        width: 80vw;
        height: 80vh;
      }

      .media-gallery-modal app-media-card {
        background-color: #fff;
        border-radius: 10px;
      }

      .media-wrapper {
        height: 100%;
        flex: 1;
      }

      .modal-content fa-icon {
        background-color: #ffffff;
        margin: 20px;
        border-radius: 5px;
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .media-full {
        object-fit: scale-down;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }

      .icon-wrapper {
        width: 60px;
      }
    `,
  ],
  standalone: false,
})
export class MediaGalleryComponent implements OnInit, OnDestroy {
  galleryData: GalleryData | null;
  currentMediaIndex: number | undefined;
  iconNext: IconDefinition = faChevronRight;
  iconPrev: IconDefinition = faChevronLeft;
  iconClose: IconDefinition = faXmark;
  private sub: Subscription | undefined;
  private keyPressThrottle: Subject<KeyPressEvent>;
  private keyPressThrottleSub: Subscription | undefined;

  constructor(private store: Store) {
    this.galleryData = null;
    this.keyPressThrottle = new Subject<KeyPressEvent>();
    this.keyPressThrottleSub = this.keyPressThrottle
      .pipe(throttleTime(100))
      .subscribe((evt) => this.processKeyPress(evt));
  }

  ngOnInit(): void {
    this.sub = this.store
      .select(MediaGallerySelectors.selectGalleryData())
      .subscribe((data) => {
        this.galleryData = data;
        this.onNavigateMedia(0);
      });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  closeGallery(): void {
    this.store.dispatch(MediaGalleryActions.closeGallery());
  }

  onKeyPress(evt: KeyPressEvent): void {
    this.keyPressThrottle.next(evt);
  }

  processKeyPress(evt: KeyPressEvent): void {
    if (evt === 'Escape') this.closeGallery();
    if (evt === 'ArrowLeft') this.onNavigateMedia(-1);
    if (evt === 'ArrowRight') this.onNavigateMedia(1);
  }

  onNavigateMedia(direction: number, evt?: Event): void {
    evt?.stopImmediatePropagation();
    if (!this.galleryData) return;
    const { mediaList, currentMedia } = this.galleryData;
    const currentIndex = mediaList.indexOf(currentMedia);
    const nextIndex = currentIndex + direction;
    if (nextIndex < 0 || nextIndex > mediaList.length - 1) return;
    this.currentMediaIndex = nextIndex;
    this.galleryData = {
      ...this.galleryData,
      currentMedia: mediaList[nextIndex],
    };
  }
}
