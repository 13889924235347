import { WeatherModelInitConfig, WeatherModelMetadata } from '../types';
import { WeatherModel } from '../types/weather.model';
import { WeatherLayer } from '../types/weather-layer.type';
import {
  PRESSURE,
  RELATIVE_HUMIDITY,
  TEMPERATURE,
  TOTAL_CLOUD_COVER,
  TOTAL_PRECIPITATION,
  WIND_KTS,
} from '../weather-layers/weather.layers';
import {
  createTsListForecast,
  decodeForecastSpec,
  getBaseModelTemplate,
  getDefaultDateRange,
  getTsListRainfall,
  removeUnwantedLayers,
} from '../utils';

export const OPEN_WRF_BALEARES: WeatherModelMetadata = {
  id: 'OPEN_WRF_BALEARES',
  label: 'OpenWRF Baleares',
  category: 'FORECAST',
  subCategory: 'LOCAL_MODELS',
  info: {
    resolution: { deg: 0.03, km: 4 },
    coverage: 'Balearic Islands',
    url: 'https://openskiron.org/en/openwrf',
    credits: 'OpenSkiron',
    schedule: [{ init: '00', time: '14:00' }],
  },
  bbox: [
    [-0.4179, 38.377], // Bottom-left
    [-0.4179, 41.437], // Top-left
    [4.586, 41.437], // Top-right
    [4.586, 38.377], // Bottom-right
    [-0.4179, 38.377], // Repeat first point to close the polygon
  ],
};

export function createOpenWrfBal(
  serverSpec: unknown,
  config?: WeatherModelInitConfig,
): WeatherModel {
  const spec = decodeForecastSpec(serverSpec);
  const timestepList = createTsListForecast(spec.initimeList, 55);
  const tsRainfall = getTsListRainfall(timestepList);
  // first 6 hours are not available
  const defaultDateRange = getDefaultDateRange(timestepList, 6);
  const defaultDateRngRainfall = getDefaultDateRange(tsRainfall, 7);
  const timestepType = { type: 'UNIX', interval: 3600 } as const;

  const aromeLayers: WeatherLayer[] = [
    {
      ...WIND_KTS,
      timestepType,
      timestepList,
      defaultDateRange,
    },
    {
      ...TEMPERATURE,
      timestepType,
      timestepList,
      defaultDateRange,
    },
    {
      ...RELATIVE_HUMIDITY,
      timestepType,
      timestepList,
      defaultDateRange,
    },
    {
      ...PRESSURE,
      timestepType,
      timestepList,
      defaultDateRange,
    },
    {
      ...TOTAL_CLOUD_COVER,
      timestepType,
      timestepList: tsRainfall,
      defaultDateRange: defaultDateRngRainfall,
    },
    {
      ...TOTAL_PRECIPITATION,
      timestepType,
      timestepList: tsRainfall,
      defaultDateRange: defaultDateRngRainfall,
    },
  ];

  const layers = removeUnwantedLayers(aromeLayers, config?.layerIdList ?? []);

  if (layers.length === 0)
    throw new Error(`No layers available for ${spec.model} model`);

  return {
    ...getBaseModelTemplate(config),
    ...OPEN_WRF_BALEARES,
    layers,
    initime: layers[0].timestepList[0].initime,
    timestep: layers[0].defaultDateRange.from,
    selectedLayerId: layers[0].id,
    dateRange: layers[0].defaultDateRange,
  };
}
