import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Form } from '3map-models';
import { Action, createReducer, on } from '@ngrx/store';
import {
  CREATE_FORM,
  DUPLICATE_FORM,
  REMOVE_FORM,
  UPDATE_FORM,
} from './form.actions';
import * as projectActions from '../project.actions';
import * as specificActions from '../specific/form-specific.actions';
import { removeElementFromArray } from '@trim-web-apps/core';

export interface EntityForm {
  id: string;
  name: string;
  specificListIds: string[];
  allowImage: boolean;
  allowAudio: boolean;
  allowVideo: boolean;
  allowFile: boolean;
}

export interface State extends EntityState<EntityForm> {}

export const adapter: EntityAdapter<EntityForm> =
  createEntityAdapter<EntityForm>();
export const initialState: State = adapter.getInitialState();

const formReducer = createReducer(
  initialState,
  on(projectActions.RESET, () => initialState),
  on(projectActions.RESTORE_PROJECT_STATE, (state, { projectState }) => ({
    ...projectState.forms,
  })),
  on(projectActions.INIT_PROJECT, (state, { project }) => {
    const entities = project.formList.map((f) => createEntityForm(f));
    return adapter.setAll(entities, state);
  }),
  on(CREATE_FORM, DUPLICATE_FORM, (state, { form }) => {
    const entityForm = createEntityForm(form);
    return adapter.addOne(entityForm, state);
  }),
  on(UPDATE_FORM, (state, { form }) => {
    const entityForm = state.entities[form.id];
    return entityForm
      ? adapter.updateOne({ id: form.id, changes: form }, state)
      : state;
  }),
  on(REMOVE_FORM, (state, { form }) => {
    return adapter.removeOne(form.id, state);
  }),
  on(
    specificActions.CREATE_SPECIFIC,
    specificActions.DUPLICATE_SPECIFIC,
    (state, { formId, specific }) => {
      const entity = state.entities[formId];
      if (!entity) return state;
      const specificListIds = [...entity.specificListIds, specific.id];
      const update = { id: formId, changes: { specificListIds } };
      return adapter.updateOne(update, state);
    }
  ),
  on(specificActions.REMOVE_SPECIFIC, (state, { formId, specificId }) => {
    const entity = state.entities[formId];
    if (!entity) return state;
    const ids = entity.specificListIds;
    const specificListIds = removeElementFromArray(ids, specificId);
    const update = { id: formId, changes: { specificListIds } };
    return adapter.updateOne(update, state);
  })
);

export function reducer(state: State | undefined, action: Action): State {
  return formReducer(state, action);
}

export function createEntityForm(form: Form): EntityForm {
  const { specificList, ...formLike } = form;
  return {
    ...formLike,
    specificListIds: form.specificList.map((s) => s.id),
  };
}
