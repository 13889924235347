import { WeatherModelInitConfig, WeatherModelMetadata } from '../types';
import { WeatherModel } from '../types/weather.model';
import { createGpm2 } from './gpm2';

export const RFE: WeatherModelMetadata = {
  id: 'RFE',
  label: 'RFE (Africa)',
  category: 'MONITORING',
  subCategory: 'RAINFALL',
  info: {
    resolution: { deg: 0.1, km: 11 },
    coverage: 'Africa',
    url: 'https://earlywarning.usgs.gov/fews/product/59#documentation',
    credits: 'USGS FEWS NET',
    schedule: [{ init: '', time: '21:00 UTC' }],
  },
  bbox: [
    [-20.05, 40.05],
    [55.05, 40.05],
    [55.05, -40.05],
    [-20.05, -40.05],
    [-20.05, 40.05],
  ],
};

export function createRfe(
  serverSpec: unknown,
  config?: WeatherModelInitConfig,
): WeatherModel {
  return {
    ...createGpm2(serverSpec, config),
    ...RFE,
  };
}
