import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weatherPopupValue',
  standalone: true,
})
export class WeatherPopupValuePipe implements PipeTransform {
  transform(d: { field: string; value: number; unit: string }): string {
    if (!d.field.includes('anomalies')) return d.value.toString();
    return d.value > 0 ? `+${d.value}` : d.value.toString();
  }
}
