import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  createUniqueName,
  fieldTypeToFormGroup,
  formGroupToFieldType,
} from '@trim-web-apps/core';
import { FieldType } from '3map-models/dist/lib/model';
import { faBars, faTrash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-field-type',
  template: `
    <form [formGroup]="formGroup">
      <div class="header show-on-hover-toggle">
        <ng-content></ng-content>
        <div class="input-icon">
          <app-field-type-icon
            [field]="formGroup.get('type')?.value"
            [hasError]="!name.valid"
          ></app-field-type-icon>
          <input
            (focusout)="emitFieldType()"
            formControlName="name"
            type="text"
            placeholder="Question"
          />
        </div>
        <fa-icon
          class="remove-icon show-on-hover"
          [icon]="iconRemove"
          (click)="removeQuestion.emit()"
        ></fa-icon>
      </div>

      <div class="body">
        <div class="toggles">
          <div class="question-hint-toggle" (click)="showHint = !showHint">
            {{ showHint ? 'Hide' : 'Show' }} hint
          </div>
          <div class="question-checks">
            <ui-checkbox
              label="Required"
              [checked]="fieldType.required"
              (click)="onCheckBoxClick('required')"
            />
            <ui-checkbox
              label="Immutable"
              [checked]="fieldType.immutable"
              (click)="onCheckBoxClick('immutable')"
            />
          </div>
        </div>

        <div class="question-hint" *ngIf="showHint">
          <input
            (focusout)="emitFieldType()"
            formControlName="info"
            type="text"
            placeholder="Question hint"
          />
        </div>

        <div
          *ngIf="
            formGroup.get('type')?.value === 'LIST' ||
            formGroup.get('type')?.value === 'CHECK'
          "
          class="answers-section"
          formArrayName="options"
        >
          <div
            *ngIf="options.length > 0"
            class="collapse-answer-toggle"
            (click)="answersCollapsed = !answersCollapsed"
          >
            {{ answersCollapsed ? 'Show' : 'Hide' }} answers
          </div>
          <div *ngIf="duplicateError" class="error-label">
            Each answer must be unique
          </div>
          <div *ngIf="emptyAnswersError" class="error-label">
            At least one answer is required
          </div>

          <div *ngIf="!answersCollapsed">
            <div
              cdkDropList
              (cdkDropListDropped)="drop($event)"
              class="drop-list"
            >
              <div
                cdkDrag
                cdkDragLockAxis="y"
                class="answer"
                *ngFor="let answer of options.controls; let i = index"
              >
                <div class="answer-input show-on-hover-toggle">
                  <fa-icon
                    cdkDragHandle
                    class="drag-icon"
                    [icon]="iconDrag"
                  ></fa-icon>
                  <input
                    [formControlName]="i"
                    type="text"
                    class="answer-text"
                    (focusout)="emitFieldType()"
                  />
                  <fa-icon
                    class="remove-icon show-on-hover"
                    [icon]="iconRemove"
                    (click)="removeAnswer(i)"
                  >
                  </fa-icon>
                </div>

                <div
                  *ngIf="
                    options.controls[i].errors && options.controls[i].dirty
                  "
                  class="error-label"
                >
                  Answer cannot be empty
                </div>
              </div>
            </div>

            <div class="actions">
              <ui-btn
                (click)="addAnswer()"
                type="border"
                label="Add answer"
              ></ui-btn>
            </div>
          </div>
        </div>
      </div>
    </form>
  `,
  styleUrls: ['field-type.component.scss'],
  standalone: false,
})
export class FieldTypeComponent implements OnInit {
  @Input() fieldType!: FieldType;
  @Output() removeQuestion: EventEmitter<void>;
  @Output() dragged: EventEmitter<void>;
  @Output() fieldTypeChange: EventEmitter<FieldType>;
  formGroup!: UntypedFormGroup;
  iconRemove = faTrash;
  iconDrag = faBars;
  answersCollapsed = false;
  showHint = false;

  constructor() {
    this.removeQuestion = new EventEmitter<void>();
    this.dragged = new EventEmitter<void>();
    this.fieldTypeChange = new EventEmitter<FieldType>();
  }

  ngOnInit(): void {
    this.formGroup = fieldTypeToFormGroup(this.fieldType);
  }

  get options(): UntypedFormArray {
    return this.formGroup.get('options') as UntypedFormArray;
  }

  get name(): UntypedFormControl {
    return this.formGroup.get('name') as UntypedFormControl;
  }

  get duplicateError(): boolean {
    return this.options.hasError('duplicateItems');
  }

  get emptyAnswersError(): boolean {
    return this.options.hasError('emptyArray');
  }

  addAnswer(): void {
    const questions = this.options.controls.map((c) => c.value);
    this.options.push(
      new UntypedFormControl(createUniqueName(questions, 'New answer'), [
        Validators.required,
        Validators.minLength(1),
      ]),
    );
    this.emitFieldType();
  }

  removeAnswer(index: number): void {
    this.options.removeAt(index);
    this.emitFieldType();
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(
      this.options.controls,
      event.previousIndex,
      event.currentIndex,
    );
    this.formGroup.setValue(this.formGroup.getRawValue());
    this.emitFieldType();
  }

  emitFieldType(): void {
    const ft = formGroupToFieldType(this.formGroup);
    this.fieldTypeChange.emit(ft);
  }

  onCheckBoxClick(target: 'required' | 'immutable'): void {
    const req = this.formGroup.get(target);
    if (!req) return;
    req.setValue(!req.value);
    this.emitFieldType();
  }

  // TODO handle fieldType formGroup validation errors
  getFieldType(): FieldType {
    return formGroupToFieldType(this.formGroup);
  }
}
