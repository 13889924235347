import { FormSpecific, Project, Record } from '3map-models';

export function getSpecific(
  project: Project,
  specificId: string,
): FormSpecific | undefined {
  return project.formList
    .map((f) => f.specificList)
    .reduce((acc, item) => [...acc, ...item], [])
    .find((specific) => specific.id === specificId);
}

export function splitByFeatureId(
  recordList: Record[],
  sortByDatetimeUtc: boolean = true,
): { [k: string]: Record[] } {
  const featureRecords: { [k: string]: Record[] } = {};

  for (let i = 0; i < recordList.length; i++) {
    const record = recordList[i];
    const list = featureRecords[record.featureId] || [];
    featureRecords[record.featureId] = [...list, record];
  }

  if (sortByDatetimeUtc) {
    for (const featureId of Object.keys(featureRecords)) {
      featureRecords[featureId] = featureRecords[featureId].sort(
        (a, b) => b.datetimeUtc.getTime() - a.datetimeUtc.getTime(),
      );
    }
  }

  return featureRecords;
}
