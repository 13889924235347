import { WeatherModelInitConfig, WeatherModelMetadata } from '../types';
import { WeatherModel } from '../types/weather.model';
import { createWrfJapan05 } from './wrf_japan_05';

export const WRF_JAPAN_25: WeatherModelMetadata = {
  id: 'WRF_JAPAN_25',
  label: 'WRF Japan - 2.5',
  category: 'FORECAST',
  subCategory: 'LOCAL_MODELS',
  info: { resolution: { deg: 0.025, km: 2.5 } },
  bbox: [
    [135.4875, 38.5125],
    [144.0125, 38.5125],
    [144.0125, 31.4875],
    [135.4875, 31.4875],
    [135.4875, 38.5125],
  ],
};

export function createWrfJapan25(
  serverSpec: unknown,
  config?: WeatherModelInitConfig,
): WeatherModel {
  return {
    ...createWrfJapan05(serverSpec, config),
    ...WRF_JAPAN_25,
  };
}
