import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Notification } from '../notification.model';
import { NotificationService } from '../notification.service';

type NotificationWithTimeout = Notification & {
  timeOutRef: number | null;
};

@Component({
    selector: 'core-notification-list',
    template: `
    <div class="notification-list-wrapper">
      <core-notification-item
        *ngFor="let item of notificationList | keyvalue"
        [notification]="item.value"
        (dismiss)="removeNotification(item.key)"
        (mouseenter)="onMouseEnter(item.value)"
        (mouseleave)="onMouseLeave(item.value)"
      >
      </core-notification-item>
    </div>
  `,
    styles: [
        `
      .notification-list-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        width: 350px;
        z-index: 5;
      }
    `,
    ],
    standalone: false
})
export class NotificationListComponent implements OnDestroy {
  notificationList: { [id: string]: NotificationWithTimeout } = {};
  private sub: Subscription | undefined;

  constructor(private notify: NotificationService) {
    this.sub = this.notify
      .getNotificationStream()
      .subscribe((notification) => this.onNewNotification(notification));
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  /**
   * Called on every new Notification. If `dueTime` is a positive value, set
   * a timeout to remove the item after dueTime ms.
   * @param notification
   */
  onNewNotification(notification: Notification): void {
    const timeOutRef =
      notification.dueTime <= 0 ? null : this.createTimeout(notification);
    this.notificationList[notification.id] = { ...notification, timeOutRef };
  }

  /**
   * Remove the given Notification by ID
   * @param notificationId: string
   */
  removeNotification(notificationId: string): void {
    const notification = this.notificationList[notificationId];
    if (notification) {
      if (notification.timeOutRef) window.clearTimeout(notification.timeOutRef);
      delete this.notificationList[notification.id];
    }
  }

  /**
   * Clear timeout if user moves mouse on the notification element (prevent
   * auto close)
   * @param notification
   */
  onMouseEnter(notification: NotificationWithTimeout): void {
    if (!notification || notification.timeOutRef === null) return;
    window.clearTimeout(notification.timeOutRef);
  }

  /**
   * Once mouse leave the item, restore the timeout. Used to restore the
   * timeout cleared in `onMouseEnter()`
   * @param notification
   */
  onMouseLeave(notification: NotificationWithTimeout): void {
    if (notification.dueTime <= 0) return;
    this.notificationList[notification.id] = {
      ...notification,
      timeOutRef: this.createTimeout(notification),
    };
  }

  private createTimeout(notification: Notification): any {
    return setTimeout(
      () => this.removeNotification(notification.id),
      notification.dueTime
    );
  }
}
