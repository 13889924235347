import { Component, Input } from '@angular/core';
import { Map } from 'mapbox-gl';
import { WeatherModel } from '@trim-web-apps/weather-models';

@Component({
  selector: 'app-weather',
  template: `
    <ng-container *ngIf="map && mapStyleId">
      <weather-core [mapInstance]="map" [mapStyleId]="mapStyleId" />
      <weather-core-weathers-list [map]="map" />
    </ng-container>
  `,
  standalone: false,
})
export class WeatherComponent {
  @Input() map: Map | undefined;
  @Input() mapStyleId: string | undefined;

  trackBy(index: number, data: WeatherModel) {
    return data.id;
  }
}
