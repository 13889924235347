import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApiService } from 'libs/api3map/src/lib/api.service';
import { fromEvent, map, Observable, of, switchMap } from 'rxjs';
import { RecordActions } from './index';
import { SidenavActions } from '../../sidenav/+state';
import { ProjectActions, ProjectSelectors } from '../../project/+state';
import { HttpClient } from '@angular/common/http';
import { Project, ResRecordList } from '3map-models';
import { catchError, take, withLatestFrom } from 'rxjs/operators';
import { WorkerMessageData } from '../../../record-decoder.worker';

@Injectable()
export class RecordEffects {
  private currentWorker?: Worker;

  constructor(
    private action$: Actions,
    private api: ApiService,
    private store: Store,
    private http: HttpClient,
  ) {}

  appInit$ = createEffect(() =>
    this.action$.pipe(
      ofType(ProjectActions.fetchProjectSuccess),
      map(() => RecordActions.fetchRecords()),
    ),
  );

  /**
   * Fetch records without worker --- uncomment this to revert to old behavior
   *

  fetchRecord$ = createEffect(() =>
    this.action$.pipe(
      ofType(RecordActions.fetchRecords, SidenavActions.reloadRecords),
      concatLatestFrom(() =>
        this.store.select(ProjectSelectors.selectProject())
      ),
      switchMap(([, project]) => {
        if (project === null) return of(RecordActions.fetchRecordsError());
        return this.api.getRecordList(project).pipe(
          map((resRecordList) =>
            RecordActions.fetchRecordsSuccess({ resRecordList })
          ),
          catchError(() => of(RecordActions.fetchRecordsError()))
        );
      })
    )
  );
   */

  fetchRecord$ = createEffect(() =>
    this.action$.pipe(
      ofType(RecordActions.fetchRecords, SidenavActions.reloadRecords),
      withLatestFrom(this.store.select(ProjectSelectors.selectProject())),
      switchMap(([, project]) => {
        if (project === null) throw new Error('Project is null');
        if (typeof Worker !== 'undefined') return this.fetchWithWorker(project);
        console.warn('Web worker not supported, using fallback.');
        return this.api.getRecordList(project);
      }),
      map((resRecordList) =>
        RecordActions.fetchRecordsSuccess({ resRecordList }),
      ),
      catchError(() => of(RecordActions.fetchRecordsError())),
    ),
  );

  private fetchWithWorker(project: Project): Observable<ResRecordList> {
    return this.api.getRawRecordList().pipe(
      switchMap((apiResponse) => {
        const workerData: WorkerMessageData = {
          op: 'decoder',
          payload: { project, apiResponse },
        };
        this.currentWorker?.terminate();
        this.currentWorker = new Worker(
          new URL('../../../record-decoder.worker', import.meta.url),
        );
        this.currentWorker.postMessage(workerData);
        return fromEvent<MessageEvent>(this.currentWorker, 'message').pipe(
          take(1),
        );
      }),
      map((evt: MessageEvent<ResRecordList | null>) => {
        if (evt.data === null) throw new Error('Failed to decode records');
        return evt.data;
      }),
    );
  }
}
