import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { TableActions, TableSelectors } from '../../+state';
import { notNullOrUndefined } from '@trim-web-apps/core';
import { filter } from 'rxjs/operators';
import {
  TABLE_CONFIG_DEFAULT,
  TableConfig,
  TableData,
  TableEvent,
} from '@trim-web-apps/map3-table';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-table',
  template: `
    <map3-table
      *ngIf="tableData$"
      [tableData$]="tableData$"
      [tableConfig]="tableConfig"
      (tableEvent)="onTableEvent($event)"
    />
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TableComponent implements OnInit {
  @Input() datasetId!: string;
  tableData$?: Observable<TableData>;
  tableConfig: TableConfig = {
    ...TABLE_CONFIG_DEFAULT,
    showActions: true,
    enableContextMenu: true,
    allowCreateRecord: false,
    apiUrl: environment.API_URL,
    allowFeatureMigrate: false,
  };

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.tableData$ = this.store
      .select(TableSelectors.selectTableData(this.datasetId))
      .pipe(filter(notNullOrUndefined));
  }

  onTableEvent(event: TableEvent): void {
    if (event.type === 'CREATE_RECORD')
      this.store.dispatch(TableActions.createRecord(event));

    if (event.type === 'EDIT_RECORD')
      this.store.dispatch(TableActions.editRecord(event));

    if (event.type === 'UPDATE_RECORD')
      this.store.dispatch(TableActions.updateRecord(event));

    if (event.type === 'DELETE_RECORD_LIST')
      this.store.dispatch(TableActions.deleteRecords(event));

    if (event.type === 'ZOOM_TO_RECORD')
      this.store.dispatch(TableActions.zoomToRecord(event));

    if (event.type === 'ZOOM_TO_RECORD_LIST')
      this.store.dispatch(TableActions.zoomToRecords(event));

    if (event.type === 'PROCESSED_RECORDS')
      this.store.dispatch(TableActions.processedRecordIdsChanged(event));

    if (event.type === 'TOGGLE_HISTORY')
      this.store.dispatch(TableActions.toggleHistory(event));

    if (event.type === 'ENABLED_SPECIFIC_ID_LIST')
      this.store.dispatch(TableActions.setEnabledSpecificList(event));

    if (event.type === 'TABLE_MEDIA')
      this.store.dispatch(TableActions.setGalleryData(event));

    if (event.type === 'TABLE_MIGRATE_FEATURES')
      console.log('TABLE_MIGRATE_FEATURES', event);
  }
}
