import { GridOptions } from 'ag-grid-community';
import { compareIgnoreCase } from './table.columns';
import { TableConfig } from '../+types/table-config.type';

export const DEFAULT_GRID_OPTIONS: GridOptions = {
  debounceVerticalScrollbar: true,
  rowBuffer: 40,
  columnDefs: [],
  statusBar: {
    statusPanels: [
      // {statusPanel: 'statusBarTableComponent', align: 'left', statusPanelParams: this.form},
      { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'right' },
      {
        statusPanel: 'agAggregationComponent',
        statusPanelParams: {
          aggFuncs: ['count', 'sum', 'min', 'max', 'avg'],
        },
      },
    ],
  },
  defaultColDef: {
    resizable: true,
    sortable: true,
    filter: true,
    comparator: compareIgnoreCase,
  },
  enableCharts: true,
  // frameworkComponents: {
  //   // statusBarTableComponent: StatusBarTableComponent,
  //   // tableMediaFilter: TableMediaFilterComponent,
  //   iconCell: IconCellComponent,
  // },
  rowSelection: 'multiple',
  suppressRowDeselection: false,
  suppressCellFocus: true,
  enableRangeSelection: false,
  sideBar: {
    toolPanels: [
      'filters',
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressSideButtons: true,
          suppressColumnFilter: true,
        },
      },
    ],
    defaultToolPanel: window.innerWidth < 1024 ? '' : 'filters',
  },
  suppressMovableColumns: true,
  suppressColumnMoveAnimation: true,
};

export const TABLE_CONFIG_DEFAULT: TableConfig = {
  showActions: true,
  enableContextMenu: true,
  allowCreateRecord: false,
  apiUrl: '',
  allowFeatureMigrate: false,
};
