import { Routes } from '@angular/router';
import { AdminComponent } from './admin.component';
import { WebhookListComponent } from './webhooks/containers/webhook-list/webhook-list.component';
import { FormListComponent } from './form/containers/form-list/form-list.component';
import { FormSpecificViewComponent } from './form/containers/form-specific-view/form-specific-view.component';
import { SettingsComponent } from './settings/settings/settings.component';
import { DeployComponent } from './deploy/components/deploy.component';
import { ResourceListComponent } from './resource/containers/resource-list.component';
import { ResourceComponent } from './resource/containers/resource.component';
import { UserListComponent } from './user/containers/user-list/user-list.component';
import { LinkListComponent } from './link/containers/link-list/link-list.component';

export const adminRoutes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'forms',
        pathMatch: 'full',
      },
      {
        path: 'forms',
        children: [
          {
            path: '',
            component: FormListComponent,
          },
          {
            path: ':formId/:specificId',
            component: FormSpecificViewComponent,
          },
        ],
      },
      {
        path: 'users',
        component: UserListComponent,
      },
      {
        path: 'webhooks',
        component: WebhookListComponent,
      },
      {
        path: 'links',
        component: LinkListComponent,
      },
      {
        path: 'resources',
        children: [
          {
            path: '',
            component: ResourceListComponent,
          },
          {
            path: ':resourceId',
            component: ResourceComponent,
          },
        ],
      },
      {
        path: 'settings',
        component: SettingsComponent,
      },
      {
        path: 'deploy',
        component: DeployComponent,
      },
    ],
  },
];
