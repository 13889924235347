import { Injectable, TemplateRef, ViewContainerRef } from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';
import { fromEvent, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root',
})
export class ContextMenuService {
  sub: Subscription | undefined;
  overlayRef: OverlayRef | null;

  constructor(private overlay: Overlay) {
    this.overlayRef = null;
  }

  /**
   * Create a context menu using TemplatePortal.
   *
   * @param template: ng-template, content of context-menu
   * @param evt: mouse event used to place context menu under cursor (using x, y of click event)
   * @param viewContainerRef: ref to container component where template should be placed
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // todo remove any
  openContextMenu(
    template: TemplateRef<any>,
    evt: MouseEvent,
    viewContainerRef: ViewContainerRef
  ): void {
    evt.preventDefault();
    this.close();
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo({ x: evt.clientX + 5, y: evt.clientY + 5 })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close(),
    });

    this.overlayRef.attach(new TemplatePortal(template, viewContainerRef));

    this.sub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter((event) => {
          const clickTarget = event.target as HTMLElement;
          return (
            !!this.overlayRef &&
            !this.overlayRef.overlayElement.contains(clickTarget)
          );
        }),
        take(1)
      )
      .subscribe(() => this.close());
  }

  close() {
    this.sub?.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }
}
