import { FieldType } from '3map-models';
import { UntypedFormControl } from '@angular/forms';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import {
  FieldCheck,
  FieldDate,
  FieldInt,
  FieldList,
  FieldNumber,
  FieldRange,
  FieldText,
} from '3map-models/dist/lib/model';

@Component({
  template: '',
  standalone: false,
})
export class FieldTypeBaseComponent implements OnDestroy {
  formControl: UntypedFormControl | undefined;
  sub: Subscription | undefined;
  @Input() fieldType!: FieldType;
  @Input() allowEdit: boolean | undefined;
  @Output() fieldChange: EventEmitter<{
    fieldType: FieldType;
    field:
      | FieldNumber
      | FieldText
      | FieldList
      | FieldRange
      | FieldCheck
      | FieldInt
      | FieldDate
      | null;
  }>;

  constructor() {
    this.fieldChange = new EventEmitter();
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  bindFormControlEmitter(): void {
    this.sub = this.formControl?.valueChanges
      .pipe(
        map(() => {
          try {
            return this.getField();
          } catch {
            return null;
          }
        }),
        catchError(() => of(null)),
      )
      .subscribe((field) =>
        this.fieldChange.emit({
          fieldType: this.fieldType,
          field,
        }),
      );
  }

  inputFocusOut(): void {
    try {
      this.fieldChange.emit({
        fieldType: this.fieldType,
        field: this.getField(),
      });
    } catch {
      // todo handle this?
    }
  }

  /**
   * NOT USED!! values are emitted directly from formControl.valueChanges
   * @throws EditorError
   */
  getField():
    | FieldNumber
    | FieldText
    | FieldList
    | FieldRange
    | FieldCheck
    | FieldInt
    | FieldDate
    | null {
    console.error('[FieldTypeBaseComponent] Not implemented, override me!');
    throw Error('Not implemented, override me!');
  }

  // https://material.angular.io/components/input/overview#input-error-state-matcher
  hasError(): boolean {
    return !!(
      this.formControl &&
      this.formControl.invalid &&
      (this.formControl.dirty || this.formControl.touched)
    );
  }

  getFieldAsRecordData(): { [fieldTypeId: string]: any } | null {
    const field = this.getField();
    return field !== null ? { [this.fieldType.id]: field } : null;
  }
}
