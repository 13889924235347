import { Inject, Injectable, Optional } from '@angular/core';
import { Store } from '@trim-web-apps/store';
import { isValidLngLat, isValidZoom } from './map-utils/map.utils';
import { DEFAULT_MAP_STYLE, MapStyleName } from './map-utils/map.styles';

interface MapState {
  center: { lng: number; lat: number };
  zoom: number;
  style: MapStyleName;
}

const initialMapState = {
  zoom: 2.5,
  center: { lng: 0, lat: 25 },
  style: DEFAULT_MAP_STYLE,
};

@Injectable({
  providedIn: 'root',
})
export class MapStore extends Store<MapState> {
  constructor(@Optional() @Inject('LSK_MAP') private LSK_MAP?: string) {
    super(initialMapState, LSK_MAP || null);
  }

  getValues(): MapState {
    const fromStorage = super.getValue();
    return isValidLngLat(fromStorage.center) && isValidZoom(fromStorage.zoom)
      ? fromStorage
      : initialMapState;
  }
}
