import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  signal,
} from '@angular/core';
import { Map } from 'mapbox-gl';
import { Resource } from '3map-models';
import { blobImageToBase64, blobToString } from '@trim-web-apps/core';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-resource-map',
  template: `
    <div class="map-wrapper">
      <map-core (mapReady)="onMapReady($event)" />
      <div class="map-overlay" *ngIf="fileError()">
        <div>Invalid file</div>
        <div class="image" *ngIf="data?.resource?.type === 'IMAGE'">
          Supported formats: jpg, jpeg, png
        </div>

        <div class="geojson" *ngIf="data?.resource?.type === 'GEOJSON'">
          Supported formats: geojson
        </div>
      </div>

      <div class="map-overlay" *ngIf="!mapReady()">Loading Map...</div>
    </div>
  `,
  styles: [
    `
      .map-wrapper {
        position: relative;
        height: 100%;
        width: 100%;
      }

      .map-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: var(--spacing-3);
        z-index: 5;
        user-select: text;
      }
    `,
  ],
  standalone: false,
})
export class ResourceMapComponent implements OnChanges {
  @Input() data?: { resource: Resource; blob: Blob | null };
  @Output() fileNotValid = new EventEmitter<void>();
  fileError = signal<boolean>(false);
  mapReady = signal<boolean>(false);

  private map?: Map;

  onMapReady(map: Map) {
    this.map = map;
    this.mapReady.set(true);
    map.on('error', (e) => {
      const sourceError = ['image', 'geojson'];
      this.onFileError();
      // if (sourceError.includes(e['source'].type)) this.onFileError();
    });
    this.draw();
  }

  ngOnChanges() {
    this.draw();
  }

  private async draw(): Promise<void> {
    this.fileError.set(false);
    if (!this.map) return;
    if (this.data?.blob === null) return this.fileError.set(true);
    if (this.data?.resource.type === 'GEOJSON') this.drawGeoJson();
    if (this.data?.resource.type === 'IMAGE') this.drawImage();
  }

  private async drawGeoJson(): Promise<void> {
    if (!this.data?.blob || !this.map) return;
    const map = this.map;
    const resource = this.data.resource;
    const data = await lastValueFrom(blobToString(this.data.blob));

    /**
     'type' property for mapbox.AnyLayer is derived from style
     Examples:
     style: { 'circle-radius': 5 }   => 'circle'
     style: { 'line-color': 'red' }  => 'line'
     style: { 'fill-opacity': 0.5 }  => 'fill'

     Available:
     "symbol" | "circle" | "background" | "fill-extrusion" | "fill" | "heatmap" | "hillshade" | "line" | "raster" | "custom"'

     If style is empty, defaults to 'circle'
     */
    const style = resource.style || {};
    const geoType = Object.keys(style)[0].split('-')[0] || 'circle';
    const geoJsonLayerId = resource.name + '__json';
    const namesLayerId = resource.name + '__names';

    if (!data || !style) return;

    if (map.getLayer(geoJsonLayerId)) map.removeLayer(geoJsonLayerId);
    if (map.getSource(geoJsonLayerId)) map.removeSource(geoJsonLayerId);
    if (map.getLayer(namesLayerId)) map.removeLayer(namesLayerId);
    if (map.getSource(namesLayerId)) map.removeSource(namesLayerId);

    const parsedData = parseAsGeoJson(data);

    if (!parsedData) return this.onFileError();

    map
      .addSource(`${geoJsonLayerId}__source`, {
        type: 'geojson',
        data: parsedData,
      })
      .addLayer({
        id: geoJsonLayerId,
        source: `${geoJsonLayerId}__source`,
        type: geoType as any,
        paint: style,
      })
      .addLayer({
        id: namesLayerId,
        source: `${geoJsonLayerId}__source`,
        type: 'symbol',
        layout: {
          'text-field': '{name}',
          'text-offset': [0, -1.0],
          'text-size': 14,
        },
      });
  }

  private async drawImage(): Promise<void> {
    if (!this.data?.blob || !this.map) return;
    const resource = this.data.resource;
    const data = await blobImageToBase64(this.data.blob);
    const coordinates = resource.boundingBox;
    const style = resource.style || {};
    const opacity = style['raster-opacity'] ? style['raster-opacity'] : 1;

    if (!this.map || !data || !coordinates || coordinates.length === 0) return;

    if (this.map.getLayer(resource.filename))
      this.map.removeLayer(resource.filename);
    if (this.map.getSource(resource.filename))
      this.map.removeSource(resource.filename);

    try {
      this.map
        .addSource(resource.filename, {
          type: 'image',
          url: data, // actually a base64 img string
          coordinates: coordinates as any,
        })
        .addLayer({
          id: resource.filename,
          source: resource.filename,
          type: 'raster',
          paint: { 'raster-opacity': opacity },
        });
    } catch {
      this.onFileError();
    }
  }

  private onFileError(): void {
    this.fileError.set(true);
    this.fileNotValid.emit();
  }
}

function parseAsGeoJson(data: string): any {
  try {
    return JSON.parse(data);
  } catch (e) {
    return null;
  }
}
