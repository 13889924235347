import { Component, Input, OnInit } from '@angular/core';
import { FieldTypeBaseComponent } from '../../../models/FieldTypeBaseComponent';
import { FieldInt } from '3map-models';
import { UntypedFormControl, Validators } from '@angular/forms';
import { extractTheFuckingNumber } from '../field-number/field-number.component';
import { EditorError } from '../../../models/EditorError';
import { isNumber } from '@trim-web-apps/core';

@Component({
  selector: 'app-field-int',
  template: `
    <app-field-name [fieldType]="fieldType"></app-field-name>
    <input
      type="number"
      placeholder="Enter an integer number"
      [formControl]="formControl!"
      [readOnly]="!allowEdit"
      (focusout)="inputFocusOut()"
    />
    <div class="error" *ngIf="hasError()">Required and integer number</div>
  `,
  styleUrls: ['../../field-type.base.component.scss'],
  styles: [],
  standalone: false,
})
export class FieldIntComponent
  extends FieldTypeBaseComponent
  implements OnInit
{
  @Input() fieldInt: FieldInt | undefined;

  ngOnInit(): void {
    if (!this.fieldType) throw Error('[ FieldInt ] Missing FieldType');
    const value = isNumber(this.fieldInt?.valueInt)
      ? this.fieldInt?.valueInt
      : '';
    const validators = this.fieldType.required ? [Validators.required] : [];
    this.formControl = new UntypedFormControl(value, [
      ...validators,
      Validators.pattern('^[0-9]*$'),
    ]);
    // this.bindFormControlEmitter();
  }

  override getField(): FieldInt | null {
    if (this.formControl?.valid) {
      const value = extractTheFuckingNumber(this.formControl.value);
      return value !== null
        ? {
            valueInt: value,
            type: 'INT',
          }
        : null;
    }
    throw new EditorError('Invalid FieldInt');
  }
}
