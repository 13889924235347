import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  faArrowsRotate,
  faMap,
  faTableCellsLarge,
} from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Form } from '3map-models';
import { SidenavActions, SidenavSelectors } from '../../+state';
import { MapResource } from '../../../map-resource/+state';
import { Router } from '@angular/router';
import { NominatimResponse } from '@trim-web-apps/nominatim';
import { faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import { SidenavData } from '../../+types/sidenav-data.type';

@Component({
  selector: 'app-sidenav',
  template: `
    <ng-container *ngIf="sidenavData$ | async as sidenavData">
      <div
        class="sidenav-container"
        (click)="toggleSidebar(sidenavData)"
        [class.sidenav-open]="sidenavData.opened"
      >
        <app-sidenav-header
          [data]="sidenavData"
          (click)="$event.stopPropagation()"
          (toggleSidenavOpened)="toggleSidenav($event)"
        />

        <div class="inner">
          <app-sidenav-item
            (click)="$event.stopPropagation(); onSectionChange('MAP')"
            [icon]="iconMap"
            [active]="sidenavData.section === 'MAP'"
            [sidenavOpen]="sidenavData.opened"
            label="Map"
          />

          <app-sidenav-item
            (click)="$event.stopPropagation(); onSectionChange('TABLE')"
            [icon]="iconTable"
            [active]="sidenavData.section === 'TABLE'"
            [sidenavOpen]="sidenavData.opened"
            label="Table"
          />

          <div class="separator"></div>

          <div class="expandable-menus">
            <app-forms-nav-item
              [sidenavOpen]="sidenavData.opened"
              [formDatasetList]="sidenavData.formDatasetList"
              (toggleForm)="toggleForm($event)"
            />

            <app-weather-nav-item [sidenavOpen]="sidenavData.opened" />

            <app-resources-nav-item
              [sidenavOpen]="sidenavData.opened"
              [mapResourceList]="sidenavData.mapResourceList"
              (toggleResource)="toggleResource($event)"
            />

            <app-links-nav-item
              [sidenavOpen]="sidenavData.opened"
              [links]="sidenavData.project.linkList ?? []"
            />
          </div>

          <div class="fill-remaining-space"></div>

          <div class="separator"></div>

          <app-sidenav-item
            label="Change Project"
            [icon]="iconProjects"
            [sidenavOpen]="sidenavData.opened"
            (click)="$event.stopImmediatePropagation(); onChangeProject()"
          />

          <app-sidenav-item
            label="Reload Records"
            [icon]="iconRecords"
            [spin]="sidenavData.recordLoading"
            [sidenavOpen]="sidenavData.opened"
            (click)="$event.stopImmediatePropagation(); onReloadRecords()"
          />

          <div class="separator"></div>

          <app-user-nav-item
            [data]="sidenavData"
            (admin)="onAdmin()"
            (logout)="onLogout()"
          />
        </div>
      </div>
    </ng-container>
  `,
  styles: [
    `
      :host {
        background-color: var(--bg-color);
        position: absolute;
        top: 0;
        z-index: 3;
      }

      .expandable-menus {
        overflow: auto;
      }

      .sidenav-container {
        display: flex;
        /*padding: var(--spacing-2);*/
        /*width: var(--spacing-5);*/
        flex-direction: column;
        box-shadow:
          0 3px 6px rgba(0, 0, 0, 0.16),
          0 3px 6px rgba(0, 0, 0, 0.23);
        height: 100vh;
        overflow: auto;
      }

      .sidenav-open {
        width: 300px;
      }

      .inner {
        display: flex;
        flex-direction: column;
        padding: var(--spacing-2);
        height: 100%;
        overflow: hidden;
      }

      .separator {
        border-bottom: 2px solid #eeeeee;
        margin: var(--spacing-3) 0;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SidenavComponent {
  sidenavData$: Observable<SidenavData | null>;

  iconProjects = faFolderOpen;
  iconRecords = faArrowsRotate;
  iconMap = faMap;
  iconTable = faTableCellsLarge;

  constructor(
    private store: Store,
    private router: Router,
  ) {
    this.sidenavData$ = this.store.select(SidenavSelectors.selectSidenavData());
  }

  toggleForm(evt: { form: Form; enabled: boolean }): void {
    const { form, enabled } = evt;
    const action = enabled
      ? SidenavActions.removeDataset({ id: form.id })
      : SidenavActions.createDataset({ formId: form.id });
    this.store.dispatch(action);
  }

  toggleResource(mapResource: MapResource): void {
    const resourceId = mapResource.filename;
    if (mapResource.status === 'DISABLED')
      this.store.dispatch(SidenavActions.enableMapResource({ resourceId }));
    if (mapResource.status === 'ENABLED' || mapResource.status === 'ERROR')
      this.store.dispatch(SidenavActions.disableMapResource({ resourceId }));
  }

  onLogout(): void {
    this.store.dispatch(SidenavActions.sidenavLogout());
  }

  onSectionChange(section: 'MAP' | 'TABLE'): void {
    this.store.dispatch(SidenavActions.setSection({ section }));
  }

  onReloadRecords(): void {
    this.store.dispatch(SidenavActions.reloadRecords());
  }

  onChangeProject(): void {
    this.store.dispatch(SidenavActions.sidenavChangeProject());
  }

  toggleSidebar(data: SidenavData): void {
    // this.sidebarOpen$.pipe(take(1)).subscribe((isOpen) => {
    //   if (isOpen && opened) return;
    if (!data.opened)
      this.store.dispatch(SidenavActions.setOpened({ opened: true }));
    // });
  }

  toggleSidenav(opened: boolean): void {
    this.store.dispatch(SidenavActions.setOpened({ opened }));
  }

  onAdmin(): void {
    this.router.navigate(['admin']);
  }

  onSearchLocation(searchLocation: NominatimResponse | null): void {
    this.store.dispatch(SidenavActions.searchLocation({ searchLocation }));
  }

  onZoomToLocation(): void {
    this.store.dispatch(SidenavActions.zoomToSearchLocation());
  }
}
