import { Component, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Link } from '3map-models';
import { WebHook } from '3map-models/dist/lib/model';

export type NameUrlDialogData = {
  title: string;
  payload?: {
    name: string;
    url: string;
  };
};

@Component({
  selector: 'app-name-url-dialog',
  template: `
    <div class="name-url-wrapper">
      <div class="section-title">
        {{ name.length === 0 ? 'Create' : 'Edit' }} {{ data?.title }}
      </div>
      <div class="row">
        <div class="label">Name</div>
        <input type="text" [(ngModel)]="name" />
      </div>
      <div class="row text-area">
        <div class="label">URL</div>
        <textarea cols="50" rows="3" [(ngModel)]="url"> </textarea>
      </div>
      <div class="row actions">
        <ui-btn
          (btnClick)="onCreate()"
          [disabled]="!isValid()"
          label="Add"
        ></ui-btn>
      </div>
    </div>
  `,
  styles: [
    `
      .name-url-wrapper {
        padding: var(--spacing-3);
        background-color: var(--bg-color);
        border-radius: var(--radius-1);
      }

      .section-title {
        font-size: var(--font-size-3);
        margin-bottom: var(--spacing-3);
        color: var(--primary-color);
      }

      .row {
        margin-bottom: var(--spacing-3);
      }

      .row input {
        width: 100%;
      }

      .actions {
        text-align: center;
      }
    `,
  ],
  standalone: false,
})
export class NameUrlDialogComponent {
  name: string;
  url: string;

  constructor(
    public dialogRef: DialogRef<Link | WebHook | null>,
    @Inject(DIALOG_DATA) public data: NameUrlDialogData | null,
  ) {
    this.name = data?.payload?.name || '';
    this.url = data?.payload?.url || '';
  }

  onCreate(): void {
    if (!this.isValid()) return;
    const name = this.name;
    const url = this.url;
    const value: Link | null = name && url ? { name, url } : null;
    this.dialogRef.close(value);
  }

  isValid(): boolean {
    const validName = this.name !== undefined && this.name.length > 0;
    const validUrl = this.url !== undefined && this.url.length > 0;
    return validName && validUrl;
  }
}
