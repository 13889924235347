import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { combineLatest, of, Subscription, switchMap, take, tap } from 'rxjs';
import { WeatherApiService } from '../../services/weather-api.service';
import {
  createInitimeLabel,
  interpolateList,
  interpolateToString,
  isVectorLayer,
} from '../../utils/weather.functions';
import { Store } from '@ngrx/store';
import { WeatherActions, WeatherSelectors } from '../../+state';
import { InterpolateType, TimestepType } from '@trim-web-apps/weather-models';
import { DropdownEvent, DropdownItem } from '@trim-web-apps/map3-ui';

@Component({
    selector: 'weather-core-settings',
    template: `
    <div class="section" *ngIf="initMenuData">
      <div class="label">Init time</div>
      <div class="content">
        <ui-dropdown
          [itemList]="initMenuData.list"
          [itemActive]="initMenuData.active"
          (itemSelected)="onInitSelected($event.source)"
        />
      </div>
    </div>

    <div class="section" *ngIf="interpolateMenuData">
      <div class="label">Interpolation</div>
      <div class="content">
        <ui-dropdown
          [itemList]="interpolateMenuData.list"
          [itemActive]="interpolateMenuData.active"
          (itemSelected)="onInterpolateSelected($event)"
        />
      </div>
    </div>

    <div class="section" *ngIf="windStyleMenuData">
      <div class="label">Arrow style</div>

      <div class="content">
        <ui-dropdown
          [itemList]="windStyleMenuData.list"
          [itemActive]="windStyleMenuData.active"
          (itemSelected)="onArrowStyleSelected($event)"
        />
      </div>
    </div>

    <div class="section">
      <div class="label">Download</div>
      <div class="content download-buttons">
        <div class="dw-button" (click)="onDownload()">GeoTiff</div>
        <div class="dw-button disabled ">GRIB</div>
        <div class="dw-button disabled">NetCDF</div>
      </div>
    </div>

    <div class="section">
      <!--      <div class="label">Date range</div>-->
      <div class="content">
        <weather-core-date-range-picker-inline
          [weatherModelId]="weatherModelId"
          [utcOffset]="utcOffset"
          [tsType]="tsType"
        />
      </div>
    </div>

    <div class="section">
      <div class="content">
        <ui-btn
          (click)="closeSettings.emit()"
          [fullWidth]="true"
          type="border"
          label="Close"
        />
      </div>
    </div>
  `,
    styles: [
        `
      .section {
        margin-bottom: var(--spacing-3);
        &:last-child {
          margin-bottom: 0;
        }
      }

      .label {
        font-weight: var(--font-weight-3);
        margin-bottom: var(--spacing-1);
        font-size: var(--font-size-2);
        color: var(--primary-color);
      }

      .download-buttons {
        display: flex;
      }

      .dw-button {
        border: 1px solid var(--border);
        padding: var(--spacing-2);
        cursor: pointer;
        flex: 1;
        text-align: center;
      }

      .dw-button:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-right: none;
      }

      .dw-button:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-left: none;
      }

      .disabled {
        cursor: not-allowed;
        color: var(--border);
      }
    `,
    ],
    standalone: false
})
export class WeatherSettingsComponent implements OnInit, OnDestroy {
  @Input() weatherModelId?: string;
  @Output() closeSettings: EventEmitter<void> = new EventEmitter<void>();
  utcOffset?: string;
  modelName?: string;
  dropdownWidth = 250;

  initMenuData?: {
    active: DropdownItem<number>;
    list: DropdownItem<number>[];
  };

  windStyleMenuData?: {
    active: DropdownItem<string>;
    list: DropdownItem<string>[];
  };

  interpolateMenuData?: {
    active: DropdownItem<InterpolateType>;
    list: DropdownItem<InterpolateType>[];
  };

  tsType?: TimestepType;

  private sub?: Subscription;
  private filenameForDownload?: string;

  constructor(
    private weatherApi: WeatherApiService,
    private store: Store,
  ) {}

  get modelId() {
    if (this.weatherModelId) return this.weatherModelId;
    throw Error('Missing WeatherModelId');
  }

  ngOnInit(): void {
    if (!this.weatherModelId) return;
    this.sub = combineLatest([
      this.store.select(WeatherSelectors.selectById(this.weatherModelId)),
      this.store.select(WeatherSelectors.selectUtcOffset()),
    ])
      .pipe(
        tap(([model, utcOffset]) => {
          if (!model) return;
          this.utcOffset = utcOffset;
          const layers = model.layers;
          const layer = layers.find((l) => l.id === model.selectedLayerId);
          if (layer) {
            this.filenameForDownload = `${model.label}_${layer.label}.tiff`;
            this.tsType = layer.timestepType;
            this.modelName = model.label;

            // do not show init time picker for non-forecast models (e.g. gpm)
            if (model.initime >= 0) {
              const list: DropdownItem<number>[] = layer.timestepList
                .map((ts) => ts.initime)
                .map((init) => {
                  return {
                    payload: init,
                    label: `${createInitimeLabel(init)} UTC`,
                  };
                });

              const active: DropdownItem<number> = {
                label: `${createInitimeLabel(model.initime)} UTC`,
                payload: model.initime,
              };
              this.initMenuData = { active, list };
            }

            this.interpolateMenuData = {
              active: {
                label: interpolateToString(model.interpolate),
                payload: model.interpolate,
              },
              list: interpolateList.map((i) => {
                return {
                  label: interpolateToString(i),
                  payload: i,
                };
              }),
            };

            // show style only for `wind` and `current` layers
            if (isVectorLayer(layer)) {
              this.windStyleMenuData = {
                active: { label: model.windStyle, payload: model.windStyle },
                list: [
                  { label: 'Arrow', payload: 'Arrow' },
                  { label: 'Standard', payload: 'Standard' },
                  { label: 'Light', payload: 'Light' },
                  { label: 'Barbs', payload: 'Barbs' },
                  { label: 'Boxfill', payload: 'Boxfill' },
                  { label: 'Direction Only', payload: 'Direction Only' },
                ],
              };
            }
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  onInitSelected(item: DropdownItem<number> | null): void {
    if (item)
      this.store.dispatch(
        WeatherActions.setInitime({
          modelId: this.modelId,
          initime: item.payload,
        }),
      );
  }

  onArrowStyleSelected(evt: DropdownEvent<string>): void {
    const windStyle = evt.source?.payload;
    if (!windStyle) return;
    const payload = { modelId: this.modelId, windStyle };
    this.store.dispatch(WeatherActions.setWindStyle(payload));
  }

  onInterpolateSelected(evt: DropdownEvent<InterpolateType>): void {
    const interpolate = evt.source?.payload;
    if (!interpolate) return;
    const payload = { modelId: this.modelId, interpolate };
    this.store.dispatch(WeatherActions.setInterpolate(payload));
  }

  onDownload(): void {
    this.store
      .select(WeatherSelectors.selectTiffUrl(this.modelId))
      .pipe(
        switchMap((url) => {
          return url && this.filenameForDownload
            ? this.weatherApi.downloadTiffAsFile(
                url,
                this.filenameForDownload || '',
              )
            : of(null);
        }),
        take(1),
      )
      .subscribe();
  }
}
