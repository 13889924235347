import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'weather-core-dialog-mobile',
    template: `
    <div class="modal-wrapper">
      <div class="header shadow">
        <fa-icon [icon]="iconBack" (click)="closeDialog.emit()" />
        <div class="title">{{ title }}</div>
        <div class="empty"></div>
      </div>
      <div class="content">
        <ng-content />
      </div>
    </div>
  `,
    styles: [
        `
      .modal-wrapper {
        background: var(--bg-color);
        height: 100dvh;
        width: 100dvw;
        overflow: auto;
      }

      .header {
        position: sticky;
        top: 0;
        display: flex;
        background-color: var(--primary-color);
        color: var(--bg-color);
        padding: var(--spacing-2);
        align-items: center;
        justify-content: space-between;
        font-size: var(--font-size-4);
      }

      .content {
        padding: var(--spacing-2);
        display: flex;
        flex-direction: column;
        gap: var(--spacing-3);
      }
    `,
    ],
    standalone: false
})
export class DialogMobileComponent {
  @Input() title?: string;
  @Output() closeDialog = new EventEmitter<void>();
  iconBack = faArrowLeft;
}
