import { WeatherLayer } from './weather-layer.type';
import { ChartData } from './chart.data';

export type ModelInfo = {
  resolution: { deg: number; km: number };
  coverage?: string;
  url?: string;
  credits?: string;
  latestUpdate?: number | null;
  schedule?: { init: string; time: string }[];
};

export type InterpolateType =
  | 'nearestValue'
  | 'bilinearInterpolation'
  | 'bicubicInterpolation'
  | 'bicubicInterpolationNoValues'
  | 'bicubicInterpolationNoValuesRound'
  | 'bicubicInterpolationAvg'
  | 'bicubicInterpolationAvgRound';

/** Subcategories are used by `weatherModelSubCategoryToString` function! (see `weather.functions.ts`) */
export type WeatherModelSubCategory =
  | 'GLOBAL_MODELS'
  | 'LOCAL_MODELS'
  | 'RAINFALL_ACCUMULATION'
  | 'HISTORY'
  | 'VEGETATION'
  | 'RAINFALL'
  | 'TEST'
  | 'TEMPERATURE';

// export type MODEL_ID = typeof FULL_MODEL_LIST[number]['id'];
export type WeatherModelMetadata = {
  id: string;
  label: string;
  category: 'FORECAST' | 'MONITORING';
  subCategory: WeatherModelSubCategory;
  info: ModelInfo;
  bbox: number[][];
};

export type WeatherModel = WeatherModelMetadata & {
  selectedLayerId: string;
  layers: WeatherLayer[];
  initime: number;
  timestep: number;
  visible: boolean;
  interpolate: InterpolateType;
  chartEnabled: boolean;
  tiffLoading: boolean;
  chartData: ChartData[];
  chartDataLoading: boolean;
  chartMode: 'compact' | 'multi-y' | 'standard' | string;
  dateRange: { from: number; to: number };
  windStyle: string;
  hasChart: boolean;
  tiffFetchError: boolean;
};

export type WeatherModelSpecRequest = {
  modelId: string;
  status: 'pending' | 'success' | 'error' | 'initial';
};
