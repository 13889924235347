import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Form, FormSpecific } from '3map-models';
import { ContextMenuService } from '@trim-web-apps/core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Map } from 'mapbox-gl';

@Component({
  selector: 'app-map-context-menu[map]',
  template: `
    <ng-template #mapContextMenuTemplate>
      <div class="map-context-menu" (mouseleave)="addRecordVisible = false">
        <div class="left">
          <div class="top-left" *ngIf="contextMenuData.length > 0">
            <div class="item" (mouseenter)="addRecordVisible = true">
              Create Feature
              <fa-icon [icon]="iconChevronRight"></fa-icon>
            </div>
          </div>

          <div class="item lng-lat" (mouseenter)="addRecordVisible = false">
            <div class="lng-lat-item">
              <span>{{ lng?.toFixed(5) }} ; {{ lat?.toFixed(5) }}</span>
              <span> (lng ; lat) </span>
            </div>
          </div>
        </div>
        <div *ngIf="addRecordVisible" class="right">
          <ng-container *ngFor="let data of contextMenuData">
            <div
              *ngFor="let specific of data.enabledSpecificList"
              class="item text-ellipsis"
              (click)="onCreateFeature(data.form.id, specific.id)"
            >
              {{ specific.name }}
            </div>
          </ng-container>
        </div>
      </div>
    </ng-template>
  `,
  styles: [
    `
      .map-context-menu {
        display: flex;
        align-items: baseline;
      }

      .left {
        background-color: #ffffff;
        margin-right: 2px;
        border-radius: var(--radius-1);
        border-top-left-radius: 0;
        overflow: hidden;
        box-shadow:
          0 3px 6px rgba(0, 0, 0, 0.16),
          0 3px 6px rgba(0, 0, 0, 0.23);
      }

      .top-left > div {
        display: flex;
        justify-content: space-between;
      }

      .right {
        border-radius: var(--radius-1);
        background-color: #ffffff;
        box-shadow:
          0 3px 6px rgba(0, 0, 0, 0.16),
          0 3px 6px rgba(0, 0, 0, 0.23);
        max-height: 300px;
        overflow: auto;
      }

      .item {
        padding: var(--spacing-2);
        cursor: pointer;
        width: 210px;
      }

      .item:hover {
        background-color: var(--hover);
      }

      .lng-lat {
        cursor: unset;
        display: flex;
        justify-content: center;
        font-size: 0.9em;
      }

      .lng-lat span:first-child {
        cursor: text;
        user-select: text;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MapContextMenuComponent implements OnInit {
  @Input() map?: Map;
  @Input() contextMenuData: {
    form: Form;
    enabledSpecificList: FormSpecific[];
  }[] = [];
  @Output() createFeature: EventEmitter<{
    formId: string;
    specificId: string;
    latitude: number;
    longitude: number;
    altitude: number;
  }> = new EventEmitter();
  @ViewChild('mapContextMenuTemplate')
  mapContextMenuTemplateRef?: TemplateRef<any>;

  addRecordVisible = false;
  iconChevronRight = faChevronRight;

  lng?: number;
  lat?: number;

  constructor(
    private ctxMenu: ContextMenuService,
    private viewContainerRef: ViewContainerRef,
  ) {}

  ngOnInit(): void {
    this.map
      ?.on('contextmenu', (evt) => {
        if (this.mapContextMenuTemplateRef) {
          this.lat = evt.lngLat.lat;
          this.lng = evt.lngLat.lng;
          this.ctxMenu.openContextMenu(
            this.mapContextMenuTemplateRef,
            evt.originalEvent,
            this.viewContainerRef,
          );
        }
      })
      .on('move', () => this.ctxMenu.close());
  }

  onCreateFeature(formId: string, specificId: string): void {
    if (this.lat === undefined || this.lng === undefined) return;
    this.addRecordVisible = false;
    this.createFeature.emit({
      formId,
      specificId,
      latitude: this.lat,
      longitude: this.lng,
      altitude: 0,
    });
  }
}
