import {
  MONTH_RAINY_DAYS,
  RAINFALL_1DAY,
  RAINFALL_1MONTH,
  RAINFALL_1MONTH_ABSOLUTE_ANOMALIES,
  RAINFALL_1MONTH_AVG,
  RAINFALL_1MONTH_RELATIVE_ANOMALIES,
  RAINFALL_1YEAR,
  RAINFALL_1YEAR_ABSOLUTE_ANOMALIES,
  RAINFALL_1YEAR_AVG,
  RAINFALL_1YEAR_RELATIVE_ANOMALIES,
  RAINFALL_3DAY,
  RAINFALL_3MONTH,
  RAINFALL_3MONTH_ABSOLUTE_ANOMALIES,
  RAINFALL_3MONTH_AVG,
  RAINFALL_3MONTH_RELATIVE_ANOMALIES,
  RAINFALL_6MONTH,
  RAINFALL_6MONTH_ABSOLUTE_ANOMALIES,
  RAINFALL_6MONTH_AVG,
  RAINFALL_6MONTH_RELATIVE_ANOMALIES,
  RAINFALL_7DAY,
  RAINFALL_QUARTER,
  RAINFALL_QUARTER_ABSOLUTE_ANOMALIES,
  RAINFALL_QUARTER_AVG,
  RAINFALL_QUARTER_RELATIVE_ANOMALIES,
  RAINFALL_SEMESTER,
  RAINFALL_SEMESTER_ABSOLUTE_ANOMALIES,
  RAINFALL_SEMESTER_AVG,
  RAINFALL_SEMESTER_RELATIVE_ANOMALIES,
} from '../weather-layers/weather.layers';

import {
  TimestepType,
  WeatherLayer,
  WeatherModel,
  WeatherModelInitConfig,
  WeatherModelMetadata,
} from '../types';
import {
  createTsListGpm,
  decodeGpmLikeSpec,
  getBaseModelTemplate,
  removeUnwantedLayers,
} from '../utils';

export const GPM: WeatherModelMetadata = {
  id: 'GPM2',
  label: 'GPM',
  category: 'MONITORING',
  subCategory: 'RAINFALL',
  info: {
    resolution: { deg: 0.1, km: 11 },
    coverage: 'Global',
    url: 'https://gpm.nasa.gov/missions/GPM',
    credits: 'NASA',
    schedule: [
      { init: 'Early', time: '06:30' },
      { init: 'Late', time: '15:45' },
    ],
  },
  bbox: [
    [-180, 90],
    [180, 90],
    [180, -90],
    [-180, -90],
    [-180, 90],
  ],
};

export function createGpm2(
  serverSpec: unknown,
  config?: WeatherModelInitConfig,
): WeatherModel {
  const spec = decodeGpmLikeSpec(serverSpec);
  const tsDay = spec.timestepList;
  const tsMonth = spec.timestepMonthList;
  const tsQuarter = spec.timestepQuarterList;
  const tsSemester = spec.timestepSemesterList;
  const tsYear = spec.timestepYearList;

  const tsListDay = createTsListGpm(tsDay, false);
  const tsTypeDay: TimestepType = { type: 'DAYS', interval: 1 };
  const defaultDateRangeDay = {
    from: tsListDay[0].timesteps[tsListDay[0].timesteps.length - 30],
    to: tsListDay[0].timesteps[tsListDay[0].timesteps.length - 1],
  };

  const tsListMonth = createTsListGpm(tsMonth, true);
  const tsTypeMonth: TimestepType = { type: '1MONTH', interval: 1 };
  const defaultDateRangeMonth = {
    from: tsListMonth[0].timesteps[tsListMonth[0].timesteps.length - 24],
    to: tsListMonth[0].timesteps[tsListMonth[0].timesteps.length - 1],
  };

  const tsListQuarter = createTsListGpm(tsQuarter, true);
  const tsTypeQuarter: TimestepType = { type: 'QUARTER', interval: 1 };
  const defaultDateRangeQuarter = {
    from: tsListQuarter[0].timesteps[tsListQuarter[0].timesteps.length - 12],
    to: tsListQuarter[0].timesteps[tsListQuarter[0].timesteps.length - 1],
  };

  const tsListSemester = createTsListGpm(tsSemester, true);
  const tsTypeSemester: TimestepType = { type: 'SEMESTER', interval: 1 };
  const defaultDateRangeSemester = {
    from: tsListSemester[0].timesteps[tsListSemester[0].timesteps.length - 12],
    to: tsListSemester[0].timesteps[tsListSemester[0].timesteps.length - 1],
  };

  const tsList1Year = createTsListGpm(tsYear, true);
  const tsType1Year: TimestepType = { type: '1YEAR', interval: 1 };
  const defaultDateRangeYear = {
    from: tsList1Year[0].timesteps[0],
    to: tsList1Year[0].timesteps[tsList1Year[0].timesteps.length - 1],
  };

  const gpmLayers: WeatherLayer[] = [
    {
      ...RAINFALL_1DAY,
      timestepType: tsTypeDay,
      timestepList: tsListDay,
      defaultDateRange: defaultDateRangeDay,
    },
    {
      ...RAINFALL_3DAY,
      timestepType: tsTypeDay,
      timestepList: tsListDay,
      defaultDateRange: defaultDateRangeDay,
    },
    {
      ...RAINFALL_7DAY,
      timestepType: tsTypeDay,
      timestepList: tsListDay,
      defaultDateRange: defaultDateRangeDay,
    },
    {
      ...RAINFALL_1MONTH,
      timestepType: tsTypeMonth,
      timestepList: tsListMonth,
      defaultDateRange: defaultDateRangeMonth,
    },
    {
      ...RAINFALL_1MONTH_ABSOLUTE_ANOMALIES,
      timestepType: tsTypeMonth,
      timestepList: tsListMonth,
      defaultDateRange: defaultDateRangeMonth,
    },
    {
      ...RAINFALL_1MONTH_RELATIVE_ANOMALIES,
      timestepType: tsTypeMonth,
      timestepList: tsListMonth,
      defaultDateRange: defaultDateRangeMonth,
    },
    {
      ...RAINFALL_1MONTH_AVG,
      timestepType: { type: '1MONTH_AVG', interval: 1 },
      timestepList: [
        { initime: -2, timesteps: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] },
      ],
      defaultDateRange: { from: 0, to: 11 },
    },
    {
      ...RAINFALL_3MONTH,
      timestepType: tsTypeMonth,
      timestepList: tsListMonth,
      defaultDateRange: defaultDateRangeMonth,
    },
    {
      ...RAINFALL_3MONTH_ABSOLUTE_ANOMALIES,
      timestepType: tsTypeMonth,
      timestepList: tsListMonth,
      defaultDateRange: defaultDateRangeMonth,
    },
    {
      ...RAINFALL_3MONTH_RELATIVE_ANOMALIES,
      timestepType: tsTypeMonth,
      timestepList: tsListMonth,
      defaultDateRange: defaultDateRangeMonth,
    },
    {
      ...RAINFALL_3MONTH_AVG,
      timestepType: { type: '1MONTH_AVG', interval: 1 },
      timestepList: [
        { initime: -2, timesteps: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] },
      ],
      defaultDateRange: { from: 0, to: 11 },
    },
    {
      ...RAINFALL_QUARTER,
      timestepType: tsTypeQuarter,
      timestepList: tsListQuarter,
      defaultDateRange: defaultDateRangeQuarter,
    },
    {
      ...RAINFALL_QUARTER_ABSOLUTE_ANOMALIES,
      timestepType: tsTypeQuarter,
      timestepList: tsListQuarter,
      defaultDateRange: defaultDateRangeQuarter,
    },
    {
      ...RAINFALL_QUARTER_RELATIVE_ANOMALIES,
      timestepType: tsTypeQuarter,
      timestepList: tsListQuarter,
      defaultDateRange: defaultDateRangeQuarter,
    },
    {
      ...RAINFALL_QUARTER_AVG,
      timestepType: { type: 'QUARTER_AVG', interval: 1 },
      timestepList: [{ initime: -2, timesteps: [2, 5, 8, 11] }],
      defaultDateRange: { from: 0, to: 11 },
    },
    {
      ...RAINFALL_6MONTH,
      timestepType: tsTypeMonth,
      timestepList: tsListMonth,
      defaultDateRange: defaultDateRangeMonth,
    },
    {
      ...RAINFALL_6MONTH_ABSOLUTE_ANOMALIES,
      timestepType: tsTypeMonth,
      timestepList: tsListMonth,
      defaultDateRange: defaultDateRangeMonth,
    },
    {
      ...RAINFALL_6MONTH_RELATIVE_ANOMALIES,
      timestepType: tsTypeMonth,
      timestepList: tsListMonth,
      defaultDateRange: defaultDateRangeMonth,
    },
    {
      ...RAINFALL_6MONTH_AVG,
      timestepType: { type: '1MONTH_AVG', interval: 1 },
      timestepList: [
        { initime: -2, timesteps: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] },
      ],
      defaultDateRange: { from: 0, to: 11 },
    },
    {
      ...RAINFALL_SEMESTER,
      timestepType: tsTypeSemester,
      timestepList: tsListSemester,
      defaultDateRange: defaultDateRangeSemester,
    },
    {
      ...RAINFALL_SEMESTER_ABSOLUTE_ANOMALIES,
      timestepType: tsTypeSemester,
      timestepList: tsListSemester,
      defaultDateRange: defaultDateRangeSemester,
    },
    {
      ...RAINFALL_SEMESTER_RELATIVE_ANOMALIES,
      timestepType: tsTypeSemester,
      timestepList: tsListSemester,
      defaultDateRange: defaultDateRangeSemester,
    },
    {
      ...RAINFALL_SEMESTER_AVG,
      timestepType: { type: 'SEMESTER_AVG', interval: 1 },
      timestepList: [{ initime: -2, timesteps: [5, 11] }],
      defaultDateRange: { from: 5, to: 11 },
    },
    {
      ...RAINFALL_1YEAR,
      timestepType: tsType1Year,
      timestepList: tsList1Year,
      defaultDateRange: defaultDateRangeYear,
    },
    {
      ...RAINFALL_1YEAR_ABSOLUTE_ANOMALIES,
      timestepType: tsType1Year,
      timestepList: tsList1Year,
      defaultDateRange: defaultDateRangeYear,
    },
    {
      ...RAINFALL_1YEAR_RELATIVE_ANOMALIES,
      timestepType: tsType1Year,
      timestepList: tsList1Year,
      defaultDateRange: defaultDateRangeYear,
    },
    {
      ...RAINFALL_1YEAR_AVG,
      timestepType: { type: '1YEAR_AVG', interval: 1 },
      timestepList: [{ initime: -2, timesteps: [0] }],
      defaultDateRange: { from: 0, to: 0 },
    },
    {
      ...MONTH_RAINY_DAYS,
      timestepType: tsTypeMonth,
      timestepList: tsListMonth,
      defaultDateRange: defaultDateRangeMonth,
    },
  ];

  const layers = removeUnwantedLayers(gpmLayers, config?.layerIdList ?? []);

  if (layers.length === 0)
    throw new Error(`No layers available for ${spec.model} model`);

  return {
    ...getBaseModelTemplate(config),
    ...GPM,
    layers,
    initime: layers[0].timestepList[0].initime,
    timestep: layers[0].defaultDateRange.to,
    selectedLayerId: layers[0].id,
    dateRange: layers[0].defaultDateRange,
    info: { ...GPM.info, latestUpdate: spec.latestUpdate },
    interpolate: 'bicubicInterpolationAvgRound',
  };
}
