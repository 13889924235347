import { createAction, props } from '@ngrx/store';

export const onTabChange = createAction(
  '[FeatureHistory] Tab Change',
  props<{ activeTab: string | null }>(),
);

export const modalClosed = createAction('[FeatureHistory] Modal Closed');

export const init = createAction(
  '[FeatureHistory] Init Modal Data',
  props<{
    selectedRecordId: string | null;
    activeTab: string | null;
  }>(),
);

export const setSelectedRecordId = createAction(
  '[FeatureHistory] Set Selected Record Id',
  props<{ selectedRecordId: string | null }>(),
);

export const setGalleryData = createAction(
  '[FeatureHistory] Set Media Gallery Data',
  props<{ recordId: string; mediaIndex: number }>(),
);
