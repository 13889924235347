import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

export type CoreMouseDragEvent = {
  initialPoint: { x: number; y: number };
  currentPoint: { x: number; y: number };
  delta: { x: number; y: number };
  evt: MouseEvent;
};

@Directive({
    selector: '[coreMouseDrag]',
    standalone: false
})
export class MouseDragDirective {
  @Output() onDrag: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Output() dragEvt = new EventEmitter<CoreMouseDragEvent>();
  private initialPoint: { x: number; y: number } | null = null;
  private overlay: HTMLDivElement | null = null;

  @HostListener('window:mousemove', ['$event'])
  private _onWindowMouseMove(evt: MouseEvent): void {
    if (this.initialPoint) {
      const delta = {
        x: evt.x - this.initialPoint.x,
        y: evt.y - this.initialPoint.y,
      };

      this.dragEvt.emit({
        initialPoint: this.initialPoint,
        currentPoint: { x: evt.x, y: evt.y },
        delta,
        evt,
      });
    }
  }

  @HostListener('window:mouseup', ['$event'])
  private _onMouseUp() {
    this.initialPoint = null;
  }

  @HostListener('mousedown', ['$event'])
  private _onMouseDown(evt: MouseEvent) {
    this.initialPoint = { x: evt.x, y: evt.y };
    // const root = document.getElementsByTagName('html')[0];
    // if (!root) throw new Error('root not found');
    // const overlay = document.createElement('div');
    // overlay.style.position = 'fixed';
    // overlay.style.top = '0';
    // overlay.style.left = '0';
    // overlay.style.width = '100dvw';
    // overlay.style.height = '100dvh';
    // overlay.style.zIndex = '9999';
    // overlay.style.background = 'red'; //'rgba(0,0,0,0.1)';
    // overlay.style.pointerEvents = 'none';
    // this.overlay = overlay;
    // overlay.addEventListener('mousemove', (evt) => {
    //   console.log('move', evt);
    // });
    // root.appendChild(overlay);
  }
}
