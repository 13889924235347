import * as fromFeatureHistory from './feature-history.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectFeatureRecords } from '../../map/+state/map.selectors';

export const selectFeatureHistoryState =
  createFeatureSelector<fromFeatureHistory.State>(
    fromFeatureHistory.FEATURE_HISTORY_FEATURE_KEY,
  );

export const selectSelectedRecordId = () =>
  createSelector(selectFeatureHistoryState, (state) => state.selectedRecordId);

export const selectActiveTab = () =>
  createSelector(selectFeatureHistoryState, (state) => state.activeTab);

/**
 * Select data for FeatureHistory modal.
 * Returns an object like: {
 *   form: Form,
 *   specific: FormSpecific,
 *   records: [Record, Record, Record] // they CAN be null!
 *   activeTab: string | null
 * }
 *
 * `records` property will have 3 item that can be Record or null, and their position in the
 * array will be used in modal. Examples:
 *    (left)    (center)     (right)
 *  [ Record,   Record,     null ]
 *  [ null,     Record,     null ]
 *  ....
 */
export const selectModalData = () =>
  createSelector(
    selectFeatureRecords(),
    selectSelectedRecordId(),
    selectActiveTab(),
    (featureRecords, selectedRecordId, activeTab) => {
      if (featureRecords === null || selectedRecordId === null) return null;
      const { form, specific, records } = featureRecords;
      const selectedRecordIndex = records.findIndex(
        (r) => r.recordId === selectedRecordId,
      );
      if (selectedRecordIndex === -1) return null;

      const centralRecord = records[selectedRecordIndex];
      const leftRecord =
        selectedRecordIndex > 0 ? records[selectedRecordIndex - 1] : null;
      const rightRecord =
        selectedRecordIndex < records.length - 1
          ? records[selectedRecordIndex + 1]
          : null;

      return {
        form,
        specific,
        records: [leftRecord, centralRecord, rightRecord],
        activeTab,
        hasRecordOnLeft: selectedRecordIndex > 0,
        hasRecordOnRight: selectedRecordIndex < records.length - 1,
      };
    },
  );
