import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, of, Subscription, switchMap, tap } from 'rxjs';
import * as ResourceSelectors from '../+state/resource.selectors';
import { Store } from '@ngrx/store';
import { Resource } from '3map-models';
import { AdminSelectors } from '../../+state';
import { map, withLatestFrom } from 'rxjs/operators';
import { ResourceService } from '../+state/resource.service';
import * as ResourceActions from '../+state/resource.actions';
import { NotificationService } from '@trim-web-apps/core';
import { isResourceFileOverLimit, maxResourceFileSizeLabel } from '../utils';

type View = {
  resource: Resource;
  blob: Blob | null;
};

@Component({
  selector: 'app-resource',
  template: `
    @if (isLoading()) {
      <div class="loading">Opening Resource...</div>
    } @else {
      <ng-container *ngIf="view$ && (view$ | async) as view">
        <div class="header">
          <app-section-label
            sectionLabel="Edit Resource"
            backRoute="resources"
          />
        </div>

        <div class="resource-name">
          <app-resource-name
            [resource]="view.resource"
            (nameChange)="onResourceNameChange($event, view.resource.filename)"
            (fileChange)="input.click()"
          />
        </div>

        <div class="resource-container">
          <app-resource-geojson
            *ngIf="view.resource.type === 'GEOJSON'"
            [view]="view.resource"
          />
          <app-resource-image
            *ngIf="view.resource.type === 'IMAGE'"
            [resource]="view.resource"
          />

          <div class="row map-wrapper">
            <app-resource-map
              [data]="view"
              (fileNotValid)="onInvalidFile(view)"
            />
          </div>
        </div>
      </ng-container>
    }

    <input #input type="file" (change)="onFileSelected(input)" />
  `,
  styles: [
    `
      :host {
        height: 100vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
      }

      .resource-name {
        padding: 0 0 25px 30px;
      }

      .resource-container {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        padding: 0 30px 30px 30px;
        overflow: auto;
      }

      .map-wrapper {
        flex: 1 1 auto;
      }

      input {
        display: none;
      }

      .loading {
        position: fixed;
        top: 30dvh;
        left: 50dvw;
        width: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    `,
  ],
  standalone: false,
})
export class ResourceComponent implements OnInit, OnDestroy {
  view$ = new BehaviorSubject<View | null>(null);
  isLoading = signal<boolean>(false);

  private sub?: Subscription;

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private router: Router,
    private resourceService: ResourceService,
    private notification: NotificationService,
  ) {}

  ngOnInit(): void {
    const { resourceId } = this.route.snapshot.params;
    this.isLoading.set(true);
    this.sub = this.store
      .select(ResourceSelectors.selectEntityResourceById(resourceId))
      .pipe(
        withLatestFrom(this.store.select(AdminSelectors.selectProject())),
        switchMap(([resource, project]) => {
          if (!resource || !project) return of(null);
          return this.resourceService
            .getResource(resource.filename, project.name)
            .pipe(map((blob) => ({ resource, blob })));
        }),
        tap((view) => {
          if (!view) this.router.navigate(['/admin/resources']);
          if (view?.blob && isResourceFileOverLimit(view.blob))
            this.notification.error(
              `File is too large (limit: ${maxResourceFileSizeLabel})`,
              5000,
            );
          this.isLoading.set(false);
        }),
      )
      .subscribe(this.view$);
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  onResourceNameChange(name: string, id: string): void {
    this.store.dispatch(ResourceActions.setResourceName({ id, name }));
  }

  onFileSelected(input: HTMLInputElement): void {
    const file = input.files?.item(0);
    const view = this.view$.getValue();
    if (!file || !view) return;
    if (isResourceFileOverLimit(file))
      return this.notification.error(
        `File is too large (limit: ${maxResourceFileSizeLabel})`,
        5000,
      );
    this.resourceService.setResourceFile(view.resource, file);
    this.view$.next({ ...view, blob: file });
    this.store.dispatch(ResourceActions.updateResourceFile());
  }

  onInvalidFile(view: View): void {
    this.resourceService.setResourceFile(view.resource, null);
  }
}
