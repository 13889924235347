import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthApiService } from '../../services/auth-api.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { CollaborationActions } from './index';

@Injectable()
export class CollaborationEffects {
  constructor(
    private actions: Actions,
    private authApi: AuthApiService,
  ) {}

  fetchCollaborations$ = createEffect(() =>
    this.actions.pipe(
      ofType(CollaborationActions.fetchCollaborations),
      switchMap(() =>
        this.authApi.getCollaborationList().pipe(
          map(({ collaborations }) =>
            CollaborationActions.fetchCollaborationsSuccess({ collaborations }),
          ),
          catchError(() => of(CollaborationActions.fetchCollaborationsError())),
        ),
      ),
    ),
  );

  createProject$ = createEffect(() =>
    this.actions.pipe(
      ofType(CollaborationActions.createNewProject),
      switchMap(({ project }) =>
        this.authApi.getCollaborationList().pipe(
          map(({ collaborations }) => {
            const projectList =
              collaborations.map((col) => col.projectName) || [];
            const projectNames = projectList.map((pName) =>
              pName.toLowerCase().trim(),
            );
            const name = project.name.toLowerCase().trim();
            if (!projectNames.includes(name)) {
              return project;
            }

            throw Error('Duplicate Project Name!');
          }),
        ),
      ),
      switchMap((project) => this.authApi.createProject(project)),
      map(() => CollaborationActions.createNewProjectSuccess()),
      catchError(() => of(CollaborationActions.createNewProjectError())),
    ),
  );

  createProjectSuccess$ = createEffect(() =>
    this.actions.pipe(
      ofType(CollaborationActions.createNewProjectSuccess),
      map(() => CollaborationActions.fetchCollaborations()),
    ),
  );
}
