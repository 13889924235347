import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  WeatherModel,
  WeatherModelSpecRequest,
} from '@trim-web-apps/weather-models';
import { Store } from '@ngrx/store';
import { WeatherActions, WeatherSelectors } from '../../+state';
import { WeatherItemData } from './weather-item-data';
import { Map } from 'mapbox-gl';
import { Dialog } from '@angular/cdk/dialog';
import { SettingsMobileComponent } from '../settings/settings-mobile.component';
import { WeatherItemConfig } from '../../weather.types';

@Component({
    selector: 'weather-core-item',
    template: `
    <ng-container *ngIf="map && weatherItemData$ && specRequest$">
      <ng-container *ngIf="specRequest$ | async as specReq">
        <weather-core-item-spec-request
          *ngIf="specReq"
          [specRequest]="specReq"
          [isMobile]="isMobile"
          (removeModel)="removeModel(specReq.modelId)"
          (reInitModel)="initModelAgain(specReq.modelId)"
        />
      </ng-container>
      <ng-container *ngIf="weatherItemData$ | async as weatherItemData">
        <weather-core-item-desktop
          *ngIf="!isMobile"
          [weatherItemData]="weatherItemData"
          [config]="config"
          (removeModel)="removeModel($event)"
          (toggleVisibility)="toggleVisibility($event)"
          (toggleChartVisibility)="toggleChartVisibility(weatherItemData)"
          (showInfo)="showInfo.emit($event)"
          (centerMap)="onCenterMap($event)"
        />
        <weather-core-item-mobile
          *ngIf="isMobile"
          [weatherItemData]="weatherItemData"
          [config]="config"
          (removeModel)="removeModel($event)"
          (toggleVisibility)="toggleVisibility($event)"
          (toggleChartVisibility)="toggleChartVisibility(weatherItemData)"
          (centerMap)="onCenterMap($event)"
          (showSettings)="showMobileSettings()"
        />
      </ng-container>
    </ng-container>
  `,
    standalone: false
})
export class WeatherItemComponent implements OnInit {
  @Input() weatherModelId?: string;
  @Input() showRemoveIcon?: boolean;
  @Input() isMobile?: boolean;
  @Input() map?: Map;
  @Input() config?: WeatherItemConfig;
  @Output() showInfo = new EventEmitter<WeatherModel>();

  weatherItemData$?: Observable<WeatherItemData | null>;
  specRequest$?: Observable<WeatherModelSpecRequest | null>;

  constructor(
    private store: Store,
    private dialog: Dialog,
  ) {}

  ngOnInit(): void {
    if (!this.weatherModelId) return;
    this.weatherItemData$ = this.store
      .select(WeatherSelectors.selectWeatherItemData(this.weatherModelId))
      .pipe(
        map((weatherItemData) => {
          if (!weatherItemData) return null;
          const showRemoveIcon = !this.isMobile;
          const showCenterMap = !!this.map;
          return { ...weatherItemData, showRemoveIcon, showCenterMap };
        }),
      );
    this.specRequest$ = this.store.select(
      WeatherSelectors.selectSpecById(this.weatherModelId),
    );
  }

  removeModel(modelId: string): void {
    this.store.dispatch(WeatherActions.removeWeatherModel({ modelId }));
  }

  toggleVisibility(modelId: string): void {
    this.store.dispatch(WeatherActions.toggleModelVisibility({ modelId }));
  }

  toggleChartVisibility(data: WeatherItemData): void {
    const modelId = data.model.id;
    const isChartEnabled = data.model.chartEnabled;

    const action = isChartEnabled
      ? WeatherActions.setChartDisabled({ modelId })
      : WeatherActions.setChartEnabled({ modelId });
    this.store.dispatch(action);
  }

  onCenterMap(model: WeatherModel): void {
    if (!this.map) console.warn('Map instance not provided');
    const sw: [number, number] = [model.bbox[3][0], model.bbox[3][1]];
    const ne: [number, number] = [model.bbox[1][0], model.bbox[1][1]];

    this.map?.fitBounds([sw, ne]);
    // this.map?.fitBounds([
    //   [43.50585, 5.615985], // southwestern corner of the bounds
    //   [43.50585, 5.615985], // northeastern corner of the bounds
    // ]);
  }

  initModelAgain(modelId: string): void {
    this.store.dispatch(WeatherActions.initWeatherModel({ modelId }));
  }

  showMobileSettings(): void {
    if (!this.weatherModelId) return;
    const data = this.weatherModelId;
    this.dialog.open(SettingsMobileComponent, {
      width: '100vw',
      height: '100vh',
      panelClass: 'settings-wrapper',
      data,
      autoFocus: false,
    });
  }
}
