import { Component, Input } from '@angular/core';
import { FieldList } from '3map-models';
import { FieldTypeBaseComponent } from '../../../models/FieldTypeBaseComponent';

@Component({
  selector: 'app-field-list',
  template: `
    <app-field-name [fieldType]="fieldType"></app-field-name>
    <div class="disabled-select text-ellipsis" *ngIf="!allowEdit">
      {{ fieldList?.text }}
    </div>

    <ui-select
      *ngIf="allowEdit"
      [items]="fieldType.options ?? []"
      [noneOptionLabel]="noneOptionLabel ?? ''"
      [itemSelected]="fieldList?.text"
      (itemSelectedChange)="onItemSelected($event)"
    />
    <div class="error" *ngIf="showError">Required field</div>
  `,
  styleUrls: ['../../field-type.base.component.scss'],
  standalone: false,
})
export class FieldListComponent extends FieldTypeBaseComponent {
  @Input() fieldList: FieldList | undefined;
  noneOptionLabel?: string;
  showError?: boolean;

  onItemSelected(item: string | null): void {
    this.showError = false;

    if (this.fieldType.required && item === null) {
      this.showError = true;
      return;
    }

    const field: FieldList | null =
      item !== null ? { text: item, type: 'LIST' } : null;

    this.fieldChange.emit({
      fieldType: this.fieldType,
      field,
    });
  }
}
