import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  duplicateSpecific,
  onDuplicateSpecific,
} from './form-specific.actions';
import { map, switchMap, take } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromMarkerStyle from './marker-style.selectors';
import { cloneSpecific } from './form-specific.functions';

@Injectable({ providedIn: 'root' })
export class FormSpecificEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
  ) {}

  onDuplicateSpecific$ = createEffect(() =>
    this.actions$.pipe(
      ofType(onDuplicateSpecific),
      switchMap(({ formId, specific }) =>
        combineLatest([
          this.store.pipe(select(fromMarkerStyle.selectEntities())),
          of(formId),
          of(specific),
        ]).pipe(take(1)),
      ),
      map(([markerStyleDict, formId, specific]) => {
        const imgBase64List = markerStyleDict[specific.id]?.imgBase64List || [];
        const newSpecific = cloneSpecific(specific);
        return duplicateSpecific({
          formId,
          specific: newSpecific,
          icons: [{ specificId: newSpecific.id, imgBase64List }],
        });
      }),
    ),
  );
}
