import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { Media } from '3map-models';
import { faTrash, IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-record-editor-media-item',
  template: `
    <div class="media-item-wrapper">
      <div class="remove-overlay" *ngIf="showRemoveButton">
        <div
          class="remove-item"
          (click)="showConfirm = !showConfirm"
          *ngIf="!showConfirm"
        >
          <fa-icon [icon]="iconRemove"></fa-icon>
        </div>

        <div class="remove-item-confirm" *ngIf="showConfirm">
          <div class="confirm-label">Remove this item?</div>
          <ui-btn
            (btnClick)="removeMedia.emit(media)"
            [type]="'fill'"
            label="Confirm"
          ></ui-btn>
        </div>
      </div>
      <div class="media-card-wrapper">
        <app-media-card
          [base64Img]="base64Img"
          [media]="media"
          [projectName]="projectName"
        ></app-media-card>
      </div>
    </div>
  `,
  styles: [
    `
      .media-item-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
      }

      .remove-overlay {
        position: absolute;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.8);
        border: 1px solid var(--border);
        border-radius: 10px;
        width: 100%;
        height: 100%;
        z-index: 1;
        color: var(--primary-color);
        cursor: pointer;
      }

      .remove-overlay,
      .remove-item {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .remove-item {
        font-size: 1.5em;
        width: 100%;
        height: 100%;
      }

      .remove-item-confirm {
        display: grid;
        grid-template-rows: 1fr 1fr;
        justify-items: center;
      }

      .remove-item-confirm ui-btn {
        width: 110px;
      }

      .media-card-wrapper {
        border: 1px solid var(--border);
        border-radius: 10px;
        overflow: hidden;
        height: 220px;
        width: 220px;
      }
    `,
  ],
  standalone: false,
})
export class RecordEditorMediaItemComponent {
  @Input() media: Media | undefined;
  @Input() projectName: string | undefined;
  @Input() base64Img: string | undefined;
  @Output() removeMedia: EventEmitter<Media> = new EventEmitter<Media>();
  iconRemove: IconDefinition = faTrash;
  showConfirm: boolean | undefined;
  showRemoveButton: boolean | undefined;

  @HostListener('mouseover') onMouseOver() {
    this.showRemoveButton = true;
  }

  @HostListener('mouseleave') onMouseOut() {
    this.showRemoveButton = false;
    this.showConfirm = false;
  }
}
