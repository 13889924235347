// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/* eslint-disable max-len */

export const environment = {
  production: false,

  // INJECTION TOKENS
  LSK_APP: 'project3map-app',
  LSK_AUTH: 'project3map-auth',
  LSK_GRID_STATE: 'project3map-grid-filters',
  LSK_MAP: 'project3map-map',

  BRANCH: 'dev',
  API_URL: 'https://3map-dev-server.trimweb.it',

  // LICENCES
  LICENSE: `Using_this_AG_Grid_Enterprise_key_( AG-046194 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( TRIM SRL )_is_granted_a_( Single Application )_Developer_License_for_the_application_( 3map )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( 3map )_need_to_be_licensed___( 3map )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 3 September 2024 )____[v2]_MTcyNTMxODAwMDAwMA==2c053d6ea019eeba3bbe4a079e667765`,
  //    'CompanyName=TRIM s.r.l.,LicensedApplication=3map,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-011335,ExpiryDate=21_October_2021_[v2]_MTYzNDc3MDgwMDAwMA==ab204b07af6a0f4983300d840cd17a2f',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
