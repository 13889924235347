import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthApiService } from '../../services/auth-api.service';
import { passwordsMatchValidator } from '../../utils/input.validators';
import { parseJwt } from '@trim-web-apps/core';

@Component({
  selector: 'app-change-password',
  template: `
    <div class="title">Change password</div>
    <ng-container *ngIf="!invalidToken">
      <form [formGroup]="form">
        <app-input-password
          (formControl)="onFormControlReady($event, 'password')"
        ></app-input-password>
        <app-input-password
          (formControl)="onFormControlReady($event, 'passwordConfirm')"
        ></app-input-password>

        <div class="error-label" *ngIf="(passwordMismatch$ | async) !== null">
          {{ passwordMismatch$ | async }}
        </div>

        <ui-btn
          class="btn-change-pwd"
          [fullWidth]="true"
          [disabled]="!form.valid || pendingRequest"
          [label]="pendingRequest ? 'Changing password...' : 'Change password'"
          (btnClick)="onSubmit()"
        />
      </form>
    </ng-container>

    <div class="error-box" *ngIf="(updateSuccess$ | async) === false">
      <p>Can not update your password.</p>
      <p>Your link may be expired.</p>
      <ui-btn
        routerLink="/auth/password-recovery"
        label="Request new password"
        [fullWidth]="true"
        class="btn-new-request"
      />
    </div>

    <div *ngIf="invalidToken">
      <div class="error-box invalid-token">
        <p>Mmmh something wrong here...</p>
        <p>Please make sure you copy/paste the full link you've received.</p>
        <ui-btn
          routerLink="/auth/password-recovery"
          label="Request new password"
          [fullWidth]="true"
          class="btn-change-pwd"
        />
      </div>
    </div>
  `,
  styles: [
    `
      form {
        display: flex;
        flex-direction: column;
      }

      button {
        margin-top: 20px;
      }

      .error-label {
        text-align: center;
        font-size: 0.8em;
        color: var(--error-color);
      }

      .title {
        margin-bottom: 30px;
        text-align: right;
        font-size: 1.5em;
      }

      .error-box {
        display: flex;
        flex-direction: column;
      }
    `,
  ],
  standalone: false,
})
export class ChangePasswordComponent implements OnInit {
  form: UntypedFormGroup;
  pendingRequest: boolean;
  invalidToken: boolean | undefined;
  updateSuccess$: Observable<boolean> | undefined;
  passwordMismatch$: Observable<string | null> | undefined;
  usernameFromToken: string | undefined;
  private token: string | undefined;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private authApi: AuthApiService,
    private route: ActivatedRoute,
  ) {
    this.pendingRequest = false;
    this.form = this.fb.group({}, { validators: [passwordsMatchValidator] });
    this.passwordMismatch$ = this.form.valueChanges.pipe(
      map(() => this.form.errors?.['passwordMismatch']?.errorMsg),
    );
  }

  ngOnInit(): void {
    const tokenParam = this.route.snapshot.paramMap.get('token');
    if (tokenParam === null) {
      this.router.navigate(['/auth']);
    } else {
      const jwt = parseJwt(tokenParam);
      // attempt to get username from jwt
      if (jwt && jwt.hasOwnProperty('username')) {
        this.token = tokenParam;
        this.usernameFromToken = jwt.username;
      } else {
        this.invalidToken = true;
      }
    }
  }

  /**
   * Add {@link FormControl} to {@link FormGroup} with the given `name`
   */
  onFormControlReady(fc: UntypedFormControl, name: string): void {
    this.form.addControl(name, fc);
  }

  /**
   * Check if `form` and `token` are valid, then perform an API request to update user's password.
   * On successful update `navigate` to `auth` (login page).
   * On error set `updateSuccess` to false.
   */
  onSubmit(): void {
    const formValid = this.form.valid;
    const password = this.form.get('password')?.value;

    if (!this.usernameFromToken || !this.token) {
      this.invalidToken = true;
      return;
    }

    if (!formValid || !password) return;

    this.pendingRequest = true;
    this.updateSuccess$ = this.authApi
      .changePassword(this.usernameFromToken, password, this.token)
      .pipe(
        catchError(() => of(false)),
        tap((updated) => {
          this.pendingRequest = false;
          if (updated) {
            this.router.navigate(['/auth'], {
              queryParams: { passwordUpdated: true },
            });
          }
        }),
      );
  }
}
