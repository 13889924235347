import { createAction, props } from '@ngrx/store';
import { Form } from '3map-models';

export const createForm = createAction(
  '[ Project ] Create Form',
  props<{ form: Form }>(),
);

export const onDuplicateForm = createAction(
  '[ Project ] On Duplicate Form',
  props<{ originForm: Form }>(),
);

export const addDuplicatedForm = createAction(
  '[ Project ] Add Duplicated Form',
  props<{
    form: Form;
    icons: { specificId: string; imgBase64List: string[] }[];
  }>(),
);

export const duplicateFormError = createAction(
  '[ Project ] Duplicate Form Error',
);

export const updateForm = createAction(
  '[ Project ] Update Form',
  props<{ form: Form }>(),
);

export const removeForm = createAction(
  '[ Project ] Remove Form',
  props<{ form: Form }>(),
);
