import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

export class CanDeactivateGuard {
  canDeactivate(
    component: CanComponentDeactivate,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    return component.canDeactivate();
  }
}

export interface CanComponentDeactivate {
  canDeactivate: () => boolean;
}
