import { Component, Inject } from '@angular/core';
import { Form, ProjectUser } from '3map-models';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

export interface UserDialogData {
  formList: Form[];
  userList: ProjectUser[] | null;
}

@Component({
  selector: 'app-user-dialog',
  template: `
    <div class="app-user-dialog-wrapper">
      <app-user-search *ngIf="!data" (userList)="onUserListSelected($event)" />
      <app-user-forms *ngIf="data" [data]="data" (saved)="dialogRef.close()" />
    </div>
  `,
  styles: [
    `
      .app-user-dialog-wrapper {
        width: 390px;
        overflow: hidden;
        padding: 20px;
        background-color: var(--bg-color);
      }
    `,
  ],
  standalone: false,
})
export class UserDialogComponent {
  data?: {
    userList: ProjectUser[];
    formList: Form[];
  };

  constructor(
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public dialogData: UserDialogData,
  ) {
    if (dialogData.userList) {
      this.data = {
        userList: dialogData.userList,
        formList: dialogData.formList,
      };
    }
  }

  onUserListSelected(userList: ProjectUser[]): void {
    this.data = {
      userList,
      formList: this.dialogData.formList,
    };
  }
}
