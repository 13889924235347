import { Component, Input, OnInit } from '@angular/core';
import { QuestionLogic } from '../../../../../../../../libs/project-core/src/lib/specific/models/FormSpecificLogic';
import { FieldType } from '3map-models';

@Component({
  selector: 'trim-web-apps-logic-questions-picker',
  template: ` <p>logic-questions-picker works!</p> `,
  styles: [],
  standalone: false,
})
export class LogicQuestionsPickerComponent implements OnInit {
  @Input() question: FieldType | undefined;
  @Input() questionLogic: QuestionLogic | undefined;

  constructor() {}

  ngOnInit(): void {}
}
