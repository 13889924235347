import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
// import { AdminActions } from '../../+state';
import * as AdminActions from '../../+state/admin.actions';
import {
  createResource,
  removeResource,
  setResourceBbox,
  setResourceName,
  setResourceStyle,
} from './resource.actions';
import { Resource } from '3map-models';

export const ADMIN_RESOURCE_FEATURE_KEY = 'admin-resource';
export type State = EntityState<Resource>;
export const adapter = createEntityAdapter<Resource>({
  selectId: (res: Resource) => res.filename,
});
export const initialState = adapter.getInitialState();

const resourceReducer = createReducer(
  initialState,
  on(
    AdminActions.initProjectError,
    AdminActions.close,
    (): State => ({ ...initialState }),
  ),
  on(AdminActions.initProjectSuccess, (state, { project }): State => {
    return adapter.setAll(project.resourceList, state);
  }),
  on(
    createResource,
    (state, { resource }): State => adapter.addOne(resource, state),
  ),
  on(removeResource, (state, { id }): State => adapter.removeOne(id, state)),
  on(
    setResourceName,
    (state, { id, name }): State =>
      adapter.updateOne({ id, changes: { name } }, state),
  ),
  on(
    setResourceStyle,
    (state, { id, style }): State =>
      adapter.updateOne({ id, changes: { style } }, state),
  ),
  on(
    setResourceBbox,
    (state, { id, boundingBox }): State =>
      adapter.updateOne({ id, changes: { boundingBox } }, state),
  ),
  // on(createResource, (state, { entity }) => {
  //   const resNameList = Object.keys(state.entities)
  //     .map((k) => state.entities[k])
  //     .filter(notNullOrUndefined)
  //     .map((res) => res.name);
  //   const resourceExists = resNameList.includes(entity.name);
  //   return resourceExists ? state : adapter.addOne(entity, state);
  // }),
  // on(setResourceData, (state, { id, resourceData }) => {
  //   const entity = state.entities[id];
  //   if (!entity) return state;
  //   const filename =
  //     entity.type === 'GEOJSON' ? `${uuid.v4()}.geojson` : `${uuid.v4()}.png`;
  //   return adapter.updateOne(
  //     { id, changes: { resourceData, filename } },
  //     state
  //   );
  // }),
);

export function reducer(state: State | undefined, action: Action): State {
  return resourceReducer(state, action);
}
