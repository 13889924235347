import { Injectable } from '@angular/core';
import { Media } from '3map-models';
import { blobImageToBase64, notNullOrUndefined } from '@trim-web-apps/core';
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UploadMediaService {
  private mediaToBeUploaded: { [mediaFilename: string]: Blob } = {};

  setMediaForUpload(uploadMedia: { media: Media; blob: Blob }): void {
    this.mediaToBeUploaded[uploadMedia.media.fileName] = uploadMedia.blob;
  }

  getMediaBlob(media: Media): { media: Media; blob: Blob } | null {
    const blob = this.mediaToBeUploaded[media.fileName];
    return blob ? { media, blob } : null;
  }

  getMediaAsBase64(media: Media): Observable<{ base64: string; media: Media }> {
    return of(this.getMediaBlob(media)).pipe(
      switchMap((blob) => {
        return blob === null
          ? of(null)
          : fromPromise(blobImageToBase64(blob.blob)).pipe(
              map((base64) => ({ base64, media })),
            );
      }),
      filter(notNullOrUndefined),
    );
  }

  getMediaListAsBase64(
    mediaList: Media[],
  ): Observable<{ base64: string; media: Media }[]> {
    if (mediaList.length === 0) return of([]);
    return forkJoin(mediaList.map((media) => this.getMediaAsBase64(media)));
  }
}
