import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EditorTab } from '../../models/EditorTab';

@Component({
  selector: 'app-record-editor-bottom-actions',
  template: `
    <ng-container *ngIf="activeTab && request">
      <ui-btn
        *ngIf="activeTab.type !== 'MEDIA'"
        (click)="next.emit()"
        label="Next"
      />

      <ng-container *ngIf="activeTab.type === 'MEDIA'">
        <div class="invalid-record" *ngIf="!recordValid">
          <p>Record is not valid</p>
          <p>Please review questions (e.g. required fields)</p>
        </div>

        <ng-container *ngIf="recordValid">
          <ui-btn
            *ngIf="!request.pending && request.error === null"
            (click)="confirm.emit()"
            label="Confirm"
          />

          <ui-btn *ngIf="request.pending" label="Uploading..." />

          <ui-btn
            *ngIf="!request.pending && request.error !== null"
            (click)="confirm.emit()"
            label="Retry"
          />
        </ng-container>
      </ng-container>
    </ng-container>
  `,
  styles: [
    `
      .invalid-record {
        color: var(--error-color);
        text-align: center;
      }
    `,
  ],
  standalone: false,
})
export class RecordEditorBottomActionsComponent {
  @Input() activeTab?: EditorTab;
  @Input() request?: { pending: boolean; error: string | null } | null;
  @Input() recordValid?: boolean;
  @Output() next = new EventEmitter<void>();
  @Output() confirm = new EventEmitter<void>();
}
