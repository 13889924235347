import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'weatherLegendLabel',
    standalone: false
})
export class WeatherLegendLabelPipe implements PipeTransform {
  transform(value: number, layerId: string): string {
    switch (value) {
      case Number.NEGATIVE_INFINITY:
        return '--';
      case Number.POSITIVE_INFINITY:
        return '++';
      case 9999:
        return '++';
      default:
        if (!layerId.includes('anomalies')) return value.toString();
        return value > 0 ? `+${value}` : value.toString();
    }
  }
}
