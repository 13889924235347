import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Form, FormSpecific, Record } from '3map-models';
import { getRecordQuestions } from '../../../project/project.utils';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-record-card-compact[record][form][specific]',
  template: `
    <div
      class="record-card-compact-wrapper"
      [class.is-first]="isFirst"
      [class.is-last]="isLast"
      (click)="recordSelected.emit()"
      *ngIf="record && specific && form"
    >
      <div class="header">
        <div class="icon">
          <app-record-icon
            [widthPx]="32"
            [record]="record"
            [form]="form"
          ></app-record-icon>
        </div>
        <div class="metadata fill-remaining-space">
          <div class="date">
            {{ record.datetimeUtc | date: 'dd LLL YYYY - HH:mm ZZZZ' }}
          </div>
          <div class="username text-ellipsis">{{ record.username }}</div>
        </div>
        <div class="menu-toggle" (click)="$event.stopPropagation()">
          <ui-dropdown-icon
            *ngIf="
              user?.role === 'ADMIN' ||
              user?.role === 'MOD' ||
              user?.username === record.username
            "
            [icon]="menuIcon"
            [itemList]="['Edit', 'Delete']"
            (itemSelected)="onEditDeleteSelected($event)"
          />
        </div>
      </div>
      <div class="questions">
        <div class="question" *ngFor="let question of getQuestions()">
          <div class="name text-ellipsis">{{ question.fieldTypeName }}:</div>
          <div class="value text-ellipsis">{{ question.answer }}</div>
        </div>
        <app-media-thumbnail-preview [mediaViewList]="record" />
      </div>
    </div>
  `,
  styles: [
    `
      .record-card-compact-wrapper {
        background-color: #f0f0f2;
        /* height should match the "itemSize" props in virtual-scroll-viewport */
        height: 200px;
        width: 330px;
        border-bottom: 1px solid #e0e0e0;
      }

      .is-first {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      .is-last {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      .header {
        padding: 20px 20px 10px 20px;
      }

      .questions {
        margin: 10px 20px 20px 20px;
      }

      .header,
      .question {
        display: flex;
        align-items: center;
      }

      .metadata {
        font-size: 0.8em;
        padding: 0 10px;
      }

      .date,
      .name {
        font-weight: bold;
      }

      .name {
        margin-right: 5px;
      }

      .menu-toggle {
        font-size: 1.3em;
      }

      .record-card-compact-wrapper:hover {
        background-color: #e7e7e7;
        cursor: pointer;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class RecordCardCompactComponent {
  @Input() record: Record | undefined;
  @Input() form: Form | undefined;
  @Input() specific: FormSpecific | undefined;
  @Input() user?: { username: string; role: 'ADMIN' | 'MOD' | 'USER' };
  @Input() isFirst: boolean | undefined;
  @Input() isLast: boolean | undefined;
  @Output() recordDelete = new EventEmitter<Record>();
  @Output() recordEdit = new EventEmitter<Record>();
  @Output() recordSelected = new EventEmitter<Record>();

  menuIcon = faEllipsisVertical;

  // TODO avoid calling this too often
  getQuestions(): { fieldTypeName: string; answer: string }[] {
    if (!this.record || !this.specific) return [];
    return getRecordQuestions(this.record, this.specific);
  }

  onEditDeleteSelected(evt: string | null): void {
    if (!this.record) return;
    if (evt === 'Delete') this.recordDelete.emit(this.record);
    if (evt === 'Edit') this.recordEdit.emit(this.record);
  }
}
