import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ChartData } from '@trim-web-apps/weather-models';

@Component({
    selector: 'weather-core-chart-actions',
    template: `
    <div class="chart-actions">
      <div class="menu-bar" cdkMenuBar>
        <div [cdkMenuTriggerFor]="menuMode" class="trigger" cdkMenuItem>
          Mode
        </div>
        <div [cdkMenuTriggerFor]="menuData" class="trigger" cdkMenuItem>
          Data
        </div>
      </div>
      <div (click)="requestCsvDownload.emit()" class="trigger">
        Download CSV
      </div>
    </div>

    <ng-template #menuMode>
      <div class="menu-content" cdkMenu>
        <div
          cdkMenuItem
          class="menu-item"
          *ngFor="let mode of chartModes"
          (click)="onSelectChartMode(mode)"
        >
          {{ mode }}
        </div>
      </div>
    </ng-template>

    <ng-template #menuData>
      <div class="menu-content" cdkMenu>
        <div class="menu-item" *ngFor="let data of chartDataList" cdkMenuItem>
          <ui-checkbox
            (click)="onDataVisibilityClick(data)"
            [label]="data.legend"
            [checked]="data.visible"
          >
          </ui-checkbox>
        </div>
      </div>
    </ng-template>
  `,
    styles: [
        `
      .chart-actions,
      .menu-bar {
        display: flex;
        cursor: pointer;
      }

      .trigger {
        margin-right: var(--spacing-3);
      }
    `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ChartActionsComponent {
  @Input() chartDataList: ChartData[] = [];
  @Output() chartModeChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() requestCsvDownload: EventEmitter<void> = new EventEmitter<void>();
  @Output() chartDataVisibilityChanged: EventEmitter<{
    data: ChartData;
    visibility: boolean;
  }> = new EventEmitter<{ data: ChartData; visibility: boolean }>();
  chartModes: string[] = ['Compact', 'Standard', 'Combined'];
  chartModeSelected = 'Compact';

  onSelectChartMode(mode: string): void {
    this.chartModeSelected = mode;
    this.chartModeChanged.emit(this.chartModeSelected);
  }

  onDataVisibilityClick(data: ChartData): void {
    this.chartDataVisibilityChanged.emit({
      data: data,
      visibility: !data.visible,
    });
  }
}
