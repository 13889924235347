import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
} from '@angular/core';
import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { TooltipComponent } from './tooltip.component';

@Directive({
    selector: '[uiTooltip]',
    standalone: false
})
export class TooltipDirective implements OnDestroy {
  @Input('uiTooltip') text?: string;

  private overlayRef: OverlayRef;
  private timer?: number;

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef
  ) {
    this.overlayRef = this.overlay.create({});
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
        },
      ]);
    this.overlayRef = this.overlay.create({ positionStrategy });
  }

  ngOnDestroy(): void {
    this.hide();
  }

  @HostListener('mouseenter')
  show() {
    clearTimeout(this.timer);
    this.timer = window.setTimeout(() => {
      const portal = new ComponentPortal(TooltipComponent);
      const tooltipRef = this.overlayRef.attach(portal);
      tooltipRef.instance.text = this.text;
    }, 300);
  }

  @HostListener('mouseleave')
  hide() {
    clearTimeout(this.timer);
    this.overlayRef.detach();
  }
}
