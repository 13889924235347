import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { MapMouseEvent } from 'mapbox-gl';
import { MapStore } from '../map-store.service';
import { getMapPlugins } from '../map-utils/map.utils';
import { createMap } from '../map-utils/map.builder';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MapboxPlugin, MapConfig } from '../map-utils/MapConfig';
import { MapService } from '../map.service';
import {
  DEFAULT_MAP_STYLE,
  MAP_STYLE_LIST,
  MapStyleName,
} from '../map-utils/map.styles';

@Component({
  selector: 'map-core',
  template: `
    <div #mapContainer class="map"></div>
    <!--    <ng-template #mapContextMenuTemplate>-->
    <!--      <lib-map-context-menu [lat]='selectedLat'-->
    <!--                            [lng]='selectedLng'-->
    <!--                            [forms]='activeForms$ | async'-->
    <!--                            (addRecord)='addRecord.emit($event)'>-->
    <!--      </lib-map-context-menu>-->
    <!--    </ng-template>-->
  `,
  styles: [
    `
      :host,
      .map {
        width: 100%;
        height: 100%;
      }

      ::ng-deep .mapboxgl-canvas {
        outline: none;
      }
    `,
  ],
  standalone: false,
})
export class MapBaseComponent implements AfterViewInit, OnDestroy {
  @Input() center: { lng: number; lat: number } | undefined;
  @Input() zoom: number | undefined;
  @Input() plugins: MapboxPlugin[] | undefined;
  @Input() maxBounds: mapboxgl.LngLatBoundsLike | undefined;
  @Input() controlPosition:
    | 'top-right'
    | 'top-left'
    | 'bottom-right'
    | 'bottom-left'
    | undefined;
  @Input() style?: MapStyleName;
  @Output() mapReady: EventEmitter<mapboxgl.Map>;
  @Output() mapContainerRef: EventEmitter<ViewContainerRef>;
  @Output() mapClick: EventEmitter<MapMouseEvent>;
  @Output() mapStyleChanged: EventEmitter<string>;
  @Output() mapRightClick: EventEmitter<MapMouseEvent>;
  @ViewChild('mapContainer') mapContainer: ElementRef | undefined;
  // @ViewChild('mapContextMenuTemplate', { static: false }) mapContextMenuTemplateRef: TemplateRef<any>;
  // @Input() disableContextMenu: boolean;

  private map: mapboxgl.Map | undefined;
  private mapStyleName: string | undefined;
  private styleSub: Subscription | undefined;

  constructor(
    private mapService: MapService,
    private mapStore: MapStore,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.mapClick = new EventEmitter<MapMouseEvent>();
    this.mapReady = new EventEmitter<mapboxgl.Map>();
    this.mapContainerRef = new EventEmitter<ViewContainerRef>();
    this.mapStyleChanged = new EventEmitter<string>();
    this.mapRightClick = new EventEmitter<mapboxgl.MapMouseEvent>();
  }

  ngAfterViewInit() {
    this.mapService.removeMap();

    if (this.mapContainer === undefined) {
      throw Error('[lib-map] Map container not found');
    }

    this.map = createMap(this.mapContainer.nativeElement, this.getMapConfig());
    this.map
      .on('load', () => this.handleMapLoad())
      .on('click', (e) => this.mapClick.emit(e))
      // .on('contextmenu', (evt: mapboxgl.MapMouseEvent) => this.showMapContextMenu(evt))
      .on('moveend', () => this.onMoveEnd());

    this.styleSub = fromEvent(this.map, 'styledata')
      .pipe(debounceTime(500))
      .subscribe(() => {
        const newStyleId = this.map?.getStyle()?.name;
        if (newStyleId && this.mapStyleName !== newStyleId) {
          this.mapStyleName = newStyleId;
          this.mapStyleChanged.emit(this.mapStyleName);

          const styleForStore =
            MAP_STYLE_LIST.find((s) => s.title === newStyleId)?.title ||
            DEFAULT_MAP_STYLE;

          this.mapStore.patchState({ style: styleForStore });
        }
      });
  }

  ngOnDestroy() {
    this.styleSub?.unsubscribe();
  }

  private getMapConfig(): MapConfig {
    const fromStore = this.mapStore.getValues();
    const zoom = this.zoom || fromStore.zoom;
    const center = this.center || fromStore.center;
    const plugins = this.plugins || getMapPlugins();
    const maxBounds = this.maxBounds || null;
    const controlPosition = this.controlPosition
      ? this.controlPosition
      : 'top-right';
    const style = this.style || fromStore.style || DEFAULT_MAP_STYLE;
    return { center, zoom, plugins, maxBounds, controlPosition, style };
  }

  private handleMapLoad(): void {
    if (this.map) {
      this.mapService.map = this.map;
      this.mapStyleName = this.map.getStyle()?.name;
      this.mapReady.emit(this.map);
      this.mapContainerRef.emit(this.viewContainerRef);
      this.mapStyleChanged.emit(this.mapStyleName);
    }
  }

  private onMoveEnd(): void {
    if (this.map) {
      const { lng, lat } = this.map.getCenter();
      const zoom = this.map.getZoom();
      this.mapStore.patchState({ center: { lng, lat }, zoom });
    }
  }
}
