import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { WebHook } from '3map-models';
import { Action, createReducer, on } from '@ngrx/store';
import { removeWebHook, upsertWebHook } from './webhook.actions';
// import { AdminActions } from '../../+state';
import * as AdminActions from '../../+state/admin.actions';

export const ADMIN_WEB_HOOK_FEATURE_KEY = 'admin-web-hook';

export type State = EntityState<WebHook>;

export const adapter = createEntityAdapter<WebHook>({
  selectId: (webhook) => webhook.name,
});

const initialState = adapter.getInitialState();

const webHookReducer = createReducer(
  initialState,
  on(
    AdminActions.initProjectError,
    AdminActions.close,
    (): State => ({ ...initialState }),
  ),
  on(AdminActions.initProjectSuccess, (state, { project }) => {
    return project.webHookList
      ? adapter.setAll(project.webHookList, state)
      : state;
  }),
  on(upsertWebHook, (state, { webHook }) => {
    return adapter.upsertOne(webHook, state);
  }),
  on(removeWebHook, (state, { webHook }) => {
    return adapter.removeOne(webHook.name, state);
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return webHookReducer(state, action);
}
