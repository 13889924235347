import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
    selector: 'ui-switch',
    template: `
    <div class="container" (click)="switchClick.emit(!enabled)">
      <div class="switch" [class.enabled]="enabled">
        <div class="slider"></div>
      </div>
      {{ label }}
    </div>
  `,
    styles: [
        `
      .container {
        display: flex;
        align-items: center;
        gap: var(--spacing-2);
        cursor: pointer;
        width: fit-content;
      }
      .switch {
        width: calc(18px + 18px);
        height: 18px;
        background-color: var(--border);
        border-radius: 34px;
      }
      .slider {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background-color: var(--bg-color);
        -webkit-transition: 0.1s;
        transition: 0.1s;
        border: 1px solid var(--border);
      }

      .switch.enabled {
        background-color: var(--primary-color);
      }

      .switch.enabled > .slider {
        -webkit-transform: translateX(18px);
        -ms-transform: translateX(18px);
        transform: translateX(18px);
        border: 1px solid var(--primary-color);
      }
    `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SwitchComponent {
  @Input() enabled?: boolean;
  @Input() label?: string;
  @Output() switchClick = new EventEmitter<boolean>();
}
