import { Component } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as ResourceSelectors from '../+state/resource.selectors';
import * as ResourceActions from '../+state/resource.actions';
import { Dialog } from '@angular/cdk/dialog';
import { Resource } from '3map-models';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ResourceCreateComponent } from './resource-create.component';
import { ResourceService } from '../+state/resource.service';

@Component({
  selector: 'app-resource-list',
  template: `
    <div class="header">
      <app-section-label sectionLabel="Resources">
        <ui-btn
          label="Add Resource"
          (click)="onAdd()"
          [icon]="iconAdd"
          type="border"
        />
      </app-section-label>
    </div>
    <div class="resources-list">
      <app-resource-thumbnail
        class="resource-item"
        *ngFor="let res of resources$ | async"
        [resource]="res"
        (removeResource)="removeResource(res)"
        (resourceSelected)="resourceSelected(res)"
      />
    </div>
  `,
  styles: [
    `
      :host {
        height: 100vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
      }

      .resources-list {
        overflow: auto;
        display: flex;
        flex-wrap: wrap;
        margin: 25px;
      }

      .resource-item {
        width: 350px;
        margin: 5px;
      }
    `,
  ],
  standalone: false,
})
export class ResourceListComponent {
  iconAdd = faPlus;
  resources$: Observable<(Resource & { valid: boolean })[]>;

  constructor(
    private dialog: Dialog,
    private router: Router,
    private store: Store,
    private resourceSrv: ResourceService,
  ) {
    this.resources$ = this.store
      .select(ResourceSelectors.selectSortedResourceList())
      .pipe(
        map((resources) => {
          return resources.map((res) => {
            const localFile = this.resourceSrv.getLocalResource(res.filename);

            // local file is null: user provided an invalid file
            if (localFile === null) return { ...res, valid: false };

            // local file is undefined: user has not provided a file (it's an existing resource)
            if (localFile === undefined) return { ...res, valid: true };

            // local file is then a Blob: user provided a valid file
            return { ...res, valid: true };
          });
        }),
        map((resources) => {
          return resources.map((res) => {
            if (res.type === 'GEOJSON') return res;
            // if resource is an image, it must have a bounding box to be valid
            const validBbox = res.boundingBox !== undefined;
            return { ...res, valid: res.valid && validBbox };
          });
        }),
      );
  }

  removeResource(resource: Resource): void {
    this.store.dispatch(
      ResourceActions.removeResource({ id: resource.filename }),
    );
  }

  resourceSelected(res: Resource): void {
    this.router.navigate([`admin/resources/${res.filename}`]);
  }

  onAdd(): void {
    this.dialog.open(ResourceCreateComponent);
  }
}
