import { Component, Input, OnInit } from '@angular/core';
import { WeatherActions, WeatherSelectors } from '../../+state';
import { filter, map, Observable } from 'rxjs';
import { DropdownEvent, DropdownItem } from '@trim-web-apps/map3-ui';
import { WeatherLayer, WeatherModel } from '@trim-web-apps/weather-models';
import { Store } from '@ngrx/store';
import { notNullOrUndefined } from '@trim-web-apps/core';

@Component({
    selector: 'weather-core-layer-picker',
    template: `
    <ui-dropdown
      *ngIf="dropdownData$ | async as data"
      [itemList]="data.itemList"
      [itemActive]="data.itemActive"
      [disabled]="data.model.tiffLoading"
      (itemSelected)="onItemSelected($event)"
    >
    </ui-dropdown>
  `,
    styles: [
        `
      ui-dropdown {
        width: 100%;
      }
    `,
    ],
    standalone: false
})
export class LayerPickerComponent implements OnInit {
  @Input() weatherModelId?: string;
  dropdownData$?: Observable<{
    itemList: DropdownItem<WeatherLayer>[];
    itemActive: DropdownItem<WeatherLayer> | null;
    model: WeatherModel;
  }>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    if (!this.weatherModelId) return;
    this.dropdownData$ = this.store
      .select(WeatherSelectors.selectById(this.weatherModelId))
      .pipe(
        filter(notNullOrUndefined),
        map((model) => {
          let itemList: DropdownItem<WeatherLayer>[] = [];
          let itemActive: DropdownItem<WeatherLayer> | null = null;

          for (const layer of model.layers) {
            const item = { label: layer.label, payload: layer };
            itemList = [...itemList, item];
            if (layer.id === model.selectedLayerId) itemActive = item;
          }

          return { itemList, itemActive, model };
        }),
      );
  }

  onItemSelected(evt: DropdownEvent<WeatherLayer>): void {
    if (this.weatherModelId && evt.source)
      this.store.dispatch(
        WeatherActions.setLayer({
          modelId: this.weatherModelId,
          layer: evt.source.payload,
        }),
      );
  }
}
