import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromMediaGallery from './media-gallery.reducer';
import { ProjectSelectors } from '../../project/+state';
import { RecordSelectors } from '../../record/+state';
import { GalleryData } from '../models/gallery-data.model';

const selectMediaGalleryState = createFeatureSelector<fromMediaGallery.State>(
  fromMediaGallery.featureKey,
);

export const selectIsGalleryOpen = () =>
  createSelector(selectMediaGalleryState, (state) => state.recordId !== null);

export const selectGalleryData = () =>
  createSelector(
    selectMediaGalleryState,
    RecordSelectors.selectRecordsDict(),
    ProjectSelectors.selectProject(),
    (state, recordDict, project): GalleryData | null => {
      const { mediaIndex, recordId } = state;
      const record = recordId ? recordDict[recordId] || null : null;
      if (
        !record ||
        !project ||
        mediaIndex < 0 ||
        mediaIndex > record.mediaList.length - 1
      )
        return null;

      const mediaList = record.mediaList.filter((m) => m.type !== 'FILE');
      return {
        mediaList,
        currentMedia: mediaList[mediaIndex],
        projectName: project.name,
      };
    },
  );
