import { createSelector } from '@ngrx/store';
import { AdminSelectors } from '../../+state';
import { selectAppAdminState } from '../../admin.reducer.map';

// const selectState = createFeatureSelector<State>(ADMIN_DEPLOY_FEATURE_KEY);
export const selectState = () =>
  createSelector(selectAppAdminState(), (state) => state.deploy);

export const selectProjectRequest = () =>
  createSelector(selectState(), (state) => state.project);

export const selectMarkerRequest = () =>
  createSelector(selectState(), (state) => state.markers);

export const selectResourcesRequest = () =>
  createSelector(selectState(), (state) => state.resources);

export const selectValidationErrors = () =>
  createSelector(selectState(), (state) => state.validationErrorList);

export const selectProjectDeployable = () =>
  createSelector(
    AdminSelectors.selectIsProjectValid(),
    selectProjectRequest(),
    selectProjectRequest(),
    selectMarkerRequest(),
    selectResourcesRequest(),
    (projectValid, projectRequest, markerRequest, resourcesRequest): boolean =>
      [
        projectValid ? 'DEPLOYED' : 'ERROR',
        projectRequest,
        markerRequest,
        resourcesRequest,
      ]
        .map((status) => status !== 'ERROR' && status !== 'PENDING')
        .reduce((acc, item) => item && acc, true),
  );

export const selectDeployView = () =>
  createSelector(
    selectState(),
    AdminSelectors.selectProject(),
    AdminSelectors.selectIsProjectValid(),
    (deployState, project, isProjectValid) => {
      return { deployState, project, isProjectValid };
    },
  );
