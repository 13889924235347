import { Injectable } from '@angular/core';
import { LngLat, LngLatBounds, Map, Marker } from 'mapbox-gl';
import { Record } from '3map-models';
import { NominatimResponse } from '@trim-web-apps/nominatim';

@Injectable({
  providedIn: 'root',
})
export class MapboxService {
  private _map: Map | null = null;
  private searchMarker?: Marker;

  isMapInitialized(): boolean {
    return this._map !== null;
  }

  set map(map: Map | null) {
    this._map = map;
  }

  get map(): Map {
    if (this._map === null) throw Error('[MapService] Map not initialized');
    return this._map;
  }

  zoomToRecords(records: Record[]): void {
    records.length === 1
      ? this.setMapToLngLat(records[0].longitude, records[0].latitude)
      : this.setMapToBounds(
          records.map((rec) => new LngLat(rec.longitude, rec.latitude)),
        );
  }

  setMapToLngLat(lng: number, lat: number): void {
    this.map.setCenter({ lng, lat });
  }

  setMapToBounds(coordinates: LngLat[]): void {
    if (coordinates && coordinates.length > 0) {
      // thanks mapbox
      const bounding = coordinates.reduce(
        (bounds, coord) => {
          return bounds.extend(coord);
        },
        new LngLatBounds(coordinates[0], coordinates[0]),
      );
      this.map?.fitBounds(bounding, { padding: 250 });
    }
  }

  setSearchMarker(searchLocation: NominatimResponse | null): void {
    if (this.searchMarker) this.searchMarker.remove();
    if (searchLocation) {
      const { lng, lat } = searchLocation;
      const node = document.createElement('div');
      node.className = 'box-shadow';
      node.style.width = '16px';
      node.style.height = '16px';
      node.style.borderRadius = '50%';
      node.style.backgroundColor = 'red';
      this.searchMarker = new Marker(node)
        .setLngLat({ lng, lat })
        .addTo(this.map);
    }
  }
}
