import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-field-text',
  template: ` <p>field-text works!</p> `,
  styles: [],
  standalone: false,
})
export class FieldTextComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
