import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRecordEditor from './record-editor.reducer';
import { notNullOrUndefined } from '@trim-web-apps/core';
import { ProjectSelectors } from '../../project/+state';
import { FieldTypeComponentInput } from '../models/FieldTypeComponentInput';
import { AuthSelectors } from '../../../auth/state/auth';
import {
  EditorTab,
  MEDIA_TAB,
  METADATA_TAB,
  QUESTION_TAB,
} from '../models/EditorTab';
import { RecordSelectors } from '../../record/+state';
import { FormSpecific, Project, Record, recordDecoder } from '3map-models';
import { Collaboration } from '../../../auth/state/collaboration/collaboration.reducer';
import { getSpecific } from '../../shared/project.utils';

export const selectEditorState = createFeatureSelector<fromRecordEditor.State>(
  fromRecordEditor.FEATURE_KEY,
);

export const selectMode = () =>
  createSelector(selectEditorState, (state) => state.mode);

/**
 User is `owner` if:
 - is ADMIN or MOD
 - its username is the same in record.username field
 */
export const selectIsRecordOwner = () =>
  createSelector(
    selectEditorState,
    AuthSelectors.selectAuthState,
    (editorState, authState) => {
      const role = authState.col?.role;
      const username = authState.username;
      const record = editorState.record;
      if (!record) return false;
      if (role === 'ADMIN' || role === 'MOD') return true;
      return username !== null && username === record.username;
    },
  );

export const selectRequest = () =>
  createSelector(selectEditorState, (state) => state.request);

export const selectTargetRecord = () =>
  createSelector(selectEditorState, (state) => state.record);

export const selectActiveTab = () =>
  createSelector(selectEditorState, (state) => state.activeTab);

export const selectEditorTabs = () =>
  createSelector(
    selectEditorState,
    selectGroupNameList(),
    (state, groupNameList): EditorTab[] => {
      const hasGroups = groupNameList && groupNameList.length > 0;
      const questionsTab: EditorTab[] = hasGroups
        ? groupNameList.map((groupName) => ({
            ...QUESTION_TAB,
            label: groupName,
            payload: groupName,
          }))
        : [{ ...QUESTION_TAB, label: 'Questions' }];
      return [METADATA_TAB, ...questionsTab, MEDIA_TAB];
    },
  );

export const selectSpecificOfTargetRecord = () =>
  createSelector(
    selectTargetRecord(),
    selectFormOfTargetRecord(),
    (record, form) => {
      if (form === null || record === null) return null;
      return (
        form.specificList.find(
          (specific) => specific.id === record.formSpecificId,
        ) || null
      );
    },
  );

export const selectFormOfTargetRecord = () =>
  createSelector(
    selectTargetRecord(),
    ProjectSelectors.selectProject(),
    (record, project) => {
      if (project === null || record === null) return null;
      return project.formList.find((form) => form.id === record.formId) || null;
    },
  );

export const selectGroupNameList = () =>
  createSelector(selectSpecificOfTargetRecord(), (specific) => {
    if (!specific || !specific.questionGroups) return null;
    return specific.questionGroups.map((g) => g.name);
  });

export const selectFieldTypeComponentInputs = () =>
  createSelector(
    selectEditorState,
    selectTargetRecord(),
    selectSpecificOfTargetRecord(),
    selectIsRecordOwner(),
    (state, record, specific, isRecordOwner) => {
      const { activeTab, mode } = state;
      if (!record || !specific) return [];
      if (!activeTab || activeTab.type !== 'QUESTIONS') return [];
      let questions = specific.questions;

      /**
       * When EditorTab.type === 'QUESTIONS`, payload property can be
       * - null: if FormSpecific has no groups
       * - string: group name currently selected/active
       *
       * If payload is not null, get the GROUP (from FromSpecific) and filter
       * the questions that belonging to it.
       */
      if (activeTab.payload !== null) {
        const group = specific.questionGroups?.find(
          (g) => g.name === activeTab?.payload,
        );
        if (!group) return [];
        questions = specific.questions.filter((ft) =>
          group.questions.includes(ft.id),
        );
      }

      return questions
        .map((fieldType): FieldTypeComponentInput | null => {
          const recordData = record.data as any;
          const field = recordData[fieldType.id];

          let allowEdit = false;
          if (mode === 'CREATE') allowEdit = true;
          if (mode === 'UPDATE') allowEdit = !fieldType.immutable;
          if (mode === 'EDIT')
            allowEdit = !fieldType.immutable && isRecordOwner;

          const listLogic = specific.listLogic;

          if (!listLogic || listLogic.length === 0)
            return { fieldType, field, allowEdit };

          // apply logic
          const logic = listLogic.find((logic) => logic.q2 === fieldType.id);
          if (!logic) return { fieldType, allowEdit, field };
          const controlAnswer = recordData[logic.q1];
          if (!controlAnswer) return null;
          const answerSubset = logic.logic.find(
            (l) => l.r1 === controlAnswer.text,
          );
          if (answerSubset) {
            const ft = { ...fieldType, options: answerSubset.r2 };
            return { fieldType: ft, field, allowEdit };
          }
          return null;
        })
        .filter(notNullOrUndefined);
    },
  );

export const selectEditorDataEditUpdate = (recordId: string) =>
  createSelector(
    RecordSelectors.selectRecordsDict(),
    ProjectSelectors.selectProject(),
    AuthSelectors.selectAuthUsername(),
    AuthSelectors.selectCollaboration(),
    (
      recordDict,
      project,
      username,
      col,
    ): {
      project: Project;
      record: Record;
      specific: FormSpecific;
      username: string;
      col: Collaboration;
    } => {
      if (!project) throw Error('Invalid Project');
      if (!username) throw Error('Invalid username');
      if (!col) throw Error('Invalid collaboration');
      const record = recordDict[recordId];
      if (!record) throw Error('RecordId not found');
      const specific = getSpecific(project, record.formSpecificId);
      if (!specific) throw Error('FormSpecific not found');
      return { project, specific, record, username, col };
    },
  );

export const selectUploadMedia = () =>
  createSelector(selectEditorState, (state) => state.uploadMediaList);

export const selectIsValidRecord = () =>
  createSelector(
    ProjectSelectors.selectProject(),
    selectTargetRecord(),
    (project, record) => {
      if (!project || !record) return false;
      return recordDecoder(project).run(JSON.parse(JSON.stringify(record))).ok;
    },
  );
