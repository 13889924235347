import { ReqLinkChangePsw } from '3map-models';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { of } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { AuthApiService } from '../../services/auth-api.service';

@Component({
  selector: 'app-password-recovery',
  template: `
    <div class="title">Recover password</div>
    <p *ngIf="recoveryResponse.success === false">
      Insert your mail, we'll send you a recovery link!
    </p>
    <form
      *ngIf="recoveryResponse.success === false"
      [formGroup]="form"
      (ngSubmit)="onSubmit()"
    >
      <app-input-mail
        (formControl)="onFormControlReady($event)"
      ></app-input-mail>

      <ui-btn
        (btnClick)="onSubmit()"
        [fullWidth]="true"
        [disabled]="!form.valid || pendingRequest"
        label="{{ pendingRequest ? 'Sending...' : 'Recover' }}"
      />
    </form>

    <div
      *ngIf="recoveryResponse.msg.length > 0"
      [ngClass]="recoveryResponse.success ? 'success' : 'error-label'"
    >
      {{ recoveryResponse.msg }}
    </div>

    <div class="fill-remaining-space"></div>

    <ui-btn
      [fullWidth]="true"
      [routerLink]="['/auth']"
      type="border"
      label="Back"
    />
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      button {
        width: 100%;
        margin-top: 10px;
      }

      .error-label {
        margin-top: 30px;
        text-align: center;
      }

      .title {
        margin-bottom: 30px;
        text-align: right;
        font-size: 1.5em;
      }
    `,
  ],
  standalone: false,
})
export class PasswordRecoveryComponent {
  form: UntypedFormGroup;
  recoveryResponse: { success: boolean; msg: string };
  pendingRequest: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private authApi: AuthApiService,
  ) {
    this.recoveryResponse = { success: false, msg: '' };
    this.pendingRequest = false;
    this.form = fb.group({});
  }

  onFormControlReady(formControl: UntypedFormControl): void {
    this.form.addControl('mail', formControl);
  }

  onSubmit(): void {
    if (this.form.valid) {
      const mail = (this.form.get('mail')?.value as string)
        .trim()
        .toLocaleLowerCase();
      const bodyRequest: ReqLinkChangePsw = { mail };
      this.pendingRequest = true;

      this.authApi
        .sendChangePasswordLink(bodyRequest)
        .pipe(
          first(),
          map((res) => ({
            success: true,
            msg: `Recovery link sent to ${bodyRequest.mail}. Follow the instructions to reset you password!`,
          })),
          catchError((res: HttpErrorResponse) => {
            const msg = res.error?.errorText
              ? res.error.errorText
              : 'Oops... Something went wrong, please refresh the page and try again!';

            return of({ success: false, msg });
          }),
        )
        .subscribe((res: { success: boolean; msg: string }) => {
          this.recoveryResponse = res;
          this.pendingRequest = false;
        });
    }
  }
}
