import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { faLocationCrosshairs } from '@fortawesome/free-solid-svg-icons';
import { Form } from '3map-models';

@Component({
  selector: 'app-form-card[formDataset]',
  template: `
    <div class="form-card-wrapper">
      <ui-expansion-panel [shadow]="true" [noContentPadding]="true">
        <div header>
          <div class="header">{{ formDataset.form.name }}</div>
        </div>
        <div content>
          <div class="row form-action-row">
            <div class="action-row-label">SELECT TO DISPLAY</div>
            <div class="fill-remaining-space"></div>
            <div class="form-actions">
              <fa-icon
                [icon]="iconGps"
                (click)="zoomToForm.emit(formDataset.form.id)"
              ></fa-icon>
              <ui-checkbox
                (click)="onToggleAllSpecific()"
                [checked]="isAllEnabled()"
              ></ui-checkbox>
            </div>
          </div>

          <div class="form-list">
            <app-form-specific-list
              [formDataset]="formDataset"
            ></app-form-specific-list>
          </div>
        </div>
      </ui-expansion-panel>
    </div>
  `,
  styles: [
    `
      .form-card-wrapper {
        margin-bottom: var(--spacing-3);
        width: 350px;
      }

      .header {
        font-weight: var(--font-weight-3);
        font-size: var(--font-size-2);
      }

      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .form-action-row {
        border-bottom: 2px solid #eeeeee;
        padding: var(--spacing-1) var(--spacing-3);
      }

      .action-row-label {
        font-size: var(--font-size-1);
      }

      .form-actions {
        display: flex;
        justify-content: right;
        align-items: center;
        font-size: var(--font-size-4);
        cursor: pointer;
      }

      .form-actions fa-icon {
        border-left: 2px solid #eeeeee;
        border-right: 2px solid #eeeeee;
        padding: 0 var(--spacing-3);
        margin-right: var(--spacing-3);
        font-size: var(--font-size-3);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MapFormCardComponent {
  @Input() formDataset!: {
    form: Form;
    dataset: { formId: string; specificIds: string[] };
  };
  @Output() setEnabledSpecificIds: EventEmitter<string[]> = new EventEmitter();
  @Output() disableForm: EventEmitter<string> = new EventEmitter();
  @Output() zoomToForm: EventEmitter<string> = new EventEmitter();

  collapsed?: boolean;
  iconGps = faLocationCrosshairs;

  isAllEnabled(): boolean {
    const specificIds = this.formDataset.form.specificList.map((s) => s.id);
    return specificIds.length === this.enabledSpecificIds.length;
  }

  onToggleAllSpecific(): void {
    const specificIds = this.formDataset.form.specificList.map((s) => s.id);
    this.setEnabledSpecificIds.emit(this.isAllEnabled() ? [] : specificIds);
  }

  private get enabledSpecificIds(): string[] {
    return this.formDataset.dataset.specificIds;
  }
}
