import { Pipe, PipeTransform } from '@angular/core';
import { weatherModelSubCategoryToString } from '../weather.functions';
import { WeatherModel } from '@trim-web-apps/weather-models';

@Pipe({
    name: 'weatherSubCategory',
    standalone: false
})
export class WeatherSubCategoryPipe implements PipeTransform {
  transform(subCategory: WeatherModel['subCategory']): string {
    return weatherModelSubCategoryToString(subCategory);
  }
}
