import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  combineLatest,
  concatMap,
  fromEvent,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { MarkerService } from '../../marker.service';
import { MapboxService } from '../mapbox.service';
import * as MapActions from './map.actions';
import { Store } from '@ngrx/store';
import { ProjectActions, ProjectSelectors } from '../../project/+state';
import { SidenavActions } from '../../sidenav/+state';
import { MapSelectors } from './index';
import { take, withLatestFrom } from 'rxjs/operators';
import { TableActions } from '../../table/+state';
import { RecordSelectors } from '../../record/+state';
import { Record } from '3map-models';
import { notNullOrUndefined } from '@trim-web-apps/core';
import { splitByFeatureId } from '../../shared/project.utils';
import { WorkerMessageData } from '../../../record-decoder.worker';

@Injectable()
export class MapEffects {
  private currentWorker?: Worker;

  constructor(
    private actions$: Actions,
    private mapboxService: MapboxService,
    private markerService: MarkerService,
    private store: Store,
  ) {}

  loadIcons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapActions.ADD_MARKERS),
      map(() => {
        // console.log('draw marker!!');
        const map = this.mapboxService.map;
        for (const [iconId, image] of this.markerService.markers) {
          if (map.hasImage(iconId)) map.removeImage(iconId);
          map.addImage(iconId, image);
        }
        return MapActions.ADD_MARKERS_SUCCESS();
      }),
    ),
  );

  /**
   * Markers need to be (re)drawn on:
   * - mapStyle change (e.g. base layer changed)
   * - Project is successfully fetched
   * Do not dispatch if Project is null or Map is not yer loaded.
   */
  styleChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          MapActions.MAP_STYLE_CHANGED,
          ProjectActions.fetchProjectSuccess,
        ),
        switchMap(() => {
          return combineLatest([
            this.store.select(ProjectSelectors.selectProject()),
            this.store.select(MapSelectors.selectMapLoaded()),
          ]).pipe(take(1));
        }),
        map(([project, mapLoaded]) => {
          if (project === null || !mapLoaded) return;
          this.store.dispatch(MapActions.ADD_MARKERS());
        }),
      ),
    { dispatch: false },
  );

  sectionChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SidenavActions.setSection),
        tap(({ section }) => {
          if (section === 'MAP') {
            try {
              setTimeout(() => this.mapboxService.map.resize());
            } catch {}
          }
        }),
      ),
    { dispatch: false },
  );

  processedRecord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TableActions.processedRecordIdsChanged),
      withLatestFrom(this.store.select(RecordSelectors.selectRecordsDict())),
      concatMap(([{ formId, processedRecordsIds }, recordsDict]) => {
        const records: Record[] = processedRecordsIds
          .map((recordId) => recordsDict[recordId])
          .filter(notNullOrUndefined);

        return this.splitByFeatureId(records).pipe(
          map((recordByFeatureId) => {
            const recordIdsByFeatureId: { [featureId: string]: string[] } = {};
            const getRecordId = (record: Record) => record.recordId;
            for (const [featId, recList] of Object.entries(recordByFeatureId)) {
              if (recList.length > 0)
                recordIdsByFeatureId[featId] = recList.map(getRecordId);
            }
            return MapActions.setMapRecords({ formId, recordIdsByFeatureId });
          }),
        );
      }),
    ),
  );

  zoomToRecord$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          MapActions.zoomToRecords,
          TableActions.zoomToRecords,
          TableActions.zoomToRecord,
        ),
        map((action) => {
          switch (action.type) {
            case MapActions.zoomToRecords.type:
              return action.recordIdsList;
            case TableActions.zoomToRecords.type:
              return action.recordIdsList;
            case TableActions.zoomToRecord.type:
              return [action.recordId];
            default:
              return [];
          }
        }),
        withLatestFrom(this.store.select(RecordSelectors.selectRecordsDict())),
        map(([recordIdsList, recordsDict]) => {
          const records = recordIdsList
            .map((id) => recordsDict[id])
            .filter(notNullOrUndefined);
          if (records.length) this.mapboxService.zoomToRecords(records);
        }),
      ),
    { dispatch: false },
  );

  zoomToForm$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MapActions.zoomToForm),
        switchMap(({ formId }) =>
          this.store
            .select(MapSelectors.selectMapRecords(formId))
            .pipe(take(1)),
        ),
        map((mapRecords) => this.mapboxService.zoomToRecords(mapRecords || [])),
      ),
    { dispatch: false },
  );

  searchLocation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SidenavActions.searchLocation),
        tap(({ searchLocation }) =>
          this.mapboxService.setSearchMarker(searchLocation),
        ),
      ),
    { dispatch: false },
  );

  zoomToSearchLocation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SidenavActions.zoomToSearchLocation),
        withLatestFrom(this.store.select(MapSelectors.selectSearchLocation())),
        tap(([, searchLocation]) => {
          if (searchLocation) {
            const { lng, lat } = searchLocation;
            this.mapboxService.setMapToLngLat(lng, lat);
          }
        }),
      ),
    { dispatch: false },
  );

  private splitByFeatureId(
    records: Record[],
  ): Observable<{ [k: string]: Record[] }> {
    if (typeof Worker !== 'undefined') {
      const workerData: WorkerMessageData = {
        op: 'splitByFeatureId',
        payload: { recordList: records, sortByDatetimeUtc: false },
      };
      this.currentWorker?.terminate();
      this.currentWorker = new Worker(
        new URL('../../../record-decoder.worker', import.meta.url),
      );
      this.currentWorker.postMessage(workerData);
      return fromEvent<MessageEvent>(this.currentWorker, 'message').pipe(
        take(1),
        map((event) => event.data),
      );
    } else {
      console.warn('[Map Effects] Worker not supported. Using fallback.');
      const recordByFeatureId = splitByFeatureId(records, false);
      return of(recordByFeatureId);
    }
  }
}
