import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'ui-loading-bar',
    template: `
    <div class="progress" *ngIf="loading">
      <div class="indeterminate"></div>
    </div>
  `,
    styles: [
        `
      .progress {
        position: relative;
        height: 4px;
        display: block;
        width: 100%;
        background-color: transparent;
        border-radius: 2px;
        background-clip: padding-box;
        overflow: hidden;
      }

      .progress .determinate {
        position: absolute;
        top: 0;
        bottom: 0;
        background-color: var(--primary-color);
        transition: width 0.3s linear;
      }

      .progress .indeterminate {
        background-color: var(--primary-color);
      }

      .progress .indeterminate:before {
        content: '';
        position: absolute;
        background-color: inherit;
        top: 0;
        left: 0;
        bottom: 0;
        will-change: left, right;
        -webkit-animation: indeterminate 2.1s
          cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
        animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395)
          infinite;
      }

      .progress .indeterminate:after {
        content: '';
        position: absolute;
        background-color: inherit;
        top: 0;
        left: 0;
        bottom: 0;
        will-change: left, right;
        -webkit-animation: indeterminate-short 2.1s
          cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
        animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
          infinite;
        -webkit-animation-delay: 1.15s;
        animation-delay: 1.15s;
      }

      @-webkit-keyframes indeterminate {
        0% {
          left: -35%;
          right: 100%;
        }
        60% {
          left: 100%;
          right: -90%;
        }
        100% {
          left: 100%;
          right: -90%;
        }
      }

      @keyframes indeterminate {
        0% {
          left: -35%;
          right: 100%;
        }
        60% {
          left: 100%;
          right: -90%;
        }
        100% {
          left: 100%;
          right: -90%;
        }
      }

      @-webkit-keyframes indeterminate-short {
        0% {
          left: -200%;
          right: 100%;
        }
        60% {
          left: 107%;
          right: -8%;
        }
        100% {
          left: 107%;
          right: -8%;
        }
      }

      @keyframes indeterminate-short {
        0% {
          left: -200%;
          right: 100%;
        }
        60% {
          left: 107%;
          right: -8%;
        }
        100% {
          left: 107%;
          right: -8%;
        }
      }
    `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LoadingBarComponent {
  @Input() loading?: boolean;
}
