import { Component, Inject } from '@angular/core';
import { QuestionsGroup } from '@trim-web-apps/project-core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { DropdownItem } from '@trim-web-apps/map3-ui';

export type RemoveGroupAction = {
  keepQuestions: boolean;
  moveToGroup: string | null;
};

@Component({
  selector: 'app-remove-question-group-dialog',
  template: `
    <div class="remove-question-group-wrapper">
      <div class="body">
        <p>
          This Group has {{ data.questionGroup.questions.length }} questions.
        </p>

        <ui-dropdown
          [itemList]="actions"
          [itemActive]="removeAction"
          (itemSelected)="onActionSelected($event.source)"
        />
      </div>

      <div class="actions">
        <ui-btn (click)="dialogRef.close()" label="Back" type="border"></ui-btn>

        <div class="fill-remaining-space"></div>

        <ui-btn (click)="onConfirm()" label="Confirm"></ui-btn>
      </div>
    </div>
  `,
  styles: [
    `
      .remove-question-group-wrapper {
        background-color: var(--bg-color);
        border-radius: var(--radius-1);
      }

      .body,
      .actions {
        padding: 20px;
      }

      .actions {
        display: flex;
        align-items: center;
        width: 350px;
      }
    `,
  ],
  standalone: false,
})
export class RemoveQuestionGroupDialogComponent {
  moveQuestionsToGroup: string | null;
  actions: DropdownItem<RemoveGroupAction>[];
  removeAction: DropdownItem<RemoveGroupAction>;

  constructor(
    public dialogRef: DialogRef<RemoveGroupAction | undefined>,
    @Inject(DIALOG_DATA)
    public data: {
      questionGroup: QuestionsGroup;
      questionGroupList: QuestionsGroup[];
    },
  ) {
    this.removeAction = {
      label: 'Remove Questions in this Group',
      payload: { keepQuestions: false, moveToGroup: null },
    };

    this.actions = [this.removeAction];

    if (this.data.questionGroupList.length === 1) {
      this.actions.push({
        label: 'Keep Questions',
        payload: { keepQuestions: true, moveToGroup: null },
      });
    }

    for (const group of data.questionGroupList) {
      if (group.groupName !== data.questionGroup.groupName) {
        this.actions.push({
          label: `Move to ${group.groupName}`,
          payload: { keepQuestions: true, moveToGroup: group.groupName },
        });
      }
    }
    this.moveQuestionsToGroup = null;
  }

  onActionSelected(item: DropdownItem<RemoveGroupAction> | null): void {
    if (item) this.removeAction = item;
  }

  onConfirm(): void {
    this.dialogRef.close(this.removeAction.payload);
  }
}
