import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Link } from '3map-models';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { take } from 'rxjs/operators';
import {
  NameUrlDialogComponent,
  NameUrlDialogData,
} from '../../../shared/components/name-url-dialog/name-url-dialog.component';
import { Store } from '@ngrx/store';
import * as LinkSelectors from '../../+state/link.selectors';
import * as LinkActions from '../../+state/link.actions';
import { Dialog } from '@angular/cdk/dialog';

@Component({
  selector: 'app-link-list',
  template: `
    <div class="header">
      <app-section-label sectionLabel="Links">
        <ui-btn
          label="Add Link"
          (click)="onAdd()"
          [icon]="iconAdd"
          type="border"
        />
      </app-section-label>
    </div>

    <div class="link-list">
      <app-link-item
        *ngFor="let link of linkList$ | async"
        [link]="link"
        (remove)="onRemove(link)"
      />
    </div>
  `,
  styles: [
    `
      :host {
        height: 100vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
      }

      .link-list {
        margin: var(--spacing-2) var(--spacing-4);
        overflow: auto;
      }
    `,
  ],
  standalone: false,
})
export class LinkListComponent {
  linkList$: Observable<Link[]>;
  iconAdd = faPlus;

  constructor(
    private dialog: Dialog,
    private store: Store,
  ) {
    this.linkList$ = this.store.select(LinkSelectors.selectLinks());
  }

  onAdd(): void {
    const data: NameUrlDialogData = { title: 'Link' };
    this.dialog
      .open<Link | null>(NameUrlDialogComponent, { data })
      .closed.pipe(take(1))
      .subscribe((link) => {
        if (link) this.store.dispatch(LinkActions.upsertLink({ link }));
      });
  }

  onRemove(link: Link): void {
    this.store.dispatch(LinkActions.removeLink({ link }));
  }
}
