import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable } from 'rxjs';
import { AddProjectError } from '@trim-web-apps/api3map';
import * as DeploySelectors from '../+state/deploy.selectors';
import { AdminSelectors } from '../../+state';

@Component({
  selector: 'app-validation-errors',
  template: `
    <ng-container *ngIf="view$ | async as view">
      <ng-container *ngIf="view.length === 0">
        Unknown error. Please contact support.
      </ng-container>

      <div class="error" *ngFor="let e of view">
        <div class="desc">
          There are {{ e.recordCount }} records that are not compatible with the
          new Project.
        </div>
        <div class="row">
          <div class="label">Error type</div>
          <div class="value">{{ e.errorText }}</div>
        </div>
        <div class="row" *ngIf="e.specificId">
          <div class="label">Form Specific</div>
          <div class="value">
            {{ e.specificName }} (ID: {{ e.specificId }} )
          </div>
        </div>
      </div>
    </ng-container>
  `,
  styles: [
    `
      .error {
        border: 1px solid var(--error-color);
        width: fit-content;
        border-radius: var(--radius-1);
        margin: var(--spacing-3) 0;
        user-select: text;
        cursor: text;
      }

      .row,
      .desc {
        display: flex;
        padding: var(--spacing-2);
      }

      .row .label {
        width: 120px;
        font-weight: bold;
        color: var(--error-color);
      }

      .row .value {
        flex: 1;
      }
    `,
  ],
  standalone: false,
})
export class ValidationErrorsComponent {
  view$: Observable<(AddProjectError & { specificName: string })[] | null>;

  constructor(private store: Store) {
    this.view$ = combineLatest([
      this.store.select(DeploySelectors.selectValidationErrors()),
      this.store.select(AdminSelectors.selectProject()),
    ]).pipe(
      map(([errors, project]) => {
        if (!errors || !project) return null;
        return errors.map((e) => {
          const specificName =
            project.formList
              .map((f) => f.specificList)
              .flat()
              .find((s) => s.id === e.specificId)?.name ?? '';
          return { ...e, specificName };
        });
      }),
    );
  }
}
