export function nearestValue(ds: number[][], x: number, y: number): number {
  return ds[Math.round(y)][Math.round(x)];
}

export function bilinearInterpolation(
  ds: number[][],
  x: number,
  y: number,
): number {
  let res: number;
  const dist1: number = (Math.ceil(x) - x) * (Math.ceil(y) - y);
  const dist2: number = (x - Math.floor(x)) * (Math.ceil(y) - y);
  const dist3: number = (Math.ceil(x) - x) * (y - Math.floor(y));
  const dist4: number = (x - Math.floor(x)) * (y - Math.floor(y));

  if (dist1 !== 0 || dist2 !== 0 || dist3 !== 0 || dist4 !== 0) {
    res =
      ds[Math.floor(y)][Math.floor(x)] * dist1 +
      ds[Math.floor(y)][Math.ceil(x)] * dist2 +
      ds[Math.ceil(y)][Math.floor(x)] * dist3 +
      ds[Math.ceil(y)][Math.ceil(x)] * dist4;
  } else {
    res = ds[Math.floor(y)][Math.floor(x)];
  }

  return res;
}

/**
 * https://www.youtube.com/watch?v=poY_nGzEEWM
 * https://www.paulinternet.nl/?page=bicubic
 */
export function bicubicInterpolation(
  ds: number[][],
  x: number,
  y: number,
): number {
  const x0 = Math.floor(x) - 1;
  const y0 = Math.floor(y) - 1;

  if (x0 < 0 || x0 + 3 >= ds[0].length || y0 < 0 || y0 + 3 >= ds.length) {
    return ds[y0 + 1][x0 + 1];
  }
  x = x - x0 - 1;
  y = y - y0 - 1;

  const a0 = ds[y0]?.[x0];
  const a1 = ds[y0 + 1]?.[x0];
  const a2 = ds[y0 + 2]?.[x0];
  const a3 = ds[y0 + 3]?.[x0];

  const b0 = ds[y0]?.[x0 + 1];
  const b1 = ds[y0 + 1]?.[x0 + 1];
  const b2 = ds[y0 + 2]?.[x0 + 1];
  const b3 = ds[y0 + 3]?.[x0 + 1];

  const c0 = ds[y0]?.[x0 + 2];
  const c1 = ds[y0 + 1]?.[x0 + 2];
  const c2 = ds[y0 + 2]?.[x0 + 2];
  const c3 = ds[y0 + 3]?.[x0 + 2];

  const d0 = ds[y0]?.[x0 + 3];
  const d1 = ds[y0 + 1]?.[x0 + 3];
  const d2 = ds[y0 + 2]?.[x0 + 3];
  const d3 = ds[y0 + 3]?.[x0 + 3];

  // prettier-ignore
  const i0 = 0.5 * (c0 - a0 + (2.0 * a0 - 5.0 * b0 + 4.0 * c0 - d0 + (3.0 * (b0 - c0) + d0 - a0) * x) * x) * x + b0
  // prettier-ignore
  const i1 = 0.5 * (c1 - a1 + (2.0 * a1 - 5.0 * b1 + 4.0 * c1 - d1 + (3.0 * (b1 - c1) + d1 - a1) * x) * x) * x + b1
  // prettier-ignore
  const i2 = 0.5 * (c2 - a2 + (2.0 * a2 - 5.0 * b2 + 4.0 * c2 - d2 + (3.0 * (b2 - c2) + d2 - a2) * x) * x) * x + b2
  // prettier-ignore
  const i3 = 0.5 * (c3 - a3 + (2.0 * a3 - 5.0 * b3 + 4.0 * c3 - d3 + (3.0 * (b3 - c3) + d3 - a3) * x) * x) * x + b3

  // prettier-ignore
  return 0.5 * (i2 - i0 + (2.0 * i0 - 5.0 * i1 + 4.0 * i2 - i3 + (3.0 * (i1 - i2) + i3 - i0) * y) * y) * y + i1
}

export function bicubicInterpolationNoValues(
  ds: number[][],
  x: number,
  y: number,
): number {
  return _bicubicInterpolationNoValues(ds, x, y, false);
}

export function bicubicInterpolationNoValuesRound(
  ds: number[][],
  x: number,
  y: number,
): number {
  return _bicubicInterpolationNoValues(ds, x, y, true);
}

export function bicubicInterpolationAvg(
  ds: number[][],
  x: number,
  y: number,
): number {
  return _bicubicInterpolationAvg(ds, x, y, false);
}

export function bicubicInterpolationAvgRound(
  ds: number[][],
  x: number,
  y: number,
): number {
  return _bicubicInterpolationAvg(ds, x, y, true);
}

function _bicubicInterpolationNoValues(
  ds: number[][],
  x: number,
  y: number,
  round: boolean,
): number {
  const x0 = Math.floor(x) - 1;
  const y0 = Math.floor(y) - 1;

  const a0 = ds[y0]?.[x0];
  const a1 = ds[y0 + 1]?.[x0];
  const a2 = ds[y0 + 2]?.[x0];
  const a3 = ds[y0 + 3]?.[x0];

  const b0 = ds[y0]?.[x0 + 1];
  const b1 = ds[y0 + 1]?.[x0 + 1];
  const b2 = ds[y0 + 2]?.[x0 + 1];
  const b3 = ds[y0 + 3]?.[x0 + 1];

  const c0 = ds[y0]?.[x0 + 2];
  const c1 = ds[y0 + 1]?.[x0 + 2];
  const c2 = ds[y0 + 2]?.[x0 + 2];
  const c3 = ds[y0 + 3]?.[x0 + 2];

  const d0 = ds[y0]?.[x0 + 3];
  const d1 = ds[y0 + 1]?.[x0 + 3];
  const d2 = ds[y0 + 2]?.[x0 + 3];
  const d3 = ds[y0 + 3]?.[x0 + 3];

  if (a0 > 9000 || a0 === undefined) return 99999;
  if (a1 > 9000 || a1 === undefined) return 99999;
  if (a2 > 9000 || a2 === undefined) return 99999;
  if (a3 > 9000 || a3 === undefined) return 99999;

  if (b0 > 9000 || b0 === undefined) return 99999;
  if (b1 > 9000 || b1 === undefined) return 99999;
  if (b2 > 9000 || b2 === undefined) return 99999;
  if (b3 > 9000 || b3 === undefined) return 99999;

  if (c0 > 9000 || c0 === undefined) return 99999;
  if (c1 > 9000 || c1 === undefined) return 99999;
  if (c2 > 9000 || c2 === undefined) return 99999;
  if (c3 > 9000 || c3 === undefined) return 99999;

  if (d0 > 9000 || d0 === undefined) return 99999;
  if (d1 > 9000 || d1 === undefined) return 99999;
  if (d2 > 9000 || d2 === undefined) return 99999;
  if (d3 > 9000 || d3 === undefined) return 99999;

  x = x - x0 - 1;
  y = y - y0 - 1;

  // prettier-ignore
  const i0 = 0.5 * (c0 - a0 + (2.0 * a0 - 5.0 * b0 + 4.0 * c0 - d0 + (3.0 * (b0 - c0) + d0 - a0) * x) * x) * x + b0
  // prettier-ignore
  const i1 = 0.5 * (c1 - a1 + (2.0 * a1 - 5.0 * b1 + 4.0 * c1 - d1 + (3.0 * (b1 - c1) + d1 - a1) * x) * x) * x + b1
  // prettier-ignore
  const i2 = 0.5 * (c2 - a2 + (2.0 * a2 - 5.0 * b2 + 4.0 * c2 - d2 + (3.0 * (b2 - c2) + d2 - a2) * x) * x) * x + b2
  // prettier-ignore
  const i3 = 0.5 * (c3 - a3 + (2.0 * a3 - 5.0 * b3 + 4.0 * c3 - d3 + (3.0 * (b3 - c3) + d3 - a3) * x) * x) * x + b3

  // prettier-ignore
  const tot  =  0.5 * (i2 - i0 + (2.0 * i0 - 5.0 * i1 + 4.0 * i2 - i3 + (3.0 * (i1 - i2) + i3 - i0) * y) * y) * y + i1

  return round ? Math.round(tot) : tot;
}

function _bicubicInterpolationAvg(
  ds: number[][],
  x: number,
  y: number,
  round: boolean,
): number {
  const x0 = Math.floor(x) - 1;
  const y0 = Math.floor(y) - 1;

  const values = getValues([
    ds[y0]?.[x0],
    ds[y0 + 1]?.[x0],
    ds[y0 + 2]?.[x0],
    ds[y0 + 3]?.[x0],
    ds[y0]?.[x0 + 1],
    ds[y0 + 1]?.[x0 + 1],
    ds[y0 + 2]?.[x0 + 1],
    ds[y0 + 3]?.[x0 + 1],
    ds[y0]?.[x0 + 2],
    ds[y0 + 1]?.[x0 + 2],
    ds[y0 + 2]?.[x0 + 2],
    ds[y0 + 3]?.[x0 + 2],
    ds[y0]?.[x0 + 3],
    ds[y0 + 1]?.[x0 + 3],
    ds[y0 + 2]?.[x0 + 3],
    ds[y0 + 3]?.[x0 + 3],
  ]);

  const a0 = values[0];
  const a1 = values[1];
  const a2 = values[2];
  const a3 = values[3];
  const b0 = values[4];
  const b1 = values[5];
  const b2 = values[6];
  const b3 = values[7];
  const c0 = values[8];
  const c1 = values[9];
  const c2 = values[10];
  const c3 = values[11];
  const d0 = values[12];
  const d1 = values[13];
  const d2 = values[14];
  const d3 = values[15];

  x = x - x0 - 1;
  y = y - y0 - 1;

  // prettier-ignore
  const i0 = 0.5 * (c0 - a0 + (2.0 * a0 - 5.0 * b0 + 4.0 * c0 - d0 + (3.0 * (b0 - c0) + d0 - a0) * x) * x) * x + b0
  // prettier-ignore
  const i1 = 0.5 * (c1 - a1 + (2.0 * a1 - 5.0 * b1 + 4.0 * c1 - d1 + (3.0 * (b1 - c1) + d1 - a1) * x) * x) * x + b1
  // prettier-ignore
  const i2 = 0.5 * (c2 - a2 + (2.0 * a2 - 5.0 * b2 + 4.0 * c2 - d2 + (3.0 * (b2 - c2) + d2 - a2) * x) * x) * x + b2
  // prettier-ignore
  const i3 = 0.5 * (c3 - a3 + (2.0 * a3 - 5.0 * b3 + 4.0 * c3 - d3 + (3.0 * (b3 - c3) + d3 - a3) * x) * x) * x + b3

  // prettier-ignore
  const tot  =  0.5 * (i2 - i0 + (2.0 * i0 - 5.0 * i1 + 4.0 * i2 - i3 + (3.0 * (i1 - i2) + i3 - i0) * y) * y) * y + i1

  return round ? Math.round(tot) : tot;
}

function getValues(v: (number | undefined)[]): number[] {
  const values: number[] = [];
  let sum = 0;
  let count = 0;

  for (let i = 0; i < v.length; i++) {
    const val = v[i];
    if (val !== undefined && val < 9000) {
      values[i] = val;
      sum += val;
      count++;
    }
  }

  const avgOthers = count === 0 ? 99999 : sum / count;

  for (let i = 0; i < v.length; i++) {
    if (values[i] === undefined || values[i] >= 9000) values[i] = avgOthers;
  }

  return values;
}
