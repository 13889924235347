import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldListComponent } from './components/field-types/field-list/field-list.component';
import { FieldNumberComponent } from './components/field-types/field-number/field-number.component';
import { FieldTextComponent } from './components/field-types/field-text/field-text.component';
import { RecordEditorMetadataComponent } from './containers/record-editor-metadata/record-editor-metadata.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldRangeComponent } from './components/field-types/field-range/field-range.component';
import { FieldCheckComponent } from './components/field-types/field-check/field-check.component';
import { FieldIntComponent } from './components/field-types/field-int/field-int.component';
import { FieldDateComponent } from './components/field-types/field-date/field-date.component';
import { FieldNameComponent } from './components/field-name/field-name.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EffectsModule } from '@ngrx/effects';
import { RecordEditorComponent } from './containers/record-editor/record-editor.component';
import { FieldTypeBaseComponent } from './models/FieldTypeBaseComponent';
import { RecordModule } from '../record/record.module';
import { CoreModule } from '@trim-web-apps/core';
import { StoreModule } from '@ngrx/store';
import { FieldTypesListComponent } from './components/field-types-list/field-types-list.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { RecordEditorEffects } from './+state/record-editor.effects';
import { RecordEditorModalComponent } from './containers/record-editor-modal/record-editor-modal.component';
import { SharedModule } from '../shared/shared.module';
import { EditorTabListComponent } from './containers/editor-tab-list/editor-tab-list.component';
import * as fromReducer from './+state/record-editor.reducer';
import { RecordEditorMediaComponent } from './containers/record-editor-media/record-editor-media.component';
import { RecordEditorMediaItemComponent } from './components/record-editor-media-item/record-editor-media-item.component';
import { RecordEditorBottomActionsComponent } from './components/record-editor-bottom-actions/record-editor-bottom-actions.component';
import { Map3UiModule } from '@trim-web-apps/map3-ui';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
  declarations: [
    RecordEditorComponent,
    FieldListComponent,
    FieldNumberComponent,
    FieldTextComponent,
    RecordEditorMetadataComponent,
    FieldRangeComponent,
    FieldCheckComponent,
    FieldIntComponent,
    FieldDateComponent,
    FieldNameComponent,
    FieldTypeBaseComponent,
    FieldTypesListComponent,
    RecordEditorModalComponent,
    EditorTabListComponent,
    RecordEditorMediaComponent,
    RecordEditorMediaItemComponent,
    RecordEditorBottomActionsComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MatTooltipModule,
    StoreModule.forFeature(fromReducer.FEATURE_KEY, fromReducer.reducer),
    EffectsModule.forFeature([RecordEditorEffects]),
    MatTabsModule,
    RecordModule,
    CoreModule,
    MatExpansionModule,
    RecordModule,
    SharedModule,
    Map3UiModule,
  ],
  exports: [RecordEditorComponent, RecordEditorModalComponent],
})
export class RecordEditorModule {}
