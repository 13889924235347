import { WeatherModelInitConfig, WeatherModelMetadata } from '../types';
import { WeatherModel } from '../types/weather.model';
import { WeatherLayer } from '../types/weather-layer.type';
import {
  createTsListForecast,
  decodeForecastSpec,
  getBaseModelTemplate,
  getDefaultDateRange,
  removeUnwantedLayers,
} from '../utils';
import { WIND_KTS } from '../weather-layers/weather.layers';

export const ALADIN: WeatherModelMetadata = {
  id: 'ALADIN',
  label: 'ALADIN',
  category: 'FORECAST',
  subCategory: 'LOCAL_MODELS',
  bbox: [
    [14.231, 37.1458],
    [18.5038, 44.209],
    [18.5038, 44.209],
    [14.231, 37.1458],
    [14.231, 37.1458],
  ],
  info: {
    resolution: { deg: 0.021, km: 2.5 },
    // url: 'https://radar.dhz.hr/~aladinhr/sailing/',
    // credits: 'https://radar.dhz.hr',
  },
};

export function createAladin(
  serverSpec: unknown,
  config?: WeatherModelInitConfig,
): WeatherModel {
  const spec = decodeForecastSpec(serverSpec);
  const timestepList = createTsListForecast(spec.initimeList, 73);
  const defaultDateRange = getDefaultDateRange(timestepList, 0, 72);
  const timestepType = { type: 'UNIX', interval: 3600 } as const;
  const aladinLayers: WeatherLayer[] = [
    {
      ...WIND_KTS,
      timestepType,
      timestepList,
      defaultDateRange,
    },
  ];

  const layers = removeUnwantedLayers(aladinLayers, config?.layerIdList ?? []);

  if (layers.length === 0)
    throw new Error(`No layers available for ${spec.model} model`);

  return {
    ...getBaseModelTemplate(config),
    ...ALADIN,
    layers: layers,
    initime: layers[0].timestepList[0].initime,
    timestep: layers[0].defaultDateRange.from,
    selectedLayerId: layers[0].id,
    dateRange: layers[0].defaultDateRange,
  };
}
