import { createAction, props } from '@ngrx/store';
import { Project } from '3map-models';

export const initProject = createAction('[ Admin ] Init Project');

export const initProjectSuccess = createAction(
  '[ Admin ] Init Project Success',
  props<{
    project: Project;
    icons: { specificId: string; imgBase64List: string[] }[];
  }>(),
);

export const initProjectError = createAction('[ Admin ] Init Project Error');

export const close = createAction('[ Admin ] Close');

export const setModified = createAction(
  '[ Admin ] Set Modified',
  props<{ modified: boolean }>(),
);

export const deleteProject = createAction(
  '[ Admin ] Delete Project',
  props<{ projectName: string }>(),
);

export const noOp = createAction('[ Admin ] No Op');
