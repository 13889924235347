import { WeatherModelInitConfig, WeatherModelMetadata } from '../types';
import { WeatherModel } from '../types/weather.model';
import { WeatherLayer } from '../types/weather-layer.type';
import {
  CLOUD_COVER,
  RELATIVE_HUMIDITY,
  TEMPERATURE,
  WIND_KTS,
} from '../weather-layers/weather.layers';
import {
  createTsListForecast,
  decodeForecastSpec,
  getBaseModelTemplate,
  getDefaultDateRange,
  removeUnwantedLayers,
} from '../utils';

export const LAMMA_ARW_3: WeatherModelMetadata = {
  id: 'LAMMA_ARW_3',
  label: 'LAMMA ARW3',
  category: 'FORECAST',
  subCategory: 'LOCAL_MODELS',
  info: {
    // resolutionDeg: '0.25°',
    resolution: { deg: 0.03, km: 3 },
    coverage: 'Italy',
    url: 'http://www.lamma.rete.toscana.it/modelli/atmo/wrf-info-sul-modello',
    credits: 'Consorzio LaMMA',
    schedule: [{ init: '00', time: '10:00' }],
  },
  bbox: [
    [-10.005, 37.495002],
    [-10.005, 51.005002],
    [16.005, 51.005002],
    [16.005, 37.495002],
    [-10.005, 37.495002],
  ],
};

export function createLammaArw3(
  serverSpec: unknown,
  config?: WeatherModelInitConfig,
): WeatherModel {
  const spec = decodeForecastSpec(serverSpec);
  const timestepList = createTsListForecast(spec.initimeList, 73);
  const defaultDateRange = getDefaultDateRange(timestepList, 0, 72);
  const timestepType = { type: 'UNIX', interval: 3600 } as const;

  const lammaLayers: WeatherLayer[] = [
    {
      ...WIND_KTS,
      timestepType,
      timestepList,
      defaultDateRange,
    },
    {
      ...TEMPERATURE,
      timestepType,
      timestepList,
      defaultDateRange,
    },
    {
      ...RELATIVE_HUMIDITY,
      timestepType,
      timestepList,
      defaultDateRange,
    },
    {
      ...CLOUD_COVER,
      timestepType,
      timestepList,
      defaultDateRange,
    },
  ];

  const layers = removeUnwantedLayers(lammaLayers, config?.layerIdList ?? []);

  if (layers.length === 0)
    throw new Error(`No layers available for ${spec.model} model`);

  return {
    ...getBaseModelTemplate(config),
    ...LAMMA_ARW_3,
    layers,
    initime: layers[0].timestepList[0].initime,
    timestep: layers[0].defaultDateRange.from,
    selectedLayerId: layers[0].id,
    dateRange: layers[0].defaultDateRange,
  };
}
