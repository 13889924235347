import { WeatherModelInitConfig, WeatherModelMetadata } from '../types';
import { WeatherModel } from '../types/weather.model';
import { WeatherLayer } from '../types/weather-layer.type';
import {
  HIGH_CLOUD_COVER,
  LOW_CLOUD_COVER,
  MEDIUM_CLOUD_COVER,
  PRESSURE,
  RAINFALL_1HOUR,
  RELATIVE_HUMIDITY,
  TEMPERATURE,
  WIND_KTS,
} from '../weather-layers/weather.layers';
import {
  createTsListForecast,
  decodeForecastSpec,
  getBaseModelTemplate,
  getDefaultDateRange,
  getTsListRainfall,
  removeUnwantedLayers,
} from '../utils';

export const AROME: WeatherModelMetadata = {
  id: 'AROME',
  label: 'AROME',
  category: 'FORECAST',
  subCategory: 'LOCAL_MODELS',
  info: {
    resolution: { deg: 0.01, km: 1 },
    coverage: 'Western Europe',
    url: 'https://donneespubliques.meteofrance.fr/?fond=produit&id_produit=131&id_rubrique=51',
    credits: 'Météo-France',
    schedule: [
      { init: '00', time: '05:00' },
      { init: '12', time: '16:00' },
    ],
  },
  bbox: [
    [-10.005, 37.495002],
    [-10.005, 51.005002],
    [16.005, 51.005002],
    [16.005, 37.495002],
    [-10.005, 37.495002],
  ],
};

export function createArome(
  serverSpec: unknown,
  config?: WeatherModelInitConfig,
): WeatherModel {
  const spec = decodeForecastSpec(serverSpec);
  const timestepList = createTsListForecast(spec.initimeList, 48);
  const tsRainfall = getTsListRainfall(timestepList);
  const defaultDateRange = getDefaultDateRange(timestepList, 0, 47);
  const defaultDateRngRainfall = getDefaultDateRange(tsRainfall, 1, 47);
  const timestepType = { type: 'UNIX', interval: 3600 } as const;

  const aromeLayers: WeatherLayer[] = [
    {
      ...WIND_KTS,
      timestepType,
      timestepList,
      defaultDateRange,
    },
    {
      ...TEMPERATURE,
      timestepType,
      timestepList,
      defaultDateRange,
    },
    {
      ...RAINFALL_1HOUR,
      timestepType,
      timestepList: tsRainfall,
      defaultDateRange: defaultDateRngRainfall,
    },
    {
      ...RELATIVE_HUMIDITY,
      timestepType,
      timestepList,
      defaultDateRange,
    },
    {
      ...PRESSURE,
      timestepType,
      timestepList,
      defaultDateRange,
    },
    {
      ...LOW_CLOUD_COVER,
      timestepType,
      timestepList: tsRainfall,
      defaultDateRange: defaultDateRngRainfall,
    },
    {
      ...MEDIUM_CLOUD_COVER,
      timestepType,
      timestepList: tsRainfall,
      defaultDateRange: defaultDateRngRainfall,
    },
    {
      ...HIGH_CLOUD_COVER,
      timestepType,
      timestepList: tsRainfall,
      defaultDateRange: defaultDateRngRainfall,
    },
  ];

  const layers = removeUnwantedLayers(aromeLayers, config?.layerIdList ?? []);

  if (layers.length === 0)
    throw new Error(`No layers available for ${spec.model} model`);

  return {
    ...getBaseModelTemplate(config),
    ...AROME,
    layers,
    initime: layers[0].timestepList[0].initime,
    timestep: layers[0].defaultDateRange.from,
    selectedLayerId: layers[0].id,
    dateRange: layers[0].defaultDateRange,
  };
}
