import { Component, Input, OnInit } from '@angular/core';
import { Observable, take } from 'rxjs';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { Store } from '@ngrx/store';
import { selectUtcOffset } from '../../+state/weather.selectors';
import {
  FULL_MODEL_LIST,
  WeatherModel,
  WeatherModelMetadata,
} from '@trim-web-apps/weather-models';
import { initWeatherModel, setUtcOffset } from '../../+state/weather.actions';
import { map } from 'rxjs/operators';
import { WeatherService } from '../../services/weather.service';
import { Dialog } from '@angular/cdk/dialog';
import { WeatherModelPickerMobileComponent } from './weather-model-picker-mobile.component';

export type ModelPickerMenuData = {
  [key in WeatherModel['category']]?: {
    [key in WeatherModel['subCategory']]?: WeatherModelMetadata[];
  };
};

function toPickerData(modelList: WeatherModelMetadata[]): ModelPickerMenuData {
  modelList.sort((a, b) => a.label.localeCompare(b.label));
  const res: ModelPickerMenuData = {};

  for (const model of modelList) {
    const category = model.category;
    const subCategory = model.subCategory;

    if (res[category] === undefined) res[category] = {};
    if (res[category]![subCategory] === undefined)
      res[category]![subCategory] = [];
    res[category]![subCategory]?.push(model);
  }

  return res;
}

@Component({
    selector: 'weather-core-model-picker',
    template: `
    <div class="desktop shadow" *ngIf="!isMobile">
      <weather-core-model-picker-desktop
        [modelPickerMenuData]="modelPickerMenuData"
        (modelSelect)="onModelSelect($event)"
      />

      <div class="fill-remaining-space"></div>

      <div class="settings" *ngIf="utcOffsetData$ | async as view">
        <div class="tz-label">Timezone</div>
        <ui-select
          [items]="view.utcOffsetList"
          [itemSelected]="view.utcOffset"
          [allowNoneOption]="true"
          noneOptionLabel="Auto"
          (itemSelectedChange)="onUtcOffsetChanged($event)"
        />
      </div>
    </div>

    <div
      class="mobile shadow"
      *ngIf="isMobile"
      (click)="toggleMobilePickerDialog()"
    >
      <fa-icon [icon]="iconAdd" />
    </div>
  `,
    styles: [
        `
      .desktop {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: var(--spacing-1);
        background-color: #fff;
      }

      .mobile {
        position: absolute;
        bottom: 10px;
        right: 10px;
        background-color: var(--primary-color);
        color: var(--bg-color);
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: var(--font-size-4);
      }

      .settings {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: var(--spacing-3);
      }
    `,
    ],
    standalone: false
})
export class WeatherModelPickerComponent implements OnInit {
  @Input() isAppReady?: boolean;
  @Input() isMobile?: boolean;
  @Input() modelList?: WeatherModelMetadata[];

  modelPickerMenuData?: ModelPickerMenuData;
  utcOffsetData$: Observable<{ utcOffsetList: string[]; utcOffset: string }>;
  iconAdd = faPlus;

  constructor(
    private store: Store,
    private weatherService: WeatherService,
    private dialog: Dialog,
  ) {
    this.utcOffsetData$ = this.store.select(selectUtcOffset()).pipe(
      map((utcOffset) => {
        const utcOffsetList = this.weatherService.getUtcOffsetList();
        return { utcOffsetList, utcOffset };
      }),
    );
  }

  ngOnInit() {
    const modelList = this.modelList ?? FULL_MODEL_LIST;
    this.modelPickerMenuData = toPickerData(modelList);
  }

  onModelSelect(model: WeatherModelMetadata) {
    this.store.dispatch(initWeatherModel({ modelId: model.id }));
  }

  onUtcOffsetChanged(utcOffset: string | null): void {
    this.store.dispatch(setUtcOffset({ utcOffset }));
  }

  toggleMobilePickerDialog(): void {
    const data = this.modelPickerMenuData;
    this.dialog
      .open<WeatherModel>(WeatherModelPickerMobileComponent, { data })
      .closed.pipe(take(1))
      .subscribe((result) => {
        if (result) this.onModelSelect(result);
      });
  }
}
