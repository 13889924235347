import { WeatherModelInitConfig, WeatherModelMetadata } from '../types';
import { WeatherModel } from '../types/weather.model';
import { WeatherLayer } from '../types/weather-layer.type';
import {
  decodeNdviSpec,
  getBaseModelTemplate,
  removeUnwantedLayers,
} from '../utils';
import { NDVI_DEKADAL } from '../weather-layers/weather.layers';

export const NDVI: WeatherModelMetadata = {
  id: 'NDVI',
  label: 'NDVI',
  category: 'MONITORING',
  subCategory: 'VEGETATION',
  info: {
    resolution: { deg: 0.00362, km: 0.375 },
    coverage: 'East Africa',
    url: 'https://earlywarning.usgs.gov/fews/product/900#documentation',
    credits: 'USGS',
    schedule: [{ init: '', time: '06:00' }],
  },
  bbox: [
    [20.9988002, 23.0026498],
    [51.9882979, 23.0026498],
    [51.9882979, -12.499741],
    [20.9988002, -12.499741],
    [20.9988002, 23.0026498],
  ],
};

export function createNdvi(
  serverSpec: unknown,
  config?: WeatherModelInitConfig,
): WeatherModel {
  const spec = decodeNdviSpec(serverSpec);
  const timestepList = spec.timestepDekadalList;
  timestepList.sort();

  const defaultDateRng: { from: number; to: number } = {
    from: timestepList[timestepList.length - 13],
    to: timestepList[timestepList.length - 1],
  };

  const timestepType = { type: 'DEKADAL', interval: 1 } as const;

  const ndviLayers: WeatherLayer[] = [
    {
      ...NDVI_DEKADAL,
      timestepType,
      timestepList: [{ initime: -2, timesteps: timestepList }],
      defaultDateRange: defaultDateRng,
    },
  ];

  const layers = removeUnwantedLayers(ndviLayers, config?.layerIdList ?? []);

  if (layers.length === 0)
    throw new Error(`No layers available for ${spec.model} model`);

  return {
    ...getBaseModelTemplate(config),
    ...NDVI,
    interpolate: config?.interpolate ?? 'bicubicInterpolationAvg',
    layers,
    initime: layers[0].timestepList[0].initime,
    timestep: layers[0].defaultDateRange.to,
    selectedLayerId: layers[0].id,
    dateRange: layers[0].defaultDateRange,
  };
}
