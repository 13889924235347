import { Component, Input } from '@angular/core';
import { Form } from '3map-models';
import { Store } from '@ngrx/store';
import * as MapActions from '../../../map/+state/map.actions';

@Component({
  selector: 'app-form-specific-list',
  template: `
    <div class="form-specific-list-wrapper" *ngIf="formDataset">
      <div
        class="specific"
        *ngFor="let specific of formDataset.form.specificList"
        (click)="toggleSpecific(specific.id)"
      >
        <div class="form-icon">
          <app-specific-icon
            [widthPx]="25"
            [specificId]="specific.id"
          ></app-specific-icon>
        </div>
        <div class="form-name text-ellipsis">
          {{ specific.name }}
        </div>
        <div class="checkbox">
          <ui-checkbox [checked]="isEnabled(specific.id)"></ui-checkbox>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .form-specific-list-wrapper {
        padding: var(--spacing-3);
        max-height: 420px;
        overflow: auto;
      }

      .specific {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: var(--font-size-2);
        margin-bottom: var(--spacing-2);
        cursor: pointer;
      }

      .specific:last-child {
        margin-bottom: 0;
      }

      .checkbox {
        width: 16px;
      }

      .form-name {
        margin-left: var(--spacing-2);
        flex: 1;
        text-align: left;
      }
    `,
  ],
  standalone: false,
})
export class FormSpecificListComponent {
  @Input() formDataset?: {
    form: Form;
    dataset: { formId: string; specificIds: string[] };
  };

  constructor(private store: Store) {}

  isEnabled(specificId: string): boolean {
    if (!this.formDataset) return false;
    return this.formDataset.dataset.specificIds.includes(specificId);
  }

  toggleSpecific(specificId: string): void {
    if (!this.formDataset) return;
    const enabledSpecificIds = this.formDataset.dataset.specificIds;
    const specificIdsList = this.isEnabled(specificId)
      ? enabledSpecificIds.filter((i) => i !== specificId)
      : [...enabledSpecificIds, specificId];

    this.store.dispatch(
      MapActions.setEnabledSpecificList({
        formId: this.formDataset.form.id,
        specificIdsList,
      }),
    );
  }
}
