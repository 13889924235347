import { Component, Inject, OnInit } from '@angular/core';
import { Form } from '3map-models';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-form-dialog',
  template: `
    <div class="wrapper" *ngIf="formProperties">
      <div class="section-title">{{ titleLabel }}</div>
      <input
        type="text"
        #formNameInput
        [(ngModel)]="formProperties.name"
        placeholder="Form name"
      />

      <div class="section-label">Allow media</div>

      <ui-checkbox
        (click)="formProperties.allowImage = !formProperties.allowImage"
        [checked]="formProperties.allowImage"
        label="Image"
      />
      <ui-checkbox
        (click)="formProperties.allowAudio = !formProperties.allowAudio"
        [checked]="formProperties.allowAudio"
        label="Audio"
      />
      <ui-checkbox
        (click)="formProperties.allowVideo = !formProperties.allowVideo"
        [checked]="formProperties.allowVideo"
        label="Video"
      />
      <ui-checkbox
        (click)="formProperties.allowFile = !formProperties.allowFile"
        [checked]="formProperties.allowFile"
        label="File"
      />

      <div class="section-label">Advanced settings</div>

      <ui-checkbox
        (click)="
          formProperties.preventDownloadInProjectData =
            !formProperties.preventDownloadInProjectData
        "
        [checked]="formProperties.preventDownloadInProjectData"
        label="Prevent download in Project data"
      />

      <div class="actions">
        <ui-btn (btnClick)="onSaveChanges()" [label]="actionLabel"></ui-btn>
      </div>
    </div>
  `,
  styles: [
    `
      .wrapper {
        width: 350px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        background-color: var(--bg-color);
      }

      .section-title {
        font-size: 1.2em;
        margin-bottom: 20px;
        color: var(--primary-color);
      }

      input {
        width: 100%;
      }

      .actions {
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }

      .section-label {
        margin: var(--spacing-4) 0 var(--spacing-2) 0;
      }

      ui-checkbox {
        margin-left: var(--spacing-3);
      }
    `,
  ],
  standalone: false,
})
export class FormDialogComponent implements OnInit {
  titleLabel: string | undefined;
  actionLabel: string | undefined;
  formProperties?: {
    name: string;
    allowImage: boolean;
    allowVideo: boolean;
    allowAudio: boolean;
    allowFile: boolean;
    preventDownloadInProjectData: boolean;
  };

  constructor(
    public dialogRef: DialogRef<Form | undefined>,
    @Inject(DIALOG_DATA)
    public data: { form: Form; mode: 'create' | 'edit' | 'duplicate' },
  ) {}

  ngOnInit(): void {
    if (!this.data) this.dialogRef.close();

    if (!this.data.form) throw Error('[FormProperties] Missing input Form');
    if (!this.data.mode) throw Error('[FormProperties] "mode" not defined');

    this.titleLabel =
      this.data.mode.charAt(0).toUpperCase() +
      this.data.mode.slice(1) +
      ' Form Collection';
    if (this.data.mode === 'create') this.actionLabel = 'Create';
    if (this.data.mode === 'edit') this.actionLabel = 'Save';
    if (this.data.mode === 'duplicate') this.actionLabel = 'Duplicate';

    this.formProperties = {
      name: this.data.form.name,
      allowImage: this.data.form.allowImage,
      allowVideo: this.data.form.allowVideo,
      allowAudio: this.data.form.allowAudio,
      allowFile: this.data.form.allowFile,
      preventDownloadInProjectData:
        this.data.form.preventDownloadInProjectData ?? false,
    };
  }

  onSaveChanges(): void {
    if (!this.data.form || !this.formProperties) return;
    const newForm = { ...this.data.form, ...this.formProperties };
    this.dialogRef.close(newForm);
  }
}
