import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncateStr',
    standalone: false
})
export class TruncateStrPipe implements PipeTransform {
  transform(value: string, limit = 25, completeWords = false) {
    if (completeWords) limit = value.substr(0, limit).lastIndexOf(' ');
    return value.length > limit ? value.substr(0, limit) + '...' : value;
  }
}
