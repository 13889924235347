import { WeatherModelInitConfig, WeatherModelMetadata } from '../types';
import { WeatherModel } from '../types/weather.model';
import { WeatherLayer } from '../types/weather-layer.type';
import {
  HIGH_CLOUD_COVER,
  LOW_CLOUD_COVER,
  MEDIUM_CLOUD_COVER,
  PRESSURE,
  RAINFALL_1HOUR,
  RELATIVE_HUMIDITY,
  TEMPERATURE,
  TOTAL_CLOUD_COVER,
  WIND_GUST,
  WIND_KTS,
} from '../weather-layers/weather.layers';
import {
  createTsListForecast,
  decodeForecastSpec,
  getBaseModelTemplate,
  getDefaultDateRange,
  removeUnwantedLayers,
} from '../utils';

export const WRF_AARHUS_05: WeatherModelMetadata = {
  id: 'WRF_AARHUS_05',
  label: 'WRF Aarhus',
  category: 'FORECAST',
  subCategory: 'LOCAL_MODELS',
  bbox: [
    [9.4975, 57.0025],
    [11.0025, 57.0025],
    [11.0025, 54.9975],
    [9.4975, 54.9975],
    [9.4975, 57.0025],
  ],
  info: { resolution: { deg: 0.005, km: 0.5 } },
};

export function createWrfAarhus05(
  serverSpec: unknown,
  config?: WeatherModelInitConfig,
): WeatherModel {
  const spec = decodeForecastSpec(serverSpec);
  const timestepList = createTsListForecast(spec.initimeList, 24);
  const defaultDateRange = getDefaultDateRange(timestepList, 0, 23);
  const timestepType = { type: 'UNIX', interval: 3600 } as const;

  const wrf05Layers: WeatherLayer[] = [
    {
      ...WIND_KTS,
      timestepType,
      timestepList,
      defaultDateRange,
    },
    {
      ...WIND_GUST,
      timestepType,
      timestepList,
      defaultDateRange,
    },
    {
      ...TEMPERATURE,
      timestepType,
      timestepList,
      defaultDateRange,
    },
    {
      ...TEMPERATURE,
      id: 'temperature_2m',
      label: 'Temperature (2m)',
      timestepType,
      timestepList,
      defaultDateRange,
    },
    {
      ...RELATIVE_HUMIDITY,
      timestepType,
      timestepList,
      defaultDateRange,
    },
    {
      ...PRESSURE,
      timestepType,
      timestepList,
      defaultDateRange,
    },
    {
      ...LOW_CLOUD_COVER,
      timestepType,
      timestepList,
      defaultDateRange,
    },
    {
      ...MEDIUM_CLOUD_COVER,
      timestepType,
      timestepList,
      defaultDateRange,
    },
    {
      ...HIGH_CLOUD_COVER,
      timestepType,
      timestepList,
      defaultDateRange,
    },
    {
      ...TOTAL_CLOUD_COVER,
      timestepType,
      timestepList,
      defaultDateRange,
    },
    {
      ...RAINFALL_1HOUR,
      timestepType,
      timestepList,
      defaultDateRange,
    },
  ];

  const layers = removeUnwantedLayers(wrf05Layers, config?.layerIdList ?? []);

  if (layers.length === 0)
    throw new Error(`No layers available for ${spec.model} model`);

  return {
    ...getBaseModelTemplate(config),
    ...WRF_AARHUS_05,
    layers,
    initime: layers[0].timestepList[0].initime,
    timestep: layers[0].defaultDateRange.from,
    selectedLayerId: layers[0].id,
    dateRange: layers[0].defaultDateRange,
  };
}
