import { TEMPERATURE } from '../weather-layers/weather.layers';

import {
  TimestepType,
  WeatherLayer,
  WeatherModel,
  WeatherModelInitConfig,
  WeatherModelMetadata,
} from '../types';
import {
  createTsListGpm,
  decodeCpcSpec,
  getBaseModelTemplate,
  removeUnwantedLayers,
} from '../utils';

export const CPC: WeatherModelMetadata = {
  id: 'CPC',
  label: 'CPC',
  category: 'MONITORING',
  subCategory: 'TEMPERATURE',
  info: {
    resolution: { deg: 0.5, km: 55 },
    coverage: 'Global',
    url: 'https://psl.noaa.gov/',
    credits: 'NOAA Physical Sciences Laboratory (PSL)',
    schedule: [{ init: 'Daily', time: '15:00' }],
  },
  bbox: [
    [-180, 90],
    [180, 90],
    [180, -90],
    [-180, -90],
    [-180, 90],
  ],
};

export function createCPC(
  serverSpec: unknown,
  config?: WeatherModelInitConfig,
): WeatherModel {
  const spec = decodeCpcSpec(serverSpec);
  const tsDay = spec.timestepList;
  console.log(tsDay);

  const tsListDay = createTsListGpm(tsDay, false);
  const tsTypeDay: TimestepType = { type: 'DAYS', interval: 1 };
  const defaultDateRangeDay = {
    from: tsListDay[0].timesteps[tsListDay[0].timesteps.length - 30],
    to: tsListDay[0].timesteps[tsListDay[0].timesteps.length - 1],
  };

  const gpmLayers: WeatherLayer[] = [
    {
      ...TEMPERATURE,
      id: 'temperature_max',
      label: 'Temperature Max',
      timestepType: tsTypeDay,
      timestepList: tsListDay,
      defaultDateRange: defaultDateRangeDay,
    },
    {
      ...TEMPERATURE,
      id: 'temperature_min',
      label: 'Temperature Min',
      timestepType: tsTypeDay,
      timestepList: tsListDay,
      defaultDateRange: defaultDateRangeDay,
    },
    // {
    //   ...RAINFALL_1DAY, // remove this
    //   timestepType: tsTypeDay,
    //   timestepList: tsListDay,
    //   defaultDateRange: defaultDateRangeDay,
    // },
  ];

  const layers = removeUnwantedLayers(gpmLayers, config?.layerIdList ?? []);

  if (layers.length === 0)
    throw new Error(`No layers available for ${spec.model} model`);

  return {
    ...getBaseModelTemplate(config),
    ...CPC,
    layers,
    initime: layers[0].timestepList[0].initime,
    timestep: layers[0].defaultDateRange.to,
    selectedLayerId: layers[0].id,
    dateRange: layers[0].defaultDateRange,
    info: { ...CPC.info },
  };
}
