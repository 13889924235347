import { createAction, props } from '@ngrx/store';
import {
  ChartData,
  InterpolateType,
  WeatherLayer,
  WeatherModel,
  WeatherModelInitConfig,
  WeatherPopupData,
} from '@trim-web-apps/weather-models';

export const initWeatherModel = createAction(
  '[ Weather ] Init Model',
  props<{ modelId: string; config?: WeatherModelInitConfig }>(),
);
export const initWeatherModelSuccess = createAction(
  '[ Weather ] Init Model Success',
  props<{ model: WeatherModel }>(),
);
export const initWeatherModelError = createAction(
  '[ Weather ] Init Model Error',
  props<{ modelId: string }>(),
);
export const removeWeatherModel = createAction(
  '[ Weather ] Disable Model',
  props<{ modelId: string }>(),
);
export const setInitime = createAction(
  '[ Weather ] Set Initime',
  props<{ modelId: string; initime: number }>(),
);
export const setLayer = createAction(
  '[ Weather ] Set Layer',
  props<{ modelId: string; layer: WeatherLayer }>(),
);
export const setLayerById = createAction(
  '[ Weather ] Set Layer By Id',
  props<{ modelId: string; layerId: string }>(),
);
export const setTimestep = createAction(
  '[ Weather ] Set Timestep',
  props<{ modelId: string; timestep: number }>(),
);
export const setDateRange = createAction(
  '[ Weather ] Set Date Range',
  props<{ modelId: string; dateRange: { from: number; to: number } }>(),
);
export const setModelVisibility = createAction(
  '[ Weather ] Set Model Visible',
  props<{ modelId: string; visible: boolean }>(),
);
export const toggleModelVisibility = createAction(
  '[ Weather ] Toggle Model Visible',
  props<{ modelId: string }>(),
);
export const setChartEnabled = createAction(
  '[ Weather ] Set Chart Enabled',
  props<{ modelId: string }>(),
);
export const setChartDisabled = createAction(
  '[ Weather ] Set Chart Disabled',
  props<{ modelId: string }>(),
);
export const setChartDataVisibility = createAction(
  '[ Weather ] Set ChartData Visibility',
  props<{ modelId: string; data: ChartData; visibility: boolean }>(),
);
export const fetchChartData = createAction(
  '[ Weather ] Fetch Chart Data',
  props<{ modelId: string }>(),
);
export const setChartData = createAction(
  '[ Weather ] Set Chart Data',
  props<{ modelId: string; chartData: ChartData[] }>(),
);
export const setWindStyle = createAction(
  '[ Weather ] Set Wind Style',
  props<{ modelId: string; windStyle: string }>(),
);
export const setInterpolate = createAction(
  '[ Weather ] Set Interpolate',
  props<{ modelId: string; interpolate: InterpolateType }>(),
);
export const tiffLoading = createAction(
  '[ Weather ] Tiff Loading',
  props<{ modelId: string }>(),
);
export const tiffLoadingSuccess = createAction(
  '[ Weather ] Tiff Loading Success',
  props<{ modelId: string }>(),
);
export const tiffLoadingError = createAction(
  '[ Weather ] Tiff Loading Error',
  props<{ modelId: string }>(),
);
export const setSelectedLngLat = createAction(
  '[ Weather ] Set Selected LngLat',
  props<{ selectedLngLat: { lng: number; lat: number } | null }>(),
);
export const setUtcOffset = createAction(
  '[ Weather ] Set UTC Offset',
  props<{ utcOffset: string | null }>(),
);
export const setMapBoundingArray = createAction(
  '[ Weather ] Set Map BBOX',
  props<{ mapBoundingArray: number[] }>(),
);

export const reset = createAction('[ Weather ] Reset ');

export const addPopupData = createAction(
  '[ Weather ] Add Popup Data',
  props<{ popupData: WeatherPopupData }>(),
);
