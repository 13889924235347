import * as mapboxgl from 'mapbox-gl';
import { MapStyleName } from './map.styles';

/**
 * maxBounds: [
 *  [ lng, lat ], // southwest
 *  [ lng, lat ]  // northeast
 * ]
 */

export interface MapConfig {
  center: { lng: number; lat: number };
  zoom: number;
  plugins: MapboxPlugin[];
  maxBounds: mapboxgl.LngLatBoundsLike | null;
  controlPosition: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left';
  style: MapStyleName;
}

export type MapboxPlugin =
  | 'SCALE'
  | 'SWITCHER'
  | 'RULER'
  | 'ZOOM'
  | 'NAVIGATION'
  | 'GEOLOCATE'
  | 'ATTRIBUTION'
  | 'FULLSCREEN';
