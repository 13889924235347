import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  faCheck,
  faChevronDown,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'ui-select-base',
    template: ``,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SelectBaseComponent implements AfterViewInit {
  @ViewChild('wrapper') wrapper?: ElementRef<HTMLDivElement>;
  @ViewChild('menuTrigger') menuTrigger?: ElementRef;
  @ViewChild('menuDiv') menuDiv?: ElementRef<HTMLDivElement>;
  @ViewChildren('myMenuItem') menuItems?: QueryList<ElementRef>;
  @Input() items: string[] = [];
  @Input() widthPx?: number;
  @Input() disabled?: boolean;
  menuWidth?: number;
  iconExpand: IconDefinition = faChevronDown;
  iconSelected: IconDefinition = faCheck;
  menuOpenedDirection: 'above' | 'below' | null = null;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.setWidth();
  }

  // TODO: dropdown size calc can be simplified using template variables
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setWidth();
  }

  setWidth() {
    /**
     * Priority: widthPx > wrapper.clientWidth
     * NOTE: wrapper.clientWidth is 0 when dropdown is hidden.
     * For some reason the border (1px) is not included in the width, so 1px per
     * side is subtracted...
     */
    const wrapperWidth = this.wrapper?.nativeElement.offsetWidth;
    const menuWidth = this.widthPx ? this.widthPx : wrapperWidth;
    this.menuWidth = menuWidth ? menuWidth - 2 : menuWidth;
  }

  /**
   * The most hacky way to detect if the menu is opened above or below the trigger...
   * Due to change detection, the menu is not yet rendered when the cdkMenuOpened
   * event is fired, so we need to wait a bit before checking the offsetTop.
   *
   * For unknown reasons at the time of writing, the offsetTop is 1 when the
   * menu is opened below the trigger, and > 1 when the menu is opened above
   */
  onCdkMenuOpened(): void {
    setTimeout(() => {
      console.log(`disabled: ${this.disabled}`);
      if (this.disabled) return;
      const menuOffsetTop = this.menuDiv?.nativeElement.offsetTop;
      // console.log('menuOffsetTop', menuOffsetTop);
      this.menuOpenedDirection =
        menuOffsetTop && menuOffsetTop > 1 ? 'above' : 'below';
      this.changeDetectorRef.detectChanges(); // yeah, I know...

      // Scroll to selected item if it is not in view
      this.menuItems
        ?.find((item) => item.nativeElement.classList.contains('selected'))
        ?.nativeElement?.scrollIntoView({ block: 'center' });
    }, 0);
  }

  onCdkMenuClosed(): void {
    this.menuOpenedDirection = null;
  }
}
