import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Form, FormSpecific, Record } from '3map-models';
import {
  faLocationCrosshairs,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';

export type FeatureViewData = {
  form: Form;
  specific: FormSpecific;
  records: Record[];
  user: { username: string; role: 'ADMIN' | 'MOD' | 'USER' };
};

@Component({
  selector: 'app-feature-view',
  template: `
    <div class="feature-view-wrapper" *ngIf="featureViewData">
      <div class="header">
        <div class="icon">
          <app-specific-icon
            [widthPx]="40"
            [specificId]="featureViewData.records[0].formSpecificId"
          ></app-specific-icon>
        </div>
        <div class="specific-name text-ellipsis">
          {{ featureViewData.specific.name }}
        </div>
        <div class="actions">
          <!--          <fa-icon [icon]="iconPlus" ></fa-icon>-->
          <fa-icon [icon]="iconGps" (click)="onZoomToRecord()"></fa-icon>
        </div>
      </div>

      <!-- "itemSize" should match app-record-card-compact height! -->
      <cdk-virtual-scroll-viewport itemSize="200" class="record-list">
        <app-record-card-compact
          *cdkVirtualFor="let record of featureViewData.records; let i = index"
          [record]="record"
          [form]="featureViewData.form"
          [specific]="featureViewData.specific"
          [user]="featureViewData.user"
          [isFirst]="i === 0"
          [isLast]="i === featureViewData.records.length - 1"
          (recordSelected)="recordSelected.emit(record)"
          (recordDelete)="recordDelete.emit($event)"
          (recordEdit)="recordEdit.emit($event)"
        />
      </cdk-virtual-scroll-viewport>

      <div class="update-feature">
        <ui-btn label="Update Feature" (click)="update()" [shadow]="false" />
      </div>

      <app-feature-metadata [recordList]="featureViewData.records" />
    </div>
  `,
  styles: [
    `
      .feature-view-wrapper {
        width: 350px;
        padding: 10px 0 10px 10px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        height: 100vh;
        box-shadow:
          0 14px 28px rgba(0, 0, 0, 0.25),
          0 10px 10px rgba(0, 0, 0, 0.22);
      }

      .header {
        align-items: center;
        margin-bottom: 10px;
        height: 40px;
        display: flex;
      }

      .specific-name {
        padding: 0 10px;
        flex: 1 1 auto;
      }

      .actions {
        width: 80px;
        display: flex;
        justify-content: right;
        align-items: center;
      }

      .actions fa-icon {
        padding: 5px;
        margin: 0 5px;
        font-size: 1.2em;
        cursor: pointer;
      }

      .update-feature {
        display: flex;
        justify-content: center;
        padding: 20px 0;
        /*position: sticky;*/
        /*bottom: 0;*/
      }

      .record-list {
        height: 100%;
        /*margin-bottom: 10px;*/
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FeatureViewComponent {
  @Input() featureViewData: FeatureViewData | undefined;
  @Output() zoomToRecord = new EventEmitter<string>();
  @Output() recordSelected = new EventEmitter<Record>();
  @Output() recordDelete = new EventEmitter<Record>();
  @Output() recordEdit = new EventEmitter<Record>();
  @Output() recordUpdate = new EventEmitter<Record>();
  iconGps = faLocationCrosshairs;
  iconPlus = faPlus;

  onZoomToRecord(): void {
    const record = this.featureViewData?.records[0];
    if (record) this.zoomToRecord.emit(record.recordId);
  }

  update(): void {
    const record = this.featureViewData?.records[0];
    if (record) this.recordUpdate.emit(record);
  }
}
