// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { TimestepType, WeatherTimestep } from './weather.timestep.ts'; // explicit extension for Deno

export type WeatherLayer = {
  id: string;
  timestepList: WeatherTimestep[];
  timestepType: TimestepType;
  defaultDateRange: { from: number; to: number };
  label: string;
  unit: string;
  conversionFactor: number;
  popupDecimalDigits: number;
  legend: {
    values: number[];
    hex: string[];
    rgb: number[][];
    opacity: number;
  };
};
