import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, map, of, switchMap, throwError } from 'rxjs';
import * as AdminActions from './admin.actions';
import { catchError, take, withLatestFrom } from 'rxjs/operators';
import { AuthActions, AuthSelectors } from '../../auth/state/auth';
import { ApiService } from '@trim-web-apps/api3map';
import { MarkerService } from '../shared/components/marker-canvas/marker.service';
import { NotificationService } from '@trim-web-apps/core';

@Injectable()
export class AdminEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private api: ApiService,
    private markerManager: MarkerService,
    private notify: NotificationService,
  ) {}

  initProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.initProject),
      withLatestFrom(this.store.select(AuthSelectors.selectCollaboration())),
      switchMap(([, collaboration]) => {
        return collaboration !== null
          ? this.api.getAdminProject(collaboration.projectName)
          : throwError('No collaboration');
      }),
      switchMap((project) =>
        combineLatest([
          this.markerManager.getProjectIconsBase64(project),
          of(project),
        ]).pipe(take(1)),
      ),
      map(([icons, project]) => {
        return AdminActions.initProjectSuccess({ project, icons });
      }),
      catchError(() => of(AdminActions.initProjectError())),
    ),
  );

  deleteProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.deleteProject),
      switchMap(({ projectName }) => this.api.deleteProject(projectName)),
      map((success) => {
        if (success) {
          this.notify.success('Project Deleted');
          return AuthActions.authLogout();
        }
        this.notify.error('Cannot delete this Project', 3000);
        return AdminActions.noOp();
      }),
    ),
  );
}
