import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { MapService } from '@trim-web-apps/map';
import { WeatherPopupData } from '@trim-web-apps/weather-models';
import { map, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { WeatherActions, WeatherSelectors } from '../../+state';
import { Popup } from 'mapbox-gl';

@Component({
    selector: 'weather-core-popup',
    template: `
    <ng-container *ngIf="popupData$ | async as popupList">
      <div class="popup-container" style="display: none">
        <div class="popup-wrapper" #popup>
          <div class="values-wrapper" *ngFor="let popup of popupList">
            <ng-container *ngIf="popup.data">
              <span class="model"> [{{ popup.modelId }}] </span>
              <div class="values-list" *ngFor="let d of popup.data">
                <span class="field"> {{ d.field }}: </span>
                <span class="value"> {{ d | weatherPopupValue }} </span>
                <span class="unit"> {{ d.unit }} </span>
              </div>
            </ng-container>
          </div>
          <span class="close-btn" (click)="onRemovePopup()"> close </span>
        </div>
      </div>
    </ng-container>
  `,
    styles: [
        `
      .model {
        font-weight: 500;
      }

      .values-list {
        padding-left: 20px;
      }

      .popup-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .values-wrapper {
        margin-bottom: 15px;
      }

      .close-btn {
        width: 100%;
        border-top: 1px solid #eeeeee;
        text-align: center;
        cursor: pointer;
      }
    `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class WeatherPopupComponent {
  @ViewChild('popup') popupComponent?: ElementRef<HTMLDivElement>;
  popupData$?: Observable<WeatherPopupData[]>;
  private popup?: Popup;

  constructor(
    private mapService: MapService,
    private store: Store,
  ) {
    this.popupData$ = this.store
      .select(WeatherSelectors.selectAllPopupData())
      .pipe(
        map((popupData) => {
          if (popupData.popupDataList.length > 0)
            this.addPopup(popupData.lngLat);
          else this.removePopup();
          return popupData.popupDataList;
        }),
      );
  }

  onRemovePopup(): void {
    this.store.dispatch(
      WeatherActions.setSelectedLngLat({ selectedLngLat: null }),
    );
  }

  removePopup(): void {
    if (this.popup) this.popup.remove();
  }

  addPopup(lngLat: { lng: number; lat: number } | null): void {
    this.removePopup();
    if (!this.popupComponent || !lngLat) return;
    this.popup = new Popup({
      closeOnClick: false,
      closeButton: false,
      maxWidth: '350px',
    })
      .setLngLat([lngLat.lng, lngLat.lat])
      .setDOMContent(this.popupComponent.nativeElement)
      // .setHTML(content)
      .addTo(this.mapService.map);
  }
}
