import { Component, Input } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export type IconButtonStatus = 'active' | 'inactive' | 'disabled';

@Component({
    selector: 'core-icon-button',
    template: `
    <button
      [ngStyle]="{
        'width.px': width,
      }"
      [ngClass]="status"
      (click)="onClick($event)"
    >
      <div class="button-content">
        <div class="left">
          <fa-icon [icon]="iconLeft" *ngIf="iconLeft"></fa-icon>
          <span *ngIf="matIconLeft" class="material-icons">
            {{ matIconLeft }}
          </span>
        </div>

        <div class="text">{{ text }}</div>

        <div class="right">
          <fa-icon [icon]="iconRight" *ngIf="iconRight"></fa-icon>
          <span *ngIf="matIconRight" class="material-icons">
            {{ matIconRight }}
          </span>
        </div>
      </div>
    </button>
  `,
    styles: [
        `
      .button-content,
      .left,
      .right {
        display: flex;
        align-items: center;
      }

      .text {
        flex: 1 1 auto;
      }

      .left {
        margin-right: 10px;
      }

      .right {
        margin-left: 10px;
      }

      .active {
        color: var(--primary-color);
        background-color: var(--primary-light-color);
        border: 1px solid var(--primary-color);
      }

      .inactive {
        color: var(--primary-color);
        background-color: #ffffff;
        border: 1px solid var(--primary-color);
      }

      .inactive:hover {
        background-color: var(--primary-light-color) !important;
      }

      .disabled {
        color: #333;
        background-color: var(--border);
      }
    `,
    ],
    standalone: false
})
export class IconButtonComponent {
  @Input() text: string | undefined;
  @Input() iconLeft: IconDefinition | undefined;
  @Input() iconRight: IconDefinition | undefined;
  @Input() matIconLeft: string | undefined;
  @Input() matIconRight: string | undefined;
  @Input() color: 'primary' | 'warn' | 'accent' | undefined;
  @Input() emitOn: 'left' | 'right' | 'all' | undefined;
  @Input() width: number | undefined;
  @Input() iconSpin = false;
  @Input() disabled: boolean | undefined;
  @Input() status: IconButtonStatus;

  constructor() {
    this.status = 'inactive';
  }

  onClick(evt: Event): void {
    if (this.status === 'disabled') evt.stopImmediatePropagation();
  }
}
