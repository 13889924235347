import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  BaseExportParams,
  Column,
  ColumnVisibleEvent,
  GetContextMenuItemsParams,
  GridApi,
  GridOptions,
  IRowNode,
  MenuItemDef,
  ProcessCellForExportParams,
} from 'ag-grid-community';
import { Observable, Subscription } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';
import { FieldType, Record } from '3map-models';
import * as moment from 'moment';
import { Moment } from 'moment';
import { DownloadService, notNullOrUndefined } from '@trim-web-apps/core';
import { TableData } from '../+types/table-data.type';
import {
  DEFAULT_GRID_OPTIONS,
  TABLE_CONFIG_DEFAULT,
} from '../+helpers/table.defaults';
import { getColumns } from '../+helpers/table.columns';
import {
  TableEvent,
  TableProcessedRecordsEvent,
  TableZoomToRecordEvent,
  TableZoomToRecordListEvent,
} from '../+types/table-record-event.type';
import { TableConfig } from '../+types/table-config.type';
import { recordToRow } from '../+helpers/table.utils';

@Component({
  selector: 'map3-table',
  template: `
    <div class="table-wrapper">
      <ng-container *ngIf="tableConfig.showActions">
        <map3-table-actions
          *ngIf="tableData"
          [tableData]="tableData"
          [columns]="columns"
          [groupByColumn]="groupByColumn"
          (columnSelected)="setGroupByColumn($event)"
          (toggleHistory)="onToggleHistory(tableData.dataset.formId)"
          (resetFilters)="onResetFilters()"
          (enabledSpecificList)="
            onSpecificEnabledChange(tableData.dataset.formId, $event)
          "
        />
      </ng-container>
      <div class="grid-wrapper">
        <ag-grid-angular
          (gridReady)="onGridReady($event)"
          [gridOptions]="gridOptions"
          style="height: 100%"
          class="ag-theme-balham"
        />
      </div>
    </div>
  `,
  styles: [
    `
      .table-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      map3-table-actions {
        /*height: 70px;*/
      }

      .grid-wrapper {
        flex: 1;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TableComponent implements OnDestroy {
  @Input() tableData$?: Observable<TableData>;
  @Input() tableConfig: TableConfig = TABLE_CONFIG_DEFAULT;
  @Output() tableEvent = new EventEmitter<TableEvent>();

  gridOptions: GridOptions = DEFAULT_GRID_OPTIONS;
  columns: Column[] = [];
  groupByColumn: Column | undefined | null;
  tableData: TableData | null = null;
  private _tableDataSub: Subscription | undefined;
  private _api: GridApi | undefined;

  constructor(
    private clipboard: Clipboard,
    private download: DownloadService,
  ) {}

  ngOnDestroy(): void {
    this._tableDataSub?.unsubscribe();
  }

  onGridReady(params: { api: GridApi }): void {
    this._api = params.api;

    this._tableDataSub = this.tableData$?.subscribe((tableData) => {
      this.tableData = tableData;
      if (!this.tableData) return;
      this.updateColumns();
      this.updateRows(this.tableData);
      this.emitProcessedRecords(this.tableData.form.id);
      this.onTableDataChange().catch();
    });

    this._api.setGridOption('onFilterChanged', (e) => {
      if (this.tableData) this.emitProcessedRecords(this.tableData.form.id);
    });

    this._api.setGridOption('onSortChanged', () => {
      if (this.tableData) this.emitProcessedRecords(this.tableData.form.id);
    });

    this._api.setGridOption('getContextMenuItems', (params) => {
      return this.getContextMenu(params);
    });

    this._api.setGridOption('onColumnVisible', (evt) => {
      this.updateColumns(evt);
    });
  }

  get gridApi(): GridApi {
    if (!this._api) throw Error('GridApi not initialized');
    return this._api;
  }

  setGroupByColumn(col: Column | null): void {
    this.gridApi.setRowGroupColumns(col ? [col] : []);
    this.groupByColumn = col;
  }

  onToggleHistory(formId: string): void {
    this.tableEvent.emit({
      type: 'TOGGLE_HISTORY',
      formId,
    });
  }

  onSpecificEnabledChange(formId: string, specificIdsList: string[]): void {
    this.tableEvent.emit({
      type: 'ENABLED_SPECIFIC_ID_LIST',
      formId,
      specificIdsList,
    });
  }

  onResetFilters(): void {
    this.gridApi.setFilterModel({});
  }

  private async onTableDataChange(): Promise<void> {
    if (!this.tableData) return;
    this.updateColumns();
    this.updateRows(this.tableData);
    this.emitProcessedRecords(this.tableData.form.id);
  }

  private updateColumns(evt?: ColumnVisibleEvent): void {
    // avoid infinite loop
    if (evt?.source === 'api') return;
    if (!this.tableData) return;

    // keep columns state to restore it after new rows are set
    // e.g. user has hidden some columns
    const columnState = this.gridApi.getColumnState();

    const iconCb = (recordId: string, mediaIndex: number) => {
      this.tableEvent.emit({ type: 'TABLE_MEDIA', recordId, mediaIndex });
    };
    const iconFileCb = (fileName: string, projectName: string) => {
      const url = `${this.tableConfig.apiUrl}/static/${projectName}/project/${fileName}`;
      this.download.downloadText(url, fileName);
    };

    const columnList = getColumns(this.tableData, iconCb, iconFileCb);
    const hiddenColumnIdList = this.columns
      .filter((c) => !c.isVisible())
      .map((c) => c.getColId());

    // remove filter item if column is hidden
    const columns = columnList.map((col) => {
      return col.field !== undefined && hiddenColumnIdList.includes(col.field)
        ? { ...col, suppressFiltersToolPanel: true }
        : col;
    });

    // this.gridApi.setColumnDefs(columns);
    this.gridApi.setGridOption('columnDefs', columns);
    this.gridApi.applyColumnState({ state: columnState });
    this.columns = this.gridApi.getColumns() || [];
  }

  private updateRows(tableData: TableData): void {
    const specificMap: Map<string, string> = new Map<string, string>();
    const filterModel = this.gridApi.getFilterModel();

    let specificQuestions: FieldType[] = [];

    tableData.form.specificList.forEach((specific) => {
      specificMap.set(specific.id, specific.name);
      specificQuestions = [...specificQuestions, ...specific.questions];
    });

    this.gridApi.setGridOption(
      'rowData',
      tableData.records.map((r) =>
        recordToRow(r, specificQuestions, specificMap),
      ),
    );

    this.gridApi.setFilterModel(filterModel);
  }

  private emitProcessedRecords(formId: string): void {
    const processedRecordsIds: string[] = [];
    this.gridApi.forEachNodeAfterFilterAndSort((row) => {
      if (!row.group) processedRecordsIds.push(row.data.recordId);
    });
    const event: TableProcessedRecordsEvent = {
      type: 'PROCESSED_RECORDS',
      processedRecordsIds,
      formId,
    };
    this.tableEvent.emit(event);
  }

  private getContextMenu(
    params: GetContextMenuItemsParams,
  ): (MenuItemDef | string)[] {
    if (!this.tableConfig.enableContextMenu || !this.tableData) return [];

    // if right-clicked on empty space (e.g. below the last row), the show only the export menu
    // this also prevent the standard browser context menu from showing
    if (!params.node) return [this.ctxMenuExport(this.tableData)];

    const contextItems: (MenuItemDef | string)[] = [];

    if (this.tableConfig.allowCreateRecord)
      contextItems.push(this.ctxMenuCreateRecord(this.tableData));

    const selectedNodes = params.api?.getSelectedNodes() || [];
    let selectedRecords = rowNodesToRecords(
      selectedNodes,
      this.tableData.records,
    );
    // right-clicked row
    const rightClickRecord = rowNodesToRecords(
      [params.node],
      this.tableData.records,
    );

    // no rows selected, marks right-clicked row as selected
    if (selectedRecords.length === 0) {
      params.node.setSelected(true);
      selectedRecords = rightClickRecord;
    } else {
      // there are some rows selected
      const selectedRecordIds = selectedRecords.map((r) => r.recordId);
      const rightClickRecordId = rightClickRecord[0].recordId;
      // if right-clicked row is *NOT* in selected rows, then unselect all rows
      // and mark right-clicked row as the only one selected
      if (!selectedRecordIds.includes(rightClickRecordId)) {
        params.api?.deselectAll();
        params.node.setSelected(true);
        selectedRecords = rightClickRecord;
      }
    }

    const userHasRightAllRecords = selectedRecords
      .map((record) => userHasEditDeleteRights(record, this.tableData))
      .reduce((acc, item) => acc && item, true);

    if (userHasRightAllRecords && selectedRecords.length > 0) {
      if (selectedRecords.length === 1) {
        const recordId = selectedRecords[0].recordId;
        contextItems.push(this.ctxMenuUpdateRecord(recordId));
        contextItems.push(this.ctxMenuEditRecord(recordId));
      }
      contextItems.push(this.ctxMenuDeleteRecord(selectedRecords));
    }

    contextItems.push(this.ctxShowRecordOnMap(selectedRecords));

    if (this.tableConfig.allowFeatureMigrate)
      contextItems.push(this.ctxMigrateFeatures(selectedRecords));

    contextItems.push('separator');

    if (selectedRecords.length === 1)
      contextItems.push(this.ctxCopyCell(params));
    contextItems.push(this.ctxMenuExport(this.tableData));

    return contextItems;
  }

  private ctxMenuCreateRecord(tableData: TableData): MenuItemDef {
    const subMenu: MenuItemDef[] = tableData.form.specificList
      .filter((s) => tableData.dataset.specificIds.includes(s.id))
      .map((specific) => {
        return {
          name: specific.name,
          action: () =>
            this.tableEvent.emit({
              type: 'CREATE_RECORD',
              formId: tableData.form.id,
              specificId: specific.id,
              latitude: 0,
              longitude: 0,
              altitude: 0,
            }),
        };
      });
    return { name: 'Create', subMenu };
  }

  private ctxMenuEditRecord(recordId: string): MenuItemDef {
    return {
      name: 'Edit',
      action: () => this.tableEvent.emit({ type: 'EDIT_RECORD', recordId }),
    };
  }

  private ctxMenuUpdateRecord(recordId: string): MenuItemDef {
    return {
      name: 'Update',
      action: () => this.tableEvent.emit({ type: 'UPDATE_RECORD', recordId }),
    };
  }

  private ctxMenuDeleteRecord(recordList: Record[]): MenuItemDef {
    const recordIdsList = recordList.map((r) => r.recordId);
    return {
      name: 'Delete',
      action: () =>
        this.tableEvent.emit({ type: 'DELETE_RECORD_LIST', recordIdsList }),
    };
  }

  private ctxShowRecordOnMap(recordList: Record[]): MenuItemDef {
    const menuName = 'Show on Map';

    if (recordList.length === 0) return { name: menuName };

    const event: TableZoomToRecordEvent | TableZoomToRecordListEvent =
      recordList.length === 1
        ? {
            type: 'ZOOM_TO_RECORD',
            recordId: recordList[0].recordId,
            formId: recordList[0].formId,
            featureId: recordList[0].featureId,
          }
        : {
            type: 'ZOOM_TO_RECORD_LIST',
            recordIdsList: recordList.map((r) => r.recordId),
          };

    return {
      name: 'Show on Map',
      action: () => this.tableEvent.emit(event),
    };
  }

  private ctxMenuExport(tableData: TableData): MenuItemDef {
    const exportParams: BaseExportParams = {
      fileName: `${tableData.form.name}`,
      processCellCallback: processCellCallback,
    };
    return {
      name: 'Export',
      subMenu: [
        {
          name: 'Export CSV',
          action: () => this.gridApi.exportDataAsCsv(exportParams),
        },
        {
          name: 'Export Excel',
          action: () => this.gridApi.exportDataAsExcel(exportParams),
        },
      ],
    };
  }

  private ctxCopyCell(params: GetContextMenuItemsParams): MenuItemDef {
    return {
      name: 'Copy cell value',
      action: () => this.clipboard.copy(params.value),
    };
  }

  private ctxMigrateFeatures(recordList: Record[]): MenuItemDef {
    return {
      name: 'Migrate Features',
      action: () => {
        const recordIds = recordList.map((r) => r.recordId);
        this.tableEvent.emit({ type: 'TABLE_MIGRATE_FEATURES', recordIds });
      },
    };
  }
}

/**
 * Format cell for export (CSV or Excel).
 * If cell contains a Moment object than check headerClass: if it contains `dateFieldType` then is a DATE
 * FieldType and must be formatted without time (see {@link getSpecificColumns} ).
 * If not a Moment then return as is.
 * @param p
 */
function processCellCallback(p: ProcessCellForExportParams): string {
  if (p.value instanceof moment) {
    const isFieldDate = p.column.getColDef().headerClass === 'dateFieldType';
    return isFieldDate
      ? (p.value as Moment).format('YYYY-MM-DD')
      : (p.value as Moment).format('YYYY-MM-DD HH:mm:ss');
  }
  return p.value;
}

function rowNodesToRecords(rowNodes: IRowNode[], records: Record[]): Record[] {
  return rowNodes
    .map((node) => node.data.recordId)
    .map((recId) => records.find((record) => record.recordId === recId))
    .filter(notNullOrUndefined);
}

function userHasEditDeleteRights(
  record: Record,
  tableData: TableData | null,
): boolean {
  if (!tableData) return false;
  const isOwner = record.username === tableData.username;
  const isModOrAdmin = tableData.role === 'ADMIN' || tableData.role === 'MOD';
  return isOwner || isModOrAdmin;
}
