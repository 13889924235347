import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

export type ButtonType = 'fill' | 'border' | 'flat';

@Component({
    selector: 'ui-btn',
    template: `
    <div
      class="btn-container"
      (click)="onClick()"
      [ngClass]="[type, size]"
      [class.shadow]="shadow"
      [class.full-width]="fullWidth"
      [class.disabled]="disabled"
    >
      @if (icon) {
        @if (spin) {
          <fa-icon animation="spin" [icon]="icon" />
        } @else {
          <fa-icon [icon]="icon" />
        }
      }
      <div class="label">{{ label }}</div>
    </div>
  `,
    styles: [
        `
      .btn-container {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: var(--radius-1);
        width: fit-content;
      }

      .btn-container.small {
        padding: var(--spacing-1) var(--spacing-3);
      }

      .btn-container.medium {
        padding: var(--spacing-2) var(--spacing-4);
      }

      .btn-container.large {
        padding: var(--spacing-3) var(--spacing-4);
      }

      .full-width {
        width: auto;
      }

      fa-icon {
        margin-right: 10px;
      }

      .label {
        font-weight: var(--font-weight-3);
      }

      .btn-container:hover {
        color: #333333;
        cursor: pointer;
      }

      .fill {
        background-color: var(--primary-color);
        border: 1px solid var(--primary-color);
        color: #ffffff;
      }

      .fill:hover {
        opacity: 0.8;
        color: #ffffff;
      }

      .border,
      .flat {
        color: var(--primary-color);
        background-color: var(--bg-color);
      }

      .border {
        border: 1px solid var(--border);
      }

      .border:hover,
      .flat:hover {
        color: var(--primary-color);
        background-color: var(--hover);
      }

      .disabled {
        opacity: 0.6 !important;
        cursor: not-allowed !important;
      }
    `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class BtnComponent {
  @Input() label?: string;
  @Input() icon?: IconDefinition;
  @Input() type: ButtonType = 'fill';
  @Input() fullWidth?: boolean;
  @Input() disabled?: boolean;
  @Input() spin?: boolean;
  @Input() shadow?: boolean;
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Output() btnClick: EventEmitter<void> = new EventEmitter<void>();

  onClick(): void {
    if (!this.disabled) this.btnClick.emit();
  }
}
